import axios from "axios";
import { useQueryString } from "@/utils/query-string";

const axiosYtApiV2 = axios.create({
  baseURL: `${vueOptions.VUE_APP_BASE_URL_BACKEND || ""}/api/v2`,
  timeout: 10000,
  paramsSerializer: (params) => {
    const { stringifyQuery } = useQueryString();

    return stringifyQuery(params);
  },
});

export { axiosYtApiV2 };
