<template>
  <div class="service-field">
    <div class="row-top">
      <Multisuggest
        :value="data"
        :is-async="true"
        :is-searchable="true"
        :is-read-only="false"
        :placeholder="$t('serviceListMultisuggestSearch.placeholder')"
        @change-input="
          $emit('change-field', {
            index: index,
            value: $event,
          })
        "
      >
        <template v-slot:error-helper>
          <div v-if="validator.name.$error">
            <span>{{ $t("validation.required") }} </span>
          </div>
        </template>
      </Multisuggest>
    </div>
    <div class="row-bottom">
      <Btn
        tag="button"
        btn-type="button"
        class="btn-delete"
        outline="outline violet-2"
        color="color-violet-2"
        @btn-click="$emit('delete-btn-click')"
      >
        <template v-slot:content>
          <Icon icon="icon-trash" />
        </template>
      </Btn>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Btn from "@/components/atoms/btn/index.vue";
import Multisuggest from "@/components/molecules/multisuggest/index.vue";

export default {
  components: {
    Icon,
    Btn,
    Multisuggest,
  },
  props: {
    data: {
      type: Object,
      validator: (prop) =>
        typeof prop === "object" &&
        (typeof prop.name === "string" || prop.name === ""),
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.service-field {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.row-top {
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  @media (max-width: var.$md) {
    flex-direction: row;
  }
}

.btn-delete {
  padding: 8px 15px;
  border-radius: 8px;
  margin-left: 12px;
  height: 50px;
  width: 56px;
}
</style>
