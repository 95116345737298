export default {
  paymentRequisites: {
    popupChangeRequisites: {
      title: 'Change requisites',
      body: 'To change your details, you need to contact customer support.\n' +
        '\n' +
        'Please note that if you are working as an individual or a sole proprietor, your name and surname in the requisites must match the name and surname in your profile.\n',
      btn: 'Write to chat',
    },
  }
}
