const cancelTourRu = {
  cancelTour: {
    mainTitle: "Условия отмены тура",
    included: "Входит в стоимость тура",
    not_included: "Не входит в стоимость тура",
    custom_cancellation: "Расскажите клиентам про политику возвратов",
    refund_policy_placeholder: "Выберите условия отмены",
    conditions: {
      title: "Вы можете указать свой текст в поле ниже или",
      text: "воспользоваться помощником для указания уcловий отмены",
      attentionText: "",
    },
    otherConditions: {
      title: "Прочие условия",
    },
    paidServises: {
      title: "Платные услуги",
    },
    navButtons: {
      navPrev: {
        text: "Назад",
      },
      navNext: {
        text: "К деталям",
      },
    },
  },

  servicesList: {
    name: {
      placeholder: "Название услуги",
    },
    price: {
      placeholder: "Стоимость",
    },
    comment: {
      placeholder: "Комментарий к услуге",
    },
    addService: {
      text: "Добавить услугу",
    },
  },
};

export { cancelTourRu };
