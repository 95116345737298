import { page404 } from "@/assets/i18n/page404/ru.js";
import { tourSelector } from "@/assets/i18n/tour-selector/ru.js";
import { dateSelector } from "@/assets/i18n/date-selector/ru.js";
import { mainPreview } from "@/assets/i18n/main-preview/ru.js";
import { dates } from "@/assets/i18n/dates/ru.js";
import auth from "@/assets/i18n/auth/ru.js";
import matchmaking from "@/assets/i18n/matchmaking/ru.js";
import { notificationsCenter }  from "@/assets/i18n/notifications-center/ru.js"
import paymentRequisites from "@/assets/i18n/payment-requisites/ru.js";
import popupPay from "@/assets/i18n/popup-pay/ru.js";

import general from "@/assets/i18n/general/ru.js";
import settings from '@/assets/i18n/dashboard/settings_ru.js';
import dashboard from '@/assets/i18n/dashboard/ru.js';
import promotion from "@/assets/i18n/promotion/ru.js";

import lkReviewPopup from "@/assets/i18n/lk-review-popup/ru.js"

const ru = Object.assign({},
  page404,
  tourSelector,
  dateSelector,
  mainPreview,
  dates,
  auth,
  matchmaking,
  general,
  settings,
  dashboard,
  notificationsCenter,
  promotion,
  paymentRequisites,
  popupPay,
  lkReviewPopup
);


export { ru };
