import axios from "axios";
import { handleError } from "./interceptors/handle-error";

const baseURL = vueOptions.VUE_APP_BASE_URL_BACKEND || "";

const axiosYt = axios.create({
  baseURL,
  timeout: 10000,
});

const axiosYtV2 = axios.create({
  baseURL: `${baseURL}/api/v2`,
  timeout: 10000,
});

axiosYt.interceptors.response.use(handleError, (v) => v);
axiosYtV2.interceptors.response.use(handleError, (v) => v);

export { axiosYt, axiosYtV2 };
