export const imageUrl = (url, width = 0, height = 0) => {
  const transformations = [
    width ? `w-${width}` : "",
    height ? `h-${height}` : ""
  ].filter(Boolean).join(',');

  const tr = transformations ? `tr:${transformations}/` : "";

  // Удаляем лишние слеши в начале url, если они есть
  url = url.replace(/^\/+/, '');

  // Основной URL
  const baseCDN = process.env.VUE_APP_CDN || "https://cf.youtravel.me/";

  // Если VUE_APP_CDN равен '/', используем baseCDN без добавления преобразований
  if (process.env.VUE_APP_CDN === '/') {
    return `${baseCDN}${url}`;
  }

  // Возвращаем итоговый URL с преобразованиями
  return `${baseCDN}${tr}${url}`;
}
export const imageUrl2x = (url, width = 0, height = 0) => {
  return  imageUrl(url, width * 2, height * 2);
}
export const getImageCdnUrl = (url, width, height, x2 = false) => {
  url = url.replace(/^\/|\/$/g, '');
  url = url.replace("https://youtravel.me/", "").replace("https://tripsider.com/", "");
  if(fakeWindow.devicePixelRatio > 1 || x2 === true) {
    return imageUrl2x(url, width, height)
  }
  return imageUrl(url, width, height)
}
