const detailsRu = {
  comfortInfo: {
    hotel_name: "Название отеля",
    title: "Уровень комфорта в путешествии",
    description: "Укажите если уверены в 100% гарантии размещения",
    type_accommodation: {
      options: [
        { id: 1, name: "Апартаменты" },
        { id: 2, name: "Квартира" },
        { id: 3, name: "Отель" },
        { id: 4, name: "Шалаш" },
        { id: 5, name: "Теплотрасса" },
      ],
      placeholder: "Где планируется проживание",
    },
    type_allocation: {
      options: [
        { id: 1, name: "1-но местный номер" },
        { id: 2, name: "2-х местный номер" },
        { id: 3, name: "3-х местный номер" },
      ],
      placeholder: "Размещение",
    },
  },
  languageSpeaker: {
    title: "На каких языках говорят в туре",
    language: {
      options: [
        { id: 1, name: "Русский" },
        { id: 2, name: "Русский" },
        { id: 3, name: "Русский" },
        { id: 4, name: "Русский" },
        { id: 5, name: "Русский" },
      ],
      placeholder: "Выберите язык",
      close: {
        text: "Закрыть",
      },
    },
    animals_rest: "В программе не эксплуатируются животные",
  },
  hotelPhotos: {
    title: "Фотографии отеля",
    inputFile: {
      text: "Загрузить фото отеля",
    },
    alt: "Описание картинки",
    placeholder: "Описание картинки",
    name: "Название фотографии",
  },
  tooltip: "Содержание всплывающей подсказки",
  travelLevel: {
    title: "Уровень сложности путешествия",

    permittedAge: {
      label: "Допустимый возраст участия",
    },
    checkboxKids: {
      label: "Возможно участие с маленькими детьми",
    },
    ageRange: {
      from: "От",
      to: "До",
    },
    dictionary: {
      varyLow: "Очень низкий",
      low: "Низкий",
      normal: "Нормальный",
      good: "Хороший",
      excellent: "Отличный",
    },
  },
  tourDescription: {
    title: "Описание тура",
    description:
      "Расскажите клиентам что включает в себя тур, чем будут заниматься путешественники, какие впечатления получат",
    fields: {
      detailDescription: {
        placeholder: "Подробное описание",
      },
      videoLink: {
        placeholder: "Ссылка на видео youtube или vimeo",
      },
      impressions: {
        placeholder: "Впечатления",
      },
      whatDo: {
        placeholder: "Чем мы займемся в туре",
      },
    },
  },
  navButtons: {
    navPrev: {
      text: "Назад",
    },
    navNext: {
      text: "К день за днем",
    },
  },
};

export { detailsRu };
