const tourFaqEn = {
  tourFaq: {
    title: "Frequently asked questions",
    empty: "No&nbsp;questions added yet",
    question: "Question",
    reply: "Reply",
    new: "new",
    controls: {
      delete: "Delete question",
      save: "Save question",
      add: "Add question",
    },
    navButtons: {
      navPrev: {
        text: "Back",
      },
    },
  },
};

export { tourFaqEn };
