const contactTravelExpertEn = {
  contactTravelExpert: {
    title: "Ask the travel expert a&nbsp;question",
    message:
      "Hello! My&nbsp;name is&nbsp;{name}. I&nbsp;am very glad that you are interested in&nbsp;our trip. What questions do&nbsp;you have?",
    messageTour:
      "Hi! I&nbsp;am {name}. I&nbsp;am happy to&nbsp;answer your questions about the &laquo;{tour}&raquo; tour! <br>Ask me&nbsp;anything here",
    placeholder: "Write your message here",
    submit: "Send",
  },
};

export { contactTravelExpertEn };
