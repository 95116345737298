const dates = {
  dates: {
    timeAgo: "назад",
    timeAgoSecond1: "секунду",
    timeAgoSecond2: "секунды",
    timeAgoSecond5: "секунд",
    savedAgo: "Сохранено",
  },
};

export { dates };
