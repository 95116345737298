import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

const axiosSurvey = {
  getSurvey(surveyId) {
    return axiosYtApiV2.get(`matchmaking/survey/${surveyId}`);
  },
  getQuestions() {
    return axiosYtApiV2.get(`matchmaking/ext-survey/questions`);
  },
};

export { axiosSurvey };
