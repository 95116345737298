const photosEn = {
  photos: {
    title: "Tour photos",
    info: {
      title: "Requirements for posted photos",
      textFirst:
        "Add at least 7 photos, the first of them will be the cover of the tour in the preview.",
      textSecond:
        "Photo resolution must be at least 600 x 600 pixels, otherwise the photo will not be uploaded to the gallery.",
      textThird:
        "DO NOT use stock content or other photographers' materials without their permission. This is an infringement of copyright law and can lead to legal proceedings and fines.",
      footer: {
        text: "Learn more about where to look and how to properly use photos and videos for your tours",
        textLink: "look in the article.",
      },
    },
    buttons: {
      add: {
        text: "Add photo",
      },
      deleteAll: {
        text: "Delete all photos",
      },
    },
    navButtons: {
      navPrev: {
        text: "Back",
      },
      navNext: {
        text: "To FAQ",
      },
    },
  },
};
export { photosEn };
