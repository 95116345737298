import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";

import { en as assetsEn } from "@/assets/config/index/en.js";
import { ru as assetsRu } from "@/assets/config/index/ru.js";

import { en } from "@/plugins/i18n/config/en.js";
import { ru } from "@/plugins/i18n/config/ru.js";

Vue.use(VueI18n);

const languageInterface = (vueOptions.lang || Cookies.get('BITRIX_SM_LANGUAGE_ID')) || "ru";

const messages = {
  en: {
    ...en,
    ...assetsEn,
  },
  ru: {
    ...ru,
    ...assetsRu,
  },
};

const i18n = new VueI18n({
  // Key - language to use the rule for, `'ru'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choiceRaw {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceRaw)`
     * @returns a final choice index to select plural word by
     */
    ru: function (choiceRaw) {
      // this === VueI18n instance, so the locale property also exists here
      // '0 машин | 1 машина | 2 машины | 5 машин'

      let choice = Math.abs(choiceRaw);

      if (choice === 0) {
        return 0;
      }

      if (choice % 1 !== 0) {
        return 2;
      }

      choice %= 100;
      if (choice >= 5 && choice <= 20) {
        return 3;
      }

      choice %= 10;
      if (choice === 1) {
        return 1;
      }

      if (choice >= 2 && choice <= 4) {
        return 2;
      }

      return 3;
    },
  },
  locale: languageInterface, // установка локализации по умолчанию
  messages, // установка сообщений локализаций
});

export { i18n };

/*import { i18n } from "@/pages/tour-edit/plugins/i18n";
// TODO: нужно подумать как сделать логику получения разных
//       языков названия тура без связи двух сторов
// import language from "@/store/modules/language/index.js";

export default {
  namespaced: true,
  state: {
    // ru: {
    //   value: "Тестовый тур серфинг Балеал Португалия",
    // },
    // en: {
    //   value: "Surfing test tour Baleal Portugal",
    // },
    value: "Тестовый тур серфинг Балеал Португалия",
    placeholder: i18n.t("common.fields.tourName.placeholder"),*/
