import queryString from "query-string";


function serialize(obj, prefix) {
  let str = [],
      p;
  for (p in obj) {
    if (obj[p]) {
      let k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
      str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}

function stringifyQuery(object) {
  return serialize(object);
}

function parseString(string) {
  return queryString.parse(string)
}

export function useQueryString() {
  return {
    stringifyQuery,
    parseString
  }
}
