import { reactive, computed, toRef } from "vue";
import { useSharedStore } from "./shared-store";

const { serpFavorite } = useSharedStore();

const state = reactive({
  tours: [],
  favorites: [...serpFavorite.value],
});

const favoritesCount = computed(() => state.favorites.length);

function setFavorites(...tours) {
  state.favorites.push(tours);
}

function likeTour(id) {
  state.favorites.push(id);
}

function dislikeTour(id) {
  const index = state.favorites.indexOf(id);

  if (index >= 0) {
    state.favorites.splice(index, 1);
  }
}

function checkIsTourFavorite(id) {
  return state.favorites.indexOf(id) >= 0;
}

export function useTours() {
  return {
    favorites: toRef(state, "favorites"),
    favoritesCount,
    likeTour,
    dislikeTour,
    setFavorites,
    checkIsTourFavorite,
  };
}
