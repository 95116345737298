<template>
  <div class="button-select" :class="{ active }" @click="$emit('click')">
    <slot name="icon" />
    <span class="font-text" v-text="text"></span>
  </div>
</template>

<script>
export default {
  name: "button-select",
  props: {
    text: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-select {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var.$black;
  border: 1px solid var.$gray-dark;
  border-radius: 4px;
  padding: 7px 10px;
  margin: 0.5px;
  cursor: pointer;
}

.active {
  border: 1.5px solid var.$black;
  padding: 6.5px 9.5px;
  margin: 0;
}
</style>
