var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._b({tag:"component",staticClass:"button",class:[
    `_${_vm.color}`,
    `_${_vm.size}`,
    {
      'font-h2': _vm.size === 'default',
      'font-semibold': _vm.size === 'md',
      _outline: _vm.outline,
      _squared: _vm.squared,
      _multiline: _vm.multiline,
      _disabled: _vm.$attrs.disabled,
    },
  ],attrs:{"type":_vm.type},on:{"click":function($event){return _vm.$emit('click')}}},'component',_vm.$attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }