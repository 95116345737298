import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

export default {
  namespaced: true,

  state: {
    surveyId: null,
    isSending: false,
    isDone: false,
    isSaving: false,
    isQuestionnaireAnswers: false,
    additionalQuestions: [],
    surveyData: {
      city: "",
      journeyGoal: [] || "",
      age: "",
      tourFrequency: "",
      interests: [] || "",
      introvertedness: 0,
      questionnaireAnswers: [] || "",
      name: "",
      email: "",
    },
  },
  getters: {
    getParsedJourneyGoal(state) {
      if (state.surveyData?.journeyGoal) {
        return Array.isArray(state.surveyData.journeyGoal)
          ? state.surveyData.journeyGoal
          : JSON.parse(state.surveyData.journeyGoal);
      }
    },
    getParsedInterests(state) {
      if (state.surveyData.interests) {
        return Array.isArray(state.surveyData.interests)
          ? state.surveyData.interests
          : JSON.parse(state.surveyData.interests);
      }
    },
    getParsedAge(state) {
      console.log(state.surveyData.age)
      if (state.surveyData.age) {
        const age = "" + state.surveyData.age;
        if(age === "+60") {
          return [age.replace("+",''), '150'];
        } else if (age === "-18") {
          return ['', age.replace("-", '0')];
        } else {
          return age.split('-')
        }
      }
    },
  },
  mutations: {
    setSurveyId(state, surveyId) {
      state.surveyId = surveyId;
    },
    sendingStarts(state) {
      state.isSending = true;
      state.isDone = false;
    },
    sendingFinished(state) {
      state.isSending = false;
      state.isDone = true;
    },
    savingStarts(state) {
      state.isSaving = true;
    },
    savingFinished(state) {
      state.isSaving = false;
    },
    setSurveyData(state, surveyData) {
      state.surveyData = surveyData;
    },
    setIsDone(state, isDone) {
      state.isDone = isDone;
    },
    setIsSending(state, bool) {
      state.isSending = bool;
    },
    setIsQuestionnaireAnswers(state, bool) {
      state.isQuestionnaireAnswers = bool;
    },
    setAdditionalQuestions(state, questions) {
      state.additionalQuestions = questions;
    },
  },
  actions: {
    async init({ commit, dispatch }, guestId) {
      commit("setIsDone", false);
      // commit("setSurveyData", {});
      // TODO понять когда создовать новый опрос или использвать текущий
      try {
        const {
          data: {
            success,
            data: { surveyId },
          },
        } = await axiosYtApiV2.post("matchmaking/init-survey", {
          guestId
        });
        if (success) {
          commit("setSurveyId", surveyId);
          await dispatch("get");
        }
      } catch (e) {
        console.error(e);
      }
    },
    async get({ commit, state }) {
      if (!state.surveyId) {
        return;
      }
      try {
        const {
          data: { success, data: surveyData },
        } = await axiosYtApiV2.get("/matchmaking/survey/" + state.surveyId);
        if (success) {
          const parsedData = Object.entries(surveyData).reduce((acc, [key, val]) => {
            let parsedVal;
            try {
              parsedVal = JSON.parse(val);
            } catch (_) {
              parsedVal = val;
            }
            return {
              ...acc,
              [key]: parsedVal,
            }
          }, {});
          commit("setSurveyData", parsedData);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async saveFields({ state, dispatch, commit }, fields) {
      const serlizedFields = Object.entries(fields).reduce((acc, [key, val]) => {
        let serlizedVal;
        if (typeof val != "string" && typeof val != "number") {
          serlizedVal = JSON.stringify(val)
        }
        return {
          ...acc,
          [key]: serlizedVal || val,
        }
      }, {});
      try {
        commit('savingStarts');
        const { data: { success } } = await axiosYtApiV2.post(
          "/matchmaking/survey/" + state.surveyId,
          serlizedFields,
        );
        if (!success) {
          throw 'save_failed';
        }
      } catch (e) {
        if (e == 'save_failed') {
          throw e;
        }
        console.error(e);
      } finally {
        await dispatch("get");
        commit('savingFinished');
      }
    },
    async done({ commit, state }) {
      if (!state.surveyId) {
        return;
      }
      commit("sendingStarts");
      /*
        fake delay 2000ms
        TODO when API is ready replace with API call
      */
      await new Promise((resolve) => setTimeout(resolve, 2000));
      commit("sendingFinished");
    },
    setVal({ commit, state }, { code, value }) {
      commit("setSurveyData", {
        ...state.surveyData,
        [code]: value,
      });
    },
    setQuestions({ commit, state }, { code, value, isChecked }) {
      if (!state.surveyData[code]) {
        commit("setSurveyData", {
          ...state.surveyData,
          [code]: [value],
        });
      } else {
        let filtredData = state.surveyData[code].filter(
          (el) => el.questionId !== value.questionId
        );
        if (isChecked) {
          commit("setSurveyData", {
            ...state.surveyData,
            [code]: [...filtredData, value],
          });
        } else {
          commit("setSurveyData", {
            ...state.surveyData,
            [code]: [...filtredData],
          });
        }
      }
    },
  },
};
