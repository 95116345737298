var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item"},[_c(_vm.isLink ? 'a' : 'div',{tag:"component",staticClass:"menu-item__parent",class:{ 'menu-item__parent--active': _vm.isActive },attrs:{"href":_vm.isLink ? _vm.menu.link : false,"data-test":_vm.menu.slug},on:{"click":function($event){return _vm.clickParrent()}}},[_c('Icon',{staticClass:"menu-item__parent-icon",class:{ isActive: _vm.isActive },attrs:{"icon":_vm.menu.icon}}),_c('span',{staticClass:"menu-item__parent-title",class:{
        'menu-item__parent-title--no-icon': !_vm.menu.icon,
        isActive: _vm.isActive,
      },domProps:{"textContent":_vm._s(_vm.menu.title)}}),(_vm.menu.options.length)?_c('Icon',{staticClass:"menu-item__parent-arrow",class:{ 'menu-item__parent-arrow--active': _vm.dropdown, isActive: _vm.isActive },attrs:{"icon":"icon-arrow-right"}}):_vm._e()],1),_c('div',{staticClass:"menu-item__children",class:{ 'menu-item__children--visible': _vm.dropdown }},_vm._l((_vm.menu.options),function(child){return _c('a',{key:child.link,class:{
        isActiveChild: _vm.isActiveChild(child.link),
        isActive: _vm.isActive,
      },attrs:{"href":child.link,"data-test":child.slug},domProps:{"textContent":_vm._s(child.title)},on:{"click":function($event){return _vm.clickChild(child)}}})}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }