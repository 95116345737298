const lkBonusesEn = {
  lkBonuses: {
    banner: {
      title: 'Collect points and use them to&nbsp;pay for&nbsp;trips.',
      balance: 'Balance',
      more: 'Learn More',
    },
    stats: {
      title: 'Bonus Account',
      status: 'Your status',
      balance: 'Your balance',
      expenses: 'Tours purchased',
    },
    tooltipExpired: 'Spend {value} bonus {label} by {date}, or they will burn off',
    steps: {
      '1': {
        title: 'First Step',
        text: '1%&nbsp;of&nbsp;the cost is&nbsp;refundable as&nbsp;points after the first trip and review on&nbsp;the platform.',
      },
      '1.5': {
        title: 'Aspiring Traveler',
        text: '1,5% of&nbsp;the cost of&nbsp;each tour is&nbsp;refundable in&nbsp;points when the sum of&nbsp;all purchases exceeds $1,000',
      },
      '2': {
        title: 'Avid traveler',
        text: '2%&nbsp;of&nbsp;the cost of&nbsp;each tour is&nbsp;refundable in&nbsp;points when the sum of&nbsp;all purchases exceeds $2,000',
      },
      '2.5': {
        title: 'Explorer',
        text: '2,5% of&nbsp;the cost of&nbsp;each tour is&nbsp;refundable in&nbsp;points when the sum of&nbsp;all purchases exceeds $3,500',
      },
      '3': {
        title: 'Always on&nbsp;the&nbsp;go',
        text: '3%&nbsp;of&nbsp;the cost of&nbsp;each tour is&nbsp;refundable in&nbsp;points when the sum of&nbsp;all purchases exceeds $5,000',
      },
    },
    how: {
      get: {
        title: 'How do&nbsp;you receive points?',
        list: [
          {
            title: 'Going on&nbsp;trips',
            text: 'After each trip, 1% to 3% of the cost is returned as points. {linkConditions}',
          },
          {
            title: 'Leave reviews on&nbsp;your travels',
            tooltip: 'By&nbsp;participating, you consent to&nbsp;your review being used in&nbsp;<a href="https://tripsider.com/" target="_blank">Tripsider</a> channels.',
            text: 'Write detailed reviews about traveling with us on {linkTrustpilot} or {linkInstagram}, send screenshots to support Chat, and get 2,000 points on your account for each review.',
          },
          {
            title: 'Invite friends to&nbsp;travel with&nbsp;us',
            text: 'Invite friends to travel with us. You will receive 1,000 bonus points for each friend invited through your link to go on a trip. The friend will receive a discount of up to $10 on the first travel booking that qualifies for the program. {linkMore}',
          },
        ],
        conditions: 'More about the terms',
        more: 'More about the terms',
        trustpilot: 'Trustpilot',
        instagram: 'Instagram',
      },
      spend: {
        title: 'How do&nbsp;you pay for trips with points?',
        list: [
          'When calculating the order amount, you will see how many points you have in&nbsp;your account.',
          'It&nbsp;is&nbsp;up&nbsp;to&nbsp;you to&nbsp;decide whether to&nbsp;use the points or&nbsp;keep saving them.',
          'You can pay up&nbsp;to&nbsp;10% of&nbsp;the total amount with points.',
          'Points are kept for 1&nbsp;year from the date of&nbsp;accrual.',
        ],
      },
    },
    received: {
      title: 'Yay, bonus points!',
    },
    history: 'Transaction History',
    bonuses: 'points | point | points',
    notificationText: '{bonus} {bonusLabel} for tour {tourName} {dateStart}-{dateEnd}',
    notificationReceived: 'Fine',
  },
};

export { lkBonusesEn };
