const contactTravelExpertRu = {
  contactTravelExpert: {
    title: "Задайте свой вопрос эксперту",
    message:
      "Привет! Меня зовут {name}. Я&nbsp;с&nbsp;удовольствием помогу организовать ваше путешествие. Какие вопросы у&nbsp;вас возникли?",
    messageTour:
      "Добрый день! Меня зовут {name}. Отвечу на&nbsp;любой Ваш вопрос по&nbsp;туру &laquo;{tour}&raquo;!<br> Напишите, что Вас интересует ниже:",
    placeholder: "Напишите здесь свое сообщение",
    submit: "Спросить",
  },
};

export { contactTravelExpertRu };
