import Cookies from "js-cookie";
import QueryString from "query-string";

export default {
  data() {
    return {
      isIosApp: false,
    };
  },
  mounted() {
    const utmCookies = QueryString.parse(Cookies.get("utm"));

    const isUtmCookies =
      utmCookies.utm_medium === "mobileapp" ||
      utmCookies.utm_term === "mobileapp";

    const utmParams = QueryString.parse(location.search);
    const isUtmParams =
      utmParams.utm_medium === "mobileapp" ||
      utmParams.utm_term === "mobileapp";

    this.isIosApp = isUtmCookies || isUtmParams;

  },
  methods: {
    emitHeaderShow(bool) {
      if (!vueOptions.ssr) {
        if (fakeWindow.innerWidth < "943") {
          const headerShowEvent = new CustomEvent("header::show", {
            detail: bool,
          });
          fakeWindow.dispatchEvent(headerShowEvent);
        }
      }
    },
    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    },
    pushStateToHistory(state, unused, url) {
      this.pagePreviewEvent();
      history.pushState(state, unused, url);
    },
    pagePreviewEvent() {
      fakeWindow.dataLayer = fakeWindow.dataLayer || [];
      fakeWindow.dataLayer.push({
        event: "gtm-pageview-event",
        page_path: fakeWindow.location?.pathname + fakeWindow.location.search,
      });
    },
  },
};
