export const mmSerpRu = {
  mmSerp: {
    searchMenu: {
      searchButton: "Найти путешествия",
    },
    reset: "Сбросить параметры",
    changeSurvey: "Изменить анкету",
    fillSurvey: "Заполнить анкету",
    nothingFound: {
      title: "Ничего не найдено",
      text: "Измените анкету или сбросьте параметры (направление и даты)",
    },
    fewFound: "Хотите увидеть больше групп?",
    error: {
      findGroup: "Найдите группу",
      withClosest: "с близкими по духу людьми",
      errorText:
        "Мы не нашли вашу анкету. Заполните опросник за 2 минуты, и мы подберем вам идеальные для вас группы",
    },
    questionnaire: {
      weFound: "мы нашли",
      groupsForYou: "подходящих вам групп",
      yourSurvey: "Ваша анкета",
      change: "Изменить",
      changeData: "Изменить данные",
      journeyGoal: "Цель поездки",
      age: "Возраст",
      tourFrequency: "Как часто путешествуете",
      interests: "Интересы",
      introvertedness: "Интровертность",
    },
    questionnaireFill: {
      title: "Как сделать поиск точнее?",
      subtitle: "Заполните расширенную анкету – это займет 5-10 мин",
      submitButton: "Заполнить",
      filledTitle: "Вы заполнили расширенную анкету",
      filledSubtitle: 'Чтобы изменить ответы, нажмите кнопку "Изменить"',
      filledSubmitButton: "Изменить",
    },
    card: {
      already: "Уже",
      people: ["человек", "человека"],
      matches: "Совпадает",
      participants: "участников",
    },
    match_type: {
      tour_guide: "Совпадение с тревел-экспертом",
      best_match: "Лучшее совпадение",
      interesting_suggestion: "Это может быть интересно",
    },
  },
};
