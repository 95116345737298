import {
  getPersonalStatistics,
  refreshPersonalStatistics,
} from "@/services/scopes/axios-statistics.js";

import { i18n } from "@/plugins/i18n";

const orderByCode = [
  'popularity',
  'total',
  'sold',
  'price'
];

export default {
  namespaced: true,
  state: {
    personal: {},
    isLoading: false,
  },
  getters: {
    personal: state => ({
      dateUpdate: new Date(state.personal.dateUpdate || new Date).toLocaleDateString(getLang(), {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }),
      stats: (
        Object.entries(state.personal)
        .filter(([key]) => key != 'dateUpdate')
        .sort(([a], [b]) => orderByCode.indexOf(a) - orderByCode.indexOf(b))
        .map(([, val]) => val)
      ),
    }),
    isLoading: state => state.isLoading,
  },
  mutations: {
    setPersonal(state, personal) {
      state.personal = personal;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async fetchPersonalStatistics({ commit }) {
      commit('setIsLoading', true);
      const { success, data } = await getPersonalStatistics().finally(() => {
        commit('setIsLoading', false);
      });
      if (success) {
        commit('setPersonal', data);
      }
      commit('setIsLoading', false);
    },
    async refreshPersonalStatistics({ commit }) {
      commit('setIsLoading', true);
      const { success, data } = await refreshPersonalStatistics().finally(() => {
        commit('setIsLoading', false);
      });
      if (success) {
        commit('setPersonal', data);
      }
    },
  },
};

function getLang() {
  return i18n.locale || 'en';
}
