export default {
  promotion: {
    link: '/lk/promotion/tours/new',
    typeTitle: 'Выберите пакет продвижения',
    tourTitle: 'Выберите тур для продвижения',
    warrning3: 'Обратите внимание, если между датой промо и датой старта вашего тура меньше 20 дней, ваша заявка может не пройти модерацию',
    startDateTitle: 'Выберите дату старта продвижения',
    selectedTourWarning: 'Если список пуст, временно отметьте тур как гарантированный для оформления заказа',
    descriptionTitle: {
      stories: 'Чем уникален ваш тур?',
      individual_letter: 'Опишите, почему ваш опыт уникален?',
    },
    descriptionSub: 'Расскажите, что выделяет тур среди других предложений. Например, уникальные локации и активности, возможность путешествовать с детьми, комфортное жильё, варианты питания, или что-то еще, что часто отмечают путешественники.',
    descriptionWarning: {
      stories: 'Данное описание используем как&nbsp;основу для текстов во&nbsp;время рекламной кампании.',
    },
    descriptionLinkWarning: {
      zen: 'Если вы&nbsp;заказываете публикацию своей статьи в&nbsp;Дзен, приложите файл в&nbsp;формате .doc или ссылку на&nbsp;Google Диск',
      individual_letter: 'Чтобы сделать классное письмо, которое прочитают много людей, вам нужно определиться с&nbsp;форматом и&nbsp;заполнить заявку. Примеры рассылок и&nbsp;заявка&nbsp;&mdash; <a href="https://docs.google.com/document/d/1BZktKAW25j2RisVxpwWARqrD6hUutbTYKisVXDgxJHo/edit#heading=h.orth0s7fy5nz" target="_blank">в&nbsp;документе</a>.',
    },
    descriptionPlaceholder: 'Введите ответ (макс. 500 символов)',
    descriptionLinkPlaceholder: 'Вставьте ссылку',
    descriptionFiles: 'Нажмите <b style="color: #000">＋</b>, чтобы загрузить файл',
    photoTitle: 'Прикрепите фото из тура',
    photoDescription: 'Нажмите на <b style="color: #000">＋</b> и загрузите фото',
    reqTitle2: 'Требования к фото:',
    reqPhoto0: 'Фото с людьми, локациями, национальной кухней, интересными местами',
    reqPhoto1: 'Вес - не менее 700 кб',
    reqPhoto3: 'Формат – jpeg',
    reqPhoto4: 'Высокое разрешение',
    reqPhoto5: 'Ориентация — вертикальные фото',
    photoWarning: 'Если не удалось найти подходящее <b>по весу</b> фото, добавьте <b>одно</b> любое. Актуальные изображения загрузите в&nbsp;Google Диск, Яндекс.Диск или другой облачный сервис и&nbsp;разместите ссылку в&nbsp;разделе с&nbsp;видео.',
    linkTitle: 'Прикрепите видео из тура',
    LinkDescription: 'Нажмите на <b style="color: #000">＋</b> чтобы добавить ссылку',
    LinkDescription_1: ', и <span class="svg-inline svg-inline--16"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24" color="inherit" class="svg inherit "><path stroke="#121212" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" d="M5 7h14M18 7v11a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V7M15 3.75H9M10 11v5M14 11v5"></path></svg></span> чтобы удалить',
    reqLink0: 'Требования к видео:',
    reqLink1: 'С YouTube, Vimeo или другого видеохостинга',
    reqLink2: 'С вашего облачного хранилища (не забудьте дать публичный доступ для просмотра видео, иначе мы не сможем его загрузить).',
    reqLink3: 'Ориентация - вертикальные для сторис - соотношение сторон 9:16, 1080 на 1920 px',
    reqLink4: 'Формат загружаемого видео для сторис MOV или MP4',
    reqLink5: 'Максимальный размер файла для сторис: 90MB',
    linkPlaceholder: 'ссылка на видео',
    linkPlaceholderError: 'Введите ссылку',
    button: 'Перейти к оплате',
    datepicker: {
      title: 'Доступные для выбора даты выделены цветом',
      hint: {
        default: 'Ваша реклама будет более эффективной, если до&nbsp;старта тура более 20&nbsp;дней',
        general_letter: 'Дата рассылки может отличаться на&nbsp;1-2 дня',
      },
    },
  },
  promotionOnWebsite: {
    link: '/lk/promotion/tours/on_website',
    title: 'Продвижение тура на сайте',
    description: 'Вы можете привлечь дополнительных путешественников на ваших туры на YouTravel.me, заказав продвижение' +
      ' на основе аукциона. После оплаты вам на почту придет доступ в личный кабинет TopSort.com, где вы сможете' +
      ' управлять промотированием вашего тура. Минимальная сумма пополнения - 3000 рублей.',
    conditions: {
      title: 'Подать заявку можно, если:',
      1: 'Вы – верифицированный тревел-эксперт;',
      2: 'Ваш тур опубликован;',
      3: 'Даты вашего заезда гарантированы;',
      4: 'До старта вашего тура 20 дней с момента подачи этой заявки.',
    },
    button: 'Пополнить баланс для поднятия в Топ каталога',
  },
  promotionOptions: {
    header: 'Выберите вариант продвижения',
    promoOnCatalog: {
      title: 'Поднимите карточку',
      titlePurple: 'в топ каталога',
      description: '<b>Туры на первых позициях поиска привлекают больше путешественников.</b><br> ' +
        'Поднимите тур в топ каталога, самостоятельно управляя продвижением. Запускайте рекламные кампании на основе аукционной модели в реальном времени и сами решайте, сколько на них потратить.',

      descriptionSecondParagraph_1: "Чтобы получить доступ к ",
      descriptionSecondParagraph_2: "системе",
      descriptionSecondParagraph_3: ", пополните баланс на сумму от 3 000 руб. При единовременном пополнении от 15 000 руб. получите бонус +20%.",
      descriptionSecondParagraphLink : "https://app.topsort.com",
      button: 'Пополнить баланс для поднятия в Топ каталога',
    },
    promoOnWebsite: {
      title: "Расскажите о вашем туре ",
      titleGreen : "аудитории YouTravel.me",
      description: 'Аудитория YouTravel.me – <b>1,5 миллиона</b> человек в месяц. Закажите пост, серию сторис в наших соцсетях, статью, письмо для рассылки или сторис в результатах поиска на сайте, и о вас узнают тысячи путешественников.',
      button: 'Заказать размещение',
    },
    promoSocial: {
      title: 'Продвижение тура через соц.сети',
      description: 'Расскажите о туре в каналах YouTravel.me. Закажите публикацию поста, серии сторис или статьи, ' +
        'и о вашей поездке узнают тысячи путешественников.',
      conditions: {
        title: 'Подать заявку можно, если:',
        1: 'Ваш профиль верифицирован;',
        2: 'Тур уже опубликован;',
        3: 'Даты заезда гарантированы;',
        4: 'Ваша реклама будет более эффективной, если до старта тура более 20 дней.',
      },
      button: 'Продвинуть в соцсетях',
    },

    moreInfoBtn: {
      title: 'Узнать  больше о продвижении',
      link: 'https://ytme.atlassian.net/wiki/spaces/helpcenter/pages/2464842050/YouTravel.me#?????-',
    },
    base: {
      text: "Подробнее о продвижении читайте в ",
      textLink: "Базе Знаний.",
      purpleLink: "https://ytme.atlassian.net/wiki/spaces/helpcenter/pages/2864414730",
      textInfo_1: "Ваш профиль должен быть верифицирован, а туры опубликованы. Подробнее читайте в базе знаний",
      textInfo_2: "“Верификация тревел-экспертов”",
      textInfo_3: "https://ytme.atlassian.net/wiki/spaces/helpcenter/pages/2462908427/-",
    }
  },
};
