export function buttonTypeComputed() {
  if (this.$attrs.type) {
    return this.$attrs.type;
  }

  if (this.tag === "button") {
    return "button";
  }

  return undefined;
}
