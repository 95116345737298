import { reactive, toRef } from "vue";
import { ytVueGlobalStore } from "@/services/yt-vue-global-store";

const state = reactive({
  phone: ytVueGlobalStore.instantGet("phone"),
  menu: ytVueGlobalStore.instantGet("menu", []),
  datepicker: ytVueGlobalStore.instantGet("datepicker"),
  tourSelectorDefault: ytVueGlobalStore.instantGet("tourSelectorDefault"),
  mainMenu: ytVueGlobalStore.instantGet("main-menu", []),
  profileMenu: ytVueGlobalStore.instantGet("profile-menu", []),
  user: ytVueGlobalStore.instantGet("user"),
  mainScreen: ytVueGlobalStore.instantGet("mainScreen"),
  session: ytVueGlobalStore.instantGet("session"),
  functionalButtons: ytVueGlobalStore.instantGet("functional-buttons", []),
  language: ytVueGlobalStore.instantGet("language"),
  currency: ytVueGlobalStore.instantGet("currency"),
  tabs: ytVueGlobalStore.instantGet("tabs", []),
  ordersOptions: ytVueGlobalStore.instantGet("orders-options", []),
  headerButtons: ytVueGlobalStore.instantGet("header-buttons", []),
  headerSettings: ytVueGlobalStore.instantGet("header-settings"),
});

const serp = reactive({
  serpQuery: ytVueGlobalStore.instantGet("serp-query"),
  serpTours: ytVueGlobalStore.instantGet("serp-tours", []),
  serpFavorite: ytVueGlobalStore.instantGet("serp-favorites", []),
  serpSeoText: ytVueGlobalStore.instantGet("serp-seo-text"),
  serpCashbackPage: ytVueGlobalStore.instantGet("serp-cashback-page"),
});

export function useSharedStore() {
  return {
    phone: toRef(state, "phone"),
    menu: toRef(state, "menu"),
    datepicker: toRef(state, "datepicker"),
    tourSelectorDefault: toRef(state, "tourSelectorDefault"),
    mainMenu: toRef(state, "mainMenu"),
    profileMenu: toRef(state, "profileMenu"),
    user: toRef(state, "user"),
    mainScreen: toRef(state, "mainScreen"),
    session: toRef(state, "session"),
    functionalButtons: toRef(state, "functionalButtons"),
    language: toRef(state, "language"),
    currency: toRef(state, "currency"),
    tabs: toRef(state, "tabs"),
    headerButtons: toRef(state, "headerButtons"),
    ordersOptions: toRef(state, "ordersOptions"),
    headerSettings: toRef(state, "headerSettings"),

    serpQuery: toRef(serp, "serpQuery"),
    serpTours: toRef(serp, "serpTours"),
    serpFavorite: toRef(serp, "serpFavorite"),
    serpSeoText: toRef(serp, "serpSeoText"),
    serpCashbackPage: toRef(serp, "serpCashbackPage"),
  };
}
