import { ytVueGlobalStore } from "@/services/yt-vue-global-store.js";

const user = ytVueGlobalStore.instantGet('user');
export default {
  namespaced: true,
  state: {
    user: user,
    id: user.id ? user.id : "",
    isLoggedIn: user.isLoggedIn
      ? user.isLoggedIn
      : "",
    userRole: user.userRole
      ? user.userRole
      : "",
    messagesCount: user.messagesCount
      ? user.messagesCount
      : "",
    favorites: user.favorites,
    favoritesCount: user.favoritesCount
      ? user.favoritesCount
      : "",
    imageUrl: user.imageUrl?.replace("/", "")
      ? user.imageUrl
      : "",
    email: user.email || "",
    personalPhone: user.personalPhone || ""
  },
  actions: {
    fetchUser({ commit }) {
      ytVueGlobalStore.get('user').then(user => {
        commit('setUser', { user })
      })
    },

    addToFavorites({ commit }, tourId) {
      commit('addToFavorites', tourId);
    },

    removeFromFavorites({ commit }, tourId) {
      commit('removeFromFavorites', tourId);
    },

    setFavorites({ commit }, favorites) {
      commit('setFavorites', favorites);
    }
  },
  getters: {
    getUserId(state) {
      return state.id;
    },
    getUserLoginStatus(state) {
      return state.isLoggedIn;
    },
    getUserRole(state) {
      return state.userRole;
    },
    getUserImage(state) {
      return state.imageUrl;
    },
    getUserContact(state) {
      return {
        email: state.email ,
        personalPhone: state.personalPhone
      }
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.id = user.id;
      state.isLoggedIn = user.isLoggedIn;
      state.userRole = user.userRole;
      state.messagesCount = user.messagesCount;
      state.favoritesCount = user.favoritesCount;
      state.imageUrl = user.imageUrl?.replace("/", "");
    },
    // setUserPhone(state, phone) {
    //   state.personalPhone = phone;
    // },
    setFavoritesCount(state, count) {
      state.user.favoritesCount = count;
      state.favoritesCount = count;
    },

    setFavorites(state, favorites) {
      state.favorites = [...new Set(favorites)];
      state.favoritesCount = state.favorites.length;
    },

    addToFavorites(state, tourId) {
      const favorites = [...state.favorites];
      favorites.push(tourId);
      state.favorites = [...new Set(favorites)];
      state.favoritesCount = state.favorites.length;
    },

    removeFromFavorites(state, tourId) {
      const favorites = [...new Set(favorites)];
      const index = favorites.indexOf(tourId);

      if (index >= 0) {
        favorites.splice(index, 1);
      }
      state.favorites = favorites;
      state.favoritesCount = state.favorites.length;

    }
  },
};
