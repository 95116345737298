import { render, staticRenderFns } from "./thumbs-up.vue?vue&type=template&id=b69df654&scoped=true&"
var script = {}
import style0 from "./thumbs-up.vue?vue&type=style&index=0&id=b69df654&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b69df654",
  null
  
)

export default component.exports