const dayAfterDayRu = {
  dayAfterDay: {
    title: "Всего дней в туре",
    fieldDaysText: "Заполнено дней",
    fieldDaysFromText: "из",
    headerTextFirst:
      "Необходимо заполнить описание для всех дней тура, чтобы путешественники понимали из чего будет состоять поездка и как будут распределены активности по дням.",
    headerTextSecond:
      "Исключения составляют путешествия следующих типов: Арт, горнолыжный, йога, мама и малыш, серфинг, фитнес. Для этих типов вы можете заполнить описание одного дня и копировать его во все остальные дни.",

    cloneDay: {
      placeholder: "Выберите день",
      label: "Копировать описание из другого дня?",
      cloned: "Скопировано описание из "
    },
    tourDayForm: {
      day: {
        name: "Название дня*",
        placeholder: "Описание дня*",
      },
      photo: {
        uploadDayPhoto: "Загрузить фото дня",
        uploadHotelPhoto: "Загрузить фото отеля",
        limitText: "(макс. 3)",
        placeholder: "Название фотографии",
        limitError: "Максимальное количество фотографий равно 3",
      },
      location: {
        placeholder: "Добавить локацию",
      },
    },

    controls: {
      delete: {
        placeholder: "Удалить день",
      },
      save: {
        placeholder: "Сохранить день",
      },
      add: {
        placeholder: "Добавить день",
      },
    },
    navButtons: {
      navPrev: {
        text: "Назад",
      },
      navNext: {
        text: "К важно знать",
      },
    },
    inform: {
      diffDays: "Для добавления дня нужно увеличить количество дней в заездах",
    },
  },
};
export { dayAfterDayRu };
