const questionnaireEn = {
  questionnaire: {
    journeyGoal: "How do you envision your travels?",
    unexpectedDirectinos: "New, unexpected directions",
    activeRest: "Active rather than passive recreation",
    samePeople: "Company by age and interests",
    extendNetwork: "Expand your social circle",
    seeMany: "See a lot in a short time",

    tourFrequency: "How often do you travel?",
    more_once_month: "Once a month +",
    once_month: "Every month",
    "3-4_times_year": "Several times a year",
    once_year: "Once a year",
    rare_once_year: "Less than once a year",
    museums: "Museums",
    places: "Interesting places",
    architecture: "Architecture",
    history: "Story",
    art: "Art",
    theatre: "Theaters",
    shopping: "Shopping",
    bars: "Bars and restaurants",
    yacht: "Yacht trips",
    "art-space": "Creative spaces",
    child: "For kids",
    photo: "The photo",
    walk: "Hiking",
    nature: "Nature and parks",
    inductrial: "Industrial",
  },
};
export { questionnaireEn };
