<template>
  <div>
    <div
      class="input-wrapper"
      :class="inputWrapperClass"
      @click="$refs[getId].focus()"
    >
      <div v-if="icon" class="input-icon" @click="iconClickHandler">
        <Icon :icon="icon" :color="iconColor" />
      </div>
      <label
        v-if="!icon && label"
        class="input-label font-text-small"
        :for="getId"
      >
        {{ label }}
      </label>
      <input
        v-if="!phone"
        class="input"
        :class="{'input-password': inputType === 'password'}"
        :name="name"
        :id="getId"
        :ref="getId"
        :type="inputType"
        :value="value"
        :readonly="disabled"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        @input="$emit('input', $event.target.value), $emit('event', $event)"
        @change="$emit('change', $event.target.value)"
      />
      <no-ssr>
        <vue-tel-input
          v-if="phone"
          v-bind="phoneOptions"
          class="input"
          :id="getId"
          :ref="getId"
          :value="value"
          @input="$emit('input', $event)"
          @country-changed="phoneCountry = $event"
        >
          <template v-slot:arrow-icon="{ open }">
            <Icon
              class="input-icon-dropdown"
              icon="arrow-dropdown"
              :color="open ? '#9AAE2D' : '#9999A9'"
            />
          </template>
        </vue-tel-input>
      </no-ssr>
      <div v-if="eye" class="input-icon-password" @click="$emit('inputType')">
        <Icon icon="eye" :color="eyeColor" />
      </div>

      <div class="input-number__btns" v-if="numberBtns">
        <div
          class="input-number__btns__minus"
          :class="{ 'input-number__btns__minus--disabled': value <= min }"
          @click.stop="subtract()"
        ></div>
        <div class="input-number__btns__plus"
             :class="{ 'input-number__btns__plus--disabled': value >= max }"
             @click.stop="add()"></div>
      </div>
    </div>
    <div v-if="error" class="input-error">
      <span v-html="error"></span>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import noSSR from "vue-no-ssr";
//import { VueTelInput } from "vue-tel-input";
import { getUniqueId } from "@/assets/js/utils/get-unique-id";
//import "vue-tel-input/dist/vue-tel-input.css";
import { mapState } from "vuex";
const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input"),
    import(
      /* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input/dist/vue-tel-input.css"
    ),
  ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
  name: "input",
  components: {
    "no-ssr": noSSR,
    Icon,
    VueTelInput,
  },
  props: {
    inputType: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    numberBtns: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    phone: {
      type: Boolean,
      default: false,
    },
    eye: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      phoneCountry: null,
      isUpdated: false,
    };
  },
  computed: {
    ...mapState({
      currentLanguage: (state) => state.language.language.id,
    }),
    getId() {
      return getUniqueId("input");
    },
    inputWrapperClass() {
      return {
        "input-wrapper--label": this.label,
        "input-wrapper--disabled": this.disabled,
        "input-wrapper--icon": this.icon,
        "input-wrapper--invalid": this.error,
      };
    },
    eyeColor() {
      return this.inputType === "password" ? "#9999A9" : "#121212";
    },
    phoneOptions() {
      return {
        mode: "international",
        validCharactersOnly: true,
        autoDefaultCountry: false,
        inputOptions: {
          maxlength: this.phoneCountry?.iso2 === "RU" ? 16 : 20,
          showDialCode: true,
          autocomplete: this.autocomplete,
        },
        dropdownOptions: {
          showSearchBox: true,
          showFlags: true,
        },
      };
    },
  },
  methods: {
    subtract() {
      if (parseInt(this.value) > this.min) {
        this.$emit("input", parseInt(this.value) - 1);
      }
    },
    add() {
      //value >= max
      let value = this.value ? parseInt(this.value) : 0;
      let max = this.max ? parseInt(this.max) : 0;
      let continuation = true;


      if (max >= 0) {
        continuation = value < max;
      }

      console.log('add',value, this.value, this.max, continuation)
      if (!continuation) {
        return;
      }

      this.$emit("input", value + 1);
    },
  },
  updated() {
    let valueLength = this.value !== undefined ? this.value.toString().length : 0;
    if (
      valueLength <= 3 &&
      this.phone &&
      this.$refs[this.getId] &&
      !this.isUpdated
    ) {
      let countryCode = "";
      if (fakeWindow.geoInfo && fakeWindow.geoInfo.countryCode) {
        countryCode = fakeWindow.geoInfo.countryCode;
      } else {
        countryCode = this.currentLanguage === "ru" ? "ru" : "us";
      }
      this.$refs[this.getId].choose(countryCode);
      this.isUpdated = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  color: var.$black;
  border: none;
  padding: 0;
  transition: 200ms all;
  box-shadow: none !important;

  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &::label {
    color: var.$secondary;
    font-weight: normal;
    font-size: 16px;
  }

  &-password {
    width: calc(100% - 20px);
  }

  &.vue-tel-input {
    position: unset;
  }

  & >>> .vti {
    &__dropdown {
      padding: 0;
      background-color: unset;
      position: unset;

      &-item {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        border-radius: 6px;
        padding: 11px 12px;

        & .by {
          padding: 0;
        }

        & strong {
          font-family: "SF Pro Text";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.02em;
          color: var.$black;
        }

        & span {
          font-family: "SF Pro Text";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.02em;
          color: var.$text-dark;
        }

        &.highlighted {
          background-color: var.$gray-pale;
        }
      }

      &-list {
        top: 57px;
        width: 100% !important;
        padding: 8px;
        box-shadow: 1px 4px 20px rgb(153 153 169 / 35%);
        border-radius: 12px;
        border: 0px;

        & input {
          border: 1px solid #d1d7e8;
          border-radius: 8px;
          height: 40px;
          box-shadow: none !important;

          &:hover {
            box-shadow: none !important;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      &.open .input-icon-dropdown {
        transform: rotate(180deg);
      }

      &:hover {
        background-color: unset;

        & path {
          stroke: var.$green-hover;
        }
      }
    }
  }

  &-wrapper {
    position: relative;
    padding: 17.5px 16px;
    border: 1px solid var.$gray-dark;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    transition: 200ms all;
    display: flex;
    align-items: center;

    &--label {
      padding: 6px 16px 9px 16px;
    }

    &--disabled {
      cursor: default;

      & > input {
        color: var.$secondary;
        cursor: default;
      }
    }

    &--icon {
      display: flex;
      align-items: center;
      grid-gap: 0.5em;
      padding: 15px 16px;
    }

    &--invalid {
      border: 1px solid var.$red;
    }

    &:focus-within:not(.input-wrapper--disabled):not(.input-wrapper--invalid) {
      border: 1px solid var.$black;
    }

    &:hover:not(.input-wrapper--disabled) {
      cursor: text;
    }
  }

  &-label {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(231, 0, 0, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
    color: var.$secondary;
    pointer-events: none;
    transition: 200ms all;
  }

  &-error {
    color: var.$red;
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 0;
    padding: 0;
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    &-password {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    &-dropdown {
      transition: all 200ms;
    }
  }

  &-number__btns {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    display: flex;
    grid-gap: 4px;

    &__minus {
      position: relative;
      background: var.$green-main;
      height: 24px;
      width: 24px;
      border-radius: 4px;
      cursor: pointer;

      &::after {
        position: absolute;
        background: #fff;
        content: "";
        width: 12px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2px;
      }

      &--disabled {
        background: var.$gray-light;

        &::after {
          background: var.$gray-dark;
        }
      }
    }

    &__plus {
      position: relative;
      background: var.$green-main;
      height: 24px;
      width: 24px;
      border-radius: 4px;
      cursor: pointer;

      &::before {
        position: absolute;
        background: #fff;
        content: "";
        height: 12px;
        width: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2px;
      }

      &::after {
        position: absolute;
        background: #fff;
        content: "";
        width: 12px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2px;
      }

      &--disabled {
        background: var.$gray-light;

        &::after,
        &::before {
          background: var.$gray-dark;
        }
      }
    }
  }
}
</style>
