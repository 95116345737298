import { render, staticRenderFns } from "./tour-rating-star.vue?vue&type=template&id=27e4a090&scoped=true&"
var script = {}
import style0 from "./tour-rating-star.vue?vue&type=style&index=0&id=27e4a090&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e4a090",
  null
  
)

export default component.exports