<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 26" width="32" height="26">
    <path fill="currentColor"
          d="M24 0c-2.861 0-6.208 3.163-8 5.2C14.208 3.163 10.861 0 8 0 2.935 0 0 3.852 0 8.754 0 14.184 5.333 19.934 16 26c10.667-6.067 16-11.7 16-16.9C32 4.198 29.065 0 24 0z"/>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped></style>
