const popupIndividualRu = {
  popupIndividual: {
    title: "Запросить индивидуальный тур",
    text: "Отправьте заявку, чтобы организовать тур только для вас и&nbsp;ваших близких",
    message: "Ваши пожелания",
    price: "Примерная стоимость в&nbsp;день",
    priceMoreDay: "Примерный бюджет на ваши даты",
    submit: "Отправить заявку",
    done: {
      title: "Заявка отправлена",
      text: "Она появилась в&nbsp;вашем Личном кабинете.<br> Тревел-эксперт ее&nbsp;скоро рассмотрит и&nbsp;ответит",
      link: "Перейти к заявке",
    },
    suggest: {
      title:
        "Возможно, вам подойдет фильтр &laquo;Доступен индивидуальный тур&raquo;",
      text: "Такой тур можно организовать только для вас и&nbsp;ваших близких",
      button: "Закрыть",
    },
  },
};

export { popupIndividualRu };
