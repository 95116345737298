const profileMenuEn = {
  profileMenu: {
    dashboard: 'Dashboard',
    payments: 'Payments',
    myTours: 'My tours',
    chat: 'Chat',
    aboutMe: 'About me',
    account: 'Account',
    requisites: 'Payments & Payouts',
    promotion: 'Promotion',
    myProfile: 'My account',
    verification: 'Request for verification',
    orders: 'Orders',
    reviews: 'Reviews',
    myTeam: 'My team',
    myTravels: 'My travels',
    myToursMenuBooking: 'My bookings',
    arbitration: 'Arbitration',
    notifications: 'Notification Center',
    integrations: 'Integrations',
    requests: 'Booking Requests',
    tourists: 'Tourists',
    menuBonusAccount: 'Bonus account',
    menuBillingDetails: 'Bank details and accounts',
    menuDashboard: 'Dashboard',
    menuProfile: 'Personal info',
    menuNotifications: 'Notifications settings',
    menuTravellerProfile: 'Traveller profile',
    menuBonusTransactionHistory: 'Transaction history',
    personalAndTeam: 'Personal and team info',
    dashboardMainPage: 'Main page',
    regimeChangeTravel : 'To travel mode',
    regimeChangeExpert: "To host mode"
  }
};

export { profileMenuEn };
