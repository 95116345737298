export const mmSerpEn = {
  mmSerp: {
    searchMenu: {
      searchButton: "Find tours",
    },
    reset: "Reset",
    changeSurvey: "Change survey",
    fillSurvey: "Fill survey",
    nothingFound: {
      title: "Nothing is found",
      text: "Change the survey or reset the parameters (direction and dates)",
    },
    fewFound: "Want to see more groups?",
    error: {
      findGroup: "Find a group",
      withClosest: "with like-minded people",
      errorText:
        "We did not find your survey. Fill out the questionnaire in 2 minutes, and we will find the ideal groups for you",
    },
    questionnaire: {
      weFound: "we have found",
      groupsForYou: "groups suitable for you",
      yourSurvey: "Your survey",
      change: "Change",
      changeData: "Change data",
      journeyGoal: "Purpose of the visit",
      age: "Age",
      tourFrequency: "How often do you travel",
      interests: "interests",
      introvertedness: "Introversion",
    },
    questionnaireFill: {
      title: "How to make the search more accurate?",
      subtitle: "Fill out the extended survey - it will take 5-10 minutes",
      submitButton: "Fill in",
      filledTitle: "You filled out an extended questionnaire",
      filledSubtitle: 'To change the answers, click the "Edit" button',
      filledSubmitButton: "Edit",
    },
    card: {
      already: "already",
      people: ["person", "person"],
      matches: "Matches",
      participants: "participants",
    },
    match_type: {
      tour_guide: "Match with tour guide",
      best_match: "Best match",
      interesting_suggestion: "Could be interesting for you",
    },
  },
};
