const serpDetailEn = {
  serpDetail: {
    stickyAnchors: {
      description: "Description",
      route: "Route",
      conditions: "Conditions",
      allocation: "Allocation",
      accommodation: "Accommodation",
      services: "Services",
      important: "Important",
      reviews: "Reviews",
    },
    bookingSteps: {
      1: {
        text: "Choose a&nbsp;tour",
      },
      2: {
        text: "Book a&nbsp;place in&nbsp;the tour",
        tooltip:
          "For booking, only an advance payment of 15% of the tour cost is required.",
      },
      3: {
        text: "Pay securely",
        tooltip: `<p>
                   Card data is securely protected according to VISA, Mastercard and MIR standards.
                  </p>
                  <p>
                    <img
                      src="/static/i/serp-detail/payment-systems.png"
                      alt=""
                      class="u-img-responsive"
                      loading="lazy"
                    />
                  </p>`,
      },
    },
    generalInfo: {
      seeAllocations: "Where will we live",
      seeRoute: "See the route",
      language: "Language",
      attending: "Who is&nbsp;attending",
      age: "{countParticipants} people, {age_from}-{age_to}",
      route: "Route",
    },
    description: {
      activity: "Activities within the same location or change of several locations during the route, infrastructure-developed locations, periodic outings to nature.",
      allocation: "Regular housing with standard level of comfort, décor, and amenities.",
    },
    mainImpressions: "Main impressions",
    organizer: {
      organized: "Organized by",
      expert: "travel-expert",
      guide: "guide",
      fromTeam: "From {name}'s team",
    },
    program: {
      title: "Program",
      day: "Day",
      comment: "A comment from the tour leader",
    },
    conditions: "Conditions",
    allocation: "Allocation",
    accommodation: "Accommodation",
    services: "Services",
    important: {
      title: "Important information",
      common: "Tour preparation",
      customCancellation: "Cancellation terms",
      demands: "Guest requirements",
      visa: "Visa",
      prepay: {
        title: "Do&nbsp;I&nbsp;need to&nbsp;pay full amount at&nbsp;once?",
        text: "The amount of&nbsp;prepayment is&nbsp;determined by&nbsp;the Travel Expert and is&nbsp;indicated on&nbsp;the tour page. For the majority of&nbsp;tours standard prepayment does not exceed&nbsp;50% of&nbsp;the tour price. If&nbsp;you have any difficulties with payment, please contact the customer service.",
      },
      book: {
        title: "How do&nbsp;I book a&nbsp;trip",
        text: "Select your dates and click {action}. You’ll be redirected to the page with fast and secure payment by credit card or by Apple Pay / Google Pay. For tours with instant booking, you get an immediate confirmation. For other tours, you can reserve a place in the group by making an advance payment, then your booking is approved by the travel expert within 24 hours. If the booking is rejected, your prepayment will be refunded instantly. Please {ask} directly if you have any questions prior to booking.",
        action: "Reserve",
        ask: "feel free to contact the tour organizer",
      },
    },
    arrival: {
      title: "Arrival Information",
      start: "Start",
      finish: "Finish",
      localTime: "local time",
    },
    similar: "Similar tours",
    recentlyViewed: "You recently viewed",
    special: "Special offers",
    sameCountry: "More",
    sameType: "More",
    included: "Included",
    notIncluded: "Not included",
    complaint: {
      title: "Complain about the tour",
      complaintPopup: {
        next: "Next",
        uploadFile: "Upload file",
        step_1: {
          header: "Thank you for helping us<br> be better!",
          bodyHeader: "Please share with us<br>why you want to complain about the tour?",
          radio_1: "Duplicate tour",
          radio_2: "Copyright infringement",
          radio_3: "Inaccurate tour information"
        },
        step_2: {
          bodyHeader_1: "Add a link to the tour on the Youtravel.me platform that duplicates this tour",
          bodyHeader_2: "Tell us about the problem",
          bodyHeader_3: "Tell us what seems<br> inaccurate or suspicious to you",
          btnText: "Submit complaint",
          hint_1: "Complaints can only be made about tours on Youtravel.me",
          placeholder_1: "Link to tour on Youtravel.me",
          hint_2: "Minimum 20 and maximum 500 characters",
          placeholder_2: "Enter text",
          hint_3: 'The response will be sent to the Support Chat. If you want a refund for the tour, go to the <a href="/lk/arbitration/" target="_blank">Resolution</a> section.'
        },
        step_3: {
          bodyHeader: "Thank you for caring, your complaint has been received. We will respond within 24 hours",
          btnText: "Close",

        }
      }
    }
  },
};

export { serpDetailEn };
