export default {
  namespaced: true,
  state: {
    listActivePopups: [],
    isActive: false,
  },
  getters: {
    getLastActivePopup(state) {
      return state.listActivePopups[state.listActivePopups.length - 1];
    },
  },
  mutations: {
    showPopup(state, dataPopup) {
      state.isActive = true;
      state.listActivePopups.push(dataPopup);
    },
    hidePopup(state) {
      state.listActivePopups.pop();

      if (state.listActivePopups.length === 0) {
        state.isActive = false;
      }
    },
  },
  actions: {
    // TODO: перенести описание функций на jsdoc
    // в payload таже может быть data с данными для popup
    showPopupHandler(context, payload) {
      const { name } = payload;

      if (name === undefined) {
        context.commit("hidePopup");
      } else {
        context.commit("showPopup", payload);
      }
    },
  },
};
