const reviewsEn = {
  reviews: {
    rating: "Rating",
    reviews: "no reviews yet | {n} review | {n} reviews",
    all: "All reviews ({n})",
    reply: {
      show: "View the travel expert's response",
      hide: "Hide the travel expert's response",
      expertLabel: "Travel-expert",
    },
  },
};

export { reviewsEn };
