const priceDateRu = {
  priceDateTitle: "Все заезды тура",
  arrivalTitle: "Редактировать заезд",
  arrivalWarrning: 'Применим изменения во всех заездах ко всем полям, отмеченным фиолетовым кругом',
  datesArrival: "Даты",
  dateArrival: "Датa",
  dayArrival1: "день",
  dayArrival2: "дня",
  dayArrival3: "дней",
  spaces: "Места",
  coast: "Стоимость",
  exceptDate: "Исключить еще даты",
  priceComment: "Комментарий к стоимости",
  addArrival: "Добавить заезд",
  apply: {
    // please be careful by changin field keys because they are used in code
    // local/templates/youtravel/vue-app/src/components/templates/price-date/arrival-form.vue
    "active": "Ко всем активным",
    "after": "Ко всем последующим",
    "before": "Ко всем предшествующим",
    "none": "Не применять",
  },
  globalFields: {
    fastReservation: {
      label: "Моментальное бронирование",
    },
    currencyList: {
      options: [
        {
          name: "₽",
          value: "rub",
        },
        {
          name: "$",
          value: "usd",
        },
        {
          name: "€",
          value: "eur",
        },
      ],
      placeholder: "Валюта тура (все заезды)",
    },
  },
  priceDateSelectArrival: {
    dateFrom: {
      text: "с",
    },
    dateTo: {
      text: "по",
    },
    price: "Стоимость",
    from: "из",
    places: "Мест",
  },
  arrivalsList: {
    name: "Заезд",
    dateFrom: {
      text: "с",
      placeholder: "Дата с",
    },
    dateTo: {
      text: "по",
      placeholder: "Дата по",
    },
    isRepeatDate: {
      label: "Повторяющийся заезд",
    },
    repeatType: {
      placeholder: "Как повторять",
      options: [
        { name: "Каждую неделю", value: "weekly" },
        { name: "Каждый месяц", value: "monthly" },
      ],
    },
    repeatWeekly: {
      placeholder: "День недели",
      options: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    repeatDateTo: {
      placeholder: "Повторять до",
    },
    isExceptDates: {
      label: "Исключить даты",
    },
    exceptDateFrom: {
      placeholder: "Дата по",
    },
    exceptDateTo: {
      placeholder: "Дата по",
    },
    totalSpaces: {
      text: "Мест",
      textFrom: "из",
      placeholder: "Мест на заезде всего",
    },
    freeSpaces: {
      placeholder: "Свободные места",
    },
    priceValue: {
      text: "Стоимость",
      placeholder: "Стоимость тура",
    },
    prepayValue: {
      placeholder: "Предоплата",
    },
    prepayType: {
      options: ["%"],
      placeholder: "Ед.измерения",
    },
    remainingPayType: {
      options: [
        { name: "В день старта", value: "start" },
        { name: "За ... дней до старта", value: "before start" },
      ],
      placeholder: "Условия внесения оставшейся суммы",
    },
    priceComment: {
      placeholder: "Комментарий к стоимости",
    },
    isDiscountActive: {
      label: "Скидка",
    },
    discountValue: {
      placeholder: "Скидка",
    },
    discountType: {
      options: ["%", "eur", "usd", "rub"],
      placeholder: "Ед.измерения",
    },
    discountTime: {
      header: "Срок действия скидки",
    },
    discountFrom: {
      placeholder: "Дата с",
    },
    discountTo: {
      placeholder: "Дата по",
    },
    collectPostPay: {
      header: "Внесение оставшейся суммы",
      label: "Оплата оставшейся суммы через сайт",
    },
    postpayDelay: {
      placeholder: "Дни до старта",
    },
    flightIncluded: {
      label: "Включен перелет",
    },
    guarantee: {
      label: "Заезд гарантирован",
    },
    scouting: {
      label: "Разведка",
    },
    deleteButton: {
      text: "Удалить",
    },
    saveButton: {
      text: "Сохранить",
    },
    addButton: {
      text: "Добавить дату",
    },
  },
  navButtons: {
    navPrev: {
      text: "Назад",
    },
    navNext: {
      text: "К отмене и Доп. услуги",
    },
  },
};

export { priceDateRu };
