<template>
  <v-popover
    ref="popover"
    class="serp-tooltip"
    :class="{ '_wrap-after-text': wrapAfterText }"
    :delay="50"
    :offset="10"
    :popover-base-class="`serp-tooltip__popover _color-${color}`"
    popover-wrapper-class="serp-tooltip__popover-wrapper"
    popover-arrow-class="serp-tooltip__popover-arrow"
    :popover-inner-class="`serp-tooltip__popover-inner font-text-small ${innerClasses}`"
    :trigger="$attrs.trigger || 'hover focus'"
    :placement="$attrs.placement || 'bottom'"
    v-bind="$attrs"
    @show="onShow"
    @hide="$emit('hide')"
    @apply-hide="$emit('apply-hide')"
  >
    <slot>
      <div class="serp-tooltip__trigger-default" @click="$emit('click-trigger')">
        <svg-inline class="u-decor">
          <icon icon="icon-info" />
        </svg-inline>
      </div>
    </slot>
    <template #popover>
      <slot name="content" :popover="$refs.popover">
        <div v-html="content"></div>
      </slot>
    </template>
  </v-popover>
</template>

<script>
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  inheritAttrs: false,
  components: {
    SvgInline,
    Icon,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "default",
    },
    innerClasses: {
      type: String,
      default: "",
    },
    wrapAfterText: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onShow() {
      this.$refs.popover.$refs.arrow.innerHTML = `<div class="svg-inline svg-inline-tooltip-arrow">
                                                    <svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M7.2614 0.937295L0 7.03703H20L12.3396 0.881936C10.8508 -0.314372 8.72384 -0.291185 7.2614 0.937295Z" fill="white"/>
                                                    </svg>
                                                  </div>`;
      this.$emit('show');
    },
  },
};
</script>

<style lang="scss">
$b: ".serp-tooltip";
$p: #{$b}__popover;

$width-default: 16px;

#{$b} {
  display: flex;

  &._wrap-after-text {
    display: inline-flex;
    vertical-align: text-bottom;
    margin-left: -$width-default;
  }

  &__trigger-default {
    width: $width-default;
    max-width: 100%;
  }

  &__popover {
    display: block;
    z-index: 99999;
    backface-visibility: hidden;

    &:focus {
      outline: none;
    }

    &[aria-hidden="true"] {
      visibility: hidden;
      opacity: 0;
      transition: var.$transition-default;
    }

    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
      transition: opacity 200ms ease-out;
    }

    &-inner {
      box-sizing: border-box;
      max-width: calc(100vw - #{var.$inner-pd});
      padding: 9px 12px;
      background: var.$white;
      box-shadow: 1px 2px 60px rgba(var.$secondary, 0.35);
      border-radius: var.$rounded-8;

      @include mixin.size-md-min {
        max-width: 223px;
      }

      &._no-gutter {
        padding: 0;
      }

      p {
        &:not(:last-child) {
          margin-bottom: func.divide(8, 12) * 1em;
        }
      }
    }

    &-arrow {
      position: absolute;
      width: 20px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        right: -50%;
        bottom: 0;
        left: -50%;
      }

      #{$b}__popover[x-placement^="top"] & {
        top: calc(100% - 1px);
        transform: rotate(180deg);
      }

      #{$b}__popover[x-placement^="right"] & {
        right: calc(100% - 1px);
        transform: translate(50%, 0) rotate(-90deg) translate(0, -50%);
      }

      #{$b}__popover[x-placement^="bottom"] & {
        bottom: calc(100% - 1px);
      }

      #{$b}__popover[x-placement^="left"] & {
        left: calc(100% - 1px);
        transform: translate(-50%, 0) rotate(90deg) translate(0, -50%);
      }
    }
  }

  .trigger {
    cursor: pointer;
    width: 100%;
    display: block !important;

    &:focus {
      outline: none;
    }
  }
}
</style>
