<template>
  <div
    class="switch"
    :class="{ checked }"
    @click="$emit('switch', !checked)">
    <div>
      <Icon v-show="checked" icon="icon-check" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";

export default {
  name: "switch",
  components: {
    Icon,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  min-height: 24px;
  border-radius: 27px;
  background: #e4e4e8;
  padding: 0 2px;
  overflow: hidden;
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var.$white;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    box-shadow: 2px 2px 15px rgba(153, 153, 169, 0.15);
    transition: 200ms transform;

    & > svg {
      color: var.$white;
    }
  }

  &:hover:not(.checked) {
    & > div {
      box-shadow: 7.5px 1.5px 22.5px rgba(100, 100, 119, 0.4);
    }
  }
}

.checked {
  background: var.$green-main;

  & > div {
    transform: translateX(15px);

    & > svg {
      color: var.$green-dark;
    }
  }

  &:hover {
    background: #9aae2d;
  }
}
</style>
