import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";
import {i18n} from "@/plugins/i18n";

const axiosReviews = {

  getReviews(tourId, params) {
    return axiosYtApiV2.get(`tours/public/${tourId}/reviews`, {
      params: {
        lang: i18n.locale,
        ...params
      }
    });
  },

  getExpertReviews(expertId) {
    return axiosYtApiV2.get(`reviews/${expertId}`);
  },

  uploadPhoto(data) {
    return axiosYtApiV2.post(`reviews/upload-photo`, data);
  },

  sendExternal(data) {
    return axiosYtApiV2.post(`reviews/send-external`, data);
  },

  getBonusAmount(tourId) {
    return axiosYtApiV2.get(`reviews/bonus/${tourId}`, );
  },
};

export { axiosReviews };
