const popupInstallmentEn = {
  popupInstallment: {
    title: "Условия рассрочки",
    list: [
      "30 дней вы ничего не платите",
      "Процент после 30 дней – от 2.9%",
      "Срок рассрочки 365 дней",
      "Оформление онлайн по 1 документу за 2 минуты",
    ],
    accept: "Перейти к оформлению",
  },
};

export { popupInstallmentEn };
