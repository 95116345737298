export default {
  paymentRequisites: {
    popupChangeRequisites: {
      title: 'Изменить реквизиты',
      body: 'Для изменения реквизитов вам нужно обратиться в службу поддержки.\n' +
        '\n' +
        'Обратите внимание, если вы работаете как физическое лицо или Индивидуальный предприниматель, то имя и фамилия в реквизитах должны совпадать с именем и фамилией в профиле.\n',
      btn: 'Написать в чат',
    },
  }
}
