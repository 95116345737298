const ytEvents = {
  track(category, name, label, props) {
    if (typeof fakeWindow.trackEvent == "function") {
      try {
        fakeWindow.trackEvent(name, category, label, props);
      } catch (err) {
        console.error(err);
      }
    }
  },
  trackFbq(name, options, eventId) {
    if (typeof fakeWindow.trackEventFbq == "function") {
      try {
        fakeWindow.trackEventFbq(name, options, eventId);
      } catch (err) {
        console.error(err);
      }
    }
  },
  trackVk(tourID, tourPrice, currency, businessValue) {
    if (typeof fakeWindow.trackEventVK == "function") {
      try {
        fakeWindow.trackEventVK(tourID, tourPrice, currency, businessValue);
      } catch (err) {
        console.error(err);
      }
    }
  },
  trackEventRrApiSetEmail(userEmail, stockId) {
    if (typeof fakeWindow.trackEventRrApiSetEmail == "function") {
      try {
        fakeWindow.trackEventRrApiSetEmail(userEmail, stockId);
      } catch (err) {
        console.error(err);
      }
    }
  },
  trackEventRrApiAddToBasket(tourId, stockId) {
    if (typeof fakeWindow.trackEventRrApiAddToBasket == "function") {
      try {
        fakeWindow.trackEventRrApiAddToBasket(tourId, stockId);
      } catch (err) {
        console.error(err);
      }
    }
  }
};

export { ytEvents };
