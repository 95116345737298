export function sizeSm() {
  return this.$mq === "size-sm";
}

export function sizeMd() {
  return this.$mq === "size-md";
}

export function sizeMdMax() {
  return sizeSm.call(this) || sizeMd.call(this);
}

export function sizeLg() {
  return this.$mq === "size-lg";
}

export function sizeLgMax() {
  return sizeMdMax.call(this) || sizeLg.call(this);
}

export function sizeXl() {
  return this.$mq === "size-xl";
}

export function sizeXlMax() {
  return sizeLgMax.call(this) || sizeXl.call(this);
}

export function sizeXxl() {
  return this.$mq === "size-xxl";
}

export function sizeXxlMax() {
  return sizeXlMax.call(this) || sizeXxl.call(this);
}
