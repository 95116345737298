export default function getVuelidateErrorMessage({
  $error,
  $each,
  required,
  email,
}) {
  if ($error) {
    if (required === false) {
      return this.$t("validation.required");
    }

    if (email === false) {
      return this.$t("validation.email");
    }

    // @todo add your validation rules

    if ($each && $each.$error) {
      return getVuelidateErrorMessage(
        Object.values($each.$iter).find((iteration) => iteration.$error)
      );
    }

    return this.$t("validation.incorrect");
  }

  return "";
}
