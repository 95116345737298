<template>
  <div class="survey_modal">
    <div class="survey_modal-header" v-if="!isDone">
      <template v-if="isSending">
        <div class="survey_modal-title">
          {{ $t("survey.pleaseWait") }}
        </div>
      </template>
      <template v-else>
        <button
          class="survey_modal-action survey_modal-action--back"
          v-show="currentStepIdx != 0"
          v-on:click="prevStep"
        ></button>
        <div class="survey_modal-title">
          {{ $t("survey." + currentStep.code) }}
        </div>
        <button
          class="survey_modal-action survey_modal-action--close"
          v-on:click="hidePopup"
        ></button>
      </template>
    </div>
    <div
      v-if="!isDone && !isSending"
      class="survey_modal-progress"
      :style="{ '--progress': progress }"
    ></div>
    <div class="survey_modal-body">
      <template v-if="isSending">
        <div class="survey_modal-loading"></div>
        <div class="survey_modal-under_text">
          {{
            $t("survey.waitAWhile", {
              duration: 2,
            })
          }}
        </div>
      </template>
      <template v-else-if="isDone">
        <button
          class="survey_modal-action survey_modal-action--close"
          v-on:click="hidePopup"
        ></button>
        <div class="survey_modal-done_icon"></div>
        <div class="survey_modal-done_text">{{ $t("survey.ready") }}</div>
        <div class="survey_modal-done_text--sub">
          {{ $t("survey.weFound", { count: total }) }}
        </div>
        <div class="survey_modal_buttons-group">
          <a
            class="
              survey_modal-next
              survey_modal-next--ready
              survey_modal-next--done
              survey_modal-next--link
            "
            target="_blank"
            href="/matchmaking/"
            @click="showGroups"
          >
            {{ $t("survey.showMatches") }}
          </a>
          <span
            v-if="checkAdditionalButtonVisible"
            class="
              survey_modal-next survey_modal-next--done survey_modal-next--link
            "
            @click="getMoreQuestions"
          >
            {{ $t("survey.refineCriteria") }}
          </span>
        </div>
      </template>
      <template v-else>
        <div
          class="survey_modal-discription"
          v-if="currentStep.code == 'introvertedness'"
        >
          {{ $t("survey.introvertedness_description") }}
        </div>
        <template v-if="currentStep.code == 'city'">
          <input
            class="survey_modal-input"
            type="text"
            @input="setPersonalInfo('city', $event.target.value)"
            :value="surveyData.city"
            :placeholder="$t('survey.city')"
          />
        </template>
        <template v-else-if="currentStep.code == 'personal_info'">
          <input
            class="survey_modal-input"
            type="text"
            @input="setPersonalInfo('name', $event.target.value)"
            :value="surveyData.name"
            :placeholder="$t('survey.name')"
          />
        </template>
        <template v-else-if="currentStep.code === 'questionnaireAnswers'">
          <div class="survey_modal_additional-questions">
            <span class="survey_modal_question">
              {{ currentStep.question.question }}
            </span>
            <Checkbox
              class="survey_modal_checkbox"
              v-for="answer in currentStep.options"
              v-bind:key="answer.id"
              @change="(e) => changeCheckbox(e, answer)"
              :label="answer.answer"
              :is-checked="checked === answer.id"
            />
          </div>
        </template>

        <template v-else>
          <div
            class="survey_modal-options"
            :class="'survey_modal-options--' + currentStep.code"
          >
            <button
              class="survey_modal-option"
              v-for="option in currentStep.options"
              :disabled="
                isSaving ||
                (isLimitReached && !currentStep.answers.includes(option))
              "
              :class="{
                'survey_modal-option--active':
                  currentStep.answer == option ||
                  (currentStep.answers || []).includes(option),
              }"
              :key="option"
              v-on:click="selectOption(option)"
            >
              <span
                v-if="
                  currentStep.code == 'age' ||
                  currentStep.code == 'introvertedness'
                "
              >
                {{
                  (option[0] == "-" &&
                    $t("survey.lessThan") + option.slice(1)) ||
                  (option[0] == "+" &&
                    $t("survey.moreThan") + option.slice(1)) ||
                  option
                }}
              </span>
              <span v-else>
                {{ $t("survey." + option) }}
              </span>
            </button>
          </div>
        </template>

        <template v-if="hasButton">
          <button
            class="survey_modal-next"
            v-if="currentStepIdx < steps.length - 1"
            :disabled="isSaving || !isCurrentStepValid"
            @click="nextStep"
          >
            <template v-if="currentStep.maxAnswer && !isLimitReached">
              <span v-if="!currentStep.answers.length">
                {{
                  $t("survey.chooseMaxCount", {
                    count: currentStep.maxAnswer,
                  })
                }}
              </span>
              <span v-else>
                {{
                  $t("survey.chooseMore", {
                    count: currentStep.maxAnswer - currentStep.answers.length,
                  })
                }}
              </span>
            </template>
            <span v-else-if="currentStep.code == 'city'">
              {{ $t("survey.select") }}
            </span>
            <span v-else>{{ $t("survey.continue") }}</span>
          </button>
          <button
            class="survey_modal-next survey_modal-next--ready"
            v-else
            :disabled="isSaving || !isCurrentStepValid"
            @click="submit"
          >
            {{ $t("survey.ready") }}
          </button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import steps from "./steps";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import dataCollector from "@/plugins/dataCollector/index";
import { ytEvents } from "@/plugins/analytics";
import Checkbox from "@/components/atoms/checkbox";
import { axiosSurvey } from "@/services/scopes/axios-survey";
import { useSerp } from "@/state/serp";

export default {
  data: () => ({
    steps,
    currentStepIdx: 0,
    checked: false,
    total: 0,
    questionsLength: 0,
  }),
  components: {
    Checkbox,
  },
  watch: {
    currentStep(value) {
      if (value?.answer) {
        this.checked = value.answer.answerId;
      }
    },
    isDone(value) {
      if (value) {
        if (!this.questionsLength) {
          this.trackEvent(
            "Match making survey",
            "Show next step",
            "middle success page"
          );
        } else {
          this.trackEvent(
            "Match making survey",
            "Show next step",
            "final success page"
          );
        }
      }
    },
  },
  computed: {
    ...mapState("survey", [
      "isSending",
      "isDone",
      "isSaving",
      "surveyId",
      "surveyData",
      "isQuestionnaireAnswers",
    ]),
    ...mapState({
      currency: (state) => state.currency.currency,
      language: (state) => state.language.language,
    }),
    ...mapGetters({
      parsedAge: "survey/getParsedAge",
      parsedJourneyGoal: "survey/getParsedJourneyGoal",
      parsedInterests: "survey/getParsedInterests",
    }),
    actualSteps() {
      return this.steps.map((step) => {
        const stepData =
          this.surveyData[step.code] || (step.answers && []) || null;
        if (step.code === "personal_info") {
          const { name, city } = this.surveyData;
          return {
            ...step,
            city,
            name,
          };
        }
        if (step.code === "questionnaireAnswers") {
          let answer;
          const { questionnaireAnswers } = this.surveyData;
          if (
            questionnaireAnswers &&
            typeof questionnaireAnswers !== "string"
          ) {
            answer = questionnaireAnswers.find(
              (el) => el.questionId === step.question.id
            );
          }
          return {
            ...step,
            answer: answer ? answer : null,
          };
        }
        return {
          ...step,
          ...(step.answers ? { answers: stepData } : { answer: stepData }),
        };
      });
    },
    isLimitReached() {
      return (
        this.currentStep.maxAnswer &&
        this.currentStep.maxAnswer <= this.currentStep.answers.length
      );
    },
    stepsLength() {
      return this.steps.length;
    },
    progress() {
      return (this.currentStepIdx + 1) / this.steps.length;
    },
    currentStep() {
      return this.actualSteps[this.currentStepIdx];
    },
    hasButton() {
      return (
        this.currentStep.code === "city" ||
        this.currentStep.code === "personal_info" ||
        this.currentStep.code === "questionnaireAnswers" ||
        this.currentStep.answers
      );
    },
    isCurrentStepValid() {
      if (this.currentStep.maxAnswer) {
        return this.isLimitReached;
      }
      if (this.currentStep.code === "personal_info") {
        return this.currentStep.name;
      }
      if (this.currentStep.answers) {
        return this.currentStep.answers.length;
      }
      return this.currentStep.answer;
    },
    checkAdditionalButtonVisible() {
      return this.currentStepIdx < 6;
    },
    toursQuery() {
      const age = this.parsedAge;
      const ageFrom = age ? `age[from]=${+age[0]}&` : "";
      const ageTo = age ? `age[to]=${+age[1]}&` : "";
      const journey_expectations = [];
      const journey_frequency = this.surveyData.tourFrequency
        ? `journey_frequency=${this.surveyData.tourFrequency}&`
        : "";
      const interests = [];
      this.parsedJourneyGoal.forEach((el) =>
        journey_expectations.push(`journey_expectations[]=${el}&`)
      );
      this.parsedInterests.forEach((el) =>
        interests.push(`interests[]=${el}&`)
      );
      return `${ageFrom}${ageTo}${journey_frequency}${journey_expectations.join(
        ""
      )}currency=${this.currency.id.toLowerCase()}&${interests.join("")}lang=${
        this.language.id
      }&take=1&skip=0`;
    },
  },
  methods: {
    ...mapActions("survey", [
      "init",
      "save",
      "done",
      "setVal",
      "saveFields",
      "setQuestions",
    ]),
    ...mapMutations({
      hidePopup: "popup/hidePopup",
      setIsDone: "survey/setIsDone",
      setIsSending: "survey/setIsSending",
      sendingStarts: "survey/sendingStarts",
    }),
    trackEvent(category, name, label, props) {
      ytEvents.track(category, name, label, props);
    },
    showGroups() {
      if (this.questionsLength) {
        this.trackEvent("Match making survey", "Click Lead me to list final");
      } else {
        this.trackEvent("Match making survey ", "Click Lead me to list fast");
      }
    },
    async nextStep() {
      if (
        this.isCurrentStepValid &&
        this.currentStepIdx < this.steps.length - 1
      ) {
        try {
          await this.saveCurrentStep();
        } catch (e) {
          console.error(e);
          return;
        }
        if (this.currentStep.code === "questionnaireAnswers") {
          this.trackEvent(
            "Match making survey",
            "Show next step",
            this.currentStep.code,
            {
              questionId: this.currentStep.question.id,
            }
          );
        } else {
          this.trackEvent(
            "Match making survey",
            "Show next step",
            this.currentStep.code
          );
        }
        this.currentStepIdx++;
      }
    },
    async saveCurrentStep() {
      if (this.currentStep.code === "personal_info") {
        await this.saveFields({
          name: this.currentStep.name,
        });
      } else if (this.currentStep.code === "questionnaireAnswers") {
        await this.saveFields({
          [this.currentStep.code]: this.surveyData.questionnaireAnswers,
        });
      } else {
        await this.saveFields({
          [this.currentStep.code]:
            this.currentStep.answers || this.currentStep.answer,
        });
      }
    },
    prevStep() {
      this.trackEvent(
        "Match making survey",
        "Click back button",
        this.currentStep.code
      );
      this.currentStepIdx--;
    },
    setPersonalInfo(field, value) {
      this.setVal({ code: field, value });
    },
    selectOption(option) {
      const code = this.currentStep.code;
      if (!this.currentStep.answers) {
        this.setVal({ code, value: option });
        this.nextStep();
        return;
      }
      if (this.currentStep.answers.includes(option)) {
        const filtered_answers = this.currentStep.answers.filter(
          (answer) => answer !== option
        );
        this.setVal({ code, value: filtered_answers });
        return;
      }
      this.setVal({ code, value: [...this.currentStep.answers, option] });
    },
    async submit() {
      await this.getTours();
      await this.saveCurrentStep();
      await this.done();
    },
    addAdditionalQuestions(data, target) {
      data.forEach((el) => {
        const options = [];
        el.answers.forEach((answer) =>
          options.push({
            answer: answer.answer,
            color: answer.color,
            id: answer.id,
          })
        );
        target.push({
          code: "questionnaireAnswers",
          question: { id: el.id, question: el.question },
          options: options,
          answer: null,
        });
      });
    },
    async getMoreQuestions() {
      this.trackEvent("Match making survey", "Click Give more data");
      this.sendingStarts();
      axiosSurvey
        .getQuestions()
        .then(({ data: { data } }) => {
          this.addAdditionalQuestions(data, this.steps);
          this.questionsLength = data.length;
          this.currentStepIdx++;
          this.setIsSending(false);
        })
        .catch((e) => console.log(e));
    },
    setChecked(options) {
      this.checked = options.id;
    },
    changeCheckbox(e, answer) {
      this.setChecked(answer);
      this.setQuestionToSurvey(answer, e);
    },
    setQuestionToSurvey(option, e) {
      const id = this.currentStep.question.id;
      const code = this.currentStep.code;
      const answer = {
        questionId: id,
        answerId: option.id,
        color: option.color,
      };
      this.setQuestions({ code, value: answer, isChecked: e });
    },
    async getTours() {
      const { getGroupsSearch } = useSerp();

      try {
        const data = await getGroupsSearch(this.toursQuery);

        this.total = data.total;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    // init survey
    let guestId = dataCollector.getCookie("BX_USER_ID");
    if (!guestId) {
      guestId = dataCollector.guid();
      dataCollector.setCookie("BX_USER_ID", guestId);
    }
    this.init(guestId);
    if (this.isQuestionnaireAnswers) {
      this.currentStepIdx = this.stepsLength - 1;
      this.getMoreQuestions();
    }
  },
};
</script>

<style lang="scss">
//Стили из global.scss
.g-visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
//
.survey_modal-body {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
.survey_modal {
  background: #fff;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 20%);
  border-radius: 16px;
  position: absolute;
  padding: 32px;
  min-width: 440px;
  max-width: 788px;
  z-index: 999; /* TODO FIX */
  --action2: 28px;

  /* center XY */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.survey_modal-header {
  position: relative;
  margin-bottom: 16px;
  height: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.survey_modal-title {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  line-height: 19px;
  position: absolute;
  width: 100%;
  padding: 0 var(--action2);
}

.survey_modal-action {
  width: var(--action2);
  height: var(--action2);
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  z-index: 1;
}
.survey_modal-action--close {
  margin-left: auto;
  background-image: url(/local/templates/youtravel/assets/i/close.svg);
  background-position: center center;
}
.survey_modal-action--back {
  background-image: url(/local/templates/youtravel/assets/i/back.svg);
}

.survey_modal-progress {
  height: 6px;
  background: #eeeef2;
  margin-bottom: 36px;
  border-radius: 2px;
  --progress: 0;
  position: relative;
  overflow: hidden;
}
.survey_modal-progress::before {
  display: inline-block;
  content: "";
  width: calc(var(--progress) * 100%);
  height: 100%;
  position: absolute;
  background: #abc232;
  transition: width 200ms ease;
}

.survey_modal-discription {
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  margin: 0 auto 24px auto;
  letter-spacing: -0.02em;
  width: 70%;
}
.survey_modal_question {
  display: block;
  margin: 24px auto;
  max-width: 376px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}
.survey_modal_checkbox {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  & .label {
    width: 100%;
    padding: 10px;
    margin-bottom: 12px;
    font-family: "SF Pro Display";
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    border-radius: 12px;
    border: 1.5px solid var.$gray-dark;
    transition: 0.2s linear all;
    box-shadow: 2px 2px 15px rgba(153, 153, 169, 0.15);
    &:hover {
      transition: 0.2s linear all;
      box-shadow: 1px 4px 20px rgba(153, 153, 169, 0.35);
    }
    @media (min-width: var.$size-md) {
      width: 658px;
      padding: 21px;
    }
  }
  & .checkbox {
    border: 1px solid var.$gray-dark;
  }
  & .input:checked + .label .checkbox {
    border-color: var.$green-main;
    background-color: var.$green-main;
  }
  & .input:checked + .label {
    border-color: var.$green-main;
  }
}

input.survey_modal-input {
  font-size: 14px;
  padding: 19px 16px;
  border: 1px solid #d1d7e8;
  box-sizing: border-box;
  border-radius: 8px;
}
input.survey_modal-input:not(:last-child) {
  margin-bottom: 12px;
}
input.survey_modal-input:focus {
  border-color: #d1d7e8;
}

.survey_modal-next {
  border: none;
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  background: #eeeef2;
  padding: 15px 24px;
  border-radius: 50px;
  display: block;
  text-transform: none;
  margin: 24px auto 0 auto;
  cursor: pointer;
}

@media (max-width: 480px) {
  .survey_modal-next {
    -webkit-margin-after: 80px;
  }
}

.survey_modal-next--link {
  cursor: pointer;
  margin: 24px auto 0 auto;
}
.survey_modal-next.survey_modal-next--ready {
  background: #abc232;
  color: #fff;
}
.survey_modal-next:disabled {
  background: #eeeef2;
  color: #9999a9;
}

.survey_modal-options {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
  justify-content: center;
}

.survey_modal-option {
  background: #fff;
  padding: 17px 20px;
  border: 1.5px solid #eeeef2;
  box-sizing: border-box;
  box-shadow: 2px 2px 15px rgba(153, 153, 169, 0.15);
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 18px;
  margin: 8px;
  color: #000;
  text-transform: none;
  transition: 0.2s linear all;
}
.survey_modal-option:disabled {
  background: #fff;
  color: rgba(16, 16, 16, 0.3);
  border: 1.5px solid #eeeef2;
}
.survey_modal-option:hover:enabled {
  border: 1.5px solid #d1d7e8;
  box-shadow: 1px 4px 20px rgba(153, 153, 169, 0.35);
  transition: 0.2s linear all;
}
.survey_modal-option--active:enabled {
  border: 1.5px solid #abc232;
  transition: 0.2s linear all;
  &:hover {
    border: 1.5px solid #abc232;
    transition: 0.2s linear all;
  }
}
.survey_modal_additional-questions {
  height: 75%;
  overflow-y: auto;
}
.survey_modal-options--journeyGoal {
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}
.survey_modal-options--journeyGoal .survey_modal-option {
  max-width: 100%;
}

.survey_modal-options--age,
.survey_modal-options--tourFrequency {
  --columns: 3;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
}

.survey_modal-options--tourFrequency {
  --columns: 2;
}

.survey_modal-loading {
  width: 60px;
  margin: 96px auto;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.survey_modal-loading::after,
.survey_modal-loading::before {
  content: "";
  display: inline-block;
}
.survey_modal-loading::before {
  width: 60px;
  height: 60px;
  background-image: url(/local/templates/youtravel/assets/i/loader.svg);
  background-size: contain;
  background-position: center;
  animation: rotate 700ms infinite ease-in;
}
.survey_modal-loading::after {
  width: 24px;
  height: 24px;
  background-image: url(/local/templates/youtravel/assets/i/time.svg);
  background-size: contain;
  position: absolute;
}
.survey_modal .multiselect__spinner {
  background: transparent;
  margin-top: 5px;
}
.survey_modal_buttons-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (min-width: var.$size-lg) {
    flex-direction: row;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.survey_modal-under_text {
  text-align: center;
  font-size: 14px;
}

.survey_modal-done_icon {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: url(/local/templates/youtravel/assets/i/done-star.svg);
}

.survey_modal-done_text {
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  margin-top: 16px;
}

.survey_modal-done_text--sub {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 12px;
}

.survey_modal .multiselect__input,
.survey_modal .multiselect__input:focus {
  border: 0;
  box-shadow: none;
}
.survey_modal .field-city .multiselect__tags {
  border-radius: 8px;
  border: 1px solid #d1d7e8;
  background: #fff;
  min-height: 52px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.survey_modal .multiselect__option--highlight {
  color: #121212;
  background: #f6f7fa;
}
.survey_modal .multiselect__content-wrapper {
  border-radius: 8px;
  background: #ffffff;
  border: 1.5px solid #eeeef2;
  box-sizing: border-box;
  box-shadow: 2px 2px 15px rgba(153, 153, 169, 0.15);
  border-radius: 8px;
  margin-top: 5px;
}
.survey_modal .multiselect__single {
  padding: 7px 10px;
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .survey_modal {
    height: auto;
    width: 100%;
    min-width: 100%;
    transform: unset;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
  }
  .survey_modal-options {
    overflow-y: auto;
  }
  .survey_modal-options--journeyGoal {
    flex-grow: 1;
    width: auto;
  }
}
</style>
