<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :type="type"
    class="button-close"
    :class="{ _disabled: $attrs.disabled }"
    @click="$emit('click')"
  >
    <span class="button-close__icon u-stroke-deep-current">
      <svg-inline tag="span">
        <icon icon="close" />
      </svg-inline>
    </span>
  </component>
</template>

<script>
import { buttonTypeComputed } from "@/assets/js/composables/buttonTypeComputed";
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  components: {
    SvgInline,
    Icon,
  },
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "button",
    },
  },
  computed: {
    type: buttonTypeComputed,
  },
};
</script>

<style lang="scss" scoped>
.button-close {
  @include mixin.clickable();
  box-sizing: border-box;
  width: 12px;
  max-width: 100%;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  color: var.$secondary;
  transition: var.$transition-fast;

  &:hover {
    color: var.$green-main;
  }

  &:active {
    color: var.$green-dark;
    transition-duration: 0s;
  }

  &:disabled,
  &._disabled {
    pointer-events: none;
  }

  &::after {
    @include mixin.full-pos(percentage(func.divide(-10, 12)));
    content: "";
    display: block;
    position: absolute;
  }

  &__icon {
    display: block;
    width: 100%;

    path {
      transition: var.$transition-fast;
    }
  }
}
</style>
