import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";
import { i18n } from "@/plugins/i18n";

const axiosOffers = {
  getOffers(tourId, query = "limit=20") {
    return axiosYtApiV2.get(`tours/${tourId}/offers?${query}`);
  },
  getPublicOffers(tourId, query = "limit=20") {
    return axiosYtApiV2.get(`tours/public/${tourId}/offers?${query}`);
  },
  getMinPriceOffer(tourId) {
    return axiosYtApiV2.get(`tours/${tourId}/min-price-offer`);
  },
  getOffer(tourId, offerId) {
    return axiosYtApiV2.get(`/tours/${tourId}/offers/${offerId}`);
  },

  getPublicOffer(tourId, offerId) {
    return axiosYtApiV2.get(`/tours/public/${tourId}/offers/${offerId}?lang=${i18n.locale}`);
  },



  postAddOffer(tourId, data) {
    return axiosYtApiV2.post(`tours/${tourId}/offers`, data);
  },

  postDeleteOffer(tourId, offerId) {
    return axiosYtApiV2.post(`/tours/${tourId}/offers/${offerId}/delete`);
  },

  postUpdateOffer(tourId, offerId, data) {
    return axiosYtApiV2.post(`tours/${tourId}/offers/${offerId}`, data);
  },

  predictSave(tourId, offerId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/offers/${offerId}/predict-save`, data);
  },

  sendBrochureOfOffer(tourId, offerId, email) {
    return axiosYtApiV2.post(
      `tours/public/${tourId}/offers/${offerId}/send-brochure`,
      {
        email,
      }
    );
  },

  batchUpdateOffers(tourId, type, fromId, data) {
    return axiosYtApiV2.post(`tours/${tourId}/offers/batch-update`, {
      type,
      fromId,
      data,
    }, {
      timeout: 1000 * 60
    });
  },
};

export { axiosOffers };
