<template>
  <div class="service-field">
    <div class="row-top">
      <div class="row-fields">
        <Field
          class="service-title-input"
          :value="data.fields.title.value"
          :placeholder="data.fields.title.placeholder"
          @field-input="inputFieldHandler($event, 'title')"
        >
          <template v-slot:error-helper>
            <div v-if="validator.fields.title.value.$error">
            <span v-if="!validator.fields.title.value.minLength">
              {{ $t("validation.minLength.text") }}
              {{ validator.fields.title.value.$params.minLength.min }}
              {{ $t("validation.minLength.minLengthText") }}.
            </span>
              <span v-else>{{ $t("validation.required") }}</span>
            </div>
          </template>
        </Field>
        <Field
          class="service-cost-input"
          :value="data.fields.price.value"
          :placeholder="data.fields.price.placeholder"
          :inputType="'number'"
          @field-input="inputFieldHandler($event, 'price')"
        >
          <template v-slot:error-helper>
            <div v-if="validator.fields.price.value.$error">
            <span v-if="!validator.fields.price.value.numeric">
              {{ $t("validation.onlyNumeric.text") }}
            </span>
              <span v-else>{{ $t("validation.required") }}</span>
            </div>
          </template>
        </Field>
      </div>

      <Btn
        tag="button"
        btn-type="button"
        class="action-btn-delete"
        outline="outline violet-2"
        color="color-violet-2"
        @btn-click="$emit('delete-btn-click')"
      >
        <template v-slot:content>
          <Icon icon="icon-trash" />
        </template>
      </Btn>
    </div>
    <div class="row-bottom">
      <FieldArea
        class="service-comment"
        :value="data.fields.comment.value"
        :placeholder="data.fields.comment.placeholder"
        @field-input="inputFieldHandler($event, 'comment')"
      />
      <Btn
        v-if="!saved"
        tag="button"
        btn-type="button"
        class="action-btn-save"
        bg-color="bg-green-1"
        color="color-neutral-100"
        :loading="loadingService"
        @btn-click="$emit('save-btn-click')"
      >
        <template v-slot:content>
          <Icon v-show="!saved" icon="icon-checkmark" />
        </template>
      </Btn>
    </div>
  </div>
</template>

<script>
import Field from "@/components/atoms/field/index.vue";
import FieldArea from "@/components/atoms/field-area/index.vue";
import Icon from "@/components/icons/index.vue";
import Btn from "@/components/atoms/btn/index.vue";
import { mapState } from "vuex";

export default {
  components: {
    Field,
    FieldArea,
    Icon,
    Btn,
  },
  data() {
    return {
      saved: false,
    };
  },
  props: {
    data: {
      type: Object,
      validator: function (prop) {
        return (
          typeof prop.fields.title.value === "string" &&
          typeof prop.fields.title.placeholder === "string" &&
          typeof prop.fields.price.value === "string" &&
          typeof prop.fields.price.placeholder === "string"
        );
      },
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.tourEdit.loading,
    }),
    loadingService() {
      return !!this.loading["service" + this.index];
    },
  },
  watch: {
    loadingService(value) {
      value && this.setSaved();
    },
  },
  methods: {
    inputFieldHandler(value, fieldName) {
      this.saved = false;
      this.$emit("change-field", {
        index: this.index,
        fieldName,
        value,
      });
    },
    setSaved() {
      this.saved = true;
    },
  },
  mounted() {
    this.data?.id && this.setSaved();
  },
};
</script>

<style lang="scss" scoped>
.service-field .row-top {
  margin-bottom: 16px;
  flex-direction: row-reverse;
  display: flex;

  @media (min-width: var.$md) {
    flex-direction: row;
  }
}

.service-title-input {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 16px;

  @media (min-width: var.$md) {
    margin-bottom: 0;
    margin-right: 12px;
    width: 100%;
  }
}

.row-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;

  @media (min-width: var.$md) {
    flex-direction: row;
  }
}
.action-btn-delete {
  border-radius: 8px;
  min-width: 56px;
  margin-left: 0;
  margin-right: 12px;
  @media (min-width: var.$md) {
    height: 48px;
    margin-left: 12px;
    margin-right: 0;
  }
}
.action-btn-save {
  border-radius: 8px;
  min-width: 56px;
  margin-left: 0;
  margin-right: 12px;
  height: 48px;
  @media (min-width: var.$md) {
    margin-left: 12px;
    margin-right: 0;
  }
}

.service-cost-input {
}

.service-comment {
  margin-bottom: 15px;
  width: 100%;
}

.btn-text-saved {
  line-height: 24px;
}

.row-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: var.$md) {
    flex-direction: row;
    //width: auto;
  }
}
</style>
