<template>
  <component
    class="svg"
    :is="getIcon"
    :color="color"
    :class="`${color} ${classMod}`"
  ></component>
</template>

<script>
import AppTopMenu from "./list/app-top-menu.vue";
import AppQrCode from "./list/app-qr-code.vue";
import AlarmCircle from "./list/alarm-circle.vue";
import IconShield from "./list/icon-shield.vue";
import IconShieldChecked from "./list/icon-shield-checked.vue";
import IconCheckmark from "./list/icon-checkmark.vue";
import IconCheck from "./list/icon-check.vue";
import IconBigCheck from "./list/icon-big-check.vue";
import IconEye from "./list/icon-eye.vue";
import IconBell from "./list/icon-bell.vue";
import IconFolder from "./list/icon-folder";
import IconNextInside from "./list/icon-next-inside.vue";
import IconMore from "./list/icon-more.vue";
import IconDelete from "./list/icon-delete.vue";
import IconCopy from "./list/icon-copy.vue";
import IconExternalLink from "./list/icon-external-link.vue";
import IconTranslate from "./list/icon-translate.vue";
import IconInfo from "./list/icon-info.vue";
import IconInfoFill from "./list/icon-info-fill.vue";
import IconTrash from "./list/icon-trash.vue";
import IconPlus from "./list/icon-plus.vue";
import IconToolbarBold from "./list/icon-toolbar-bold.vue";
import IconToolbarItalic from "./list/icon-toolbar-italic.vue";
import IconToolbarStrike from "./list/icon-toolbar-strike.vue";
import IconToolbarHeading1 from "./list/icon-toolbar-heading-1";
import IconToolbarHeading2 from "./list/icon-toolbar-heading-2";
import IconToolbarParagraph from "./list/icon-toolbar-paragraph.vue";
import IconToolbarUnorderedList from "./list/icon-toolbar-unordered-list.vue";
import IconToolbarOrderedList from "./list/icon-toolbar-ordered-list.vue";
import IconToolbarLink from "./list/icon-toolbar-link.vue";
import IconToolbarUnlink from "./list/icon-toolbar-unlink.vue";
import IconToolbarImage from "./list/icon-toolbar-image.vue";
import IconToolbarUndo from "./list/icon-toolbar-undo.vue";
import IconToolbarRendo from "./list/icon-toolbar-rendo.vue";
import IconAttention from "./list/icon-attention";
import IconAttentionSmall from "./list/icon-attention-small.vue";
import IconAttentionTriangle from "./list/icon-attention-triangle";
import IconDropdown from "./list/icon-dropdown.vue";
import IconGear from "./list/icon-gear.vue";
import IconPen from "./list/icon-pen.vue";
import IconUserMessage from "./list/icon-user-message.vue";
import IconUser from "./list/icon-user.vue";
import IconCurrency from "./list/icon-currency.vue";
import IconLanguage from "./list/icon-language.vue";
import IconPlane from "./list/icon-plane.vue";
import IconDocument from "./list/icon-document.vue";
import IconSupport from "./list/icon-support.vue";
import IconFavorites from "./list/icon-favorites.vue";
import IconTravels from "./list/icon-travels.vue";
import IconChat from "./list/icon-chat.vue";
import IconFeedback from "./list/icon-feedback.vue";
import IconExit from "./list/icon-exit.vue";
import IconExpertProfile from "./list/icon-expert-profile.vue";
import IconMyTours from "./list/icon-my-tours.vue";
import IconMyOrders from "./list/icon-my-orders.vue";
import IconMyWallet from "./list/icon-my-wallet.vue";
import IconGift from "./list/icon-gift.vue";
import IconMyTeam from "./list/icon-my-team.vue";
import IconModeration from "./list/icon-moderation.vue";
import IconMyTravels from "./list/icon-my-travels.vue";
import IconArrowRight from "./list/icon-arrow-right.vue";
import IconArrowUp from "./list/icon-arrow-up.vue";
import IconSearch from "./list/icon-search.vue";
import Icon404 from "./list/icon-404.vue";
import IconGeo from "./list/icon-geo.vue";
import IconGeoSharp from "./list/icon-geo-sharp.vue";
import GeoCircleError from "./list/geo-circle-error.vue";
import LogoYoutravel from "./list/logo-youtravel.vue";
import GooglePlayButton from "./list/google-play-button.vue";
import IconRotateLeft from "./list/icon-rotate-left.vue";
import IconRotateRight from "./list/icon-rotate-right.vue";
import IconMirrorX from "./list/icon-mirror-x.vue";
import IconMirrorY from "./list/icon-mirror-y.vue";
import IconMenuBurger from "./list/icon-menu-burger.vue";
import IconCalendar from "./list/icon-calendar.vue";
import IconComment from "./list/icon-comment.vue";
import IconReject from "./list/icon-reject.vue";
import IconUpload from "./list/icon-upload";
import IconMir from "./list/icon-mir.vue";
import IconWarning from "./list/icon-warning.vue";
import IconWarningFill from "./list/icon-warning-fill.vue";
import AppStoreButton from "./list/app-store-button.vue";
import LogoYoutravelColor from "./list/logo-youtravel-color.vue";
import LogoTs from "./list/logo-ts.vue";
import LogoTsColor from "./list/logo-ts-color.vue";
import Search from "./list/search.vue";
import Calendar from "./list/calendar.vue";
import Setting from "./list/setting.vue";
import SettingActive from "./list/setting-active.vue";
import Back from "./list/back.vue";
import Dropdown from "./list/dropdown.vue";
import Close from "./list/close.vue";
import CloseCircleFill from "./list/close-circle-fill.vue";
import CloseMini from "./list/close-mini.vue";
import Heart from "./list/heart.vue";
import Clock from "./list/clock.vue";
import Lightning from "./list/lightning.vue";
import Star from "./list/star.vue";
import Crown from "./list/crown.vue";
import TourRatingStar from "./list/tour-rating-star.vue";
import LoaderCircle from "./list/loader-circle.vue";
import LoaderCircleLarge from './list/loader-circle-large.vue';
import Email from "./list/email.vue";
import User from "./list/user.vue";
import Eye from "./list/eye.vue";
import ArrowDropdown from "./list/arrow-dropdown.vue";
import Triangle from "./list/triangle.vue";
import Person from "./list/person.vue";
import IconCalendarNew from "./list/icon-calendar-new.vue";
import IconFilledHeart from "./list/icon-filled-heart.vue";
import Info from "./list/info.vue";
import IconAngleUp from "./list/icon-angle-up.vue";
import IconAngleUpMini from "./list/icon-angle-up-mini.vue";
import Minus from "./list/minus.vue";
import Plus from "./list/plus.vue";
import Checkmark from "./list/checkmark.vue";
import CheckmarkThick from "./list/checkmark-thick.vue";
import CheckmarkCircle from "./list/checkmark-circle.vue";
import CheckmarkCircleOutline from "./list/checkmark-circle-outline.vue";
import Thumbup from "./list/thumbup.vue";
import Ok from "./list/ok.vue";
import Phone from "./list/phone.vue";
import Shield from "./list/shield.vue";
import ThumbsUp from "./list/thumbs-up.vue";
import PhoneTalk from "./list/phone-talk.vue";
import ShieldOutline from "./list/shield-outline.vue";
import InputDropdown from "./list/input-dropdown.vue";
import InputEye from "./list/input-eye.vue";
import InputClose from "./list/input-close.vue";
import InputSearch from "./list/input-search.vue";
import arrowDropdown from "./list/arrow-dropdown.vue";
import inputEyeOff from "./list/input-eye-off.vue";
import Refresh from "./list/refresh.vue";
import addChat from "./list/add-chat.vue";
import addDocs from "./list/add-docs.vue";
import books from "./list/books.vue";
import satellite from "./list/satellite.vue";
import smile from "./list/smile.vue";
import addIcon from "./list/add-icon.vue";
import profile from "./list/profile.vue";
import requisites from './list/requisites.vue';
import trash from './list/trash.vue';
import errorClose from './list/error-close.vue';
import Flag from "./list/flag.vue";
import Envelope from "./list/envelope.vue";
import Building from "./list/building.vue";
import RocketLaunch from "./list/rocket-launch.vue";
import RocketLanding from "./list/rocket-landing.vue";
import MapPin from "./list/map-pin.vue";
import BubbleCorner from "./list/bubble-corner.vue";
import errorRedTriangle from "./list/error-red-triangle.vue";
import VerificationIntro from "./list/verification-intro.vue";
import s7 from "./list/s7.vue";
import IconAttentionPurple from "./list/icon-attention-purple.vue";
import PromotionLogo from "./list/promotion-logo";
import LogoMir from "./list/logo-mir";
import LogoMastercard from "./list/logo-mastercard";
import LogoVisa from "./list/logo-visa";
import Tile from "./list/tile.vue";
import Sun from "./list/sun.vue"
import Moon from "./list/moon.vue";
import List from "./list/list.vue"
import Stopwatch from "./list/stopwatch.vue"
import Utair from "./list/utair";
import Ribbon from "./list/ribbon";
import Rocket from "./list/rocket";
import PromotionTrending from "./list/promotion-trendig";
import ClockAnswer from "./list/icon-clock";
import Google from "./list/google.vue";
import Otzovik from "./list/otzovik.vue";
import IRecommend from "./list/irecommend.vue";
import Yandex from "./list/yandex.vue";
import StarInCircle from "./list/star-in-circle.vue";
import FlagGray from "./list/flag-gray.vue";
import BonusSteps from "./list/bonus-steps.vue";
import VK from  "./list/vk.vue";

export default {
  components: {
    AppTopMenu,
    AppQrCode,
    AlarmCircle,
    IconShield,
    IconShieldChecked,
    IconCheckmark,
    IconCheck,
    IconBigCheck,
    IconEye,
    IconBell,
    IconFolder,
    IconNextInside,
    IconMore,
    IconDelete,
    IconCopy,
    IconTranslate,
    IconExternalLink,
    IconInfo,
    IconInfoFill,
    IconTrash,
    IconPlus,
    IconToolbarBold,
    IconToolbarItalic,
    IconToolbarStrike,
    IconToolbarHeading1,
    IconToolbarHeading2,
    IconToolbarParagraph,
    IconToolbarUnorderedList,
    IconToolbarOrderedList,
    IconToolbarLink,
    IconToolbarUnlink,
    IconToolbarImage,
    IconToolbarUndo,
    IconToolbarRendo,
    IconAttention,
    IconAttentionSmall,
    IconAttentionTriangle,
    IconDropdown,
    IconGear,
    IconPen,
    IconRotateLeft,
    IconRotateRight,
    IconMirrorX,
    IconMirrorY,
    IconMenuBurger,
    IconCalendar,
    IconUserMessage,
    IconUser,
    IconCurrency,
    IconLanguage,
    IconPlane,
    IconDocument,
    IconSupport,
    IconFavorites,
    IconTravels,
    IconChat,
    IconFeedback,
    IconExit,
    IconExpertProfile,
    IconMyTours,
    IconMyOrders,
    IconMyWallet,
    IconGift,
    IconMyTeam,
    IconModeration,
    IconMyTravels,
    IconArrowRight,
    IconArrowUp,
    IconSearch,
    Icon404,
    IconGeo,
    IconGeoSharp,
    IconMir,
    GeoCircleError,
    IconComment,
    IconReject,
    IconUpload,
    IconWarning,
    IconWarningFill,
    LogoYoutravel,
    LogoTs,
    LogoTsColor,
    GooglePlayButton,
    AppStoreButton,
    LogoYoutravelColor,
    Search,
    Calendar,
    Setting,
    SettingActive,
    Back,
    Dropdown,
    Close,
    CloseCircleFill,
    CloseMini,
    Heart,
    Clock,
    Lightning,
    TourRatingStar,
    Star,
    Crown,
    LoaderCircle,
    LoaderCircleLarge,
    Email,
    User,
    Eye,
    ArrowDropdown,
    Triangle,
    Person,
    IconCalendarNew,
    IconFilledHeart,
    Info,
    IconAngleUp,
    IconAngleUpMini,
    Minus,
    Plus,
    Checkmark,
    CheckmarkThick,
    CheckmarkCircle,
    CheckmarkCircleOutline,
    Thumbup,
    Ok,
    Phone,
    Shield,
    ThumbsUp,
    PhoneTalk,
    ShieldOutline,
    InputDropdown,
    InputClose,
    InputSearch,
    arrowDropdown,
    InputEye,
    inputEyeOff,
    Refresh,
    addChat,
    addDocs,
    books,
    satellite,
    smile,
    addIcon,
    profile,
    requisites,
    trash,
    errorClose,
    Flag,
    Envelope,
    Building,
    RocketLaunch,
    RocketLanding,
    MapPin,
    BubbleCorner,
    errorRedTriangle,
    VerificationIntro,
    s7,
    IconAttentionPurple,
    PromotionLogo,
    LogoMastercard,
    LogoMir,
    LogoVisa,
    Tile,
    Sun,
    Moon,
    List,
    Stopwatch,
    Utair,
    Ribbon,
    Rocket,
    PromotionTrending,
    ClockAnswer,
    Google,
    Otzovik,
    IRecommend,
    Yandex,
    FlagGray,
    StarInCircle,
    BonusSteps,
    VK
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    // TODO: Удалить если не воспользуюсь
    color: {
      type: String,
      default: "inherit",
    },
    classMod: {
      type: String,
      default: ""
    }
  },
  computed: {
    getIcon() {
      return this.icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.svg {
  pointer-events: none;
  fill: currentColor;
  // transition: color 0.3s, transform 0.3s;

  &.no-fill {
    fill: none;
  }

  &.neutral-100 {
    color: var.$neutral-100;
  }

  &.violet-1 {
    color: var.$violet-1;
  }
  &.teal-280 {
    color: var.$teal-280;
  }
  &.teal-300 {
    color: var.$teal-300;
  }

  &.gold-400 {
    color: var.$gold-400;
  }

  &.gray-dark {
    color: var.$gray-dark;
  }
  &.secondary{
    color: var.$secondary;
  }
  &.black{
    color: var.$black;
  }
  &.green-hover {
    color: var.$green-hover;
  }
  &.stroke-neutral-100 {
    stroke: var.$neutral-100;
  }
  &.stroke-green-main {
    stroke: var.$green-main;
  }
  &.stroke-green-hover {
    stroke: var.$green-hover;
  }
  &.stroke-gray-dark {
    stroke: var.$gray-dark;
  }
  &.stroke-teal-300 {
    stroke: var.$teal-300;
  }
}
</style>
