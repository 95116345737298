const detailsEn = {
  comfortInfo: {
    hotel_name: "The name of the hotel",
    title: "Travel comfort level",
    description: "Indicate if you are sure of a 100% guarantee of placement",
    type_accommodation: {
      options: [
        { id: 1, name: "Apartments" },
        { id: 2, name: "Apartment" },
        { id: 3, name: "Hotel" },
        { id: 4, name: "Hut" },
        { id: 5, name: "Heating main" },
      ],
      placeholder: "Where you plan to stay",
    },
    type_allocation: {
      options: [
        { id: 1, name: "Single room" },
        { id: 2, name: "Double Room" },
        { id: 3, name: "Triple room" },
      ],
      placeholder: "Accommodation",
    },
  },

  languageSpeaker: {
    title: "What languages are spoken on the tour",
    language: {
      options: [
        { id: 1, name: "Russian" },
        { id: 2, name: "Russian" },
        { id: 3, name: "Russian" },
        { id: 4, name: "Russian" },
        { id: 5, name: "Russian" },
      ],
      placeholder: "Choose language",
      close: {
        text: "Close",
      },
    },
    animals_rest: "The program does not use animals",
  },
  hotelPhotos: {
    title: "Hotel photos",
    inputFile: {
      text: "Upload hotel photo",
    },
    alt: "Description of the picture",
    placeholder: "Description of the picture",
    name: "Photo title",
  },
  tooltip: "Tooltip content",
  travelLevel: {
    title: "Travel difficulty level",
    permittedAge: {
      label: "Permitted age of participation",
    },
    checkboxKids: {
      label: "Participation with small children is possible",
    },
    dictionary: {
      varyLow: "Very low",
      low: "Low",
      normal: "Normal",
      good: "Good",
      excellent: "Excellent",
    },
  },
  tourDescription: {
    title: "Tour description",
    description:
      "Tell clients what the tour includes, what the travelers will do, what impressions they will get",
    fields: {
      detailDescription: {
        placeholder: "Detailed description",
      },
      videoLink: {
        placeholder: "Link to youtube or vimeo video",
      },
      impressions: {
        placeholder: "Highlights",
      },
      whatDo: {
        placeholder: "What are we going to do on tour",
      },
    },
  },
  navButtons: {
    navPrev: {
      text: "Back",
    },
    navNext: {
      text: "Day after day",
    },
  },
};

export { detailsEn };
