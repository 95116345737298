const mainMenuEn = {
  mainMenu: {
    tours: 'All Adventures',
    company: 'company',
    about: 'About',
    te: 'List a tour',
    more: 'More info',
    signUp: 'Sign up',
    support: 'Help center',
    helpme: 'Help me select a tour',
    howItWorks: 'How it works'
  }
};

export { mainMenuEn };
