<template>
  <div class="settings-wrapper" @click="hidePopup">
    <div class="settings" @click.stop="null">
      <div class="settings-title">{{ $t('settings' )}}</div>
      <div class="settings-tabs">
        <div class="settings-tab"
          v-for="item, index in settingsMenu" :key="index"
          v-on:click="toLocation(item.link)">
          {{$t(item.text)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import settingsMenu from './settings-menu.js';
export default {
  data() {
    return {
      settingsMenu: settingsMenu,
    };
  },
  methods: {
    toLocation(link) {
      location.href = link;
    },
    ...mapMutations({
      hidePopup: "popup/hidePopup",
    }),
  }
}
</script>

<style>
.settings-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.settings {
  background: #fff;
  padding: 1.5em;
  max-width: 650px;
  width: 100%;
  box-shadow: 1px 2px 60px rgba(153, 153, 169, 0.35);
  border-radius: 24px;
}

.settings-title {
  font-size: 1.125em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}

.settings-tabs {
  display: flex;
  background: #EEEEF2;
  border-radius: 12px;
  padding: 4px;
}

.settings-tab {
  font-size: .875em;
  font-weight: 600;
  padding: 11px 8px;
  flex: 1 1 0;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-tab:hover {
  border-radius: 8px;
  background: #fff;
}
.settings-tab:hover:after {
  display: none;
}
</style>
