import lang from "vue2-datepicker/locale/es/en";
lang.monthFormat = "MMMM";
const dateSelector = {
  dateSelector: {
    datepickerLang: lang,
    placeholder: "Choose dates",
  },
};

export { dateSelector };
