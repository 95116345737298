export default {
  bannerLK: {
    tellSmth: "Tell us about yourself to make recommendations",
    goal: "We can then recommend your tours to interested travelers",
    toFill: "Fill in",
  },
  bannerTours: {
    motivation: {
      a: "Join a group",
      b: "with like-minded",
      c: "people",
    },
    toDo: "We will select the best groups for you based on your responses to the questionnaire in just 2 minutes",
    start: "Connect with an expert",
  },
  survey: {
    city: "Input your city",
    select: "Select",
    cityPlaceholder: "City",
    continue: "Continue",
    chooseMaxCount: "Choose {count} categories",
    chooseMore: "{count} more categories",

    journeyGoal: "How do you envision your travels?",
    unexpectedDirectinos: "🧭 New, unexpected directions",
    activeRest: "🎒 Active rather than passive recreation",
    samePeople: "🤛 Company by age and interests",
    extendNetwork: "🤝 Expand your social circle",
    seeMany: "🌎 See a lot in a short time",

    tourFrequency: "How often do you travel?",
    more_once_month: "Once a month +",
    once_month: "Every month",
    "3-4_times_year": "Several times a year",
    once_year: "Once a year",
    rare_once_year: "Less than once a year",

    age: "How old are you?",
    lessThan: "Less than ",
    moreThan: "More than ",

    interests: "Что вам интересно?",
    museums: "🏛 Museums",
    places: "👁️ Interesting places",
    architecture: "🏙 Architecture",
    history: "⚔️️ Story",
    art: "👁️ Art",
    theatre: "🎭️ Theaters",
    shopping: "🛍️ Shopping",
    bars: "🍷️ Bars and restaurants",
    yacht: "⛵️ Yacht trips",
    "art-space": "🎨️ Creative spaces",
    child: "👦 For kids",
    photo: "📸️ The photo",
    walk: "👣️ Hiking",
    nature: "🌳️ Nature and parks",
    inductrial: "🏭 Industrial",
    // FIXME keys below are incorrect
    // "museums": "Slum",
    // "museums": "Workshops and lofts",

    introvertedness: "How introverted are you?",
    introvertedness_description:
      "1 is the maximum introvert, and 5 is the maximum extrovert",

    personal_info: "Last step",
    name: "Name",
    email: "Email",

    pleaseWait: "Please wait...",
    waitAWhile: "Processing may take up to {duration} seconds",

    ready: "All is ready!",
    weFound:
      "You can go to {count} groups that fit your criteria, or you can refine your search by providing more information",
    showMatches: "Show travel",
    refineCriteria: "Refine criteria",
  },
  multisuggest: {
    emptyList: "The list is empty",
    emptyResult: "No matches found. Try another query.",
  },
};
