import { ytVueGlobalStore } from "@/services/yt-vue-global-store";
import { i18n } from "@/plugins/i18n";
import { abTestController } from "@/plugins/abtest";
const user = ytVueGlobalStore.instantGet("user");

let dashboardUrl = '/lk/dashboard';
let toursUrl = '/lk/tours/';
let profileUrl = '/lk/profile/';
let myUrl = '/lk/my/';
let teamUrl = '/lk/team/';
let notificationsUrl = '/lk/notifications/';
let billingUrl = '/lk/billing/';
const newLkFrontHost = process.env.VUE_APP_NEWLK_FRONT_HOST.replace(/\/$/, '');
switch (abTestController.getSegment('NEWLK')) {
  case 'A':
    dashboardUrl = newLkFrontHost + '/dashboard';
    toursUrl = newLkFrontHost + '/tours/active';
    profileUrl = newLkFrontHost + '/settings/account';
    myUrl = newLkFrontHost + '/settings/profile';
    teamUrl = newLkFrontHost + '/settings/team';
    notificationsUrl = newLkFrontHost + '/settings/notifications';
    billingUrl = newLkFrontHost + '/settings/legaldata';
    break;
  default:
    break;
}

const updateProfileMenu = (user) => {
  let profileMenu = [];

  if (user.isLoggedIn && user.userRole === "Expert") {
    profileMenu.push({
      id: 0,
      title: i18n.t('profileMenu.dashboard'),
      link: dashboardUrl,
      type: "tab",
      icon: "icon-user",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [
        {
          id: 0,
          title: i18n.t('profileMenu.dashboardMainPage'),
          link: dashboardUrl,
          trackEvent: {
            category: 'LK',
            name: 'Click Sidebar menu item',
            props: {
              code: 'DASHBOARD_MAIN_PAGE',
            },
          },
          FROM_IBLOCK: true,
          IS_PARENT: false,
          DEPTH_LEVEL: 2,
          slug: "menu_left_dashboard",
        },
        {
          id: 1,
          title: i18n.t('profileMenu.myProfile'),
          link: profileUrl,
          trackEvent: {
            category: 'LK',
            name: 'Click Sidebar menu item',
            props: {
              code: 'MY_PROFILE',
            },
          },
          FROM_IBLOCK: true,
          IS_PARENT: false,
          DEPTH_LEVEL: 2,
          slug: "menu_left_profile",
        },
        {
          id: 2,
          title: i18n.t('profileMenu.personalAndTeam'),
          link: myUrl,
          trackEvent: {
            category: 'LK',
            name: 'Click Sidebar menu item',
            props: {
              code: 'PERSONAL_AND_TEAM',
            },
          },
          FROM_IBLOCK: true,
          IS_PARENT: false,
          DEPTH_LEVEL: 2,
          slug: "menu_left_my",
        },
        {
          id: 3,
          title: i18n.t('profileMenu.myTeam'),
          link: teamUrl,
          trackEvent: {
            category: 'LK',
            name: 'Click Sidebar menu item',
            props: {
              code: 'MY_TEAM',
            },
          },
          icon: "icon-my-team",
          FROM_IBLOCK: true,
          IS_PARENT: false,
          DEPTH_LEVEL: 2,
          slug: "menu_left_team",
        },
        {
          id: 4,
          title: i18n.t('profileMenu.menuNotifications'),
          link: notificationsUrl,
          trackEvent: {
            category: 'LK',
            name: 'Click Sidebar menu item',
            props: {
              code: 'NOTIFICATIONS_SETTINGS',
            },
          },
          FROM_IBLOCK: true,
          IS_PARENT: false,
          DEPTH_LEVEL: 2,
          slug: "menu_sub_left_notifications",
        },
      ],
    });
  }

  if (user.isLoggedIn && user.userRole === "Expert") {
    profileMenu.push({
      id: 1,
      title: i18n.t('profileMenu.notifications'),
      link: "/lk/my-notifications/",
      type: "tab",
      icon: "icon-bell",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole !== "User") {
    profileMenu.push({
      id: 2,
      title: i18n.t('profileMenu.myTours'),
      link: toursUrl,
      type: "tab",
      icon: "icon-my-travels",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "User") {
    profileMenu.push({
      id: 3,
      title: i18n.t('profileMenu.chat'),
      link: "/lk/im/",
      type: "tab",
      icon: "icon-chat",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "User") {
    profileMenu.push({
      id: 4,
      title: i18n.t('profileMenu.myToursMenuBooking'),
      link: "/lk/mytours/bookings/",
      type: "tab",
      icon: "icon-my-orders",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "Expert") {
    profileMenu.push({
      id: 5,
      title: i18n.t('profileMenu.orders'),
      link: "/lk/orders/",
      type: "tab",
      icon: "icon-my-orders",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [
        {
          id: 0,
          title: i18n.t('profileMenu.requests'),
          link: "/lk/orders/requests/",
          trackEvent: {
            category: "LK",
            name: "Click Sidebar menu item",
            props: {code: "ORDERS_REQUESTS"},
          },
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_orders_requests",
        },
        {
          id: 1,
          title: i18n.t('profileMenu.payments'),
          link: "/lk/orders/payments/",
          trackEvent: {
            category: "LK",
            name: "Click Sidebar menu item",
            props: {code: "ORDERS_PAYMENTS"},
          },
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_orders_payments",
        },
        {
          id: 2,
          title: i18n.t('profileMenu.tourists'),
          link: "/lk/orders/tourists/",
          trackEvent: {
            category: "LK",
            name: "Click Sidebar menu item",
            props: {code: "ORDERS_TOURISTS"},
          },
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_orders_tourists",
        },
        {
          id: 3,
          title: i18n.t('profileMenu.arbitration'),
          link: "/lk/arbitration/",
          trackEvent: {
            category: "LK",
            name: "Click Sidebar menu item",
            props: {code: "ORDERS_ARBITRATION"},
          },
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_arbitration",
        },
      ],
    });
  }

  if (user.isLoggedIn) {
    profileMenu.push({
      id: 6,
      title: i18n.t('profileMenu.reviews'),
      link: "/lk/reviews/",
      type: "tab",
      icon: "icon-feedback",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "Expert") {
    profileMenu.push({
      id: 7,
      title: i18n.t('profileMenu.requisites'),
      link: billingUrl,
      type: "tab",
      icon: "icon-my-wallet",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [
        {
          id: 0,
          title: i18n.t('profileMenu.menuBonusAccount'),
          link: "/lk/bonuses/transactions/",
          trackEvent: {
            category: "LK",
            name: "Click Sidebar menu item",
            props: {code: "BONUS_ACCOUNT"},
          },
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_bonuses_transactions",
        },
        {
          id: 1,
          title: i18n.t('profileMenu.menuBillingDetails'),
          link: billingUrl,
          trackEvent: {
            category: "LK",
            name: "Click Sidebar menu item",
            props: {code: "BILLING_DETAILS"},
          },
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_billing",
        },
      ],
    });
  }

  if (user.isLoggedIn && user.userRole === "User") {
    profileMenu.push({
      id: 8,
      title: i18n.t('profileMenu.account'),
      link: "/lk/profile/",
      type: "tab",
      icon: "icon-user",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [
        {
          id: 0,
          title: i18n.t('profileMenu.menuDashboard'),
          link: "/lk/dashboard/",
          trackEvent: "",
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_dashboard",
        },
        {
          id: 1,
          title: i18n.t('profileMenu.menuProfile'),
          link: "/lk/profile/",
          trackEvent: "",
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_profile",
        },
        {
          id: 2,
          title: i18n.t('profileMenu.menuTravellerProfile'),
          link: "/lk/profile/me/",
          trackEvent: "",
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_profile_me",
        },
        {
          id: 3,
          title: i18n.t('profileMenu.menuNotifications'),
          link: "/lk/notifications/",
          trackEvent: "",
          FROM_IBLOCK: false,
          IS_PARENT: false,
          DEPTH_LEVEL: 1,
          slug: "menu_sub_left_notifications",
        },
      ],
    });
  }

  if (user.isLoggedIn && user.userRole === "User") {
    profileMenu.push({
      id: 9,
      title: i18n.t('profileMenu.arbitration'),
      link: "/lk/arbitration/",
      type: "tab",
      icon: "icon-document",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "User") {
    profileMenu.push({
      id: 10,
      title: i18n.t('profileMenu.menuBonusAccount'),
      link: "/lk/bonuses/",
      type: "tab",
      icon: "icon-gift",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "Expert" && !user.trustCheck) {
    profileMenu.push({
      id: 11,
      title: i18n.t('profileMenu.verification'),
      link: "/lk/dashboard/survey/verification/",
      type: "tab",
      icon: "",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "Expert" && user.trustCheck) {
    profileMenu.push({
      id: 12,
      title: i18n.t('profileMenu.promotion'),
      link: "/lk/promotion/",
      type: "tab",
      icon: "icon-document",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }

  if (user.isLoggedIn && user.userRole === "Expert") {
    profileMenu.push({
      id: 13,
      title: i18n.t('profileMenu.integrations'),
      link: "/lk/integrations/",
      type: "tab",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
    });
  }
  return profileMenu;
}
export default {
  namespaced: true,
  state: {
    items: updateProfileMenu(user) || [],
  },
  mutations: {
    setProfileMenu(state, value) {
      state.items = value;
    }
  },
  actions: {
    updateProfileMenu({commit}, {user}) {
      const profileMenu = updateProfileMenu(user);
      commit('setProfileMenu', profileMenu)
    }
  }
};
