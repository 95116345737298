import { ytVueGlobalStore } from "@/services/yt-vue-global-store";
import { i18n } from "@/plugins/i18n";

const language = ytVueGlobalStore.instantGet('language');
export default {
  namespaced: true,
  state: {
    language: language || {},
    mainContentLanguage: "",
    contentLanguage: "",
    isTranslateYourSelf: false,
  },
  getters: {
    getLanguageContent(state) {
      return state.isTranslateYourSelf
        ? state.contentLanguage
        : state.mainContentLanguage;
    },
    getLanguage() {
      return i18n.locale;
    }
  },
  mutations: {
    changeContentLanguage(state, language) {
      state.contentLanguage = language;
    },
    changeMainContentLanguage(state, language) {
      state.mainContentLanguage = language;
    },
    changeIsTranslateYourSelf(state, value) {
      state.isTranslateYourSelf = value;
    },

    changeActiveLanguage(state, value) {
      state.activeLanguage = value;
    },
  },
  actions: {},
};
