import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

export async function getPersonalStatistics() {
  const { data } = await axiosYtApiV2.get("/personal/statistics");
  return data;
}

export async function refreshPersonalStatistics() {
  const { data } = await axiosYtApiV2.get("/personal/refresh");
  return data;
}
