export default {
  namespaced: true,
  state: {
    points: [],
  },
  mutations: {
    addPoint(state, point) {
      state.points.push(point);
    },
  },
};
