import { axiosSearchhelper } from "@/services/scopes/axios-searchhelper";

export default {
  namespaced: true,
  state: {
    datepicker: {},
    dateCategories: {},
    isLoad: false,
  },
  mutations: {
    setDatepicker(state, data) {
      state.datepicker = data || {};
      state.dateCategories = data?.dateCategories || {};
      state.isLoad = true;
    },
  },
  actions: {
    async fetchDatepicker({ commit }) {
      const { success, data } = await axiosSearchhelper.getDatepicker();
      if (success) {
        commit('setDatepicker', data);
      }
    },
  },
};
