import vuestore from './dev/vuestoreauth.json';
const ytVueGlobalStore = {
  onChange(endpoint, callback) {
    // Listen for the event.
    typeof window != "undefined" && fakeWindow.addEventListener("vueglobalstoreupdated-" + endpoint, callback);
  },

  initEndpoint(endpoint, map) {
    let store = vueOptions.ytvuestore || {};
    if (typeof store == "undefined") {
      store = map;
    }
  },

  get(endpoint) {
    let store = vueOptions.ytvuestore || {};
    return new Promise((resolve, reject) => {
      if (typeof store[endpoint] == "undefined") {
        reject({ error: true, message: "Endpoint is empty" });
      }
      resolve(store[endpoint]);
    });
  },

  fillFromMock() {
    //const vuestore = await import('./dev/vuestoreauth.json');
    vueOptions.ytvuestore = {
      ...vuestore,
    };
  },

  instantGet(endpoint, defaultValue = {}) {
    if (
      typeof vueOptions.ytvuestore == "undefined" &&
      process.env.NODE_ENV === "development"
    ) {
      this.fillFromMock();
    }

    let store = vueOptions.ytvuestore || {};
    if (typeof store[endpoint] != "undefined") {
      return store[endpoint];
    }
    return defaultValue;
  },
};

export { ytVueGlobalStore };
