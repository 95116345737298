import { ytVueGlobalStore } from "@/services/yt-vue-global-store.js";

export default {
  namespaced: true,
  state: {
    mmSerpGroups: { ...ytVueGlobalStore.instantGet("mm-serp-groups") },
    mmSerpQuery: { ...ytVueGlobalStore.instantGet("mm-serp-query") },
    loading: true,
    showReset: false,
    dates: null,
    location: null,
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    selectDates(state, dates) {
      state.dates = dates;
    },
    selectLocation(state, location) {
      state.location = location;
    },
  },
};
