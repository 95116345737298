import { globalRu } from "@/assets/config/global/global-ru.js";
import { generalRu } from "@/assets/config/general/general-ru.js";
import { priceDateRu } from "@/assets/config/price-date/price-date-ru.js";
import { cancelTourRu } from "@/assets/config/cancel-tour/cancel-tour-ru.js";
import { detailsRu } from "@/assets/config/details/details-ru.js";
import { dayAfterDayRu } from "@/assets/config/day-after-day/day-after-day-ru";
import { importantKnowRu } from "@/assets/config/important-know/important-know-ru";
import { photosRu } from "@/assets/config/photos/photos-ru";
import { popupRu } from "@/assets/config/popup/popup-ru";
import { popupMapRu } from "@/assets/config/popup-map/popup-map-ru";
import { serpRu } from "@/assets/config/serp/serp-ru";
import { mmSerpRu } from "@/assets/config/mm-serp/mm-serp-ru";
import { questionnaireRu } from "@/assets/config/survey/questionnaire-ru";
import survey from "@/assets/i18n/matchmaking/ru";
import { howItWorksRu } from "@/assets/config/how-it-works/how-it-works-ru";
import { tourFaqRu } from "@/assets/config/tour-faq/tour-faq-ru";
import { locationNearbyRu } from "@/assets/config/location-nearby/location-nearby-ru";

import { popupIndividualRu } from "@/assets/config/popup-individual/popup-individual-ru";
import { popupClaimPdfRu } from "@/assets/config/popup-claim-pdf/popup-claim-pdf-ru";
import { reviewsRu } from "@/assets/config/reviews/reviews-ru";
import { participantsRu } from "@/assets/config/participants/participants-ru";
import { dropdownTriggerRu } from "@/assets/config/dropdown-trigger/dropdown-trigger-ru";
import { serpDetailRu } from "@/assets/config/serp-detail/serp-detail-ru";
import { bookingPopupRu } from "@/assets/config/booking-popup/booking-popup-ru";
import { contactTravelExpertRu } from "@/assets/config/contact-travel-expert/contact-travel-expert-ru";
import { popupFlexibleCancellationRu } from "@/assets/config/popup-flexible-cancellation/popup-flexible-cancellation-ru";
import { popupInstallmentRu } from "@/assets/config/popup-installment/popup-installment-ru";
import { pageBreakingRules } from "@/assets/i18n/page-breaking-rules/ru.js";
import { profileMenuRu } from "@/assets/config/profile-menu/profile-menu-ru";
import { mainMenuRu } from "@/assets/config/main-menu/main-menu-ru";
import { lkBonusesRu } from '@/assets/config/lk-bonuses/lk-bonuses-ru';

const ru = {
  ...globalRu,
  ...generalRu,
  ...priceDateRu,
  ...cancelTourRu,
  ...detailsRu,
  ...dayAfterDayRu,
  ...importantKnowRu,
  ...photosRu,
  ...popupRu,
  ...popupMapRu,
  ...serpRu,
  ...mmSerpRu,
  ...questionnaireRu,
  ...survey,
  ...howItWorksRu,
  ...tourFaqRu,
  ...popupClaimPdfRu,
  ...reviewsRu,
  ...participantsRu,
  ...dropdownTriggerRu,
  ...serpDetailRu,
  ...bookingPopupRu,
  ...contactTravelExpertRu,
  ...popupFlexibleCancellationRu,
  ...locationNearbyRu,
  ...popupIndividualRu,
  ...popupInstallmentRu,
  ...pageBreakingRules,
  ...profileMenuRu,
  ...mainMenuRu,
  ...lkBonusesRu,
};

export { ru };
