const popupIndividualEn = {
  popupIndividual: {
    title: "Запросить индивидуальный тур",
    text: "Отправьте заявку, чтобы организовать тур только для вас и&nbsp;ваших близких",
    message: "Ваши пожелания",
    price: "Примерная стоимость в&nbsp;день",
    priceMoreDay: "Approximate budget for your dates",
    submit: "Отправить заявку",
    done: {
      title: "Заявка отправлена",
      text: "Она появилась в&nbsp;вашем Личном кабинете.<br> Тревел-эксперт ее&nbsp;скоро рассмотрит и&nbsp;ответит",
      link: "Перейти к заявке",
    },
    suggest: {
      title:
        "Filter &laquo;Private tours available&raquo; might be useful to you",
      text: "This type of tour can only be arranged for you and your loved ones.",
      button: "Close",
    },
  },
};

export { popupIndividualEn };
