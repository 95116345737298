import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

const axiosNotifications = {
  getNotifications(query = "limit=100") {
    return axiosYtApiV2.get(`notifications?${query}`);
  },
  setRead(id) {
    return axiosYtApiV2.post(`notifications/${id}/mark-as-read`);
  },
  setReadAll() {
    return axiosYtApiV2.post(`notifications/mark-as-read-all`);
  }

}
export {axiosNotifications}
