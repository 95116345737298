<template>
  <div
    class="multisuggest-wrapper"
    :class="{
      'multiselect-invalid': $slots['error-helper'],
    }"
  >
    <multiselect
      ref="multiselect"
      :id="getId"
      :value="value"
      :placeholder="isMultiply ? placeholder : ''"
      :options="computedOptions"
      :select-label="isMultiply ? $t('multiselect.select') : ''"
      :selected-label="isMultiply ? $t('multiselect.selected') : ''"
      :deselect-label="isMultiply ? $t('multiselect.delete') : ''"
      :label="getTrackBy"
      :track-by="getTrackBy"
      :multiple="isMultiply"
      :close-on-select="!isMultiply"
      :taggable="isMultiply && isTaggable"
      :searchable="isSearchable"
      :allow-empty="isMultiply"
      :loading="isLoading"
      :internal-search="!canUseMap"
      @input="handleInput"
      @select="selectOption"
      @search-change="debounceAsync($event)"
      @tag="addTagHandler"
      @close="resetDropDown()"
      :disabled="isReadOnly"
      :max="value.length > maxCountSelected ? value.length : maxCountSelected"
    >
      <template v-slot:maxElements>
        <span class="multiselect__custom_option"
          v-text="$t('multisuggest.maximumCountSelected')"/>
      </template>
      <template v-slot:noOptions>{{ isAsync && !value.length ? $t("multisuggest.startSearching") : $t("multisuggest.emptyList") }}</template>
      <template v-slot:noResult>
        {{ $t("multisuggest.emptyResult") }}
      </template>
      <template v-slot:beforeList v-if="canUseMap && !this.isHaveOptions">
        <li class="multiselect__element">
          <span class="multiselect__option ">
            <span>{{ $t("multisuggest.emptyResult") }}</span>
          </span>
        </li>
      </template>
      <template v-slot:caret>
        <div v-if="$slots['caret']" class="multiselect__custom-caret">
          <slot name="caret"></slot>
        </div>
        <v-popover
          v-if="$slots['tooltip-inner']"
          class="tooltip__inner"
          placement="top"
          trigger="hover"
          :delay="{ show: 100, hide: 500 }"
          :autoHide="false"
        >
          <Icon icon="icon-info" color="teal-300" />

          <template slot="popover">
            <slot name="tooltip-inner"></slot>
          </template>
        </v-popover>
      </template>
      <template v-slot:tag><span></span></template>
      <template slot="selection" slot-scope="{ isOpen, search }">
        <span
          v-if="isMultiply && !isOpen && !!value.length"
          class="multiselect__selection"
        >
          {{ placeholder }}
        </span>
        <label
          v-if="!isMultiply"
          class="multisuggest-placeholder"
          :class="{
            'multisuggest-placeholder--active': search || !checkObject(value),
            'multisuggest-placeholder--open': isOpen,
          }"
          :for="getId"
        >
          {{ placeholder }}
        </label>
      </template>
      <template #option="{ option, search }">
        <slot name="option" :option="option" :search="search" />
      </template>
      <template #singleLabel="{ option }">
        <slot name="singleLabel" :option="option" />
      </template>
    </multiselect>
    <div v-if="$slots['error-helper']" class="error-helper">
      <slot name="error-helper"></slot>
    </div>
    <p v-if="$slots['tooltip-bottom']" class="tooltip__bottom">
      <slot name="tooltip-bottom"></slot>
    </p>

    <PopupMap
      v-if="isActiveMap"
      @save="savePoint($event)"
      @close="isActiveMap = false"
    />
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Preloader from "@/components/atoms/preloader/index.vue";
import Multiselect from "vue-multiselect";

import { axiosLocations } from "@/services/scopes/axios-locations.js";
import { isObjectValuesEmpty } from "@/assets/js/utils/is-object-values-empty";
import { getUniqueId } from "@/assets/js/utils/get-unique-id";
import { mapState } from "vuex";
import debounce from "@/mixins/debounce.js";

const PopupMap = () => ({
  component: import("@/components/molecules/popup-map/index.vue"),
  loading: Preloader,
  delay: 100,
});

export default {
  mixins: [debounce],
  components: {
    Icon,
    PopupMap,
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      // required: true,
    },
    value: {
      validator: (prop) =>
        prop === null ||
        typeof prop === "object" ||
        typeof prop === "string" ||
        typeof prop === "number",
      required: true,
      default: [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    isAsync: {
      type: Boolean,
      default: false,
    },
    isMultiply: {
      type: Boolean,
      default: false,
    },
    isTaggable: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: "name",
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    canUseMap: {
      type: Boolean,
      default: false,
    },
    usePagination: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    asyncHandler: {
      type: Promise,
    },
    maxCountSelected: {
      default: 50,
    },
    isHaveOptions: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    isLoading: false,
    searchQuery: '',
    locations: [],
    page: 1,
    isActiveMap: false,
  }),
  computed: {
    ...mapState({
      points: (state) => state.popupMap.points,
    }),
    getTrackBy() {
      return this.trackBy.length === 0 ? null : this.trackBy;
    },
    computedOptions() {
      let result;
      result = this.options;
      if (this.isAsync) {
        result = result.concat([
          ...this.points,
          ...this.locations,
        ])
        if (this.canUseMap && !this.isLoading) {
          result.push(
              {
                name: this.$t("dayAfterDay.tourDayForm.location.select"),
                value: "mapbox",
              }
          );
        }
      }
      return result;
    },
    getId() {
      return getUniqueId("multiselect");
    },
  },
  methods: {
    handleInput(event) {
      if (event.value === "mapbox") {
        this.isActiveMap = true;
      } else {
        this.$emit("change-input", event);
      }
    },
    selectOption(event) {
      this.$emit("select-option", event);
    },
    defaultAsyncHandler(value){
      return axiosLocations
        .getLocations(value);
    },
    debounceAsync(event) {
      if (!this.isAsync || event.length === 0) {
        return;
      }
      this.isLoading = true;
      this.debounce(this.asyncFind, event)(event)
    },

    loadNextPage(value) {
      let handler = this.defaultAsyncHandler;
      if(this.asyncHandler){
        handler = this.asyncHandler;
      }
      handler(value, this.pageSize, (this.page - 1) * this.pageSize)
        .then(({ data }) => {
          if (data.success) {
            this.locations = this.locations.concat(data.data.items);
            this.page++;
          }
          this.isHaveOptions = !!data.data.items.length;
          this.isLoading = false;
          this.canUseMap = true;
        })
        .catch((error) => {
          // TODO: нужен тех дизайн для ошибок
          console.error(error);
          this.isLoading = false;
        });
    },
    handleScroll(event) {
      const target = event.target;
      const scrollHeight = target.scrollHeight;
      const scrollTop = target.scrollTop;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight && this.locations.length > 0) {
        this.loadNextPage(this.searchQuery);
      }
    },
    asyncFind(value) {
      this.searchQuery = value;
      this.page = 1;
      this.locations = [];
      this.loadNextPage(value);
    },
    addTagHandler(newTag) {
      if (!this.isMultiply || !this.isTaggable) {
        return;
      }

      const tag = {
        name: newTag,
        id: getUniqueId("multisuggest"),
      };

      this.$emit("add-tag", tag);
    },
    savePoint(e) {
      this.$emit("change-input", e);
      this.isActiveMap = false;
      this.options.concat(e);
    },
    checkObject(obj) {
      return obj ? isObjectValuesEmpty(obj) : false;
    },
    resetDropDown() {
      if (this.canUseMap) {
        this.points = [];
        this.locations = [];
        this.options = [];
      }
      this.canUseMap = false;
    }
  },
  mounted() {
    if(this.usePagination && typeof this.$refs.multiselect != "undefined") {
      const contentWrapper = this.$refs.multiselect.$el.querySelector('.multiselect__content-wrapper');
      contentWrapper.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    if(this.usePagination && typeof this.$refs.multiselect != "undefined") {
      const contentWrapper = this.$refs.multiselect.$el.querySelector('.multiselect__content-wrapper');
      contentWrapper.removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.multisuggest {
  &-wrapper {
    position: relative;
    width: 100%;
  }

  &-placeholder {
    left: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    background: transparent;
    transform: translateY(85%);

    top: 0;
    padding: 0 4px;
    position: absolute;

    color: var.$teal-300;

    pointer-events: none;

    transition: all 0.2s;
    z-index: 1;

    &--active {
      left: 16px;
      transform: translate(0, -50%);
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(231, 0, 0, 0) 51%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &--open {
      z-index: 51;
    }
  }
}

.bottom-helper {
  color: var.$teal-300;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
  padding: 0;

  a {
    color: inherit;
  }
}

.error-helper {
  color: var.$ruby-400;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
}
</style>
