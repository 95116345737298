import { getSurvey, saveSurvey } from "@/services/scopes/axios-onboarding-survey.js";

export default {
  namespaced: true,
  state: {
    type: "",
    currentStepIdx: 0,
    searchValue: '',
    isLoading: false,
    errors: [],
    survey: {
      modals: [],
      steps: [],
      /*
      modals: [{
        code: string,
        title: string,
        description: string,
        button: string
      }],
      steps: [{
        code: string,
        title: string,
        description: string,
        fields: [
          {
            code: string,
            type: input|checkboxSuggest|radio-link|region,
            value: string
            isChecked: bool,
            options: [{title, code, isChecked}],
          }
        ]
      }]
  */},
  },
  getters: {
    survey: state => state.survey,
    type: state => state.type,
    steps: state => state.survey.steps || [],
    modals: state => state.survey.modals || [],
    isStarted: state => state.survey.isStarted,
    errors: state => state.errors || [],
    lastModal: state => state.survey.modals.slice(-1).pop() || {},
    totalSteps: state => state.survey.steps.length,
    currentStepIdx: state => state.currentStepIdx,
    currentStep: state => (state.survey.steps || [])[state.currentStepIdx] || {},
    searchValue: state => state.searchValue,
    isDone: (_, getters) => (
      !getters.isLoading &&
      getters.currentStepIdx >= getters.steps.length
    ),
    currentStepFieldsValue: (_, getters) => getters.currentStep.fields(getFieldValue),
    isLoading: state => state.isLoading,
    isStepInvalid: state => state.errors.length !== 0
  },
  mutations: {
    setSurvey(state, { type, survey }) {
      state.survey = survey.data;
      state.type = type;
    },
    incrementCurrentStepIdx(state) {
      state.currentStepIdx++;
    },
    decrementCurrentStepIdx(state) {
      state.currentStepIdx--;
    },
    setCurrentStepFieldValue(state, { fieldIdx, value }) {

      state.survey.steps[state.currentStepIdx]
        .fields[fieldIdx].value = value;
    },

    setCurrentStepFileValue(state,{fieldIdx,  value}) {
        state.survey
          .steps[state.currentStepIdx]
          .fields[fieldIdx].value.splice(0,value)
    },

    setCurrentStepOptionValue(state, { fieldIdx, optionIdx, value }) {
      state.survey
        .steps[state.currentStepIdx]
        .fields[fieldIdx]
        .options[optionIdx].isChecked = value;
    },
    setCurrentStepListValue(state, { fieldIdx, itemIndx, value }) {
      state.survey
        .steps[state.currentStepIdx]
        .fields[fieldIdx].value[itemIndx] = value
    },
    uncheckAllOptions(state, { fieldIdx }) {
      state.survey
        .steps[state.currentStepIdx]
        .fields[fieldIdx]
        .options.forEach(option => {
          option.isChecked = false;
        });
    },
    setSearchValue(state, value) {
      state.searchValue = value;
    },
    setOptionLikeInputValue(state, { fieldIdx, optionIdx, value }) {
      state.survey
        .steps[state.currentStepIdx]
        .fields[fieldIdx]
        .options[optionIdx].input.value = value;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    finishLoading(state) {
      state.isLoading = false;
    },
    addListItem(state, { fieldIdx, value = '' }) {
      const listValue = (
        state.survey
        .steps[state.currentStepIdx]
        .fields[fieldIdx]
        .value || []
      );
      state.survey
        .steps[state.currentStepIdx]
        .fields[fieldIdx]
        .value = [
          ...listValue || [value],
          value,
        ];
    },
    removeListItem(state, { fieldIdx, itemIdx }) {
      state.survey
        .steps[state.currentStepIdx]
        .fields[fieldIdx]
        .value.splice(itemIdx, 1);
    },
    validateStep(state) {
      let isNecessaryOneValue = false;

      state.errors.splice(0, state.errors.length);
      state.survey
        .steps[state.currentStepIdx]
        .fields.forEach((item) => {
          if (!item.value && (item.type == 'input' )) {
            state.errors.push({code:item.code, invalid: true});
          }
          if(!item.value && item.type == 'textarea') {
            state.errors.push({code:item.code, invalid: true});
          }

          if(item.type == 'checkboxSuggest') {
            let checkedItems = item.options.filter(item =>  item.isChecked);
            if(checkedItems.length == 0) {
              state.errors.push({code:item.code, invalid: true});
            }
          }
          if(item.value && (item.type == 'input')){
            isNecessaryOneValue = true;
          }
          if(item.type === 'list') {
            if(item.value !== null) {
              if(item.value.length === 0){
                state.errors.push({code:item.code, invalid: true});
              }
              else {
                item.value.forEach((listValue) => {
                  if(listValue) {
                    isNecessaryOneValue = true;
                  }
                  else {
                    state.errors.push({code:item.code, invalid: true});
                  }
                })
              }
            }
            else {
              state.errors.push({code:item.code, invalid: true});
            }
          }
      });
      if(state.currentStepIdx == 0 && isNecessaryOneValue){
        state.errors.splice(0, state.errors.length);
      }
    },

  },
  actions: {
    async getSurvey({ commit }, type) {
      const { data: survey } = await getSurvey(type);
      commit("setSurvey", { type, survey });
    },
    async saveCurrentStep({ state, getters }) {
      const fields = (
        getters.currentStep
        .fields
        .map(getFieldValue)
        .map(([code, value]) => ({ code, value }))
      );
      await saveSurvey({
        fields,
        type: state.type,
        stepCode: getters.currentStep.code,
      });
    },
    async nextStep({ dispatch, commit, state }) {

      commit ("validateStep");

      if(!state.errors.length) {
        commit('startLoading');
        await dispatch('saveCurrentStep');
        await dispatch('getSurvey', state.type);
        commit('finishLoading');
        dispatch("incr");
      }

    },
    async prevStep({ dispatch, commit, state, getters }) {
      if (getters.currentStepIdx == 0) {
        return;
      }
      const currentStepFilled = (
        getters
        .currentStep
        .fields
        .map(getFieldValue)
        .map(([,value]) => value)
        .every(Boolean)
      );
      commit('startLoading');
      if (currentStepFilled) {
        await dispatch('saveCurrentStep');
      }
      await dispatch('getSurvey', state.type);
      commit('finishLoading');
      dispatch('decr');
      commit ("validateStep");
    },

    // if it's option-like field type, then control value in top-level
    async changeFieldValue({ getters, commit }, {
      stepCode,
      fieldCode,
      optionCode,
      itemIndx,
      value,
    }) {

      if (getters.currentStep.code != stepCode) {
        return;
      }
      const fieldIdx = getters.currentStep.fields.findIndex(field => field.code == fieldCode);
      if (fieldIdx == -1) {
        console.error('Field not found');
        return;
      }
      // option type
      const fieldType = getters.currentStep.fields[fieldIdx].type;

      if(fieldType == 'list') {
        commit('setCurrentStepListValue', { fieldIdx, itemIndx, value });
        commit ("validateStep");
        return;
      }

      if (optionCode) {
        const optionIdx = (
          getters.currentStep
          .fields[fieldIdx]
          .options.findIndex(option => option.code == optionCode)
        );
        if (optionIdx == -1) {
          console.error('Option not found');
          return;
        }

        // radio is not multiselectable
        if (fieldType == 'radioLink') {
          commit('uncheckAllOptions', { fieldIdx });
        }
        commit('setCurrentStepOptionValue', { fieldIdx, optionIdx, value });
        commit ("validateStep");
        return;
      }

      if(fieldType == "files") {
        commit('setCurrentStepFileValue', { fieldIdx, value});
        return;
      }

      commit("setCurrentStepFieldValue", { fieldIdx, value });
      commit ("validateStep");
    },
    changeSearchValue({ commit }, value) {
      commit('setSearchValue', value);
    },
    incr({ commit, state, getters }) {
      if (state.currentStepIdx >= getters.totalSteps) {
        return;
      }
      commit("setSearchValue", '');
      commit("incrementCurrentStepIdx");
    },
    decr({ commit, state }) {
      if (state.currentStepIdx <= 0) {
        return;
      }
      commit("setSearchValue", '');
      commit("decrementCurrentStepIdx");
    },
    setOptionLikeInputValue({ commit, getters }, { fieldCode, optionCode, value }) {
      const fieldIdx = getters.currentStep.fields.findIndex(field => field.code == fieldCode);
      const optionIdx = (
        getters.currentStep
        .fields[fieldIdx]
        .options.findIndex(option => option.code == optionCode)
      );
      commit('setOptionLikeInputValue', { fieldIdx, optionIdx, value });
    },
  },
};

/**
* @returns {Object[2]} code, value
*/
function getFieldValue(field) {
  const getValue = () => {
    switch (field.type) {
      case 'checkboxSuggest':
        return (
          field.options
          .filter(option => option.isChecked)
          .map(option => option.code)
        );
      case 'radioLink':
        return (field.options.find(option => option.isChecked) || {}).code;
      case 'checkboxLink':
        return (
          field.options
          .filter(option => option.isChecked)
          .map(option => ({
            code: option.code,
            ...option.input && {
              input: option.input.value
            },
          }))
        );
      case 'list':  return field?.value?.filter(item => item !== "") || [];
      case 'files':
        return field.value.filter(Boolean);
      default:
        return field.value;
    };
  };
  return [field.code, getValue()];
}
