import { ytVueGlobalStore } from "@/services/yt-vue-global-store";

const headerSettings = ytVueGlobalStore.instantGet('header-settings');
export default {
  namespaced: true,
  state: {
    ...headerSettings,
  },
  actions: {
    setBackgroundImage({ commit }, isBackgroundImage) {
      commit('setBackgroundImage', isBackgroundImage);
    }
  },
  getters: {
    getBackgroundImage(state) {
      return state.isBackgroundImage;
    }
  },
  mutations: {
    setBackgroundImage(state, isBackgroundImage) {
      state.isBackgroundImage = isBackgroundImage;
    }
  }
};
