const abTestController = {
  getSegment(name) {
    if(typeof amplitudeUserProperties != 'undefined'
      && typeof amplitudeUserProperties["abtest_" + name] != 'undefined'){
      return amplitudeUserProperties["abtest_" + name];
    } else {
      return 'N';
    }
  },
};

export { abTestController };
