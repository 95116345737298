<template>
  <div class="popup-photo">
    <div class="popup-wrapper">
      <div class="popup-content">
        <div class="photo-wrapper">
          <div class="image-wrapper">
            <img class="image" :src="data.src" :alt="data.alt" />
          </div>
          <div class="photo-title">
            <Icon class="title-icon" icon="icon-pen" />
            <input class="title" type="text" :placeholder="data.placeholder" />
          </div>
        </div>

        <div class="popup-sidebar">
          <div class="edit-photo-wrapper">
            <button class="btn-edit-img" type="button">
              <Icon icon="icon-rotate-left" />
              <span class="btn-edit-img-text">{{
                $t("popup.photos.actions.ternLeft")
              }}</span>
            </button>
            <button class="btn-edit-img" type="button">
              <Icon icon="icon-rotate-right" />
              <span class="btn-edit-img-text">{{
                $t("popup.photos.actions.ternRight")
              }}</span>
            </button>
            <button class="btn-edit-img" type="button">
              <Icon icon="icon-mirror-x" />
              <span class="btn-edit-img-text">{{
                $t("popup.photos.actions.flipVertically")
              }}</span>
            </button>
            <button class="btn-edit-img" type="button">
              <Icon icon="icon-mirror-y" />
              <span class="btn-edit-img-text">{{
                $t("popup.photos.actions.flipHorizontally")
              }}</span>
            </button>
          </div>
          <div class="info-wrapper">
            <p class="info-text">
              {{ $t("popup.photos.textFirst") }}
            </p>
            <p class="info-text">
              {{ $t("popup.photos.textSecond") }}
            </p>
          </div>
        </div>
      </div>
      <div class="controls-btn">
        <Btn
          tag="button"
          btn-type="button"
          bg-color="bg-green-1"
          color="color-neutral-100"
          class="btn-save"
        >
          <template v-slot:content>
            <span>{{ $t("popup.photos.actions.save") }}</span>
            <Icon icon="icon-checkmark" />
          </template>
        </Btn>
        <Btn
          tag="button"
          btn-type="button"
          outline="outline teal-300"
          class="btn-cancel"
          @btn-click="hidePopup"
        >
          <template v-slot:content>
            <span>{{ $t("popup.photos.actions.cancel") }}</span>
          </template>
        </Btn>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Btn from "@/components/atoms/btn/index.vue";

import { mapMutations } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    Icon,
    Btn,
  },
  methods: {
    ...mapMutations({
      hidePopup: "popup/hidePopup",
    }),
  },
};
</script>

<style lang="scss" scoped>
.popup-photo {
  position: relative;
  z-index: var.$z-10;
  box-sizing: border-box;
  display: flex;
  margin: auto;
  padding: 15px;
  max-width: 100%;

  @media (min-width: var.$md) {
    max-width: 930px;
  }
}

.popup-wrapper {
  position: relative;
  z-index: var.$z-20;
  background-color: var.$neutral-100;
  border: 2px solid #ffcc66;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 12px;
  padding-bottom: 24px;
  max-width: 100%;
}

.popup-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: var.$lg) {
    flex-direction: row;
    margin-bottom: 24px;
  }
}

.photo-wrapper {
  flex-grow: 1;
  margin-bottom: 20px;

  @media (min-width: var.$lg) {
    margin-bottom: 0;
    margin-right: 24px;
  }
}

.image-wrapper {
  max-width: 480px;
  height: auto;
  width: 100%;

  @media (min-width: var.$lg) {
    width: 480px;
    height: 280px;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.popup-sidebar {
  @media (min-width: var.$lg) {
    flex-shrink: 0;
    width: 350px;
  }
}

.edit-photo-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @media (min-width: var.$lg) {
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.btn-edit-img {
  background-color: transparent;
  border: none;
  color: var.$teal-500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-right: 32px;
  padding: 0;
  width: fit-content;
  transition: color 0.3s;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: var.$green-1;
  }

  @media (min-width: var.$lg) {
    margin-right: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.btn-edit-img-text {
  font-size: 0;
  line-height: 0;
  display: inline-block;

  @media (min-width: var.$lg) {
    font-size: 16px;
    line-height: 24px;
    margin-left: 15px;
  }
}

.info-wrapper {
}

.info-text {
  color: var.$neutral-500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.controls-btn {
  display: flex;
  flex-direction: column;

  @media (min-width: var.$lg) {
    flex-direction: row;
  }
}

.btn-save {
  padding: 12px 18px;
  margin-bottom: 20px;

  @media (min-width: var.$lg) {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.btn-cancel {
  padding: 15px 18px;
}

.photo-title {
  position: relative;

  margin-top: 8px;
}

.title-icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0, -50%);

  width: 18px;
  height: 18px;

  color: var.$teal-300;

  pointer-events: none;
}

.title {
  width: 100%;

  padding-left: 28px;
  padding-top: 5px;
  padding-bottom: 5px;

  background-color: transparent;
  border: none;
  border-radius: 6px;

  color: var.$teal-500;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  &:focus {
    outline: none;

    box-shadow: 0 0 5px var.$violet-1;
  }

  &::placeholder {
    color: var.$neutral-500;
  }
}
</style>
