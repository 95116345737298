<template>
  <div class="menu-item">
    <component
      :is="isLink ? 'a' : 'div'"
      :href="isLink ? menu.link : false"
      class="menu-item__parent"
      :data-test="menu.slug"
      :class="{ 'menu-item__parent--active': isActive }"
      @click="clickParrent()"
    >
      <Icon
        class="menu-item__parent-icon"
        :class="{ isActive }"
        :icon="menu.icon"
      />

      <span
        class="menu-item__parent-title"
        :class="{
          'menu-item__parent-title--no-icon': !menu.icon,
          isActive,
        }"
        v-text="menu.title"
      >
      </span>

      <Icon
        v-if="menu.options.length"
        class="menu-item__parent-arrow"
        :class="{ 'menu-item__parent-arrow--active': dropdown, isActive }"
        icon="icon-arrow-right"
      />
    </component>

    <div
      class="menu-item__children"
      :class="{ 'menu-item__children--visible': dropdown }"
    >
      <a
        v-for="child in menu.options"
        v-text="child.title"
        :class="{
          isActiveChild: isActiveChild(child.link),
          isActive,
        }"
        :href="child.link"
        :key="child.link"
        :data-test="child.slug"
        @click="clickChild(child)"
      />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import { ytEvents } from "@/plugins/analytics";

export default {
  components: {
    Icon,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      links: [],
      dropdown: false,
    };
  },
  computed: {
    isActive() {
      return this.links.find((link) => fakeWindow.location.pathname.includes(link));
    },
    isLink() {
      return !this.menu.options.length;
    },
  },
  methods: {
    clickParrent() {
      if (!this.isLink) {
        this.dropdown = !this.dropdown;
      }
      this.trackEvent(this.menu.trackEvent);
    },
    clickChild(obj) {
      this.trackEvent(obj.trackEvent);
    },
    trackEvent(event) {
      if (event && !!event["category"] && !!event["name"]) {
        ytEvents.track(
          event["category"],
          event["name"],
          event["label"] || "",
          event["props"] || ""
        );
      }
    },
    isActiveChild(link) {
      return fakeWindow.location?.pathname === link;
    },
  },
  mounted() {
    this.links = this.menu.options.reduce(
      (acc, option) => [...acc, option.link],
      [this.menu.link]
    );
    if (this.isActive) {
      this.dropdown = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  width: 100%;
  margin-bottom: 8px;
  user-select: none;

  &__parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 8px 16px 8px 8px;
    border-radius: 8px;
    transition: background 200ms;
    cursor: pointer;

    &:hover {
      background: var.$neutral-200;
    }

    &--active {
      background: var.$neutral-200;
    }

    &-icon {
      width: 20px;
      height: 20px;
      color: var.$neutral-500;
    }

    &-title {
      width: 100%;
      padding-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var.$neutral-500;

      &--no-icon {
        padding-left: 29px;
      }
    }

    &-arrow {
      transform: rotate(270deg);
      transition: transform 200ms;
      color: var.$neutral-400;

      &--active {
        transform: rotate(90deg);
      }
    }
  }

  &__children {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 200ms;

    & > a {
      width: 80%;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      color: var.$neutral-500;
      margin: 4px 0 0 26px;
      padding-left: 10px;
      border-radius: 8px;
      transition: background 200ms;
      text-decoration: none;

      & >>> {
        text-decoration: none;
      }

      &:hover {
        background: var.$neutral-200;
      }
    }

    &:hover,
    &:active {
      overflow: auto;
    }

    &--visible {
      visibility: visible;
      max-height: 220px;
    }
  }

  .isActive {
    color: var.$teal-400;

    &Child {
      background: var.$neutral-200;
    }
  }
}

* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  ::-webkit-scrollbar {
    width: 4px !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }
}
</style>
