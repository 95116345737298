const globalRu = {
  multisuggest: {
    emptyList: "Список пуст",
    startSearching: "Начните вводит текст",
    emptyResult: "Совпадений не найдено. Попробуйте другой запрос.",
    maximumCountSelected: "Достигнуто маскимальное количество. Попробуйте удалить лишнее.",
  },
  multiselect: {
    select: "Выбрать",
    selected: "Выбрано",
    delete: "Удалить",
    noOptions: "Список пуст",
    noResult: "Нет результатов",
  },
  editorTextarea: {
    edit: "Редактировать",
    delete: "Удалить",
  },
  dropdownDraggableList: {
    empty: "Не заполнено",
    textTemplate: "День",
  },
  serviceListMultisuggestSearch: {
    placeholder: "Добавить локацию",
    actions: {
      delete: "Удалить",
    },
  },
  serviceListMultisuggest: {
    actions: {
      delete: "Удалить",
    },
  },
  serviceListMultisuggestField: {
    actions: {
      save: "Сохранить",
      delete: "Удалить",
    },
  },
  tourTitle: {
    actions: {
      edit: "Редактировать",
      save: "Сохранить",
    },
  },
  tourEditControlPanel: {
    lastSaveText: "Последнее сохранение",
    actions: {
      save: "Сохранить",
      view: "Просмотр",
      moderation: "Модерация",
      unpublish: "Снять с публикации",
      copy: "Создать копию",
      delete: "Удалить",
    },
    dictionaryStatus: {
      draft: "Черновик",
      hidden: "Скрыт",
      moderate: "На модерации",
      no_publish: "Не публиковать",
      publish: "Опубликован",
      delete: "Удален",
    },
  },
  breadcrumbs: {
    main: "Главная",
  },
  validation: {
    required: "Поле обязательно",
    number: "Значение должно быть числом",
    minLength: {
      text: "Имя должно иметь не менее",
      minLengthText: "букв",
    },
    rangeValue: {
      text: "Значение должно быть между",
      textAnd: "и",
    },
    minValue: {
      text: "Значение должно быть не менее",
    },
    date: {
      minValueFrom: "Дата должна быть не раньше",
      maxValueFrom: "Дата должна быть до даты завершения заезда",
      minValueTo: "Дата должна быть после даты начала заезда",
      minValueDiscountTo: "Дата должна быть после даты начала скидки",
      maxValueDiscountFrom: "Дата должна быть до даты завершения скидки",
      minValueRepeatTo: "Дата должна быть после даты завершения заезда",
      minValueExceptTo: "Дата должна быть после даты начала исключения",
      minValueTime: "Время должно быть минмиум 6 часов",
    },
    onlyNumeric: {
      text: "Только цифры",
    },
    email: "Некорректный email",
    incorrect: "Поле заполнено некорректно",
    server: "Ошибка сервера, попробуйте позже",
    client: "Произошла ошибка, попробуйте позже",
    network: "Ошибка сети",
  },
  actions: {
    save: "Сохранить",
    cancel: "Отмена",
  },
  clipboard: {
    copy: 'Скопировать',
    copied: 'Скопировано',
  },
  inviteLink: 'Ваша ссылка для друзей',
};
export { globalRu };
