const bookingPopupRu = {
  bookingPopup: {
    day: "день",
    fieldset: {
      chooseDates: "Выберите даты",
      participants: "Участников",
      customDates: "Свои даты",
      customDatesPlaceholder: "с — по",
      places: ["место", "места", "мест"],
      noPlaces: "мест нет",
    },
    chooseDates: "Выбрать даты",
    choose: "Выбрать",
    error: "Ошибка!",
    book: "Забронировать",
    fullyPaid: "Тур оплачен",
    payTour: "Оплатить тур",
    postPayTour: "Оплатить остаток",
    bookAgain: "Забронировать снова",
    awaitConfirm: "Ожидает подтверждения",
    bookShort: "Заброниров.",
    from: "от",
    left: "Осталось {free}&nbsp;из&nbsp;{total}&nbsp;мест",
    badges: {
      guarantee: {
        title: "Гарантия проведения",
        tooltip: "Тур будет проведен даже при неполной группе",
      },
      instant: {
        title: "Мгновенная бронь",
        tooltip: "Подтверждение сразу после оплаты",
      },
    },
    note: {
      prepay: "Вы&nbsp;пока ни&nbsp;за&nbsp;что не&nbsp;платите.",
      prepayFree: "Вы&nbsp;пока ни&nbsp;за&nbsp;что не&nbsp;платите.",
      cancel: "Полная отмена в&nbsp;течение 24&nbsp;часов",
      flexible: "Гибкие условия отмены",
      prepayment: "Предоплата",
    },
    individual: {
      trigger: "Или запросить индивидуальный тур",
      tooltip: "Можно организовать только для вас и&nbsp;ваших друзей",
      tooltipName: "Индивидуальный тур",
    },
    pers: "чел.",
    popupTitle: "Даты и&nbsp;участники",
    outOfPlaces: {
      text: {
        over: "К&nbsp;сожалению, места закончились.",
        leave: "Оставьте",
        pick: " контакты, и&nbsp;мы&nbsp;подберем вам тур",
      },
      submit: "Подберите мне тур",
    },
    duration: "Длительность ",
    installment: {
      tooltip: "Рассрочка от {symbol} {from} / мес.",
    },

    popup: {
      sandContact : {
        title: "Забронировать тур",
        name: "Имя",
        lastName: "Фамилия",
        error: {
          name: 'Не заполнено имя',
          lastName: 'Не заполнена фамилия',
          email: 'Не заполнен email',
          phone: 'Не заполнен телефон'
        },
        btn: "Заказать тур"
      },
      sendPhone: {
        title: "Укажите свой номер телефона",
        desc: "Вы сможете запросить обратный звонок от тревел-эксперта, если захотите проговорить вопросы голосом",
        btn: "Продолжить"
      },
      helpMe : {
        title: "Запрос на подбор тура",
        desc: "Оставьте свои контакты и мы свяжемся с Вами, чтобы помочь выбрать подходящий тур.",
        note: "Время работы наших менеджеров с 09:00 - 21:00 МСК",
        btn: "Оставить контакты",
        result: {
          title: "Спасибо за заявку!",
          text: "Мы свяжемся с вами в течение <b>10 минут</b> для того, чтобы помочь Вам выбрать подходящий тур."
        },
        wrongPhone: "Введен неверный номер телефона",
      },
      redirect: {
        textFirst: "Сейчас вы будете перенаправлены на страницу оплаты...",
        textSecond: "Кликните на «Перейти к оплате», если ничего не произошло",
        btn: "Перейти к оплате"
      },
      termsGeneral: {
        text1: "Отправляя форму вы соглашаетесь с условиями ",
        textOffer: "публичной оферты",
        text2: "и выражаете свое согласие на",
        textPrivacy: "обработку персональных данных."
      },
      termsBooking: {
        text1: "Нажимая «Заказать тур», я принимаю условия ",
        textOffer: "публичной оферты",
        text2: "и выражаю свое согласие на",
        textPrivacy: "обработку персональных данных."
      },

    },

    bonuses: {
      text: "бонусов | бонус | бонуса | бонусов",
      tooltip: "Можно потратить в следующих поездках на <a href='https://youtravel.me/'>YouTravel.me</a>",
    },

  },
};

export { bookingPopupRu };

