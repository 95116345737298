const generalRu = {
  common: {
    title: "Общее",
    fields: {
      tourName: {
        placeholder: "Название тура",
      },
      mainType: {
        placeholder: "Основной тип тура",
        options: [
          { id: 1, name: "Авторский тур" },
          { id: 2, name: "Обычный тур" },
        ],
        hint: {
          text: "Справочник типов туров",
          link: "Ссылка",
        },
      },
      tourActivity: {
        placeholder: "Активности тура",
        options: [
          { id: 1, name: "Сон" },
          { id: 2, name: "Еда" },
        ],
      },
      tourCountry: {
        placeholder: "Страна тура",
        options: [
          { id: 1, name: "Россия" },
          { id: 2, name: "США" },
          { id: 337, name: "Япония" },
        ],
      },
      selectRegion: {
        placeholder: "Регион",
        options: [
          { id: 1, name: "Россия" },
          { id: 2, name: "США" },
          { id: 337, name: "Япония" },
        ],
      },
      startTourCity: {
        placeholder: "Город (начните вводить)",
        title: "Старт путешествия",
        text: "Если города нет в списке, пропустите пункт",
      },
      startTourTime: {
        placeholder: "hh:mm",
      },
      finishTourCity: {
        placeholder: "Город (начните вводить)",
        title: "Финиш путешествия",
        text: "Если города нет в списке, пропустите пункт",
      },
      finishTourTime: {
        placeholder: "hh:mm",
      },
      linkSite: {
        placeholder: "Прямая ссылка на тур на вашем сайте",
      },
      checkboxTourOnlyLink: {
        label:
          "Тур доступен по прямой ссылке (не будет отображаться в каталоге)",
      },
      checkboxPlacedOnlyHere: {
        label:
          "Я размещаюсь только на Youtravel",
      },
    },
    nextButtonTitle: "К ценам и датам",
    days: "0 дней | {n} день | {n} дня | {n} дней",
    already: "Уже",
    save: "Сохранить",
    saved: "Сохранено",
    organizer: "Организатор",
    verified: "Надежный",
    contact: "Написать",
    askQuestion: "Задать вопрос",
    answerTime: "Среднее время ответа ",
    terms:
      'Отправляя форму вы&nbsp;соглашаетесь с&nbsp;условиями <a href="/help/public-offer" target="_blank">публичной оферты</a> и&nbsp;выражаете свое согласие на&nbsp;<a href="/help/privacy-agree" target="_blank">обработку персональных данных</a>',
  },
  tourLeader: {
    title: "Лидер тура",
    fields: {
      selectLeader: {
        placeholder: "Выберите турлидера",
      },
      fieldName: {
        placeholder: "Фамилия и имя гида",
      },
      descriptionLeader: {
        placeholder: "Информация о гиде",
      },
    },
    addNewTourLeader: "Добавить нового тур лидера",
    uploadTourLeaderPhoto: "Загрузить фото гида",
    warning:
      "Путешественники оставляют негативные отзывы, если вместо обещанного гида видят другого. Пожалуйста, указывайте актуальную информацию о том, кто будет сопровождать группу",
  },
  languageInterface: {
    fields: {
      mainLanguage: {
        options: [
          {
            id: "1",
            name: "Русский",
            value: "ru",
          },
          {
            id: "2",
            name: "English",
            value: "en",
          },
        ],
        placeholder: "Выберите язык",
      },
      translate: {
        title: "Основной язык описания тура",
        text: "Тур будет автоматически переведен на иностранный язык",
        label: "Я хочу сам перевести тур",
      },
    },
  },
  pagination: {
    text: "{current} из {total}",
  },
};

export { generalRu };
