import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

const axiosFiles = {
  postNewPhoto(data) {
    return axiosYtApiV2.post(`/files`, data);
  },

  editFile({ fileId, body }) {
    return axiosYtApiV2.post(`/files/${fileId}`, body);
  },

  deleteFile(fileId) {
    return axiosYtApiV2.post(`/files/${fileId}/delete`);
  },
};

export { axiosFiles };
