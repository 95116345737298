<template>
  <svg class="input_dropdown" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="transparent">
    <path d="M15 7.5L10 12.5L5 7.5" :stroke="color || '#000'" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: ['color'],
}
</script>
<style scoped>
.input_dropdown {
  fill: transparent !important;
}
</style>
