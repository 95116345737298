const popupEn = {
  popup: {
    photos: {
      actions: {
        ternLeft: "Turn left",
        ternRight: "Turn right",
        flipVertically: "Flip vertically",
        flipHorizontally: "Flip horizontally",
        save: "Save",
        cancel: "Cancel",
      },
      textFirst:
        "Photo resolution must be at least 600 by 600 pixels, otherwise the photo will not be uploaded to the gallery.",
      textSecond: "You can edit the name or leave the uploaded file name",
    },
    cancel: {
      title:
        "Specify the terms of cancellation of the tour, the text will be generated automatically",
      actions: {
        save: "Save",
        cancel: "Cancel",
      },
    },
  },
  yes: 'Yes',
  no: 'No',
  ok: 'OK',
  draftPopup: {
    title: 'Moderation',
    description: 'You are about to submit your tour for moderation. It will take up to one day and will make the tour available for the travellers',
  },
  moderatePopup: {
    title: 'Moderation',
    description: 'You are about to submit your tour for moderation. It will take up to one day and will make the tour available for the travellers',
  },
  publishPopup: {
    title: 'You are about to send the tour for moderation',
    description: 'It will be not suggested to the travellers as we are checking it. Usually it takes several days. When your tour is checked, it will be available for the tourists',
  },
  moderateStatus: {
    title: 'Hold tight, your tour is being moderated',
    description: 'As soon as we check it, its status will change to ‘Published’ and it will be available for the travellers to book.',
  },
  savePopup: {
    title: 'Moderation',
    description: 'You are about to submit your tour for moderation. It will take up to one day and will make the tour available for the travellers.',
  },
  saveToDraftPopup: {
    title: 'Changes to the tour',
    description: 'You have modified the trip details, and the amendments require moderation. We will change the trip status to "Draft", and the trip will be unlocked for modification. Please do not forget to click on "Save" button, once all changes are introduced. Important notice: after saving please send the trip for moderation. Upon moderation is complete, your amended trip will be published.',
  },
  unsavedPopup: {
    title: 'You have unsaved changes',
    description: 'Click “Save” to publish your changes',
  },
  deletePhoto: {
    title: 'Tour photos',
    description: 'Tour must have at least 7 photos. To delete this photo, first add another one.',
  },
  deleteParentDay: {
    title: 'Delete this day?',
    description: 'If you delete this day, the days copied from it will also be deleted.',
  },
  linkAccessPopup: {
    title: '',
    description: 'The option "Tour available only via direct link" is selected, so travelers cannot see the tour in the catalog. Are you sure you want to hide the tour from the website visitors?',

  },
  save: 'Save',
};
export { popupEn };
