export default {
  bannerLK: {
    tellSmth: 'Расскажите нам о себе, чтобы создать рекомендации',
    goal: 'Так мы сможем рекомендовать ваши туры, подходящим путешественникам',
    toFill: 'Заполнить',
  },
  bannerTours: {
    motivation: {
      a: 'Найдите группу с',
      b: 'близкими по духу',
      c: 'людьми',
    },
    toDo: 'Заполните опросник за 2 минуты, и мы подберем вам идеальные для вас группы',
    start: 'Подберите мне группу',
  },
  survey: {
    city: "Введите ваш город",
    cityPlaceholder: "Город",
    select: "Выбрать",
    continue: "Продолжить",
    chooseMaxCount: "Выберите {count} категорий",
    chooseMore: "Выберите {count} еще категорий",

    journeyGoal: "Что вы ожидаете от путешествий?",
    "unexpectedDirectinos": "🧭 Новые, неожиданные направления",
    "activeRest": "🎒 Активного, а не пассивного отдыха",
    "samePeople": "🤛 Компанию по возрасту и интересам",
    "extendNetwork": "🤝 Расширить свой круг общения",
    "seeMany": "🌎 За короткое время посмотреть много",

    tourFrequency: "Как часто вы путешествуете?",
    "more_once_month": "Раз в месяц +",
    "once_month": "Каждый месяц",
    "3-4_times_year": "3-4 раза в год",
    "once_year": "1 раз в год",
    "rare_once_year": "Реже, чем раз в год",

    age: "Сколько вам лет?",
    lessThan: "Меньше ",
    moreThan: "Больше ",

    interests: "Что вам интересно?",
    "museums": "🏛 Музеи",
    "places": "👁️ Интересные места",
    "architecture": "🏙 Архитектура",
    "history": "⚔️️ История",
    "art": "👁️ Искусство",
    "theatre": "🎭️ Театры",
    "shopping": "🛍️ Шоппинг",
    "bars": "🍷️ Бары и рестораны",
    "yacht": "⛵️ Прогулки на яхте",
    "art-space": "🎨️ Творческие пространства",
    "child": "👦 Для детей",
    "photo": "📸️ Фотография",
    "walk": "👣️ Пешие прогулки",
    "nature": "🌳️ Природа и парки",
    "inductrial": "🏭 Индастриал",
    // FIXME keys below are incorrect
    // "museums": "Трущобы",
    // "museums": "Мастерские и лофты",

    introvertedness: "Оцените вашу интровертность",
    introvertedness_description: "Где 1 – максимальный интроверт, 5 – максимальный экстраверт",
    questionnaireAnswers: "Ответьте на вопрос",
    personal_info: "Последний шаг",
    name: "Имя",
    email: "Электронная почта",

    pleaseWait: 'Пожалуйста, подождите...',
    waitAWhile: 'Обработка займет до {duration} секунд',

    ready: 'Готово',
    weFound: `Мы нашли {count} групп подходящие под ваши критерии, вы можете перейти к ним, либо уточнить поиск предоставив дополнительную информацию`,
    showMatches: 'Показать путешествия',
    refineCriteria: "Уточнить критерии",
  },
  multisuggest: {
    emptyList: "Список пуст",
    emptyResult: "Совпадений не найдено. Попробуйте другой запрос.",
  },
};
