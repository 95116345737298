const popupClaimPdfEn = {
  popupClaimPdf: {
    trigger: "Claim brochure in&nbsp;PDF",
    label: "Type your e-mail",
    title: "Claim brochure in&nbsp;PDF",
    text: "Enter your email to&nbsp;get the brochure containing all the important information right to&nbsp;your inbox.",
    button: "Claim",
    success: {
      title: "Brochure successfully sent!",
      text: "We&rsquo;ve sent the brochure to&nbsp;{email}. Check your inbox!",
    },
  },
};

export { popupClaimPdfEn };
