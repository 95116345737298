const locationNearbyEn = {
  locationNearby: {
    trigger: {
      text: "Search nearby",
    },
    denied: "No&nbsp;access to&nbsp;geolocation",
    popup: {
      text: "To&nbsp;see tours nearby, you need to&nbsp;turn on&nbsp;geolocation",
      button: "Allow access",
    },
    label: "Tours nearby",
    hint: "Show all tours up to 600 miles away from you",
  },
};

export { locationNearbyEn };
