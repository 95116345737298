const photosRu = {
  photos: {
    title: "Фотографии тура",
    info: {
      title: "Требования к размещаемым фотографиям",
      textFirst:
        "Добавьте не менее 7 фотографий, первая из них станет обложкой тура на предпросмотре.",
      textSecond:
        "Разрешение фотографии должно быть не менее чем 600 на 600 пикселей иначе фотография не будет загружена в галерею.",
      textThird:
        "НЕ используйте стоковый контент и материалы других фотографов без их разрешения. Это является нарушением авторского права и может привести к судебным разбирательствам и штрафам.",
      footer: {
        text: "Подробнее о том, где искать и как правильно использовать фото и видео для своих туров",
        textLink: "смотрите в статье.",
      },
    },
    buttons: {
      add: {
        text: "Добавить фото",
      },
      deleteAll: {
        text: "Удалить все фото",
      },
    },
    navButtons: {
      navPrev: {
        text: "Назад",
      },
      navNext: {
        text: "К вопросам",
      },
    },
  },
};
export { photosRu };
