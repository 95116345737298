<template>
  <div class="yt-drop-zone"
    draggable="false"
    @drop.capture.prevent="dropHandler"
    @dragstart.prevent="dragStartHandler"
    @dragover.prevent>
      <UploadFile
        v-for="file in files"
        :key="file.id"
        :id="file.id"
        :path="file.path"
        class="yt-drop-zone-item"
        @delete-file="deleteFileHandler"
      />
    <div class="yt-drop-zone__empty" v-if="maxItemsAmount!==0 && files.length < maxItemsAmount">
      <UploadFile
        v-for="(item, index) in getMinItemsAmount"
        :key="item"
        :text="text"
        :load="index < loadAmount"
        :is-zone="true"
        class="yt-drop-zone-item"
        @upload-file="uploadFileHandler"/>
    </div>
    <div class="yt-drop-zone__empty" v-if="maxItemsAmount==0">
      <UploadFile
        v-for="(item, index) in getMinItemsAmount"
        :key="item"
        :text="text"
        :load="index < loadAmount"
        :is-zone="true"
        class="yt-drop-zone-item"
        @upload-file="uploadFileHandler"/>
    </div>
  </div>
</template>

<script>
import UploadFile from "@/components/ui-kit/yt-upload-file/index.vue";

export default {
  components: {
    UploadFile
  },
  props: {
    minItemsAmount: {
      type: Number,
      default: 3,
    },
    maxItemsAmount: {
      type: Number,
      default: 0,
    },
    files: {
      type: Array,
      default: () => {
        return [];
      },
    },
    text: {
      type: String
    },
    loadAmount: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getMinItemsAmount() {
      return Math.max(this.minItemsAmount - this.files.length, 1);
    },
  },
  methods: {
    async deleteFileHandler(fileId) {
      if(!this.disabled) {
        this.$emit("delete-file", fileId);
      }
    },
    uploadFileHandler(formData) {
      if(!this.disabled) {
        this.$emit("drop-files", {formData: formData, amount: 1});
      }
    },
    dragStartHandler(evt) {
      if(!this.disabled) {
        evt.dataTransfer.setData("text/plain", evt.target.id);
      }
    },
    dropHandler(evt) {
      if(!this.disabled) {
          let formData = new FormData();
          let uploadedAmount = 0;
          this.maxItemsAmount = parseInt(this.maxItemsAmount);

          if(this.maxItemsAmount) {
            let availableAmountToUpload = this.maxItemsAmount - this.files.length;
            uploadedAmount = evt.dataTransfer.items.length  > availableAmountToUpload
              ? availableAmountToUpload
              : evt.dataTransfer.items.length;
          }
          else {
            uploadedAmount = evt.dataTransfer.items.length ;
          }

          if (uploadedAmount) {
            for (let i = 0; i < uploadedAmount; i++) {
              if (evt.dataTransfer.items[i].kind === 'file') {
                let file = evt.dataTransfer.items[i].getAsFile();
                formData.append('file[' + i + ']', file);
              }
            }
          }
          this.$emit("drop-files", {formData: formData, amount: uploadedAmount});
        }
      },
  },
}
</script>

<style scoped lang="scss">
.yt-drop-zone {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -20px;
}
.yt-drop-zone-item {
  margin-left: 20px;
  margin-bottom: 20px;
}
.drag-n-drop__drop-zone {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -20px;
}
.yt-drop-zone__empty{
  display: flex;
  flex-wrap: wrap;
  /*margin-left: -20px;*/
  margin-bottom: -20px;
}
</style>
