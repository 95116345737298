const serpEn = {
  serp: {
    tours: {
      resetFilters: "Reset all filters",
      noFilters: "Filters not selected",
      foundTours: {
        mobile: "{total} found",
        desktop: "We've found {total} adventures",
      },
      sort: "Sort by:",
      sortBy: {
        recommended: "Recommended",
        popular: "Popular",
        new: "New",
        closest: "Dates",
        priceLow: "Price: Low to High",
        priceHigh: "Price: High to Low",
        discount: "Discount",
      },
      view: {
        list: "List",
        tile: "Tile"
      },
      nothingFound: "Nothing found",
      tryToChangeFilters: "Try changing your search or resetting filters",
      showMore: "Show more",
    },
    filters: {
      reset: "Reset",
      duration: "Trip duration",
      durationFrom: "From (days)",
      durationTo: "To (days)",
      durationDays: "days | day | days",
      travelType: "Travel style",
      allCheckstypes: "All styles",
      allCheckslanguages: "All languages",
      price: "Price",
      from: "From",
      to: "To",
      discount: "Only with discounts",
      cashback: "Tours with cashback",
      installment: "Можно в рассрочку",
      cashbackHelper: `Only tours with 20% cashback with "Mir" card`,
      comfort: "Lodging level",
      comfortChecks: [
        "Tents or campsites",
        "1* hotels or guesthouses",
        "2* hotels or apartments",
        "3* hotels or villas",
        "4-5* hotels or glamping",
      ],
      ages: "Travelers' age",
      children: "Suitable for children",
      childrenHelper: "Select the age of the youngest child:",
      childrenPlaceholder: "Age not selected",
      childrenAges: {
        all: "All",
        year: "under one year old | {n} year | {n} years",
      },
      activities: "Level of endurance",
      activitiesChecks: [
        "Relaxing",
        "Easy",
        "Moderate",
        "Serious",
        "Heart-pumping",
      ],
      guarantees: "Confirmed tours",
      guaranteesHelper:
        "Display only trips that have hit the minimum number of travelers and are on!",
      private: "Private tours",
      privateHelper:
        "A private tour can be arranged just for you and your friends",
      languages: "Group languages",
      spaces: "Number of seats",
      maxSpaces: "Maximum number of participants",
      spacesHelper: "What is the size of your group?",
      spacesCounter: "{n} seats | {n} seat | {n} seats",
      rating: "Travel expert's rating",
      ratingChecks: [
        "Any",
        "Excellent or higher",
        "Very good or higher",
        "Good or higher",
        "Satisfactory or higher",
      ],
      checksMoreCount: "{count} more",
      trigger: "Filters",
      showTours: "Show adventures",
    },
    tourCard: {
      new: "New",
      places: ["1 place", "places"],
      moreDates: ["date", "dates", "dates"],
      recommended: "Recommended!",
      blackFriday: "Black-Friday",
      private_label: "Private tour",
      cashback: "20% cashback",
      sponsored: "Sponsored",
      durationDays: "day|days|days",
      durationNights: "night|nights|nights",
      durationHours: "hour|hours|hours",
      route: "location|locations|locations",
      routeTitle: "Route",
      hide: 'Hide',
      viewTour: 'Show more'
    },
    topMenu: {
      search: "Show",
      searchLong: "Show tours",
      searchMob: "Search tours",
      where: "Where do you want to go?",
      better: "The best match",
      popular: "Popular",
      countries: "Countries",
      continents: "Continents",
      regions: "Regions",
      destinations: "Directions",
      history: "Search history",
      types: "Tour types",
      tours: "Tours",
      when: "Month or dates",
      monthOrPeriod: "Month or period",
      exactDates: "Exact dates",
      additionalDays: "± 3 days",
      manyDays: "Multi days",
      oneDay: "One day",
      unusual: "Unusual",
      allDirections: "All directions",
      allDates: "Any dates"
    },
    hiw: {
      button: "How it works",
      smallHeader: "What are author tours?",
      bigHeader: "This is a new approach of exciting trips to unique places",
      image: [
        {
          text: "Unconventional routes",
          subtext:
            "And friendly local places from the verified experts across the world",
        },
        {
          text: "Small group travel",
          subtext: "The opportunity to travel with like-minded people",
        },
        {
          text: "No worries",
          subtext:
            "Accommodation, food and activities are already organized for you by the tour author",
        },
      ],
      gray: [
        {
          text: "Best Price Guarantee",
          subtext: "We offer the best price for the service",
        },
        {
          text: "Verified travel experts",
          subtext: "Reliable tour arrangement by a travel expert",
        },
        {
          text: "24/7 Support",
          subtext: "We answer your questions and help you find the best tour",
        },
        {
          text: "Secure Payments",
          subtext: "Secure payment and full guarantee on all services",
        },
      ],
      bookHeader: "How to book a trip?",
      book: [
        {
          text: "Select your destination",
          subtext: "More than 30,000 tours from 5,600 verified travel experts",
        },
        {
          text: "Specify all details",
          subtext:
            "Write to the travel expert and ask pertinent questions about the program",
        },
        {
          text: "Book your tour",
          subtext:
            "Reserve a place with an advance payment. From 15% of the price of the tour",
        },
      ],
      closeButton: "Close",
    },
    seo: {
      readMore: "Read more",
      showMore: "Show more",
      actionConditions: "Terms of promotion:",
      toursByMonth: "Tours by month",
      toursByRoutes: "Tours from other cities",
    },
  },
};
export { serpEn };
