<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :type="type"
    class="button-arrow"
    :class="{ _disabled: $attrs.disabled }"
    @click="$emit('click')"
  >
    <span class="button-arrow__icon u-decor">
      <svg-inline tag="span" :dir="dir" :flip="flip">
        <icon icon="icon-angle-up" />
      </svg-inline>
    </span>
  </component>
</template>

<script>
import { buttonTypeComputed } from "@/assets/js/composables/buttonTypeComputed";
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  components: {
    SvgInline,
    Icon,
  },
  inheritAttrs: false,
  props: {
    dir: {
      type: String,
      default: undefined,
    },
    flip: {
      type: String,
      default: undefined,
    },
    tag: {
      type: String,
      default: "button",
    },
  },
  computed: {
    type: buttonTypeComputed,
  },
};
</script>

<style lang="scss" scoped>
.button-arrow {
  @include mixin.clickable();
  box-sizing: border-box;
  width: 28px;
  max-width: 100%;
  display: inline-flex;
  vertical-align: middle;
  border: none;
  border-radius: 50%;
  outline: none;
  background: var.$gray-light;
  padding: 0;
  transition: var.$transition-fast;

  &:hover {
    background: var.$gray-hover;
  }

  &:active {
    background: var.$gray-dark;
    transition-duration: 0s;
  }

  &:disabled,
  &._disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__icon {
    display: block;
    width: 100%;
    max-width: 16px;
    margin: percentage(func.divide(6, 28));

    svg {
      transition: var.$transition-fast;
    }
  }
}
</style>
