export default new DataCollector();
import { i18n } from '@/plugins/i18n';
// duplicated local/templates/youtravel/src/js/globals/DataCollector.js
/*eslint-disable */
function DataCollector(){
  let _self = this;
  let user_agent = "";

  this._request = async function(payload){
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', '/api/v2/data/collect');
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.responseType = 'json';
      xhr.send(JSON.stringify([payload]));

      xhr.onload = function() {
        let responseObj = xhr.response;
        if (xhr.status !== 200) { // анализируем HTTP-статус ответа, если статус не 200, то произошла ошибка
          reject(responseObj)
        } else { // если всё прошло гладко, выводим результат
          resolve(responseObj);
        }
      };
    })
  }

  this.setCookie = function(name, value, options = {}){
    options = {
      path: '/',
      secure: true,
      // при необходимости добавьте другие значения по умолчанию
      ...options
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  this.getCookie = function(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  this._getRef = function(){
    return _self.getCookie('ref') || "";
  }

  this._getUtm = function(){
    let utm = _self.getCookie('utm') || "";
    if(utm !== "") {
      return JSON.parse('{"' + utm.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
        return key === "" ? value : decodeURIComponent(value)
      });
    }
    return false;
  }

  //generates random id;
  this.guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + s4() +  s4() + s4() + s4() + s4() + s4();
  }

  this.trackEvent = function(category, event, payload){
    let user_id = _self.getCookie('BITRIX_SM_USER_BX_ID');
    let guest_id = _self.getCookie('BX_USER_ID');
    let gclid = _self.getCookie('_ga');

    if(!guest_id){
      guest_id = _self.guid();
      let date = new Date(Date.now() + 365*86400000);
      _self.setCookie('BX_USER_ID', guest_id, {expires: date.toUTCString()})
    }

    payload.lang = i18n.locale;
    if(!!gclid){
      payload.gclid = gclid;
    }

    if(!!_self._getRef()){
      payload.ref = _self._getRef() || "";
    }

    if(!!window && !!fakeWindow.location){
      payload.location = fakeWindow.location
    }

    if(typeof fakeWindow.amplitudeUserProperties != "undefined"){
      payload.amplitudeUserProps = fakeWindow.amplitudeUserProperties;
    }

    let data = {
      'user_id': parseInt(user_id),
      'guest_id': guest_id,
      'user_agent': _self.user_agent,
      'utm': JSON.stringify(_self._getUtm()) || "",
      'event_category': category,
      'event_action': event,
      'event_payload': JSON.stringify(payload)
    };

    _self._request(data)
      .then((result) => {});
  }

  this._init = function(){
    _self.user_agent = typeof navigator != "undefined" ? navigator.userAgent : "";
  }
  _self._init();
}

youtravel = youtravel || {};
if(typeof youtravel.DataCollector == "undefined"){
  youtravel.DataCollector = new DataCollector();
}
