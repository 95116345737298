<template>
  <component
    :is="tag"
    class="svg-inline"
    :class="{ [`svg-inline-${name}`]: name }"
    :data-dir="dir"
    :data-flip="flip"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "div",
    },
    name: {
      type: String,
      default: undefined,
    },
    dir: {
      type: String,
      default: undefined,
      validator(prop) {
        return [undefined, "up", "right", "down", "left"].includes(prop);
      },
    },
    flip: {
      type: String,
      default: undefined,
      validator(prop) {
        return [undefined, "horizontal", "vertical"].includes(prop);
      },
    },
  },
};
</script>

<style lang="scss">
$b: ".svg-inline";

#{$b} {
  @include mixin.aspect-ratio();
  position: relative;
  display: block;
  width: 100%;

  &[data-dir="up"] svg {
    transform: rotate(0);
  }

  &[data-dir="right"] svg {
    transform: rotate(90deg);
  }

  &[data-dir="down"] svg {
    transform: rotate(180deg);
  }

  &[data-dir="left"] svg {
    transform: rotate(270deg);
  }

  &[data-flip="horizontal"] svg {
    transform: scale(-1) rotateX(180deg);
  }

  &[data-flip="vertical"] svg {
    transform: scale(-1) rotateY(180deg);
  }

  svg {
    @include mixin.fill-abs();
    transform-origin: 50% 50%;
  }

  //&-example {
  //  @include mixin.aspect-ratio(width, height);
  //}

  &--16{
    display: inline-block!important;
    width: 16px;
    vertical-align: text-bottom;
  }

  &-tooltip {
    &-arrow {
      @include mixin.aspect-ratio(20, 8);
    }
  }

  &-bubble-corner {
    @include mixin.aspect-ratio(26, 10);
  }

  &-yt {
    &-tooltip {
      &-arrow {
        @include mixin.aspect-ratio(26, 10);
      }
    }
  }
}
</style>
