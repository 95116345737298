<template>
  <label
    class="label-file"
    :class="[this.bgColor, this.outline, this.color]"
    :for="getId"
  >
    <span> <slot name="text"></slot></span>
    <span v-if="$slots.icon" class="label-icon">
      <slot name="icon"></slot>
    </span>
    <input
      :id="getId"
      ref="input"
      class="input-file"
      type="file"
      accept="image/png, image/jpeg, image/webp"
      multiple
      @change="change($event)"
    />
  </label>
</template>

<script>
import { getUniqueId } from "@/assets/js/utils/get-unique-id";

export default {
  props: {
    bgColor: {
      type: String,
      default: "",
      validator: function (prop) {
        return ["", "bg-green-1"].indexOf(prop) !== -1;
      },
    },
    outline: {
      type: String,
      default: "",
      validator: function (prop) {
        return ["", "outline teal-500"].indexOf(prop) !== -1;
      },
    },
    color: {
      type: String,
      default: "",
      validator: function (prop) {
        return ["", "color-neutral-100"].indexOf(prop) !== -1;
      },
    },
  },
  computed: {
    getId: () => {
      return getUniqueId("input-file");
    },
  },
  methods: {
    change(event) {
      this.$emit("change-input", event);
      this.$refs.input.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.label-file {
  border-radius: 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}

.label-icon {
  line-height: 0;
  margin-left: 15px;
}

.input-file {
  display: none;
}

// Background
.label-file {
  &.bg-green-1 {
    background-color: var.$green-1;

    &:hover {
      background-color: darken(var.$green-1, 10%);
    }
  }
}

// Color
.label-file {
  &.color-neutral-100 {
    color: var.$neutral-100;
  }
}

// Outline
.label-file {
  &.outline {
    background-color: transparent;

    &.teal-500 {
      border: 1px solid var.$teal-500;
      color: var.$teal-500;

      &:hover {
        background-color: var.$teal-500;
        color: var.$neutral-100;
      }
    }
  }
}
</style>
