<template>
  <div class="custom-modal__wrapper">
    <div class="custom-modal__blur" @mousedown.self="$emit('close')">
      <div class="custom-modal">
        <VueFocusLock class="custom-modal__focus-lock">
          <FocusTrap :returnFocusOnDeactivate="false" class="custom-modal__focus-trap">
            <slot name="icon" class="custom-modal__icon"></slot>
            <slot></slot>
          </FocusTrap>
        </VueFocusLock>
      </div>
    </div>
  </div>
</template>

<script>
import VueFocusLock, { FocusTrap } from 'vue-focus-lock'
export default {
  components: {
    VueFocusLock,
    FocusTrap,
  }
};
</script>

<style lang="scss" scoped>
.custom-modal {
  display: flex;
  width: 90%;
  max-width: 980px;
  border-radius: 24px;
  background: #fff;
  margin: auto;

  &__focus-lock{
    width: 100%;
  }

  &__wrapper {
    position: relative;
  }

  &__blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #22222295;
    z-index: 100000;

    display: flex;
    align-items: center;
    padding: 56px 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
