const howItWorksEn = {
  howItWorks: {
    what: {
      question: "What are small group adventures?",
      title:
        "This is&nbsp;a&nbsp;new approach of&nbsp;exciting trips to&nbsp;unique places",
      items: [
        {
          image: "/static/i/how-it-works/what-item-1.jpg",
          title: "Unconventional routes",
          text: "And friendly local places from the verified experts across the world",
        },
        {
          image: "/static/i/how-it-works/what-item-2.jpg",
          title: "Small group travel",
          text: "The opportunity to&nbsp;travel with like-minded people",
        },
        {
          image: "/static/i/how-it-works/what-item-3.jpg",
          title: "No&nbsp;worries",
          text: "Accommodation, food and activities are already organized for you by&nbsp;the tour author",
        },
      ],
    },
    benefits: [
      {
        icon: "thumbs-up",
        title: "Best Price Guarantee",
        text: "We&nbsp;offer the best price for the service",
      },
      {
        icon: "checkmark-circle-outline",
        title: "Verified travel experts",
        text: "Reliable tour arrangement by&nbsp;a&nbsp;travel expert",
      },
      {
        icon: "phone-talk",
        title: "24/7 Support",
        text: "We&nbsp;answer your questions and help you find the best tour",
      },
      {
        icon: "shield-outline",
        title: "Secure Payments",
        text: "Secure payment and full guarantee on&nbsp;all services",
      },
    ],
    steps: {
      title: "How to&nbsp;book a&nbsp;trip?",
      list: [
        {
          title: "<a href='/tours'>Select</a> your destination",
          text: "More than 30,000 tours from 5,600 verified travel experts",
        },
        {
          title: "Specify all details",
          text: "Write to&nbsp;the travel expert and ask pertinent questions about the program",
        },
        {
          title: "Book your tour",
          text: "Reserve a&nbsp;place with an&nbsp;advance payment. From&nbsp;15% of&nbsp;the price of&nbsp;the tour",
        },
      ],
    },
    close: "Close",
  },
};

export { howItWorksEn };
