const questionnaireRu = {
  questionnaire: {
    journeyGoal: "Что вы ожидаете от путешествий?",
    unexpectedDirectinos: "Новые, неожиданные направления",
    activeRest: "Активного, а не пассивного отдыха",
    samePeople: "Компанию по возрасту и интересам",
    extendNetwork: "Расширить свой круг общения",
    seeMany: "За короткое время посмотреть много",

    tourFrequency: "Как часто путешествуете?",
    more_once_month: "Раз в месяц +",
    once_month: "Каждый месяц",
    "3-4_times_year": "3-4 раза в год",
    once_year: "1 раз в год",
    rare_once_year: "Реже, чем раз в год",

    interests: "Что вам интересно?",
    museums: "Музеи",
    places: "Интересные места",
    architecture: "Архитектура",
    history: "️История",
    art: "Искусство",
    theatre: "Театры",
    shopping: "Шоппинг",
    bars: "Бары и рестораны",
    yacht: "Прогулки на яхте",
    "art-space": "Творческие пространства",
    child: "Для детей",
    photo: "Фотография",
    walk: "Пешие прогулки",
    nature: "Природа и парки",
    inductrial: "Индастриал",
  },
};
export { questionnaireRu };
