const dropdownTriggerRu = {
  dropdownTrigger: {
    default: {
      extended: "Свернуть",
      shrunk: "Показать полностью",
    },
    description: {
      extended: "Свернуть описание",
      shrunk: "Развернуть описание",
    },
    more: {
      extended: "Показать меньше",
      shrunk: "Показать еще",
    },
    all: {
      extended: "Свернуть",
      shrunk: "Показать все",
    },
    days: {
      extended: "Свернуть все дни",
      shrunk: "Раскрыть все дни",
    },
  },
};

export { dropdownTriggerRu };
