const generalEn = {
  common: {
    title: "General",
    fields: {
      tourName: {
        placeholder: "Tour name",
      },
      mainType: {
        placeholder: "Main travel style",
        options: [
          { id: 1, name: "Author's tour" },
          { id: 2, name: "Regular tour" },
        ],
        hint: {
          text: "Tour types reference",
          link: "Link",
        },
      },
      tourActivity: {
        placeholder: "Additional travel styles",
        options: [
          { id: 1, name: "Dream" },
          { id: 2, name: "Food" },
        ],
      },
      tourCountry: {
        placeholder: "Tour country",
        options: [
          { id: 1, name: "Russia" },
          { id: 2, name: "USA" },
          { id: 337, name: "Japan" },
        ],
      },
      selectRegion: {
        placeholder: "Region",
        options: [
          { id: 1, name: "Russia" },
          { id: 2, name: "USA" },
          { id: 337, name: "Japan" },
        ],
      },
      startTourCity: {
        placeholder: "City (start typing)",
        title: "Start of the trip",
        text: "If the city is not in the list, skip the item",
      },
      startTourTime: {
        placeholder: "hh:mm:",
      },
      finishTourCity: {
        placeholder: "City (start typing)",
        title: "Finish of the trip",
        text: "If the city is not in the list, skip the item",
      },
      finishTourTime: {
        placeholder: "hh:mm",
      },
      linkSite: {
        placeholder: "Link to the tour on your website",
      },
      checkboxTourOnlyLink: {
        label:
          "The tour is available via a direct link (will not appear in the catalog)",
      },
      checkboxPlacedOnlyHere: {
        label:
          "I am hosted only on Tripsider",
      },
    },
    nextButtonTitle: "Prices and dates",
    days: "{n} days | {n} day | {n} days",
    already: "Already",
    save: "Save",
    saved: "Saved",
    organizer: "Organizer",
    verified: "Verified",
    contact: "Contact",
    askQuestion: "Ask a question",
    terms:
      'By&nbsp;submitting the form, you agree to&nbsp;the terms &amp;&nbsp;conditions <a href="/help/public-offer" target="_blank">public offer</a> and agree to&nbsp;<a href="/help/privacy-agree" target="_blank">processing of&nbsp;personal data</a>',
  },
  tourLeader: {
    title: "Tour leader",
    fields: {
      selectLeader: {
        placeholder: "Choose a tour leader",
      },
      fieldName: {
        placeholder: "Surname and name of the guide",
      },
      descriptionLeader: {
        placeholder: "Guide information",
      },
    },
    addNewTourLeader: "Добавить нового тур лидера",
    uploadTourLeaderPhoto: "Загрузить фото гида",
    warning:
      "Travelers leave negative reviews if they meet another guide instead of promised one. Please, put the actual information about the tour leader",
  },
  languageInterface: {
    fields: {
      mainLanguage: {
        options: [
          {
            id: "1",
            name: "Русский",
            value: "ru",
          },
          {
            id: "2",
            name: "English",
            value: "en",
          },
        ],
        placeholder: "Choose language",
      },
      translate: {
        title: "Tour main description language",
        text: "The tour will be automatically translated into a foreign language",
        label: "I want to translate the tour myself",
      },
    },
  },
  pagination: {
    text: "{current} of {total}",
  },
};

export { generalEn };
