const popupRu = {
  popup: {
    photos: {
      actions: {
        ternLeft: "Повернуть влево",
        ternRight: "Повернуть вправо",
        flipVertically: "Отразить по вертикали",
        flipHorizontally: "Отразить по горизонтали",
        save: "Сохранить",
        cancel: "Отменить",
      },
      textFirst:
        "Разрешение фотографии должно быть не менее чем 600 на 600  пикселей, иначе фотография не будет загружена в галерею.",
      textSecond:
        "Вы можете отредактировать название или оставить подгрузившееся название файла",
    },
    cancel: {
      title:
        "Укажите условия отмены тура, текст будет сгенерирован автоматически",
      actions: {
        save: "Сохранить",
        cancel: "Отменить",
      },
    },
  },
  yes: 'Да',
  no: 'Нет',
  ok: 'Понятно',
  draftPopup: {
    title: 'Модерация',
    description: 'Процесс занимает около дня, после чего тур будет доступен для путешественников. Вы можете отправлять тур на модерацию неограниченное количество раз.',
  },
  moderatePopup: {
    title: 'Модерация',
    description: 'Процесс занимает около дня, после чего тур будет доступен для путешественников. Вы можете отправлять тур на модерацию неограниченное количество раз.',
  },
  publishPopup: {
    title: 'Вы хотите отправить тур на модерацию? ',
    description: 'Тур будет убран из предложений путешественникам и отправлен на проверку нашему сотруднику. Это займет несколько дней. Когда мы проверим тур, он снова станет доступен путешественникам',
  },
  moderateStatus: {
    title: 'Ваш тур на модерации',
    description: 'Это занимает несколько дней. Как только мы проверим тур, статус тура изменится на “Опубликован”, и он будет доступен для путешественников.',
  },
  savePopup: {
    title: 'Модерация',
    description: 'Одно или несколько полей, которые вы изменили, требуют модерации. Тур в это время будет недоступен для новых бронирований, текущие бронирования не сбросятся. Процесс занимает около дня, после чего тур будет доступен для путешественников.',
  },
  saveToDraftPopup: {
    title: 'Внесены изменения в тур',
    description: 'Вы внесли изменения в тур, которые потребуют дополнительной модерации. Мы изменим статус вашего тура на «Черновик», и вы сможете внести изменения. Не забудьте после этого подтвердить внесенные изменения, нажав кнопку «Сохранить», это обеспечит сохранение изменений. Важно: после сохранения изменений не забудьте отправить ваш тур на модерацию. После прохождения модерации тур будет опубликован.',
  },
  unsavedPopup: {
    title: 'У вас есть несохраненные изменения',
    description: 'Чтобы опубликовать их, нажмите на "Сохранить"',
  },
  deletePhoto: {
    title: 'Фотографии тура',
    description: 'В опубликованном туре должно быть не менее 7 фотографий. Чтобы удалить эту фотографию, сначала добавьте ещё одну',
  },
  deleteParentDay: {
    title: 'Удалить день?',
    description: 'Если удалить этот день, то дни, скопированные из него, тоже удалятся.',
  },
  linkAccessPopup: {
    title: '',
    description: 'Выбрана опция "Тур доступен по прямой ссылке", а значит путешественники не будут видеть тур в каталоге на сайте. Вы уверены, что хотите скрыть тур на сайте?',
  },
  save: 'Сохранить',
};
export { popupRu };
