import Vue from "vue";
import Vuex from "vuex";
import user from './user.js';
import popup from '../popup/index.js';
import survey from './survey.js';
import statistics from './statistics.js';
import { fetchOnboarding, getTips } from "@/services/scopes/axios-dashboard.js";
import { profileMenuGet } from "@/services/scopes/axios-profile-menu.js";

const iconByCode = {
  'usefulMaterials': 'books',
  'addReviews': 'smile',
  'installApp': 'addDocs',
  'addMessengers': 'addChat',
  'fillProfile': 'profile',
  'addTours': 'add-icon',
  'addRequisites': 'requisites',
};

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    user,
    popup,
    survey,
    statistics,
  },
  state: {
    onboarding: {
      steps: [],
      notifications: [],
      modals: [],
      labels: [],
    },
    sidebarMenu: [],
    tips: [],
  },
  getters: {
    sidebarMenu: state => state.sidebarMenu,
    transformedSteps: transformSteps,
    steps: state => state.onboarding.steps,
    notifications: state => state.onboarding.notifications,
    modals: state => state.onboarding.modals,
    labels: state => state.onboarding.labels,
    progress: (_, getters) => (
      getters.steps
      .filter(step => step.status === 'done').length / getters.steps.length || 0
    ),
    isVerified: (_, getters) => (
      getters.steps.some(step => (
        step.type == 'verifiedCheck' &&
        step.status == 'done'
      ))
    ),
    tips: state => state.tips.map(tip => ({
      ...tip,
      icon: iconByCode[tip.code]
    })),
  },
  mutations: {
    setOnboarding(state, onboarding) {
      state.onboarding = onboarding;
    },
    setSidebarMenu(state, sidebarMenu) {
      state.sidebarMenu = sidebarMenu;
    },
    setTips(state, tips) {
      state.tips = tips;
    }
  },
  actions: {
    async initOnboarding({ dispatch }) {
      await Promise.all([
        dispatch('user/fetchUser'),
        dispatch('fetchSidebarMenu'),
        dispatch('fetchOnboarding'),
      ])
    },
    async initDashboard({ dispatch }) {
      await Promise.all([
        dispatch('user/fetchUser'),
        dispatch('fetchSidebarMenu'),
        dispatch('statistics/fetchPersonalStatistics'),
        dispatch('getTips'),
      ]);
    },
    async fetchSidebarMenu({ commit }) {
      const { success, data } = await profileMenuGet();
      if (success && data) {
        commit('setSidebarMenu', data);
      }
    },
    async fetchOnboarding({ commit }) {
      const { success, data } = await fetchOnboarding();
      if (success) {
        commit('setOnboarding', data);
      }
    },
    async getTips({ commit }) {
      const { success, data } = await getTips();
      if (success) {
        commit('setTips', data);
      }
    },
  },
});

// adding new locked status
function transformSteps(state) {
  const isIntroDone = state.onboarding.steps.some(step => (
    step.type === 'intro' && step.status === 'done'
  ));
  const isVerifyReady = (
    state.onboarding.steps
    .filter(step => step.type != 'intro' && step.type != 'verifiedCheck')
    .every(step => step.status == 'done')
  );

  return state.onboarding.steps.map(step => {
    if (step.type != 'intro' && !isIntroDone && !step.skiplock) {
      step.status = 'locked';
    }
    if (step.type == 'verifiedCheck' && !isVerifyReady && !step.skiplock) {
      step.status = 'locked';
    }
    return step;
  });
}
