import Vue from "vue";
import Vuex from "vuex";
import state from "./state.js";
import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";
import tourEdit from "./modules/tour-edit/index.js";
import tourTitle from "./modules/tour-title/index.js";
import tourSelector from "./modules/tour-selector/index.js";
import popup from "./modules/popup/index.js";
import user from "./modules/user/index.js";
//import menu from "./modules/menu/index.js";
import tabs from "./modules/tabs/index.js";
import language from "./modules/language/index.js";
import currency from "./modules/currency/index.js";
import mainScreen from "./modules/mainScreen/index.js";
import phone from "./modules/phone/index.js";
import menu from "./modules/menu/index.js";
import dateCategories from "./modules/dateCategories/index.js";
import headerButtons from "./modules/header-buttons/index.js";
import functionalButtons from "./modules/functional-buttons/index.js";
import mainMenu from "./modules/main-menu/index.js";
import headerSettings from "./modules/header-settings/index.js";
import profileMenu from "./modules/profile-menu/index.js";
import popupMap from "./modules/popup-map/index.js";
import notificationsCenter from "./modules/notifications-center/index.js";
import serp from "./modules/serp/index.js";
import survey from "./modules/survey/index.js";
import matchmaking from "./modules/matchmaking";
import page404 from "./modules/page404";
import changeMode from "./modules/changeMode/index.js";
import serpDetail from "./modules/serp-detail";
import country from "./modules/country";
// import language from "./modules/language/index.js";
import SyncPlugin from "./plugins/sync.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  plugins: [SyncPlugin],
  state,
  mutations,
  actions,
  getters,
  modules: {
    tourEdit,
    tourTitle,
    tourSelector,
    popup,
    user,
    menu,
    // language,
    changeMode,
    language,
    currency,
    tabs,
    mainScreen,
    phone,
    dateCategories,
    headerButtons,
    functionalButtons,
    mainMenu,
    headerSettings,
    profileMenu,
    popupMap,
    serp,
    survey,
    notificationsCenter,
    matchmaking,
    page404,
    serpDetail,
    country,
  },
});

export default store;
