const popupClaimPdfRu = {
  popupClaimPdf: {
    trigger: "Получить на&nbsp;email",
    label: "Укажите e-mail",
    title: "Получить программу в&nbsp;PDF",
    text: "Укажите свой Email и&nbsp;мы&nbsp;пришлем вам программу в&nbsp;PDF на&nbsp;почту",
    button: "Получить",
    success: {
      title: "Программа отправлена !",
      text: "Мы&nbsp;отправили вам программу на&nbsp;почтовый ящик {email}. Проверьте ваши входящие письма",
    },
  },
};

export { popupClaimPdfRu };
