import { format, isSameYear, isThisYear, isSameMonth } from "date-fns";
import getDateFnsLocale from "@/assets/js/utils/get-date-fns-locale";

export default function getFormattedDateRange(dateFrom, dateTo, fullMonth = false, languageId) {
  if (!dateFrom || !dateTo) {
    return "";
  }

  const locale = getDateFnsLocale.call(this, languageId);
  const isYearThis = isThisYear(dateFrom);

  const month = (fullMonth
    && isSameMonth(dateFrom, dateTo)
    && isSameYear(dateFrom, dateTo)) ? 'MMMM' : 'MMM'

  if (!isSameYear(dateFrom, dateTo)) {
    return `${format(dateFrom, `d ${month} yyyy`, { locale })} – ${format(
      dateTo,
      `d ${month} yyyy`,
      { locale }
    )}`;
  }

  if (!isSameMonth(dateFrom, dateTo)) {
    return `${format(dateFrom, `d ${month}`, { locale })} – ${format(
      dateTo,
      `d ${month}${isYearThis ? "" : " yyyy"}`,
      { locale }
    )}`;
  }

  return `${format(dateFrom, "d", { locale })} – ${format(
    dateTo,
    `d ${month}${isYearThis ? "" : " yyyy"}`,
    { locale }
  )}`;
}
