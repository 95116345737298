<template>
  <mounting-portal mount-to="#vue-popups" append slim>
    <transition name="fade">
      <div
        v-if="modelValue"
        class="popup u-fixed-full"
        :class="[className, { [`_size-${size}`]: !!size }]"
      >
        <div
          v-scroll-lock="modelValue"
          class="popup__container u-absolute-fill"
          @click.self="close"
        >
          <div class="popup__content" @click.self="close">
            <div
              class="popup__content-wrapper"
              :class="{ 'u-overflow-hidden': !contentOverflow }"
            >
              <button
                v-if="showCloseButton"
                type="button"
                class="
                  popup__close
                  u-flex-shrink-0 u-clickable u-text-secondary
                "
                @click="close"
              >
                <svg-inline class="u-decor u-stroke-deep-current">
                  <icon icon="close" />
                </svg-inline>
              </button>
              <div class="popup__content-container">
                <div class="popup__slot">
                  <slot />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </mounting-portal>
</template>

<script>
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  components: {
    SvgInline,
    Icon,
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    className: {
      type: [String, Object, Array],
      default: "",
    },
    size: {
      type: String,
      default: "",
      validator(size) {
        return ["", "sm", "md", "lg"].includes(size);
      },
    },
    contentOverflow: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    fakeWindow.addEventListener("keydown", this.onEsc);
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("keydown", this.onEsc);
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("change", false);
    },
    onEsc({ keyCode }) {
      if (keyCode === 27 && this.modelValue) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$b: ".popup";

#{$b} {
  outline: none;
  z-index: 100000;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::before {
    @include mixin.decor();
    content: "";
    position: absolute;
    top: -100px;
    right: 0;
    bottom: -100px;
    left: 0;
    background-color: rgba(var.$black, 0.4);
  }

  &__container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    overflow-y: auto;

    &::after {
      display: inline-block;
      content: "";
      vertical-align: middle;
      height: 100%;
      width: 0;
    }
  }

  &__content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    z-index: 1045;
    width: 100%;

    @include mixin.size-lg-min {
      padding: (var.$inner-pd * 3) var.$inner-pd;
    }

    &-wrapper {
      position: relative;
      cursor: auto;
      text-align: left;
      white-space: normal;
      margin: 0 auto;

      @include mixin.size-lg-min {
        max-width: 832px;

        #{$b}._size-sm & {
          max-width: 440px;
        }

        #{$b}._size-md & {
          max-width: 632px;
        }
      }
    }

    &-container {
      display: flex;
      position: relative;
      min-height: 100vh;
      padding: 24px;
      background: var.$white;

      @include mixin.size-lg-min {
        min-height: auto;
        box-shadow: 2px 2px 20px rgba(var.$black, 0.2);
        border-radius: 24px;
      }
    }
  }

  &__slot {
    width: 100%;
  }

  &__close {
    @include mixin.button-reset();
    position: absolute;
    top: 29px;
    right: 24px;
    width: 12px;
    transition: var.$transition-default;
    z-index: 2;

    &::before {
      @include mixin.full-pos(-50%);
      position: absolute;
      display: block;
      content: "";
    }
  }
}
</style>
