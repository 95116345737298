const tourFaqRu = {
  tourFaq: {
    title: "Часто задаваемые вопросы",
    empty: "Пока не&nbsp;добавлено ни&nbsp;одного вопроса",
    question: "Вопрос",
    reply: "Ответ",
    new: "новый",
    controls: {
      delete: "Удалить вопрос",
      save: "Сохранить вопрос",
      add: "Добавить вопрос",
    },
    navButtons: {
      navPrev: {
        text: "Назад",
      },
    },
  },
};

export { tourFaqRu };
