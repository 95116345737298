const serpRu = {
  serp: {
    tours: {
      resetFilters: "Сбросить все фильтры",
      noFilters: "Фильтры не выбраны",
      foundTours: {
        mobile: "{total} найдено",
        desktop: "Найдено {total} туров",
      },
      sort: "Сортировать:",
      sortBy: {
        recommended: "Рекомендовано Вам",
        popular: "По популярности",
        new: "Новые",
        closest: "Ближайшие",
        priceLow: "Сначала дешевые",
        priceHigh: "Сначала дорогие",
        discount: "По скидке",
      },
      view: {
        list: "Список",
        tile: "Плитка"
      },
      nothingFound: "Ничего не найдено",
      tryToChangeFilters: "Попробуйте изменить поиск или сбросить фильтры",
      showMore: "Показать еще",
    },
    awards: {
      bestExpert: "Лучший организатор 2023",
      bestTours: "Выбор путешественников",
      superExpert: 'Суперэксперт',
    },
    filters: {
      reset: "Сбросить",
      duration: "Длительность",
      durationFrom: "От (дней)",
      durationTo: "До (дней)",
      durationDays: "дней | дня | дней | дней",
      travelType: "Тип тура",
      allCheckstypes: "Все типы",
      allCheckslanguages: "Все языки",
      price: "Цена",
      from: "От",
      to: "До",
      discount: "Только со скидками",
      installment: "Можно в рассрочку",
      cashback: "Тур с кэшбэком",
      cashbackHelper: `Только туры с кэшбэком 20% при оплате картой "Мир"`,
      comfort: "Комфорт",
      comfortChecks: [
        "Палатки или кемпинги",
        "Гостиницы 1* или гостевые дома",
        "Гостиницы 2* или аппартаменты",
        "Гостиницы 3* или виллы",
        "Гостиницы 4-5* или глемпинги",
      ],
      ages: "Возраст группы",
      children: "Можно с детьми",
      childrenHelper: "Выберите возраст самого младшего ребенка:",
      childrenPlaceholder: "Возраст не выбран",
      childrenAges: {
        all: "Все",
        year: "Младше 1 года | {n} год | {n} года | {n} лет",
      },
      activities: "Уровень активности",
      activitiesChecks: [
        "Расслабляющая",
        "Спокойная",
        "Умеренная",
        "Интенсивная",
        "Экстрим",
      ],
      guarantees: "Гарантированный тур",
      guaranteesHelper: "Тур точно состоится – даже если группа не наберется",
      private: "Доступен индивидуальный тур",
      privateHelper: "Можно организовать только для вас и ваших друзей",
      languages: "Язык тура",
      spaces: "Сколько вас человек",
      maxSpaces: "Максимальное кол-во участников",
      spacesHelper: "Человек",
      spacesCounter: "{n} человек | {n} человек | {n} человека | {n} человек",
      rating: "Рейтинг тревел-эксперта",
      ratingChecks: [
        "Любой",
        "Превосходно и выше",
        "Очень хорошо и выше",
        "Хорошо и выше",
        "Удовлетворительно и выше",
      ],
      checksMoreCount: "еще {count}",
      trigger: "Фильтры",
      showTours: "Показать путешествия",
    },
    tourCard: {
      new: "Новый",
      places: ["1 место", "места"],
      moreDates: ["дата", "даты", "дат"],
      recommended: "Рекомендуем!",
      blackFriday: "Black-Friday",
      private_label: "Можно индивидуально",
      cashback: "20% кэшбэк",
      sponsored: "Спонсировано",
      guarantee_label: "Тур Гарантирован",
      durationDays: "день|дня|дней",
      durationNights: "ночь|ночи|ночей",
      durationHours: "час|часа|часов",
      route: "локация|локации|локаций",
      routeTitle: "Маршрут",
      hide: 'Свернуть',
      viewTour: 'Подробнее'
    },
    topMenu: {
      search: "Найти",
      searchLong: "Найти туры",
      searchMob: "Поиск туров",
      where: "Куда вы хотите?",
      better: "Лучшее совпадение",
      popular: "Популярное",
      countries: "Страны",
      continents: "Континенты",
      regions: "Регионы",
      destinations: "Направления",
      history: "История поиска",
      types: "Типы туров",
      tours: "Туры",
      when: "Месяц или даты",
      monthOrPeriod: "Месяц или период",
      exactDates: "Точные даты",
      additionalDays: "± 3 дня",
      manyDays: "Многодневные",
      oneDay: "Однодневные",
      unusual: "Необычные",
      allDirections: "Все направления",
      allDates: "Любые даты"
    },
    hiw: {
      button: "Как это работает",
      smallHeader: "Что такое авторские туры?",
      bigHeader: "Это новый формат необычных путешествий в уникальные места",
      image: [
        {
          text: "Нестандартные маршруты",
          subtext: "И места для «своих» от проверенных авторов путешествий",
        },
        {
          text: "Путешествие небольшой компанией",
          subtext: "Близких по духу единомышленников",
        },
        {
          text: "Никаких забот",
          subtext:
            "Проживание, питание и активности за тебя уже продумал автор путешествия",
        },
      ],
      gray: [
        {
          text: "Гарантия лучшей цены",
          subtext: "Мы гарантируем самую низкую стоимость",
        },
        {
          text: "Проверяем организаторов",
          subtext: "Туры только с надежными профессионалами",
        },
        {
          text: "Поддержка 24/7",
          subtext:
            "Отвечаем на ваши вопросы и помогаем найти лучшее путешествие",
        },
        {
          text: "Платежи под защитой",
          subtext: "Безопасная оплата и полная гарантия на все услуги",
        },
      ],
      bookHeader: "Как забронировать?",
      book: [
        {
          text: "Выберите тур",
          subtext:
            "У нас представлено более 30 000 туров от 5 600 проверенных тревел-экспертов",
        },
        {
          text: "Уточните все детали",
          subtext: "Расспросите обо всем важном автора тура напрямую",
        },
        {
          text: "Забронируйте место",
          subtext:
            "Для бронирования потребуется только предоплата - от 15% от стоимости тура",
        },
      ],
      closeButton: "Закрыть",
    },
    seo: {
      readMore: "Читать полностью",
      showMore: "Показать еще",
      actionConditions: "Условия участия в акции:",
      toursByMonth: "Туры по месяцам",
      toursByRoutes: "Туры из других городов",
    },
  },
};
export { serpRu };
