import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

export async function fetchOnboarding() {
  const { data } = await axiosYtApiV2.get('/onboarding');
  return data;
}

export async function hideModal(type) {
  await axiosYtApiV2.post(`/onboarding/modal/${type}/hide`);
}

export async function getTips() {
  const { data } = await axiosYtApiV2.get('personal/tips');
  return data;
}
