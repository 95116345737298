import { render, staticRenderFns } from "./heart.vue?vue&type=template&id=c404d214&scoped=true&"
var script = {}
import style0 from "./heart.vue?vue&type=style&index=0&id=c404d214&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c404d214",
  null
  
)

export default component.exports