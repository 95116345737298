<template>
  <div class="popup-cancel-tour">
    <div class="popup-wrapper">
      <Icon class="popup-icon" icon="icon-attention" color="gold-400" />
      <b class="title">
        {{ $t("popup.cancel.title") }}
      </b>
      <div class="row-fields">
        <ServiceList
          component="ServiceListField"
          :services-list="servicesList"
          :validator-data="$v.servicesList"
          @change-field="serviceFieldInputHandler"
          @add-field="serviceFieldAddHandler"
          @remove-field="serviceFieldRemoveHandler"
        />
      </div>

      <div class="row-checkboxes">
        <Checkbox
          class="popup-checkbox"
          label="Возврат по COVID"
          :is-checked="covidReturn"
          @change="covidReturn = $event"
        />
        <Checkbox
          class="popup-checkbox"
          label="Замена путешественника"
          :is-checked="travelerReplacement"
          @change="travelerReplacement = $event"
        />
      </div>

      <div class="row-bottom">
        <Btn
          tag="button"
          btn-type="button"
          bg-color="bg-green-1"
          color="color-neutral-100"
          class="popup-btn-control"
          @btn-click="saveFormHandler"
        >
          <template v-slot:content>
            <span class="popup-btn-text btn-text">{{
              $t("popup.cancel.actions.save")
            }}</span>
            <Icon icon="icon-checkmark" />
          </template>
        </Btn>
        <Btn
          tag="button"
          btn-type="button"
          outline="outline teal-300"
          color="color-teal-300"
          class="popup-btn-control"
        >
          <template v-slot:content>
            <span class="popup-btn-text">{{
              $t("popup.cancel.actions.cancel")
            }}</span>
          </template>
        </Btn>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Btn from "@/components/atoms/btn/index.vue";
import Checkbox from "@/components/atoms/checkbox/index.vue";
import ServiceList from "@/components/organisms/service-list/index.vue";

import { required, minLength, numeric } from "vuelidate/lib/validators";
import { getUniqueId } from "@/assets/js/utils/get-unique-id";

export default {
  components: {
    Icon,
    ServiceList,
    Btn,
    Checkbox,
  },
  data: (vm) => ({
    servicesList: [
      {
        idPlug: "das",
        fields: {
          title: {
            placeholder: vm.$t("servicesList.name.placeholder"),
            value: "",
          },
          price: {
            placeholder: vm.$t("servicesList.price.placeholder"),
            value: "",
          },
          comment: {
            placeholder: vm.$t("servicesList.comment.placeholder"),
            value: "",
          },
        },
      },
    ],
    covidReturn: false,
    travelerReplacement: false,
  }),
  validations: {
    servicesList: {
      $each: {
        fields: {
          price: {
            value: {
              required,
              numeric,
            },
          },
          title: {
            value: {
              required,
              minLength: minLength(3),
            },
          },
        },
      },
    },
  },
  methods: {
    serviceFieldInputHandler(payload) {
      const { index, fieldName, value } = payload;

      this.servicesList[index].fields[fieldName].value = value;
    },
    serviceFieldAddHandler() {
      const newServiceItem = {
        idPlug: getUniqueId("service"),
        fields: {
          title: {
            placeholder: this.$t("servicesList.name.placeholder"),
            value: "",
          },
          price: {
            placeholder: this.$t("servicesList.price.placeholder"),
            value: "",
          },
          comment: {
            placeholder: this.$t("servicesList.comment.placeholder"),
            value: "",
          },
        },
      };

      this.servicesList.push(newServiceItem);
    },
    serviceFieldRemoveHandler(index) {
      this.servicesList.splice(index, 1);
    },
    saveFormHandler() {
      this.$v.$touch();
      if (this.$v.$error) {
        console.log("error");
      } else {
        console.log("logic send");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-cancel-tour {
  position: relative;
  z-index: var.$z-10;
  box-sizing: border-box;
  display: flex;
  margin: auto;
  padding: 15px;
  max-width: 100%;

  @media (min-width: var.$md) {
    max-width: 640px;
  }
}

.popup-wrapper {
  position: relative;
  z-index: var.$z-20;
  background-color: var.$neutral-100;
  border: 2px solid #ffcc66;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  max-width: 100%;
}

.popup-icon {
  position: absolute;
  right: -17px;
  top: -17px;
}

.title {
  color: #2a3b4d;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 24px;
  display: block;
}

.row-fields {
  margin-bottom: 30px;
}

.field-days {
  width: 57%;
  margin-right: 24px;
}

.row-checkboxes {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.popup-checkbox {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.popup-btn-control {
  margin-right: 25px;
  min-width: 141px;
  box-sizing: border-box;
  padding: 12px 18px;

  &:last-of-type {
    margin-right: 0;
  }
}
.popup-btn-new-service {
  padding: 8px 18px;
}

.popup-btn-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
  margin: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}
.popup-btn-text-left {
  margin-right: 6px;
}

.popup-service-list {
  padding: 0;
  margin: 0;

  list-style: none;
}

.popup-service-list-item {
  margin-bottom: 15px;
}
</style>
