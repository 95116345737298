<template>
  <div class="service-fields">
    <ul v-if="servicesList.length !== 0" class="service-list">
      <li
        class="service-list-item"
        v-for="(service, index) in servicesList"
        :id="service.id ? service.id : service.idPlug"
        :key="service.id ? service.id : service.idPlug"
      >
        <component
          :is="component"
          :data="service"
          :index="index"
          :validator="validatorData.$each[index]"
          @change-field="$emit('change-field', $event)"
          @delete-btn-click="$emit('remove-field', service.id, index)"
          @save-btn-click="$emit('update-field', service, index)"
        ></component>
      </li>
    </ul>

    <Btn
      tag="button"
      btn-type="button"
      class="btn-new-service"
      color="color-teal-500"
      outline="outline teal-500"
      @btn-click="$emit('add-field')"
    >
      <template v-slot:content>
        <span class="btn-text-left">{{ addServiceText }}</span>
        <Icon icon="icon-plus" color="teal-300" />
      </template>
    </Btn>
  </div>
</template>

<script>
import Icon from "@/components/icons";
import Btn from "@/components/atoms/btn";
import ServiceListField from "@/components/molecules/service-list-field";
import ServiceListMultisuggest from "@/components/molecules/service-list-multisuggest";
import ServiceListMultisuggestSearch from "@/components/molecules/service-list-multisuggest-search";

export default {
  components: {
    Icon,
    Btn,
    ServiceListField,
    ServiceListMultisuggest,
    ServiceListMultisuggestSearch,
  },
  props: {
    servicesList: {
      type: Array,
      required: true,
    },
    validatorData: {
      type: Object,
      required: false,
    },
    component: {
      type: String,
      required: true,
    },
    addServiceText: {
      type: String,
      default: "Добавить услугу",
    },
  },
};
</script>

<style lang="scss" scoped>
.service-list {
  padding: 0;
  margin: 0;

  list-style: none;
}

.service-list-item {
  margin-bottom: 0;
  padding: 0;
}

.row-top {
  display: flex;
  margin-bottom: 16px;

  @media (max-width: var.$md) {
    flex-direction: column;
  }
}

.btn-delete {
  padding: 8px 18px;
}

.btn-new-service {
  padding: 8px 15px;
  @media (max-width: var.$md) {
    width: 100%;
  }
}
.btn-text-left{
  margin-right: 12px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
}
</style>
