export default function getBackendErrorMessage(
  { response, request, message },
  customMessages = {}
) {
  if (response) {
    const { status } = response;

    if (customMessages[status]) {
      return customMessages[status];
    }

    // if backend answers with human-readable message
    const responseMessage = response.data?.data?.message;
    if (responseMessage) {
      return responseMessage;
    }

    const isServerError = Math.floor(status / 100) === 5;

    if (isServerError) {
      return this.$t("validation.server");
    }

    return this.$t("validation.client");
  }

  if (request) {
    return this.$t("validation.network");
  }

  return message;
}
