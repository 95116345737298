const locationNearbyRu = {
  locationNearby: {
    trigger: {
      text: "Искать поблизости",
    },
    denied: "Нет доступа к&nbsp;местоположению",
    popup: {
      text: "Чтобы вы&nbsp;смогли увидеть туры поблизости, требуется включить вашу геолокацию",
      button: "Разрешить доступ",
    },
    label: "Туры поблизости",
    hint: "Покажем все туры на расстоянии до 1000 километров от вас"
  },
};

export { locationNearbyRu };
