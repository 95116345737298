const participantsRu = {
  participants: {
    count: "0 участников | {n} участник | {n} участника | {n} участников",
    view: "Смотреть участников",
    detailTitle: "Кто уже едет",
    btnRegister: "Зарегистрироваться, чтобы посмотреть",
    btnBooking: "Присоединиться к группе",
  },
};

export { participantsRu };
