<template>
  <component
    :is="group ? 'transition-group' : 'transition'"
    v-bind="$attrs"
    :name="`slide-toggle-${dimension}`"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @before-leave="extend"
    @after-leave="onAfterLeave"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "SlideToggle",
  inheritAttrs: false,
  props: {
    group: {
      type: Boolean,
      default: false,
    },
    dimension: {
      type: String,
      default: "height",
      validator(prop) {
        return ["height", "width", "full"].includes(prop);
      },
    },
  },
  computed: {
    animateFull() {
      return this.dimension === "full";
    },
    animateHeight() {
      return this.animateFull || this.dimension === "height";
    },
    animateWidth() {
      return this.animateFull || this.dimension === "width";
    },
  },
  methods: {
    extend(el) {
      if (this.animateHeight) {
        el.style.height = `${el.scrollHeight}px`;
      }

      if (this.animateWidth) {
        el.style.width = `${el.scrollWidth}px`;
      }
    },
    reset(el) {
      if (this.animateHeight) {
        el.style.height = "";
      }

      if (this.animateWidth) {
        el.style.width = "";
      }
    },
    onEnter(el) {
      // wait for next frame, because content in component default slot might change
      fakeWindow.requestAnimationFrame(() => this.extend(el));
    },
    onAfterEnter(el) {
      this.reset(el);
      this.$emit("extended");
    },
    onAfterLeave(el) {
      this.reset(el);
      this.$emit("collapsed");
    },
  },
};
</script>
