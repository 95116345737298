const lkBonusesRu = {
  lkBonuses: {
    banner: {
      title: 'Копите бонусы и&nbsp;оплачивайте ими&nbsp;поездки',
      balance: 'Баланс',
      more: 'Подробнее',
    },
    stats: {
      title: 'Бонусный счет',
      status: 'Ваш статус',
      balance: 'Ваш баланс',
      expenses: 'Расходы на&nbsp;путешествия',
    },
    tooltipExpired: 'Потратьте {value} {label} до {date}, или они сгорят',
    steps: {
      '1': {
        title: 'Первый шаг',
        text: '1%&nbsp;стоимости возвращается бонусами после первой поездки и&nbsp;отзыва на&nbsp;площадке',
      },
      '1.5': {
        title: 'Начинающий путешественник',
        text: '1,5% стоимости каждого тура возвращается бонусами, когда сумма всех покупок превышает 100&nbsp;000&nbsp;₽',
      },
      '2': {
        title: 'Путешественник',
        text: '2%&nbsp;стоимости каждого тура возвращается бонусами, когда сумма всех покупок превышает 200&nbsp;000&nbsp;₽',
      },
      '2.5': {
        title: 'Заядлый путешественник',
        text: '2,5% стоимости каждого тура возвращается бонусами, когда сумма всех покупок превышает 350&nbsp;000&nbsp;₽',
      },
      '3': {
        title: 'Всегда в&nbsp;пути',
        text: '3%&nbsp;стоимости каждого тура возвращается бонусами, когда сумма всех покупок превышает 500&nbsp;000&nbsp;₽',
      },
    },
    how: {
      get: {
        title: 'Как получать бонусы?',
        list: [
          {
            title: 'Ездить в&nbsp;путешествия',
            text: 'После каждой поездки от 1% до 3% стоимости возвращается бонусами. {linkConditions}',
          },
          {
            title: 'Оставлять отзывы о&nbsp;путешествиях',
            tooltip: 'Участвуя в&nbsp;акции, вы&nbsp;даете согласие на&nbsp;использование вашего отзыва в&nbsp;каналах <a href="https://youtravel.me/" target="_blank">Youtravel.me</a>',
            text: 'Пишите подробные отзывы о поездках с нами на {linkIrecommend}, {linkOtzovik}, в ВК или в {linkInstagram}, отправляйте скриншоты отзыва в Чат с поддержкой и получите 2 000 бонусов на счет за каждый отзыв, {linkYandex}: 500 бонусов, {linkGoogle}: 500 бонусов.',
          },
          {
            title: 'Приглашать друзей в&nbsp;поездки с&nbsp;нами',
            text: 'За каждого приглашенного по вашей ссылке друга, который съездит в путешествие, вы получите 1 000 бонусов. Друг получит скидку до 1 000 ₽ на первое бронирование путешествия, которое соответствует условиям программы. {linkMore}',
          },
        ],
        conditions: 'Подробнее об&nbsp;условиях',
        more: 'Подробнее о программе',
        irecommend: 'irecommend.ru',
        otzovik: 'otzovik.ru',
        instagram: 'Инстаграме',
        yandex: 'Яндекс',
        google: 'Google',
        tooltipInstagram: 'Запрещен в&nbsp;РФ',
      },
      spend: {
        title: 'Как оплатить путешествие бонусами?',
        list: [
          'При расчете стоимости тура вы&nbsp;увидите, сколько бонусов на&nbsp;вашем счете',
          'Вы&nbsp;сами решаете, списывать бонусы или продолжать копить',
          'Вы&nbsp;можете оплатить бонусами не&nbsp;более&nbsp;10% от&nbsp;общей суммы',
          'Бонусы хранятся 1&nbsp;год с&nbsp;момента начисления',
        ],
      },
    },
    received: {
      title: 'Ура, начислены бонусы!',
    },
    history: 'История транзакций',
    bonuses: 'бонусов | бонус | бонуса | бонусов',
    notificationText: '{bonus} {bonusLabel} за тур {tourName} {dateStart}-{dateEnd}',
    notificationReceived: 'Отлично',
  },
};

export { lkBonusesRu };
