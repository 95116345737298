export default {
  namespaced: true,
  state: {
    notifications: {
      all: [],
      new: []
    } ,
  },
  getters: {
    getAll(state) {
      return state.notifications.all
    },
    getNew(state) {
      return state.notifications.new
    },
  },
  mutations: {
    setNotifications (state, {type, value}) {
      state.notifications = {
        ...state.notifications,
        [type]: value,
      };
    },

    addNotification(state, value) {
      state.notifications.all.push(value);
    },

    updateNotification(state, params) {
      let {id, field, value} = params;
      state.notifications.new = state.notifications.new.filter((item) => item.id != id);

      if(state.notifications.all.length > 0) {
        let index = state.notifications.all.findIndex((item) => item.id == id);
        state.notifications.all[index][field] = value;
      }

    }
  },
  actions: {},
};
