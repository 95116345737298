/**
 * Форматирование числа по разрядам с разделителем
 *
 * @param value
 * @param separator
 * @returns {string}
 */
export const thousandsSeparator = (value, separator = " ") => {
  const n =
    Number(value) === value && value % 1 !== 0 ? value.toFixed(1) : value;

  return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`);
};

export default thousandsSeparator;
