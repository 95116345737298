<script>

export default {
  render: function (createElement) {
    return createElement(
      this.tag,
      {
        class: [
          { btn: true },
          { loading: this.loading },
          this.bgColor,
          this.outline,
          this.color,
        ],
        attrs: {
          type: this.btnType.length ? this.btnType : false,
          href: this.link.length ? this.link : false,
          to: this.to ? this.to : false,
        },
        on: {
          click: this.clickHandler,
        },
      },
      [
        this.loading &&
          createElement("div", {
            class: ["loader"],
          }),
        this.$slots.content,
      ]
    );
  },
  props: {
    tag: {
      type: String,
      required: true,
      validator: function (prop) {
        return ["button", "a", "router-link"].indexOf(prop) !== -1;
      },
    },
    link: {
      type: String,
      default: "",
    },
    to: {
      type: [String, Object],
      default: "",
    },
    btnType: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "",
      validator: function (prop) {
        return (
          [
            "",
            "bg-green-1",
            "bg-teal-100",
            "bg-teal-300",
            "bg-teal-400",
          ].indexOf(prop) !== -1
        );
      },
    },
    outline: {
      type: String,
      default: "",
      validator: function (prop) {
        return (
          [
            "",
            "outline violet-2",
            "outline teal-300",
            "outline teal-200",
            "outline teal-500",
            "outline neutral-100",
          ].indexOf(prop) !== -1
        );
      },
    },
    color: {
      type: String,
      default: "",
      validator: function (prop) {
        return (
          [
            "",
            "color-neutral-100",
            "color-violet-2",
            "color-teal-300",
            "color-teal-500",
          ].indexOf(prop) !== -1
        );
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loading(value) {
      for (let el of this.$el.children) {
        el.style.visibility = value ? "hidden" : "visible";
      }
    },
  },
  methods: {
    clickHandler() {
      this.$emit("btn-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s;

  &-notification-action {
    font-weight: 600;
    font-size: 15px;
    padding: 14px 15px;
    text-transform: none;
    white-space: nowrap;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// Background
.btn {
  &.bg-green-1 {
    background-color: var.$green-1;

    &:hover {
      background-color: darken(var.$green-1, 10%);
    }
  }

  &.bg-teal-100 {
    background-color: var.$teal-100;

    &:hover {
      background-color: darken(var.$teal-100, 10%);
    }
  }

  &.bg-teal-300 {
    background-color: var.$teal-300;

    &:hover {
      background-color: darken(var.$teal-300, 10%);
    }
  }

  &.bg-teal-400 {
    background-color: var.$teal-400;

    &:hover {
      background-color: darken(var.$teal-400, 10%);
    }
  }

  &.bg-ruby-500 {
    background-color: var.$ruby-500;

    &:hover {
      background-color: darken(var.$ruby-500, 10%);
    }
  }

  &:disabled {
    opacity: 0.75;
    pointer-events: none;
    user-select: none;
  }
}

// Outline
.btn {
  &.outline {
    background-color: transparent;

    &.violet-2 {
      border: 1px solid var.$violet-2;
      color: var.$violet-2;

      &:hover {
        background-color: var.$violet-2;
        color: var.$neutral-100;
      }
    }

    &.teal-500 {
      border: 1px solid var.$teal-500;
      color: var.$teal-500;

      &:hover {
        background-color: var.$teal-500;
        color: var.$neutral-100;
      }
    }

    &.teal-300 {
      border: 1px solid var.$teal-300;
      color: var.$teal-300;

      &:hover {
        transition: 0.3s;
        background-color: var.$teal-300;
        color: var.$neutral-100;
      }
    }

    &.teal-200 {
      border: 1px solid var.$teal-200;
      color: var.$teal-500;

      &:hover {
        transition: 0.3s;
        background-color: var.$teal-300;
        color: var.$neutral-100;
      }
    }

    &.neutral-100 {
      border: 1px solid var.$neutral-100;
      color: var.$neutral-100;

      &:hover {
        transition: 0.3s;
        background-color: var.$neutral-100;
        color: var.$teal-500;
      }
    }
  }
}

// Color
.btn {
  &.color-neutral-100 {
    color: var.$neutral-100;

    &.loading::before {
      border-top-color: var.$neutral-100;
    }
  }

  &.color-violet-2 {
    color: var.$violet-2;

    &.loading::before {
      border-top-color: var.$violet-2;
    }
  }

  &.color-teal-300 {
    color: var.$teal-300;

    &.loading::before {
      border-top-color: var.$teal-300;
    }
  }

  &.color-teal-500 {
    color: var.$teal-500;

    &.loading::before {
      border-top-color: var.$teal-500;
    }
  }
}

.loading::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  margin: auto;
  border: 4px solid transparent;
  border-radius: 50%;
  animation: loading 0.5s ease infinite;
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
