export default {
  namespaced: true,
  state: {
    title: {
      ru: "",
      en: "",
    },
  },
  mutations: {
    changeTourTitle(state, payload) {
      const { text, lang } = payload;

      state.title[lang] = text;
    },
  },
};
