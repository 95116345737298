<template>
  <div class="field">
    <div class="field-wrapper">
      <input
        :id="getId"
        class="field-input"
        :class="{
          'field-input--invalid': $slots['error-helper'],
          'field-input--tooltip': $slots['tooltip-inner'],
          'field-input--disabled': isReadOnly,
        }"
        :placeholder="placeholder"
        :type="inputType"
        :value="value"
        :readonly="isReadOnly"
        :disabled="isReadOnly"
        @input="fieldInputHandler"
        @change="fieldChangeHandler"
      />
      <label class="field-title" :for="getId" v-if="title">
        {{ title }}
      </label>
      <label :class="{'field-fake-placeholder': !title, 'field-placeholder': title}"  :for="getId">
        {{ placeholder }}
      </label>
      <div v-if="$slots.icon" class="field-icon" @click="iconClickHandler">
        <slot name="icon"></slot>
      </div>
      <v-popover
        v-if="$slots['tooltip-inner']"
        class="tooltip__inner"
        placement="top"
        trigger="hover"
        :delay="{ show: 100, hide: 500 }"
        :autoHide="false"
      >
        <Icon icon="icon-info" color="teal-300" />

        <template slot="popover">
          <slot name="tooltip-inner"></slot>
        </template>
      </v-popover>
    </div>
    <div v-if="$slots['error-helper']" class="error-helper">
      <slot name="error-helper"></slot>
    </div>
    <p v-if="$slots['tooltip-bottom']" class="tooltip__bottom">
      <slot name="tooltip-bottom"></slot>
    </p>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import { getUniqueId } from "@/assets/js/utils/get-unique-id";

export default {
  components: {
    Icon,
  },
  props: {
    inputType: {
      type: String,
      default: "text",
    },
    value: {
      validator: (prop) =>
        prop === null || typeof prop === "string" || typeof prop === "number",
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getId() {
      return getUniqueId("field");
    },
  },
  methods: {
    fieldInputHandler(evt) {
      this.$emit("field-input", evt.target.value);
    },
    fieldChangeHandler(evt) {
      this.$emit("field-change", evt.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  position: relative;
}

.field-wrapper {
  position: relative;

  width: 100%;
}

.field-input {
  //TODO: color?
  box-sizing: border-box;
  width: 100%;

  padding: 15px 12px;

  border: 1px solid var.$teal-200;
  border-radius: 8px;

  color: var.$teal-500;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  transition: all 0.2s;

  &:focus:not(.field-input--disabled) {
    outline: none;
    border: 1px solid var.$teal-300;
  }

  &::placeholder {
    font-size: 0;
    visibility: hidden;
  }

  &:focus ~ .field-fake-placeholder {
    top: 0;
    left: 16px;
    font-size: 12px;
  }

  &:focus ~ .field-placeholder {
    font-size: 0;
    visibility: hidden;
  }

  &:not(:placeholder-shown) ~ .field-fake-placeholder {
    top: 0;
    left: 16px;
    font-size: 12px;
  }

  &:not(:placeholder-shown) ~ .field-placeholder {
    font-size: 0;
    visibility: hidden;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #F6F7FA;
    color: var.$neutral-400;
  }

  &:disabled ~ .field-fake-placeholder {
    background: linear-gradient(0deg,#f6f7fa 50%,rgba(231,0,0,0) 51%,hsla(0,0%,100%,0));
    color: var.$teal-200;
  }
}

.field-input--invalid {
  border: 1px solid var.$ruby-300;

  &:focus {
    border: 1px solid var.$ruby-300;
  }
}

.field-fake-placeholder {
  padding: 0 4px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
  width: max-content;

  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(231, 0, 0, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  color: var.$teal-300;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  right: 2px;
  pointer-events: none;

  transition: all 0.2s;
}
.field-placeholder {
  padding: 0 4px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(231, 0, 0, 0) 51%,
      rgba(255, 255, 255, 0) 100%
  );
  color: var.$teal-300;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  pointer-events: none;
}

.field-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: var.$z-10;
  transform: translateY(-50%);
  cursor: pointer;
}

.error-helper {
  color: var.$ruby-400;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
  padding: 0;
}

.field-input--tooltip {
  padding-right: 40px;
}

.field-input--disabled {
  color: #a6a6a6;
}

.field-input--disabled ~ .field-fake-placeholder {
  color: #a6a6a6;
  background: none;
}
.field-title{
  padding: 0 4px;
  position: absolute;
  color: #64788f;
  pointer-events: none;
  left: 16px;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: linear-gradient(0deg,#fff 50%,rgba(231,0,0,0) 51%,hsla(0,0%,100%,0));
  top: 0;

  pointer-events: none;
}
</style>
