<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :type="type"
    class="button-icon"
    :class="[`_${color}`, { _disabled: $attrs.disabled }]"
    @click="$emit('click')"
  >
    <span class="button-icon__container u-stroke-deep-current">
      <svg-inline tag="span">
        <transition name="fade" mode="out-in">
          <icon :icon="icon" :key="icon" />
        </transition>
      </svg-inline>
    </span>
  </component>
</template>

<script>
import { buttonTypeComputed } from "@/assets/js/composables/buttonTypeComputed";
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  components: {
    SvgInline,
    Icon,
  },
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: "green",
      validator(prop) {
        return ["", "green", "gray"].includes(prop);
      },
    },
    icon: {
      type: String,
      default: "plus",
    },
    tag: {
      type: String,
      default: "button",
    },
  },
  computed: {
    type: buttonTypeComputed,
  },
};
</script>

<style lang="scss" scoped>
.button-icon {
  @include mixin.clickable();
  box-sizing: border-box;
  width: 24px;
  max-width: 100%;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  border: none;
  border-radius: 6px;
  outline: none;
  padding: 0;
  color: var.$white;
  background-color: var.$green-main;
  transition: var.$transition-fast;

  &:hover {
    background-color: var.$green-hover;
  }

  &:active {
    background-color: var.$green-dark;
    transition-duration: 0s;
  }

  &._gray {
    color: var.$black;
    background-color: var.$gray-light;

    &:hover {
      background-color: var.$gray-hover;
    }

    &:active {
      background-color: var.$gray-dark;
    }
  }

  &:disabled,
  &._disabled {
    color: var.$gray-dark;
    background-color: var.$gray-light;
    pointer-events: none;
  }

  &__container {
    display: block;
    width: 100%;
    max-width: 16px;
    margin: percentage(func.divide(4, 24));

    path {
      transition: var.$transition-fast;
    }
  }
}
</style>
