<template>
  <div class="textarea">
    <textarea
      :id="getId"
      class="textarea-input"
      :class="{ 'textarea-input--invalid': $slots['error-helper'] }"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="textareaInputHandler"
      @change="textareaChangeHandler"
    />
    <label class="textarea-fake-placeholder" :for="getId">
      {{ placeholder }}
    </label>
    <div v-if="$slots['error-helper']" class="error-helper">
      <slot name="error-helper"></slot>
    </div>
    <v-popover
      v-if="$slots['tooltip-inner']"
      class="tooltip__inner tooltip__inner__field-area"
      placement="top"
      trigger="hover"
      :delay="{ show: 100, hide: 500 }"
      :autoHide="false"
    >
      <Icon icon="icon-info" color="teal-300" />

      <template slot="popover">
        <slot name="tooltip-inner"></slot>
      </template>
    </v-popover>
    <p v-if="$slots['tooltip-bottom']" class="tooltip__bottom">
      <slot name="tooltip-bottom"></slot>
    </p>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import { getUniqueId } from "@/assets/js/utils/get-unique-id";

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getId() {
      return getUniqueId("textarea");
    },
  },
  methods: {
    textareaInputHandler(evt) {
      this.$emit("field-input", evt.target.value);
    },
    textareaChangeHandler(evt) {
      this.$emit("field-change", evt.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  position: relative;
  display: flex;
  flex-direction: column;
}

.textarea-input {
  box-sizing: border-box;
  width: 100%;

  padding: 15px 12px;

  border: 1px solid var.$teal-200;
  border-radius: 8px;

  color: var.$teal-500;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  flex-grow: 1;
  resize: none;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border: 1px solid var.$teal-300;
  }

  &::placeholder {
    transition: border 0.2s;

    font-size: 0px;
    visibility: hidden;
  }

  &:focus ~ .textarea-fake-placeholder {
    top: 0;
    left: 16px;
    transform: translate(0, -50%);
    font-size: 12px;
  }

  &:not(:placeholder-shown) ~ .textarea-fake-placeholder {
    top: 0;
    left: 16px;
    transform: translate(0, -50%);
    font-size: 12px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var.$neutral-100;
    color: var.$neutral-400;
  }

  &:disabled ~ .textarea-fake-placeholder {
    background: transparent;
    color: var.$teal-200;
  }
}

.textarea-input--invalid {
  border: 1px solid var.$ruby-300;

  &:focus {
    border: 1px solid var.$ruby-300;
  }
}

.textarea-fake-placeholder {
  padding: 0 4px;
  position: absolute;
  left: 12px;
  top: 12px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(231, 0, 0, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  color: var.$teal-300;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  pointer-events: none;
  transition: all 0.2s;
}

.error-helper {
  color: var.$ruby-400;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
}
</style>
