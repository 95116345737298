<template>
  <component
    :is="tag"
    class="btn"
    :class="classes"
    @click="!disabled && $emit('click')"
  >
    <slot></slot>
    <span class="font-h2" v-text="text"></span>
  </component>
</template>

<script>
export default {
  name: "button",
  props: {
    color: {
      type: String,
      default: "green",
      validator(prop) {
        return ["", "green", "white", "gray", "violet"].indexOf(prop) !== -1;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "div",
    },
    squared: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        `btn-${this.color}`,
        this.disabled ? "btn-disabled" : "",
        { "btn-squared": this.squared },
        { "btn-hidden": this.hidden },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 2px 2px 15px rgba(153, 153, 169, 0.15);
  text-transform: none;

  &-violet-1{
    background: var.$violet-1;
    color: var.$white;
    border: 1px solid var.$violet-1;

    &:hover:not(.btn-disabled) {
      background: var.$violet-4;
      border-color: var.$violet-4;
    }
  }

  &-violet {
    background: var.$violet-3;
    color: var.$white;
    border: 1px solid var.$violet-3;

    &:hover:not(.btn-disabled) {
      background: var.$violet-2;
      border-color: var.$violet-2;
    }
  }

  &-green {
    background: var.$green-main;
    color: var.$white;
    border: 1px solid var.$green-main;

    &:hover:not(.btn-disabled) {
      background: var.$green-hover;
      border-color: var.$green-hover;
    }
  }

  &-white {
    background: var.$white;
    color: var.$black;
    border: 1px solid var.$gray-dark;

    &:hover:not(.btn-disabled) {
      border-color: var.$secondary;
    }
  }

  &-gray {
    background: var.$gray-light;
    color: var.$black;
    border: 1px solid var.$gray-light;

    &:hover:not(.btn-disabled) {
      background: var.$gray-hover;
      border-color: var.$gray-hover;
    }
  }

  &-disabled {
    background: var.$gray-light;
    color: var.$secondary;
    border: 1px solid var.$gray-light;
    cursor: default;
  }

  &-squared {
    border-radius: var.$rounded-8;
  }

  &-hidden {
    @media (min-width: var.$size-lg) {
      display: none;
    }
  }
}
</style>
