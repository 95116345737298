const importantKnowRu = {
  importantKnow: {
    title: "Памятка туриста и требования к гостю",
    fields: {
      tourPreparation: {
        placeholder: "Подготовка к туру",
      },
      whatToTakeWithYou: {
        placeholder: "Что взять с собой",
      },
      guestRequirements: {
        placeholder: "Требования к гостю",
      },
    },

    navButtons: {
      navPrev: {
        text: "Назад",
      },
      navNext: {
        text: "К фото",
      },
    },
  },
};

export { importantKnowRu };
