const tourSelector = {
  tourSelector: {
    startTyping: "Start typing the name of the tour or location",
    nothingToShow: "No matches found ;(",
    chooseDirection: "Choose a destination or tour",
    tours: "{n} adventure | {n} adventures",
  },
};

export { tourSelector };
