<template>
  <div class="checkbox-wrapper">
    <input
      :id="getId"
      class="input g-visually-hidden"
      type="checkbox"
      :checked="isChecked"
      :value="isChecked"
      @change="$emit('change', $event.target.checked)"
    />
    <label class="label" :for="getId">
      <span class="checkbox"></span>
      <span class="label-text">
        {{ label }}
      </span>
    </label>
    <v-popover
      v-if="$slots['tooltip-inner']"
      class="checkbox-tooltip__inner"
      placement="top"
      trigger="hover"
      :delay="{ show: 100, hide: 500 }"
      :autoHide="false"
    >
      <Icon icon="icon-info" color="teal-300" />
      <template slot="popover">
        <slot name="tooltip-inner"></slot>
      </template>
    </v-popover>
  </div>
</template>

<script>
import { getUniqueId } from "@/assets/js/utils/get-unique-id";
import Icon from "@/components/icons/index.vue";
export default {
  components: {
    Icon,
  },
  props: {
    isChecked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    getId() {
      return getUniqueId("checkbox");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}

.checkbox {
  position: relative;
  background-color: var.$neutral-100;
  border: 1px solid var.$neutral-500;
  border-radius: var.$rounded-4;
  box-sizing: border-box;
  flex-shrink: 0;
  display: inline-block;
  height: 20px;
  width: 20px;
  transition: border-color 0.3s, background-color 0.3s;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: transparent;
    transition: background-color 0.3s;
  }

  &::before {
    top: calc(50% + 2px);
    left: calc(50% - 5px);
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 6px;
    width: 2px;
  }

  &::after {
    top: 50%;
    left: calc(50% + 2px);
    transform: translate(-50%, -50%) rotate(45deg);
    height: 14px;
    width: 2px;
  }
}

.input:checked + .label .checkbox {
  border-color: var.$violet-1;
  background-color: var.$violet-1;

  &::before,
  &::after {
    background-color: var.$neutral-100;
  }
}

.label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.label-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  margin-left: 12px;
  align-items: center;
}

.checkbox-tooltip__inner {
  margin-left: 15px;
  cursor: pointer;
}
</style>
