const cancelTourEn = {
  cancelTour: {
    mainTitle: "Cancellation policy",
    included: "Included in the tour price",
    not_included: "Not included in the tour price",
    custom_cancellation: "Tell customers about the return policy",
    refund_policy_placeholder: "Select return policy",
    conditions: {
      title: "You can specify your text in the box below or",
      text: "use the wizard to indicate the terms of cancellation",
      attentionText:
        "Please note that the service fee is paid by the travel expert and is actually an expense incurred.",
    },
    otherConditions: {
      title: "Other conditions",
    },
    paidServises: {
      title: "Paid services",
    },
    navButtons: {
      navPrev: {
        text: "Back",
      },
      navNext: {
        text: "Cancellation and Add. services",
      },
    },
  },

  servicesList: {
    name: {
      placeholder: "Service name",
    },
    price: {
      placeholder: "Price",
    },
    comment: {
      placeholder: "Service comment",
    },
    addService: {
      text: "Add service",
    },
  },
};

export { cancelTourEn };
