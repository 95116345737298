<template>
  <div class="yt-upload-file-item "
    :class="{
      'yt-upload-file__uploaded': id ,
      'yt-upload-file__unloaded':(!id && !getIsDrag) || load,
      'yt-upload-file__drag': getIsDrag
    }">
    <div v-if="load" class="yt-upload-file__load">
      <Icon icon="loader-circle"/>
    </div>
    <div v-else-if="id" class="yt-upload-file__uploaded-item">
      <Icon
        class="yt-upload-file__uploaded-item-icon-done"
        icon="icon-big-check"
        color="stroke-green-hover"/>
      <img
        v-if="showPreview"
        class="yt-upload-file__uploaded-item-preview"
        :src="path"/>
      <div
        class="yt-upload-file__uploaded-item-delete"
        @click="deleteFile(id)">
        <Icon icon="icon-trash"/>
      </div>
    </div>
    <label
      v-else
      class="yt-upload-file__unloaded-label"
      :for="getId"
      draggable="false"
      @drop.capture.prevent="dropHandler"
      @dragstart="dragStartHandler"
      @dragover.prevent="dragOverHandler"
      @dragleave="dragEndHandler">

      <Icon
        class="yt-upload-file__icon"
        icon="icon-upload"
        :color="getIconStroke"/>
      <span class="yt-upload-file__label-text" v-if="text && !getIsDrag">
        {{text}}
      </span>
      <input
        :id="getId"
        ref="input"
        class="yt-upload-file__input"
        type="file"
        accept="image/png, image/jpeg"
        @change="change"/>
    </label>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import {getUniqueId} from "@/assets/js/utils/get-unique-id";

export default {
  components: {
    Icon,
  },
  data: () => ({
    isDrag: false
  }),
  props: {
    id: {
      type: Number,
      default: 0
    },
    path: {
      type: String,
    },
    text: {
      type: String,
    },
    load: {
      type: Boolean,
      default: false
    },
    showPreview: {
      type: Boolean,
      default: true
    },
    isZone: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    getId: () => {
      return getUniqueId("input-file");
    },
    getIconStroke () {
      if(this.isDrag)
        return "stroke-green-hover";
      else {
        return "stroke-gray-dark"
      }
    },
    getIsDrag() {
      return this.isDrag;
    }
  },
  methods: {
    deleteFile(id) {
      this.$emit("delete-file", id);
    },
    change(event) {
      const fileTarget = event.target.files;
      const formData = new FormData();
      formData.append("file[]", fileTarget[0]);
      this.$emit("upload-file", formData);
    },
    dragStartHandler(event) {
      event.dataTransfer.setData("text/plain", event.target.id);
    },
    dropHandler(event) {
      this.isDrag = false;
      if(!this.isZone) {
        const formData = new FormData();
        const file = event.dataTransfer.items[0].getAsFile();
        formData.append('file[0]', file);
        this.$emit("upload-file", formData);
      }
    },
    dragOverHandler() {
      this.isDrag = true;
    },
    dragEndHandler() {
      this.isDrag = false;
    }
  },
}
</script>

<style scoped lang="scss">
.yt-upload-file-item {
  width: 112px;
  height: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: var.$rounded-16;
  background-color: var.$white;
}
.yt-upload-file__uploaded-item-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.yt-upload-file__uploaded{
  background: linear-gradient(154.17deg, #F9FBEC 4.03%, #F1F8F3 83.99%);
  overflow: hidden;
}
.yt-upload-file__uploaded-item {
  height: 100%;
}
.yt-upload-file__uploaded-item-icon-done{
  width: 24px;
  height: 17px;
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 12px);
}
.yt-upload-file__unloaded {
  border: 1.4px dashed var.$gray-dark;
}
.yt-upload-file__drag {
  border: 1.4px dashed var.$green-hover;
}
.yt-upload-file__unloaded-label{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.yt-upload-file__uploaded-item-delete{
  width: 28px;
  height: 28px;
  background-color: var.$neutral-100;
  box-shadow: 1px 4px 20px rgba(153, 153, 169, 0.35);
  border-radius: var.$rounded-8;
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yt-upload-file__input{
  display: none;
}
.yt-upload-file__label-text{
  width: 100%;
  padding: 6px 12px;
  text-align: center;
  box-sizing: border-box;
  color: var.$text-dark;
  font-family: var.$font-family-sf-pro-text;
  font-size: 12px;
}
.yt-upload-file__label-text:hover {
  color: var.$green-hover;
}
.yt-upload-file__load {
  & > svg {
    animation: spin 2s linear infinite;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
