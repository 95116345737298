import { globalEn } from "@/assets/config/global/global-en.js";
import { generalEn } from "@/assets/config/general/general-en.js";
import { priceDateEn } from "@/assets/config/price-date/price-date-en.js";
import { cancelTourEn } from "@/assets/config/cancel-tour/cancel-tour-en.js";
import { detailsEn } from "@/assets/config/details/details-en.js";
import { dayAfterDayEn } from "@/assets/config/day-after-day/day-after-day-en";
import { importantKnowEn } from "@/assets/config/important-know/important-know-en";
import { photosEn } from "@/assets/config/photos/photos-en";
import { popupEn } from "@/assets/config/popup/popup-en";
import { popupMapEn } from "@/assets/config/popup-map/popup-map-en";
import { serpEn } from "@/assets/config/serp/serp-en";
import { mmSerpEn } from "@/assets/config/mm-serp/mm-serp-en";
import { questionnaireEn } from "@/assets/config/survey/questionnaire-en";
import survey from "@/assets/i18n/matchmaking/en";
import { howItWorksEn } from "@/assets/config/how-it-works/how-it-works-en";
import { tourFaqEn } from "@/assets/config/tour-faq/tour-faq-en";
import { popupClaimPdfEn } from "@/assets/config/popup-claim-pdf/popup-claim-pdf-en";
import { reviewsEn } from "@/assets/config/reviews/reviews-en";
import { participantsEn } from "@/assets/config/participants/participants-en";
import { dropdownTriggerEn } from "@/assets/config/dropdown-trigger/dropdown-trigger-en";
import { serpDetailEn } from "@/assets/config/serp-detail/serp-detail-en";
import { bookingPopupEn } from "@/assets/config/booking-popup/booking-popup-en";
import { contactTravelExpertEn } from "@/assets/config/contact-travel-expert/contact-travel-expert-en";
import { popupFlexibleCancellationEn } from "@/assets/config/popup-flexible-cancellation/popup-flexible-cancellation-en";
import { locationNearbyEn } from "@/assets/config/location-nearby/location-nearby-en";
import { popupIndividualEn } from "@/assets/config/popup-individual/popup-individual-en";
import { popupInstallmentEn } from "@/assets/config/popup-installment/popup-installment-en";
import { pageBreakingRules } from "@/assets/i18n/page-breaking-rules/en.js";
import { profileMenuEn } from "@/assets/config/profile-menu/profile-menu-en";
import { mainMenuEn } from "@/assets/config/main-menu/main-menu-en";
import { lkBonusesEn } from '@/assets/config/lk-bonuses/lk-bonuses-en';

const en = {
  ...globalEn,
  ...generalEn,
  ...priceDateEn,
  ...cancelTourEn,
  ...detailsEn,
  ...dayAfterDayEn,
  ...importantKnowEn,
  ...photosEn,
  ...popupEn,
  ...popupMapEn,
  ...serpEn,
  ...mmSerpEn,
  ...questionnaireEn,
  ...survey,
  ...howItWorksEn,
  ...tourFaqEn,
  ...popupClaimPdfEn,
  ...reviewsEn,
  ...participantsEn,
  ...dropdownTriggerEn,
  ...serpDetailEn,
  ...bookingPopupEn,
  ...contactTravelExpertEn,
  ...popupFlexibleCancellationEn,
  ...popupIndividualEn,
  ...locationNearbyEn,
  ...popupIndividualEn,
  ...popupInstallmentEn,
  ...pageBreakingRules,
  ...profileMenuEn,
  ...mainMenuEn,
  ...lkBonusesEn,
};

export { en };
