const reviewsRu = {
  reviews: {
    rating: "Рейтинг",
    reviews: "еще нет отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
    all: "Все отзывы ({n})",
    reply: {
      show: "Смотреть ответ тревел-эксперта",
      hide: "Скрыть ответ тревел-эксперта",
      expertLabel: "Тревел-эксперт",
    },
  },
};

export { reviewsRu };
