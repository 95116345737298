import { page404 } from "@/assets/i18n/page404/en.js";
import { tourSelector } from "@/assets/i18n/tour-selector/en.js";
import { dateSelector } from "@/assets/i18n/date-selector/en.js";
import { mainPreview } from "@/assets/i18n/main-preview/en.js";
import { dates } from "@/assets/i18n/dates/en.js";
import auth from "@/assets/i18n/auth/en.js";
import matchmaking from "@/assets/i18n/matchmaking/en.js";

import { notificationsCenter }  from "@/assets/i18n/notifications-center/en.js"
import paymentRequisites from "@/assets/i18n/payment-requisites/en.js";

import general from "@/assets/i18n/general/en.js";
import settings from '@/assets/i18n/dashboard/settings_en.js';
import dashboard from '@/assets/i18n/dashboard/en.js';

import lkReviewPopup from "@/assets/i18n/lk-review-popup/ru.js"
const en = Object.assign({},
  page404,
  tourSelector,
  dateSelector,
  mainPreview,
  dates,
  auth,
  matchmaking,
  general,
  settings,
  dashboard,
  notificationsCenter,
  paymentRequisites,
  lkReviewPopup
);


export { en };
