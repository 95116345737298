<template>
  <ul class="list-markered">
    <li
      v-for="{ id, content } in normalizedItems"
      :key="id"
      class="list-markered__item"
    >
      <div
        class="
          list-markered__marker
          font-text-small
          u-decor u-flex-shrink-0 u-stroke-deep-current
        "
      >
        <slot name="marker"> &bull; </slot>
      </div>
      <div class="list-markered__content u-flex-grow font-regular">
        <slot name="content" :content="content">
          <div v-html="content"></div>
        </slot>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    normalizedItems() {
      return this.items.map((item) => {
        if (typeof item === "string") {
          return { id: item, content: item };
        }

        return item;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-markered {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;

      @include mixin.size-md-only {
        margin-bottom: 12px;
      }

      @include mixin.size-xl-min {
        margin-bottom: 12px;
      }
    }
  }

  &__marker {
    min-width: 24px;
    padding: 5px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: normal;

    &:has(svg) {
      padding: 4px;
    }

    > * {
      width: 100%;
    }
  }
}
</style>
