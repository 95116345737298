const globalEn = {
  multisuggest: {
    emptyList: "The list is empty",
    startSearching: "Start typing",
    emptyResult: "No matches found. Try another query.",
    maximumCountSelected: "The maximum amount has been reached. Try to remove the excess.",
  },
  multiselect: {
    select: "Select",
    selected: "Selected",
    delete: "Delete",
    noOptions: "List is&nbsp;empty",
    noResult: "No elements found",
  },
  editorTextarea: {
    edit: "Edit",
    delete: "Delete",
  },
  dropdownDraggableList: {
    empty: "Not filled",
    textTemplate: "Day",
  },
  serviceListMultisuggestSearch: {
    placeholder: "Add location",
    actions: {
      delete: "Delete",
    },
  },
  serviceListMultisuggest: {
    actions: {
      delete: "Delete",
    },
  },
  serviceListMultisuggestField: {
    actions: {
      save: "Save",
      delete: "Delete",
    },
  },
  tourTitle: {
    actions: {
      edit: "Edit",
      save: "Save",
    },
  },
  tourEditControlPanel: {
    lastSaveText: "Last save",
    actions: {
      save: "Save",
      view: "View",
      moderation: "Moderation",
      unpublish: "Unpublish",
      copy: "Create a copy",
      delete: "Delete",
    },
    dictionaryStatus: {
      draft: "Draft",
      hidden: "Is hidden",
      moderate: "Moderation",
      no_publish: "Don`t publish",
      publish: "Published",
      delete: "Deleted",
    },
  },
  breadcrumbs: {
    main: "Home",
  },
  validation: {
    required: "Field is required",
    number: "The value must be a number",
    minLength: {
      text: "Name must have at least",
      minLengthText: "letters",
    },
    rangeValue: {
      text: "The value must be between",
      textAnd: "and",
    },
    minValue: {
      text: "The value must be at least",
    },
    date: {
      minValueFrom: "The date must be before",
      maxValueFrom: "The arrival date must be before finish date",
      minValueTo: "The date must be after arrival date",
      minValueDiscountTo: "The date must be after discount start date",
      maxValueDiscountFrom: "The date must before disount end date",
      minValueRepeatTo: "The date must be after finish date",
      minValueExceptTo: "The date must be after exlusion start date",
      minValueTime: "Time must be at least 6 hours",
    },
    onlyNumeric: {
      text: "Only numeric",
    },
    email: "Invalid email",
    incorrect: "Value is incorrect",
    server: "Server error, try again later",
    client: "Error occurred, try again later",
    network: "Network error",
  },
  actions: {
    save: "Save",
    cancel: "Cancel",
  },
  clipboard: {
    copy: 'Copy',
    copied: 'Copied',
  },
  inviteLink: 'Your link for friends',
};
export { globalEn };
