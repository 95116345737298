import mitt from "mitt";

if (typeof window != "undefined" && !fakeWindow.mitt) {
  fakeWindow.mitt = new mitt();
}

export default function SyncPlugin(store) {
  if (vueOptions.ssr) {
    return;
  }

  if(typeof window == "undefined"
    || typeof fakeWindow.mitt == "undefined"){
    return;
  }
  let _blockSub = false;
  let lastEmitedMutation = null;
  store.subscribe((mutation) => {
    if (_blockSub) {
      return;
    }

    lastEmitedMutation = mutation;
    fakeWindow.mitt?.emit("store.changed", {
      mutation,
    });
  });

  fakeWindow.mitt?.on("store.changed", ({ mutation }) => {
    const myOwnMutation = mutation === lastEmitedMutation;
    if (myOwnMutation) {
      return;
    }

    // if it's incoming mutation then prevent stack overflow blocking subscription
    _blockSub = true;
    store.commit(mutation.type, mutation.payload);
    _blockSub = false;
  });
}
