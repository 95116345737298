const tourSelector = {
  tourSelector: {
    startTyping: "Начните вводить название тура или места",
    nothingToShow: "Совпадений не найдено ;(",
    chooseDirection: "Куда вы хотите",
    tours: "0 туров | {n} тур | {n} тура | {n} туров",
  },
};

export { tourSelector };
