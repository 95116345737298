export default {
  createNewTour: "Создать новый тур",
  support: "Поддержка",
  reviewing: "На проверке",
  startTutorial: "Тур по личному кабинету",
  copyProfileLink: "Публичная страница профиля",
  copyProfileLinkTip: "Скопировать в буфер!",
  copyProfileLinkTipCopied: "Ссылка скопирована!",
  onboarding: {
    title: "Добро пожаловать! Всего 4 шага отделяют вас от первой продажи на YouTravel.me",
    titleV2: "Добро пожаловать! Всего 5 шага отделяют вас от первой продажи на YouTravel.me",
    titleVerified: "Теперь вы проверенный тревел-эксперт!",
    subtitleVerified: "Перейдите в Личный кабинет, чтобы начать публиковать туры и работать с платформой",
    updateProfile: "Заполнить профиль",
    updateProfile_description: 'Основные данные о вас и ваших турах',
    payments: "Указать реквизиты",
    payments_description: "Куда вы хотите получать выплаты",
    proveExperience: "Подтвердить опыт",
    proveExperience_description: "Станьте проверенным экспертом, заполнив анкету",
    collectReviews: "Добавить отзывы",
    collectReviews_description: "Попросите ваших клиентов дать рекомендации",
    toLk: "В Личный кабинет",
    survey: {
      step: "Шаг",
      stepOf: 'из',
      next: "Далее",
      back: "Назад",
      select: "Выбрать",
      errors: {
        required: 'Поле обязательно'
      }
    },
  },
  sidebar: {
    myProfile: "Мой профиль",
    myTours: "Мои туры",
    orders: "Заказы",
    requests: "Заявки на бронирования",
    payments: "Оплаты",
    travelers: "Путешественники",
    arbitration: "Урегулирование",
    myReviews: "Мои Отзывы",
    billings: "Счета и Платежи",
    bonusAccount: "Бонусный счет",
    billingDetails: "Реквизиты и счета",
  },
  tutorial: {
    populateSections: {
      title: "Заполните 4 шага, чтобы начать продавать туры",
      intro: "Это займет не более часа, зато даст вам новый канал продаж"
    },
    populateSectionsV2: {
      title: "Заполните 5 шагов, чтобы продавать туры",
      intro: "Это займет не более часа, зато даст новый канал продаж"
    },
    createTour: {
      title: "Необходимо отправить тур на модерацию",
      intro: "Чтобы успешно пройти шаг «Создать тур»",
    },
    beforeVerification: {
      title: "Прежде чем отправить заявку на верификацию...",
      intro: "Убедитесь, что сначала заполнили другие разделы",
    },
    support: {
      title: "Возникли проблемы?",
      intro: "Обратитесь в поддержку",
    },

    dashboardTitle: {
      title: 'Знакомьтесь: Обновленный дизайн личного кабинета тревел-эксперта',
      intro: ''
    },
    dashboardProfile: {
      title: 'Новое меню',
      intro: 'При наведении на эту иконку, вы развернете меню, которые нужно для пользования личным кабинетом'
    },
    dashboardNotifications: {
      title: 'Центр уведомлений',
      intro: 'Уведомления от YouTravel.me появятся здесь',
    },
    dashboardChats: {
      title: 'Чаты с путешественниками',
      intro: 'Путешественники будут писать вам здесь',
    },
    dashboardStatistics: {
      title: 'Новая статистика',
      intro: 'Пока показываем статистику за последние 30 дней, но уже работаем над фильтрами',
    },
    dashboardStatisticsViews: {
      title: 'Статистика по просмотрам',
      intro: 'Чтобы статистика по просмотрам накопилась, потребуется некоторое время',
    },
  },
  blockedStatusHintMessage: "Сначала заполните другие шаги",

  statistics: {
    title: "Статистика",
    lastUpdate: "Обновлено",
    percentHint: "По сравнению с прошлым месяцем",
    tooltip: 'Данные за последние 30 дней'
  },
  advices: {
    title: "Как улучшить работу",
  },
  verificationIntro: {
    btn: "Стать проверенным организатором",
    text: "Добро пожаловать! <br> Для публикации туров на платформе необходимо пройти проверку",
  },
};
