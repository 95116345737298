const importantKnowEn = {
  importantKnow: {
    title: "Tourist memo and guest requirements",
    fields: {
      tourPreparation: {
        placeholder: "Tour preparation",
      },
      whatToTakeWithYou: {
        placeholder: "What to take with you",
      },
      guestRequirements: {
        placeholder: "Guest requirements",
      },
    },

    navButtons: {
      navPrev: {
        text: "Back",
      },
      navNext: {
        text: "To the photos",
      },
    },
  },
};

export { importantKnowEn };
