import {axiosDays} from "@/services/scopes/axios-days";
import {axiosTour} from "@/services/scopes/axios-tour";

export default {
  namespaced: true,
  state: {
    allForms: {
      isMainInfoInvalid: false,
      isCancelTourInvalid: false,
      isPriceDateInvalid: false,
      isDetailsInvalid: false,
      isImportantInvalid: false,
      isDayToDayInvalid: false,
      isPhotosInvalid: false,
      isFaqInvalid: false,
    },
    allLanguage: {
      ru: false,
      en: false,
    },
    loading: {
      save: false,
      moderate: false,
      leader: false,
      arrivalSave: false,
      arrivalRemove: false,
      daySave: false,
      dayRemove: false,
      faq: false,
    },
    tour: {
      days: [],
      coordinates: [],
      previewSrc: ""
    },
    isOneDayTour: false,
    errorsMessages: [],
    arrivalDaysCount: 0,
  },
  getters: {
    getIsAllFormIsValid(state) {
      const validValues = Object.values(state.allForms);
      const formInvalid = (element) => element === true;
      return !validValues.some(formInvalid);
    },
  },
  mutations: {
    changeValidForm(state, payload) {
      state.allForms = { ...state.allForms, ...payload };
    },
    changeValidLanguage(state, payload) {
      state.allLanguage = { ...state.allLanguage, ...payload };
    },
    setIsLoading(state, { module, bool }) {
      state.loading = {
        ...state.loading,
        [module]: bool,
      };
    },
    setIsOneDayTour(state, value) {
      state.isOneDayTour = value;
    },
    setDays(state, value) {
      state.tour.days = value;
    },
    updateDay(state, {index, field, value}) {
      state.tour.days[index][field] = value;
    },
    updateDayMeta(state, {index, field, lang, value}) {
      state.tour.days[index].meta[lang][field] = value;
    },
    addDayMutation(state, value) {
      state.tour.days.push(value);
    },
    removeDay(state, index) {
      state.tour.days.splice(index, 1);
    },
    setCoordinates(state, value) {
      state.tour.coordinates = value;
    },
    setArrivalDaysCount(state, value) {
      state.arrivalDaysCount = value;
    },
    setTourPreview(state, value){
      state.tour.previewSrc = value;
    },
    setErrorsMessages(state, value) {
      state.errorsMessages = value;
    }
  },
  actions: {
    async sortDays({commit, state}, {tourId, newIndex, dayId}) {
      let data = [];
      state.tour.days.forEach((item) => {
        if(item.id != dayId ) {
          data.push({id: item.id});
        }
      })
      data.splice( newIndex, 0, {id: dayId});
      data.forEach((item, i) => {
        item.sort = i;
      })
      await axiosDays
        .resortDays(tourId, { data })
        .then(({ data }) => {
          if (data.success) {
            commit("setDays", data.data.days);
          }
        })
        .catch((error) => {
          // TODO: нужен тех дизайн обработки ошибок
          console.error(error);
        });
    },
    async deleteDay({commit, state}, {tourId, dayId}) {
       let index = state.tour.days.findIndex((item) =>  item.id === dayId )
        if(dayId) {
          const result = await axiosDays
            .deleteDay(tourId, dayId)
            .then(({ data }) => {
              return data;
            });
          if(result.success) {
            commit("removeDay",index);
          }
          return result;
        } else {
          commit("removeDay",index);
        }

    },
    getDayIndex({state},dayId){
      return state.tour.days.findIndex((item) => item.id === dayId)
    },
    async getDays({commit}, {tourId}){
      await axiosDays
        .getDays(tourId)
        .then(({ data: { data, success } }) => {
          if (success) {
            let daysData = data.days
              .filter(({ status }) => status !== "delete");
            commit("setCoordinates",data.coordinates);
            commit("setDays",daysData || []);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async addDay({commit},{tourId, body}) {
       const arDay  = await axiosDays
        .addDay(tourId, body)
        .then(({ data }) => {
          if (data.success) {

            let day = {
              ...data.data.day,
              photo: [],
              meta: data.data.meta
            };
            return day;
          }
        })
        .catch((error) => {
          // TODO: нужен тех дизайн обработки ошибок
          console.error(error);
        });
      commit('addDayMutation', arDay);
    },

    async setTourPreview({commit}, {tourId, src}) {
      await axiosTour.postTourPreview(tourId, {'preview_src':src})
        .then((response) => {
            const {data} = response;
            if (data.success) {
              commit("setTourPreview", src);
            }
          }
        )
        .catch((error) => {
          // TODO: нужен тех дизайн обработки ошибок
          console.error(error);
        });
    }
  },
};
