export default {
  lkReviewPopup: {
    case1: {
      title: "Спасибо за вашу обратную связь!",
      text: "Нам жаль, что вы столкнулись с негативным опытом. <br>Чтобы поднять ваше настроение, мы дарим вам <b>{bonusAmount} бонусами</b>, которые можно потратить на бронирование любых туров на YouTravel.me",
      btn: "Хорошо"
    },

    case2: {
      bonuses: "бонусов",
      title: "Спасибо за вашу обратную связь!",
      text: "Предлагаем заработать дополнительные тревел-бонусы <br>за эти отзывы. В благодарность за ваш отзыв мы начислим вам тревел-бонусы, которые можно использовать на бронирование любого авторского тура:",
      yandex: "За отзыв в Яндекс",
      google: "За отзыв в Google",
      otzovik: "За отзыв в Отзовик",
      irecommend: "За отзыв на Irecommend",
      chatLink: "Сообщите об опубликованном отзыв<br>(приложите скриншот или пришлите ссылку)<br><a target='_blank' href='/lk/im/support'>в чате поддержки</a>:",
      btn: "Позже, закрыть",
      btnSend: "Отправить"
    },
    case3: {
      title: "Спасибо за вашу обратную связь!",
      btn: "Закрыть"
    },
    case4: {
      title: "Все готово!",
      text: "Мы подобрали для вас горящие туры",
      btn: "Показать горящие туры"
    },
    caseError: {
      title: "Произошла ошибка",
      btn: "Закрыть"
    }
  },
};


