import { ytVueGlobalStore } from "@/services/yt-vue-global-store";
import Vue from "vue";
const currency = ytVueGlobalStore.instantGet('currency');

export default {
  namespaced: true,
  state: {
    currency: currency || "",
  },
  actions: {
    fetchCurrency({ commit }) {
      ytVueGlobalStore.get('currency').then(currency => {
        commit('setCurrency', { currency })
      })
    }
  },
  getters: {
    symbol({ currency: { title } }) {
      return title.charAt(0);
    },
    currencyId({ currency: { id } }) {
      return id.toLowerCase();
    },
  },
  mutations: {
    setCurrency(state, currency) {
      Vue.set(state.currency, currency)
    },
    changeActiveCurrency(state, value) {
      state.activeCurrency = value;
    },
  },
};
