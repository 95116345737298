const priceDateEn = {
  priceDateTitle: "All arrivals of the tour",
  arrivalWarrning: 'Changes will be applied to all the fields with violet circle for all your dates',
  arrivalTitle: "Edit arrival",
  datesArrival: "Dates",
  dateArrival: "Date",
  dayArrival1: "day",
  dayArrival2: "days",
  dayArrival3: "days",
  spaces: "Spaces",
  coast: "Coast",
  exceptDate: "Except more dates",
  priceComment: "Price comment",
  addArrival: "Add Date",
  apply: {
    // please be careful by changin field keys because they are used in code
    // local/templates/youtravel/vue-app/src/components/templates/price-date/arrival-form.vue
    "active": "To all active",
    "after": "To all that follow",
    "before": "To all previous ones",
    "none": "Не применять",
  },
  globalFields: {
    fastReservation: {
      label: "Instant booking",
    },
    currencyList: {
      options: [
        {
          name: "₽",
          value: "rub",
        },
        {
          name: "$",
          value: "usd",
        },
        {
          name: "€",
          value: "eur",
        },
      ],
      placeholder: "Tour currency (all arrivals)",
    },
  },
  priceDateSelectArrival: {
    dateFrom: {
      text: "from",
    },
    dateTo: {
      text: "to",
    },
    price: "Price",
    from: "in",
    places: "Places",
  },
  arrivalsList: {
    name: "Arrival",
    dateFrom: {
      text: "from",
      placeholder: "Date from",
    },
    dateTo: {
      text: "to",
      placeholder: "Date to",
    },
    isRepeatDate: {
      label: "Recurring check-in",
    },
    repeatType: {
      placeholder: "How to repeat",
      options: [
        { name: "Every week", value: "weekly" },
        { name: "Every month", value: "monthly" },
      ],
    },
    repeatWeekly: {
      placeholder: "Day of week",
      options: [1, 2, 3, 4, 5, 6, 7],
    },
    repeatDateTo: {
      placeholder: "Repeat until",
    },
    isExceptDates: {
      label: "Exclude dates",
    },
    exceptDateFrom: {
      placeholder: "Date from",
    },
    exceptDateTo: {
      placeholder: "Date to",
    },
    totalSpaces: {
      text: "Places",
      textFrom: "from",
      placeholder: "Places at check-in total",
    },
    freeSpaces: {
      placeholder: "Free places",
    },
    priceValue: {
      text: "Cost",
      placeholder: "Tour cost",
    },
    prepayValue: {
      placeholder: "Prepayment",
    },
    prepayType: {
      options: ["%"],
      placeholder: "Units",
    },
    remainingPayType: {
      options: [
        { name: "On the day of the start", value: "start" },
        { name: "Days before the start", value: "before start" },
      ],
      placeholder: "Conditions for depositing the remaining amount",
    },
    priceComment: {
      placeholder: "Price comment"
    },
    isDiscountActive: {
      label: "Discount",
    },
    discountValue: {
      placeholder: "Discount",
    },
    discountType: {
      options: ["%", "eur", "usd", "rub"],
      placeholder: "Units",
    },
    discountTime: {
      header: "Discount period",
    },
    discountFrom: {
      placeholder: "Date from",
    },
    discountTo: {
      placeholder: "Date to",
    },
    collectPostPay: {
      header: "Payment of the remaining amount",
      label: "Payment of the remaining amount through the website",
    },
    postpayDelay: {
      placeholder: "Days before the start",
    },
    flightIncluded: {
      label: "Flight included",
    },
    guarantee: {
      label: "Check-in guaranteed",
    },
    scouting: {
      label: "Scouting",
    },
    deleteButton: {
      text: "Delete",
    },
    saveButton: {
      text: "Save",
    },
    addButton: {
      text: "Add date",
    },
  },
  navButtons: {
    navPrev: {
      text: "Back",
    },
    navNext: {
      text: "Cancellation and Add. services",
    },
  },
};

export { priceDateEn };
