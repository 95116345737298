import { ytVueGlobalStore } from "@/services/yt-vue-global-store.js";
import { useSerp } from "@/state/serp";
import { i18n } from "@/plugins/i18n";
import { useTours } from "@/state/tours";

export default {
  namespaced: true,
  state: {
    serpQuery: { ...ytVueGlobalStore.instantGet("serp-query") },
    serpTours: { ...ytVueGlobalStore.instantGet("serp-tours") },
    favorites: [...ytVueGlobalStore.instantGet("serp-favorites")],
    serpSeoText: { ...ytVueGlobalStore.instantGet("serp-seo-text") },
    isCashbackPage: { ...ytVueGlobalStore.instantGet("serp-cashback-page") },
    ordersOptions: { ...ytVueGlobalStore.instantGet("orders-options") },
    analyticsParam: { ...ytVueGlobalStore.instantGet("analytics-param") },
    locationInfo: { ...ytVueGlobalStore.instantGet("location-info") },
    loading: false,
    loadingTours: true,
    mobileLocPopupIsOpen: false,
    mobileDatePopupIsOpen: false,
    showReset: false,
    strictPeriod: true,
    dates: null,
    location: null,
    only_exclusive: 0,
    productType: 'multiDays',
  },

  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setLoadingTours(state, bool) {
      state.loadingTours = bool;
    },
    setShowReset(state, bool) {
      state.showReset = bool;
    },
    setStrictPeriod(state, bool) {
      state.strictPeriod = bool;
    },
    selectDates(state, dates) {
      state.dates = dates;
    },
    selectLocation(state, location) {
      state.location = location;
    },
    setFavorite(state, { id, add }) {
      const { likeTour, dislikeTour } = useTours();

      if (add) {
        likeTour(id);
      } else {
        dislikeTour(id);
      }
    },
    setFavorites(state, favorites) {
      const { setFavorites } = useTours();

      setFavorites(favorites);
    },
    setIsOnlyExclusive(state, only_exclusive) {
      state.only_exclusive = only_exclusive;
    },
    setMobileLocPopupIsOpen(state, bool) {
      state.mobileLocPopupIsOpen = bool;
    },
    setMobileDatePopupIsOpen(state, bool) {
      state.mobileDatePopupIsOpen = bool;
    },
    setProductType(state, productType) {
      state.productType = productType;
    }
  },

  actions: {
    setFavorites({ commit }, favorites) {
      commit("setFavorites", favorites);
      commit("user/setFavoritesCount", favorites.length, { root: true });
    },
    async selectLocation({ commit }, location) {
      const locationsArray = location
        ? await Promise.all(
            location.map(async (loc) => {
              if (!loc.label) {
                return await getLocation(loc);
              }

              return loc;
            })
          )
        : location;

      commit("selectLocation", locationsArray);
    },
  },
};

async function getLocation(location) {
  const { getLocationById } = useSerp();
  const result = {
    ...location,
    code: "N/A",
    displayType: "N/A",
    label: "",
  };

  if (isDistanceLocation(location)) {
    return returnDistanceLocation(location);
  }

  try {
    let data = await getLocationById(location.type, location.id);

    result.code = data.code;
    result.label = data.label;
    result.displayType = data.displayType;
  } catch (error) {
    // console.error(error);
  }

  return result;
}

function returnDistanceLocation(location) {
  return {
    ...location,
    label: i18n.t("locationNearby.label"),
  };
}

function isDistanceLocation({ type }) {
  return type === "distance";
}
