<template>
  <a
    :href="link"
    :title="title"
    ref="tour"
    target="_blank"
    style="display: inline-block"
    :class="{ 'product' : isSponsored }"
    v-bind="topsortData"
    @contextmenu="setLocalStorageInfo"
    @click="setLocalStorageInfo(); sendClickDataToGa(); $emit('init-topsort-id')"
  >
    <div class="tour-card" :class="{ loading }" v-bind="cardMeta" ref="tourRef">
      <div ref="imageRef" class="tour-card__image">
        <img
          :class="{
            'lazyload': lazyImage,
           }"
          :alt="title"
          :src="imageUrlNoMounted"
          :srcset="lazyImage ? imageUrl1x1 : imageUrl"
          :data-src="imageUrl"
          :data-srcset="imageUrl"
        />

        <div class="tour-card__image__top">
          <div class="tour-card__image__top__left">
            <div
              class="tour-card__image__top__super-expert"
              v-if="isSuperExpert"
            >
              {{ $t('serp.awards.superExpert') }}
              <div class="tour-card__image__top__super-expert-icon">
                <Icon icon="ribbon" />
              </div>
            </div>

            <div
              class="tour-card__image__top__type"
              v-if="type"
              v-text="type"
            />

            <div class="tour-card__image__top__new" v-if="isNew">
              <span v-text="$t('serp.tourCard.new')" />
            </div>

            <div
              class="tour-card__image__top__private"
              v-if="showGuaranteeLabel"
            >
              <span v-text="$t('serp.tourCard.guarantee_label')" />
            </div>
            <div class="tour-card__image__top__private" v-else-if="isPrivate">
              <span v-text="$t('serp.tourCard.private_label')" />
            </div>

            <div class="tour-card__image__top__additional" v-if="isRecommended">
              <span v-text="$t('serp.tourCard.recommended')" />
            </div>

            <div class="tour-card__image__top__additional" v-if="isSponsored">
              <span v-text="$t('serp.tourCard.sponsored')" />
            </div>

            <div
              class="tour-card__image__top__black_friday"
              v-if="isBlackFriday"
            >
              <span v-text="$t('serp.tourCard.blackFriday')" />
            </div>

            <div class="tour-card__image__top__mir" v-if="isCashBackMir">
              <div class="tour-card__image__top__mir-text">
                {{ $t("serp.tourCard.cashback") }}
              </div>
              <div class="tour-card__image__top__mir-icon">
                <Icon icon="icon-mir" />
              </div>
            </div>
          </div>

          <div
            class="tour-card__image__top__favorite"
            :data-itemid="tour.id"
            @click.stop.prevent="toggleFavorite"
          >
            <Icon icon="heart" :class="{ favorite: isFavorite }" />
          </div>
        </div>

        <div class="tour-card__image__bottom">
          <div
            class="tour-card__image__expert font-text"
            @click.stop.prevent="clickExpert"
          >
            <img
              class="tour-card__image__expert__photo lazyload"
              :class="{
                  'awards_photo': getAwards,
               }"
              loading="lazy"
              :data-src="expert.avatar"
              :data-srcset="expert.avatar"
              :src="expert.avatar"
              :srcset="imageUrl1x1"
              :alt="expert.name"
            />

            <div class="tour-card__image__expert__title">
              <div
                class="tour-card__image__expert__name font-h1"
                :class="{
                  'awards_title': getAwards,
                 }"
                v-text="expert.name"
              />
              <div
                v-if="getAwards"
                :class="'awards_item ' + getAwards.code"
                v-text="getAwards.name"
              />
            </div>


          </div>

          <div
            class="tour-card__image__discount font-text"
            v-if="priceDiscount"
            v-text="priceDiscountPercent"
          />
        </div>
      </div>

      <div class="tour-card__body">
        <div class="tour-card__body__section">
          <div class="tour-card__body__rating">
            <div class="tour-card__body__rating__icon">
              <Icon icon="tour-rating-star" />
            </div>

            <div
              v-if="expert.rating === 0"
              class="tour-card__body__rating__new"
            >
              {{ $t("serp.tourCard.new") }}
            </div>

            <template v-else>
              <div
                class="tour-card__body__rating__rating"
                v-text="expert.rating"
              />

              <div
                class="tour-card__body__rating__number"
                v-text="expert.reviews"
              />
            </template>

            <div class="tour-card__body__location font-text">
              · {{ location }}
            </div>
          </div>

          <div class="tour-card__body__title font-semibold">
            <span v-text="title" />
          </div>

          <div
            v-if="showDescriptionInCard"
            class="tour-card__body__description"
          >
            <span v-text="description" />
          </div>

          <div
            v-show="isShowMinPriceTest"
            class="tour-card__body__price tour-card__body__price--min-price"
          >
            <span
              class="tour-card__body__price__discount tour-card__body__price__discount--min-price font-h1"
              v-if="minPriceDiscount"
              v-text="minPriceDiscount"
            />

            <span
              class="tour-card__body__price__original tour-card__body__price__original--min-price font-h1"
              :class="{
                'tour-card__body__price__original--discount tour-card__body__price__original--discount--min-price': minPriceDiscount,
              }"
              v-text="minPrice"
            />

            <span class="tour-card__body__price__days tour-card__body__price__days--min-price font-text"> / </span>

            <span class="tour-card__body__price__days tour-card__body__price__days--min-price font-text">
              {{ duration }}
            </span>
          </div>

          <div
            v-show="!isShowMinPriceTest"
            class="tour-card__body__price"
          >
            <span
              class="tour-card__body__price__original font-h1"
              :class="{
                'tour-card__body__price__original--discount': priceDiscount,
              }"
              v-text="price"
            />

            <span
              class="tour-card__body__price__discount font-h1"
              v-if="priceDiscount"
              v-text="priceDiscount"
            />

            <span class="tour-card__body__price__days font-text">
              / {{ duration }}
            </span>
          </div>
          <div class="tour-card__body__dates">
            <div class="tour-card__body__date font-text">
              <div class="tour-card__body__date__text">
                {{ dates }}
              </div>

              <div class="tour-card__body__date__free-spaces" v-if="freeSpace">
                <Icon icon="lightning" />

                <span v-text="freeSpace" />
              </div>
              <div v-else style="display:none"></div>
            </div>

            <div
              v-if="moreDates > 1"
              class="tour-card__body__more-dates font-text"
            >
              +{{ moreDates }}
              {{
                plural(moreDates, [
                  $t("serp.tourCard.moreDates[0]"),
                  $t("serp.tourCard.moreDates[1]"),
                  $t("serp.tourCard.moreDates[2]")
                ])
              }}
            </div>
          </div>
        </div>
      </div>
      <component
        v-bind:is="'script'"
        type="application/ld+json"
        v-html="this.getTourCardMeta"
      >
      </component>
    </div>
  </a>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { useAnalytics } from "@/plugins/events";
import { useSharedStore } from "@/state/shared-store";
import Icon from "@/components/icons/index.vue";
import { i18n } from "@/plugins/i18n";
import {
  format,
  formatDistanceStrict,
  addDays,
  isSameYear,
  isThisYear,
  isSameMonth,
} from "date-fns";
import { ru } from "date-fns/locale";
import {mapActions, mapState} from "vuex";
import queryString from "query-string";
import { abTestController } from "@/plugins/abtest";
import {ytEvents} from "@/plugins/analytics";
import {axiosUser} from "@/services/scopes/axios-user";
import {getImageCdnUrl} from "@/assets/js/utils/get-img-cdn";
export default defineComponent({
  name: "tour-card",
  components: {
    Icon,
  },
  props: {
    tour: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
    lazyImage: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    participants: {
      type: String,
      default: "1",
    },
    isSuperExpert: {
      type: Boolean,
      default: false,
    },
    locationInfo: {
      type: Object,
      default: () => {
        return [];
      },
    }
  },
  data() {
    return {
      eventFavoriteName: "favorite-tour",
      pendingFavorite: false,
      isRussian: i18n.locale === "ru",
      toursWithAwards: [
        25392,
        29738,
        11073,
        23607,
        17952,
        17952,
        7129,
        25127,
        10557,
        28022
      ]
    };
  },
  setup(props) {
    const { trackEvent } = useAnalytics();
    const { user } = useSharedStore();

    const tourRef = ref(undefined);
    const imageRef = ref(undefined);
    const mounted = ref(false);

    const link = computed(() => props.tour.link);
    const title = computed(() => props.tour.title);
    const preview = computed(() => props.tour.preview_image);
    const isPrivate = computed(() => props.tour.is_private);
    const isGuarantee = computed(() => props.tour.dates.group.guarantee);
    const isSponsored = computed(() => props.tour.sponsored);
    const topsortResolvedBidId = computed(() => props.tour.topsort_resolved_bid_id);
    const isRecommended = computed(() => props.tour.recommended);
    const isBlackFriday = computed(() => {
      return false;//!!props.tour.dates.group.prices_with_discount;
    });

    const description = computed(() => props.tour.description ?
      props.tour.description.replace(/&nbsp;/g," ").replace(/<\/?[^>]+>/g,'').replace("&nbsp;"," ").slice(0,60) + '...' : '');
    const isNew = computed(() => props.tour.is_new);
    const expert = computed(() => {
      const expert = props.tour.expert;
      const avatar = getImageCdnUrl(expert.avatar, 64, 64);
      const avatar1x1 = getImageCdnUrl(expert.avatar, 1, 1)
      const rating = expert.rating ? expert.rating.toFixed(1) : 0;

      return {
        avatar,
        avatar1x1,
        rating,
        name: expert.name,
        reviews: `(${expert.count_reviews || 0})`,
      };
    });
    const type = computed(() => {
      return props.tour.types.find(({ main }) => main).title;
    });

    const imageUrl = computed(() => {
      const width = +imageRef.value?.clientWidth || 276;
      const height = +imageRef.value?.clientHeight || 256;

      return getImageCdnUrl(preview.value, width, height, true);
    });
    const imageUrl1x1 = computed(() => {
      return getImageCdnUrl("upload/placeholders/eeeef2.png", 1, 1, false);
    });

    const imageUrlNoMounted = computed(() => {
      const width = +imageRef.value?.clientWidth || 276;
      const height = +imageRef.value?.clientHeight || 256;

      return getImageCdnUrl(preview.value, width, height, true);
    });
    onMounted(() => {
      mounted.value = true;
    });

    function clickExpert() {
      const url = `/expert/${props.tour.expert.id}/`;

      fakeWindow.open(url, "_blank").focus();
    }

    function setLocalStorageInfo() {
      localStorage.setItem('date_id', String(props.tour.dates.group.id));
      localStorage.setItem('participants', String(props.participants));
    }

    function sendClickDataToGa() {
      fakeWindow.sendClickDataToGa(
        tourRef.value,
        queryString.parse(props.query, { arrayFormat: "index" })
      );
    }

    return {
      tourRef,
      imageRef,
      mounted,
      user,
      type,
      link,
      title,
      description,
      expert,
      isNew,
      preview,
      imageUrlNoMounted,
      imageUrl,
      imageUrl1x1,
      isPrivate,
      isGuarantee,
      isSponsored,
      topsortResolvedBidId,
      isRecommended,
      isBlackFriday,
      setLocalStorageInfo,
      sendClickDataToGa,
      trackEvent,
      clickExpert,
    };
  },
  mounted() {
    fakeWindow.addEventListener("auth.close", this.onAuthClose);
    fakeWindow.addEventListener("auth.success", this.onAuthSuccess);
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("auth.close", this.onAuthClose);
    fakeWindow.removeEventListener("auth.success", this.onAuthSuccess);
  },
  computed: {
    ...mapState('user', ['favorites']),
    ...mapState('user', ['isLoggedIn']),
    ...mapState({
      currency: (state) => state.currency.currency,
      language: (state) => state.language.language,
      mirIsPromoAvailable: (state) =>
        state.serp?.ordersOptions?.mirIsPromoAvailable,
      mirFirstStartTourDate: (state) =>
        state.serp?.ordersOptions?.mirFirstStartTourDate,
      mirLastFinishTourDate: (state) =>
        state.serp?.ordersOptions?.mirLastFinishTourDate,
    }),
    topsortData() {
      if (!this.isSponsored) {
        return {};
      }

      return {
        'data-ts-product': this.tour.id,
        'data-ts-resolved-bid': this.topsortResolvedBidId,
      };
    },
    isFavorite() {
      return this.favorites?.map(String).includes(this.tour.id.toString());
    },
    price() {
      const price = Math.ceil(this.tour.dates.group.price)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      return `${this.currency?.title.charAt(0)} ${price}`;
    },
    priceDiscount() {
      const pricesWithDiscount = this.tour.dates.group.prices_with_discount;

      if (pricesWithDiscount) {
        const price = Math.ceil(pricesWithDiscount)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        return `${this.currency?.title.charAt(0)} ${price}`;
      }

      return "";
    },
    priceDiscountPercent() {
      const serpDiscountPercent = this.isShowMinPriceTest
        ? this.getMinPriceDiscountPercent
        : this.tour.dates.group.discount_percent;

      const discountPercent = Math.round(serpDiscountPercent);

      return `-${discountPercent}%`;
    },
    isSpecialPages() {
      const pathName = vueOptions.ssr ? context.url : window.location.pathname;

      return pathName.indexOf("/specials/") !== -1;
    },
    isShowMinPriceTest() {
      return (
        abTestController.getSegment(17178) === "B" &&
        this.isRussian
      );
    },
    minPrice() {
      const serpPrice = this.tour.dates.group_min_price.price;

      const price = Math.ceil(serpPrice)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      const text = !this.getMinPriceDiscountPercent ? 'от ' : '';

      return `${text}${this.currency?.title.charAt(0)} ${price}`;
    },
    minPriceDiscount() {
      const pricesWithDiscount = this.tour.dates.group_min_price.prices_with_discount;

      if (pricesWithDiscount) {
        const price = Math.ceil(pricesWithDiscount)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        const text = this.getMinPriceDiscountPercent ? 'от ' : '';

        return `${text}${this.currency?.title.charAt(0)} ${price}`;
      }

      return "";
    },
    getMinPriceDiscountPercent() {
      return this.tour.dates.group_min_price.discount_percent;
    },
    dates() {
      let dateFrom = new Date(this.tour.dates.group.date_from * 1000);
      let dateTo = new Date(this.tour.dates.group.date_to * 1000);

      if (this.isShowMinPriceTest) {
        dateFrom = new Date(this.tour.dates.group_min_price.date_from * 1000);
        dateTo = new Date(this.tour.dates.group_min_price.date_to * 1000);
      }
      const isYearSame = isSameYear(dateFrom, dateTo);
      const isYearThis = isThisYear(dateFrom);
      const isMonthSame = isSameMonth(dateFrom, dateTo);

      if (!isYearSame) {
        return `${format(dateFrom, "d MMM yyyy", {
          locale: this.isRussian && ru,
        })} - ${format(dateTo, "d MMM yyyy", {
          locale: this.isRussian && ru,
        })}`;
      } else if (!isMonthSame) {
        return `${format(dateFrom, "d MMM", {
          locale: this.isRussian && ru,
        })} - ${format(dateTo, isYearThis ? "d MMM" : "d MMM yyyy", {
          locale: this.isRussian && ru,
        })}`;
      } else {
        return `${format(dateFrom, "d", {
          locale: this.isRussian && ru,
        })} - ${format(dateTo, isYearThis ? "d MMM" : "d MMM yyyy", {
          locale: this.isRussian && ru,
        })}`;
      }
    },
    priceMeta() {
      const tourTitle = this.tour.title;
      const startDate = new Date(
        this.tour.dates.group.date_from * 1000
      ).toISOString();
      const endDate = new Date(
        this.tour.dates.group.date_to * 1000
      ).toISOString();

      return {
        availabilityStarts: startDate,
        availabilityEnds: endDate,
        validFrom: startDate,
        url: !vueOptions.ssr && fakeWindow.location.origin + this.tour.link,
        name: tourTitle,
      };
    },
    cardMeta() {
      return {
        "data-id": this.tour.id,
        "data-name": this.tour.title,
        "data-price": Math.ceil(this.tour.dates.group.price),
        "data-countries": this.location,
        "data-type": this.tour.types.reduce(
          (acc, el) => (acc?.title || acc) + `, ${el.title}`
        ),
        "data-currency-code": this.currency.id,
        "data-meta": JSON.stringify(this.tour.meta),
        "data-list": !vueOptions.ssr && fakeWindow.location?.pathname,
      };
    },
    getAwards() {
      if (this.toursWithAwards.indexOf(this.tour.id ) != -1) {
        return {
          code: 'best_tour',
          name: this.$t("serp.awards.bestTours"),
        }
      }

      if (this.tour.expert.id === 9103) {
        return {
          code: 'best_expert',
          name: this.$t("serp.awards.bestExpert"),
        }
      }

      return false;
    },
    getDescriptionForMeta() {
      const description = this.tour.description || "";
      if (description.length) {
        const text = description.replace(/<.*?>/gm, "").split(" ");
        let idx = 0;
        let canIterate = true;
        let out = [];

        while (text[idx] && canIterate) {
          const check = [...out, text[idx]].join(" ");

          if (check.length < 147) {
            out.push(text[idx]);
            idx++;
          } else {
            canIterate = false;
          }
        }
        return out.join(" ") + "...";
      }
      return "";
    },
    getTourCardMeta() {
      const dateFrom = new Date(this.tour.dates.group.date_from * 1000);
      const dateTo = new Date(this.tour.dates.group.date_to * 1000);
      const baseUrl = process.env.VUE_APP_BASE_URL || 'https://youtravel.me';
      const countryUrl = (this.tour.countries?.[0]).toLowerCase(); ""
      const arEmoji = ["&#11088;", "&#128293;", "&#10004;&#65039;", "&#9728;&#65039;", "&#129505;", "&#129523;"];
      const emojiIndex = Math.floor(Math.random() * (5 - 0 + 1)) + 0;

      const regex = /^[^\w\dА-Яа-яёЁ!?+—–−_«»"'.,^;()|\s]+/gu;

      return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Event",
        name: arEmoji[emojiIndex]+this.title.replace(regex, '').trim(),
        startDate: dateFrom,
        endDate: dateTo,
        url: `${baseUrl}${this.link}`,
        image: [getImageCdnUrl(this.preview, 1000, 1000)],
        eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
        eventStatus: "https://schema.org/EventScheduled",
        description: this.getDescriptionForMeta,
        organizer: {
          "@type": "Person",
          name: `${this.tour.expert.name}.`,
          url: `${baseUrl}${this.tour.expert.link}`,
        },
        performer: {
          "@type": "PerformingGroup",
          name: `${this.tour.expert.name}.`,
          url: `${baseUrl}${this.tour.expert.link}`,
        },
        location: {
          "@type": "Place",
          name: this.location,
          url: `${baseUrl}/tours/country/${countryUrl}`,
          address: {
            "@type": "PostalAddress",
            addressRegion: this.tour.regions?.[0],
            addressCountry: this.tour.countries?.[0],
          },
        },
        offers: {
          "@type": "Offer",
          availability: "https://schema.org/InStock",
          validFrom: dateFrom,
          url: `${baseUrl}${this.link}`,
          price: Math.ceil(this.tour.dates.group.price),
          priceCurrency: this.currency.id,
        },
      }, null, 2);
    },
    moreDates() {
      return this.tour.dates.total ? this.tour.dates.total-1 : this.tour.dates.total;
    },
    duration() {
      const start = new Date(this.tour.dates.group.date_from * 1000);
      const end = new Date(this.tour.dates.group.date_to * 1000);
      const options = {
        locale: this.isRussian && ru,
        roundingMethod: "ceil",
        unit: "day",
      };

      return formatDistanceStrict(start, addDays(end, 1), options);
    },
    location() {
      let locName = this.tour?.regions?.[0] || "";

      if(parseInt(this.locationInfo.seo_optimized) == 1 && this.locationInfo.meta[i18n.locale]['name'] ) {
        locName = this.locationInfo.meta[i18n.locale]['name']
      }
      return [
        this.tour?.countries?.[0] || "",
        locName,
      ]
        .filter((v) => v)
        .join(", ");
    },
    freeSpace() {
      const freeSpaces = this.isShowMinPriceTest ? this.tour.dates.group_min_price.free_spaces : this.tour.dates.group.free_spaces;
      const hasSpaces = freeSpaces && freeSpaces <= 3;

      if (hasSpaces) {
        switch (freeSpaces) {
          case 1:
            return this.$t("serp.tourCard.places[0]");
          default:
            return [
              this.tour.dates.group.free_spaces,
              this.$t("serp.tourCard.places[1]"),
            ].join(" ");
        }
      }

      return false;
    },
    isCashBackMir() {
      return (
        this.tour.cashback_mir &&
        this.mirIsPromoAvailable &&
        this.tour.dates.group.date_from >= this.mirFirstStartTourDate &&
        this.tour.dates.group.date_to <= this.mirLastFinishTourDate
      );
    },
    showGuaranteeLabel() {
      return (
        abTestController.getSegment(14940) === "B" &&
        this.isRussian &&
        this.isPrivate &&
        this.isGuarantee
      );
    },
    showDescriptionInCard() {
      if(abTestController.getSegment(15384) === "B") {
        return false;
      }
      return (
        this.isRussian
      );
    },
  },
  methods: {
    ...mapActions("user", ["setFavorites"]),
    toggleFavorite() {
      if (this.pendingFavorite) {
        return;
      }
      const { isFavorite } = this;
      const id = this.tour.id


      ytEvents.track("Tour detail", "Click " + (isFavorite ? "Remove" : "Add") + " to favorites button", id);

      if (this.isLoggedIn) {
        this.pendingFavorite = true;
        return axiosUser[isFavorite ? "favouriteRemove" : "favouriteAdd"]({
          id,
        })
            .finally(() => {
              this.pendingFavorite = false;
            })
            .then(
                ({
                   data: {
                     data: { favourites },
                   },
                 }) => {
                  this.setFavorites(favourites);
                }
            )
            .catch(console.log.bind(console));
      }
      fakeWindow.showVueAuthModal({ "emit-event": this.eventFavoriteName + "-" + id });
    },
    onAuthClose({ detail }) {
      const event = this.eventFavoriteName + "-" + this.tour.id;
      if (detail === event) {
        this.pendingFavorite = false;
      }
    },
    onAuthSuccess({ detail }) {
      this.isLoggedIn = true;
      const event = this.eventFavoriteName + "-" + this.tour.id;
      if (detail === event) {
        setTimeout(() => {
          this.toggleFavorite();
        });
      }
    },
    plural(n, forms) {
      return n % 10 == 1 && n % 100 != 11
        ? forms[0]
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? forms[1]
          : forms[2];
    },
  }
});
</script>

<style lang="scss" scoped>
.tour-card {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  background: var.$white;
  color: var.$black;
  min-width: 276px;
  max-width: 500px;
  cursor: pointer;
  transition: all 200ms;
  z-index: 0;
  height: 100%;
  box-shadow: 1px 2px 45px rgba(153, 153, 169, 0.22);

  &:visited {
    color: var.$black;
  }
  &:hover {
    color: var.$black;
    box-shadow: 1px 3px 60px rgba(153, 153, 169, 0.35);
    transform: translateY(-6px);
  }
  &:active {
    color: var.$black;
  }

  &__image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 256px;
    border-radius: 12px 12px 0 0;
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.25) 19.68%,
      rgba(0, 0, 0, 0.15) 37.85%,
      rgba(0, 0, 0, 0) 47.83%,
      rgba(0, 0, 0, 0.25) 60.62%,
      rgba(0, 0, 0, 0.65) 80.57%,
      rgba(0, 0, 0, 0.75) 100%
    );

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      border-radius: 12px 12px 0 0;
      z-index: -1;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 12px;
      z-index: 1;

      &__left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        > * {
          margin-right: 4px;
          margin-bottom: 4px;
        }
      }

      &__type {
        background: var.$white;
        border-radius: 4px;
        line-height: 17px;
        font-weight: 600;
        font-size: 13px;
        padding: 3px 6px;
      }

      &__private {
        background: var.$white;
        border-radius: 4px;
        padding: 3px 6px;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.02em;
      }

      &__additional {
        grid-gap: 4px;
        background: #9f18f1;
        color: var.$white;
        border-radius: 4px;
        padding: 3px 6px;
        font-size: 13px;
        font-weight: 600;
        line-height: 17px;
      }

      &__black_friday {
        grid-gap: 4px;
        background: #000000;
        color: var.$white;
        border-radius: 4px;
        padding: 3px 6px;
        font-size: 13px;
        font-weight: 600;
        line-height: 17px;
      }

      &__new {
        color: #fff;
        padding: 3px 6px;
        font-size: 13px;
        font-weight: 600;
        line-height: 17px;
        border-radius: 4px;
        background: #abc232;
      }

      &__favorite {
        justify-self: flex-end;
        margin-top: 2px;

        svg {
          &.favorite {
            fill: var.$white !important;
          }
        }

        &:hover svg {
          fill: var.$gray-dark !important;

          path {
            stroke: var.$gray-dark !important;
          }
        }
      }

      &__mir {
        background: #fefefe;
        padding: 6px;
        display: flex;
        border-radius: 4px;
        gap: 4px;
        box-sizing: border-box;

        &-text {
          color: #006842;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 700;
          letter-spacing: 0.02em;
        }
        &-icon {
          display: flex;
        }
      }

      &__super-expert {
        height: 24px;
        padding: 6px 6px 7px 6px;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 6px;
        background: #2E7CF6;
        display: flex;
        align-items: center;
        color: var(--white, #FFF);
        font-size: 13px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: -0.26px;
        gap: 4px;

        &-icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      grid-gap: 10px;
      padding: 16px;
      z-index: 1;
    }

    &__expert {
      display: flex;
      align-items: center;
      margin-top: 12px;

      &__photo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        background: #9f18f1;
      }

      &__name {
        color: var.$white;
        margin: 0 4px 0 8px;
        font-size: 13px;
        line-height: 17px;
      }

      &_title {
        max-width: 70%;
      }

      &:hover {
        & .tour-card__image__expert__name {
          text-decoration: underline;
        }
      }
    }

    &__discount {
      position: absolute;
      bottom: 0;
      right: 16px;
      padding: 10px 6px;
      color: var.$white;
      background: var.$red;
      border-radius: 6px 6px 0 0;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__section {
      flex: 1;
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
    }

    &__title {
      color: var.$black;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 10px;
      margin-bottom: auto;
      font-size: 14px;

      & > a {
        color: var.$black;

        &:visited {
          color: var.$black;
        }
        &:hover {
          color: var.$black;
        }
        &:active {
          color: var.$black;
        }
      }
    }

    &__description {
      font-weight: 400;
      height:29px;
      overflow:hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      color: #76768D;
      margin: 8px 0 11px;
    }

    &__price {
      display: flex;
      grid-gap: 8px;
      margin-top: 5px;
      align-items: flex-end;

      &--min-price {
        white-space: nowrap;
      }

      &__original {
        color: var.$black;
        font-size: 18px;

        &--min-price {
          @media (min-width: var.$size-md) {
            font-size: 16px;
          }
        }

        &--discount {
          text-decoration: line-through;
          color: var.$secondary;
          line-height: 18px;
          font-size: 13px;

          &--min-price {
            @media (min-width: var.$size-md) {
              font-size: 12px;
            }
          }
        }
      }

      &__discount {
        color: var.$black;
        font-size: 18px;

        &--min-price {
          @media (min-width: var.$size-md) {
            font-size: 16px;
          }
        }
      }

      &__days {
        color: var.$secondary;
        font-size: 13px;
        line-height: 18px;

        &--min-price {
          @media (min-width: var.$size-md) {
            font-size: 11px;
          }
        }
      }
    }

    &__dates {
      display: flex;
      margin-top: 10px;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;
    }

    &__date {
      display: flex;
      align-items: center;
      padding: 3px 8px;
      color: #9999a9;
      border: 1px solid #e4e4e8;
      border-radius: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;

      &__free-spaces {
        display: flex;
        align-items: center;
        color: var.$red;

        svg {
          margin-right: 2px;
        }
      }
    }

    &__more-dates {
      padding: 3px 8px;
      color: var.$secondary;
      border: 1px solid #e4e4e8;
      background: #e4e4e8;
      border-radius: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__rating {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 13px;

      &__icon {
        display: flex;
        margin-right: 2.4px;
        justify-content: center;
        width: 15px;
        height: 14px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__new {
        font-size: 13px;
        line-height: 17px;
        color: var.$green-dark;
      }

      &__rating {
        color: var.$black;
        margin-right: 2.4px;
      }

      &__number {
        color: var.$secondary;
      }
    }

    &__location {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var.$secondary;
      margin-left: 2px;
      font-size: 13px;
      width: 0;
      flex: 1;
    }
  }

  &.loading {
    box-shadow: 1px 2px 60px rgba(153, 153, 169, 0.35);

    .tour-card__image {
      background: var.$gray-light;
      box-shadow: none;

      & div {
        opacity: 0;
      }

      & img {
        opacity: 0;
      }
    }

    .tour-card__body__rating {
      background: #eeeef2;
      border-radius: 4px;
      div {
        opacity: 0;
      }
    }
    .tour-card__body__title {
      text-indent: 100%;
      height: 2em;
      background: var.$gray-light;
      border-radius: 4px;
      span {
        opacity: 0;
      }
    }

    .tour-card__body__description {
      text-indent: 100%;
      background: var.$gray-light;
      border-radius: 4px;
      span {
        opacity: 0;
      }
    }

    .tour-card__body__price {
      width: 50%;
      height: 1.5em;
      background: #eeeef2;
      border-radius: 4px;

      span {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        background: none;
        padding: 0;
      }
    }

    .tour-card__body__dates {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      height: 1.5em;
      background: #eeeef2;
      border-radius: 4px;
      border: 0;
      padding: 0;
      width: 100%;

      & > div {
        display: none;
      }
    }

    .tour-card__body__expert {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      height: 1.5em;
      background: #eeeef2;
      border-radius: 4px;
      border: 0;
      padding: 0;
      width: 100%;

      div,
      img {
        opacity: 0;
      }
    }
  }
}
.awards {
  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 5px 6px;
    color: #121212;
    margin: 0 8px;
    background: linear-gradient(107.46deg, #FCEF7F 17.49%, #FFC785 95.86%);
    border-radius: 4px;
    font-size: 11px;
    height: 18px;
    white-space: nowrap;
  }

  &_title {
    color: #FDE581;
    margin-bottom: 2px;
  }

  &_photo {
    border: 2px solid #FEE481;
  }

}
</style>
