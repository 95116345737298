<template>
  <div class="loader">
    <Icon icon="loader-circle" />
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";

export default {
  name: "tour-card",
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  box-shadow: 1px 4px 20px rgba(153, 153, 169, 0.35);
  border-radius: 16px;
  background: var.$white;

  & > svg {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
