<template>
  <div class="lang_modal-background">
    <div class="lang_modal">
      <div class="lang_modal-header">
        <div class="lang_modal-title">Language</div>
        <button class="lang_modal-close" v-on:click="hidePopup"></button>
      </div>
      <div class="lang_modal-list">
        <template v-for="[code, emoji, name, isAvaiable] in langs">
          <Tooltip
            :key="code"
            v-if="!isAvaiable"
            :text="'Coming soon'"
            :isClick="true">
            <span class="lang_modal-lang"
              :emoji="emoji"
               @click="trackEvent(
                      'Click Language link',
                      'Header main menu',
                      code
                    )
                  ">
              {{ name }}
            </span>
          </Tooltip>
          <a v-else class="lang_modal-lang"
            :key="code"
            :class="{ 'lang_modal-lang--active': currentLanguage.id == code }"
            :href="'?lang=' + code"
            :emoji="emoji"
             @click="trackEvent(
                      'Click Language link',
                      'Header main menu',
                      code
                    )
                  ">
            {{ name }}
          </a>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import langs from './langs.js';
import { mapMutations, mapState } from 'vuex';
import Tooltip from '@/components/atoms/tooltip/index.vue';
import {ytEvents} from "@/plugins/analytics";

export default {
  components: { Tooltip },
  data: () => ({
    langs,
  }),
  computed: {
    ...mapState('language', {
      currentLanguage: 'language',
    }),
  },
  methods: {
    ...mapMutations('popup', ['hidePopup']),
    trackEvent(category, name, label) {
      ytEvents.track(category, name, label);
    }
  },
}
</script>

<style>
.lang_modal-background {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
.lang_modal {
  background: #fff;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 20%);
  border-radius: 16px;
  position: absolute;
  padding: 32px;
  min-width: 400px;
  max-width: 788px;
  z-index: 999; /* TODO FIX */
  --action2: 19px;

  /* center XY */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lang_modal-header {
  position: relative;
  margin-bottom: 27px;
  height: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lang_modal-title {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  position: absolute;
  width: 100%;
  padding: 0 var(--action2);
}

.lang_modal-close {
  width: var(--action2);
  height: var(--action2);
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  z-index: 1;
  margin-left: auto;
  background-size: cover;
  background-image: url(/local/templates/youtravel/assets/i/close.svg);
}

.lang_modal-lang {
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 2px 2px 15px rgba(153, 153, 169, 0.15);
  border: 1.5px solid #EEEEF2;
  border-radius: 12px;
  height: 56px;
  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.01em;
  --padding: 20px;
  position: relative;
  padding: 0 var(--padding);
  transition: color 200ms ease, box-shadow 200ms ease;
  color: inherit;
}
.lang_modal-lang:last-child { margin-bottom: 0; }
.lang_modal-lang::before {
  content: attr(emoji);
  display: inline-block;
  position: absolute;
  left: var(--padding);
  font-size: 18px;
}
.lang_modal-lang--active {
  border-color: #ABC232;
  box-shadow: 1px 4px 20px rgba(153, 153, 169, 0.35);
 }
 .lang_modal-lang:hover {
  box-shadow: 1px 4px 20px rgba(153, 153, 169, 0.35);
 }

@media only screen and (max-width: 480px) {
  .lang_modal {
    height: auto;
    width: 100%;
    min-width: 100%;
    transform: unset;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
  }
}
</style>
