const dayAfterDayEn = {
  dayAfterDay: {
    title: "Total days on tour",
    fieldDaysText: "Filled days",
    fieldDaysFromText: "from",
    headerTextFirst:
      "It is necessary to fill in the description for all days of the tour, so that travelers understand what the trip will consist of and how activities will be distributed over the days.",
    headerTextSecond:
      "The exceptions are travel of the following types: Art, ski, yoga, mom and baby, surfing, fitness. For these types, you can fill in the description for one day and copy it to all other days.",

    cloneDay: {
      placeholder: "Choose a day",
      label: "Copy description from another day?",
      cloned: "Copied description from "
    },
    tourDayForm: {
      day: {
        name: "Day name*",
        placeholder: "Day Description*",
      },
      photo: {
        uploadDayPhoto: "Upload photo of the day",
        uploadHotelPhoto: "Upload hotel photo",
        limitText: "(max 3)",
        limitError: "Max photos count - 3",
        placeholder: "Photo title",
      },
      location: {
        placeholder: "Add location",
      },
    },
    controls: {
      delete: {
        placeholder: "Delete day",
      },
      save: {
        placeholder: "Save day",
      },
      add: {
        placeholder: "Add day",
      },
    },
    navButtons: {
      navPrev: {
        text: "Back",
      },
      navNext: {
        text: "Important to know",
      },
    },
    inform: {
      diffDays: "To add a day, you need to increase the number of days in arrivals",
    },
  },
};
export { dayAfterDayEn };
