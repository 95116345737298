import {ytVueGlobalStore} from "@/services/yt-vue-global-store";
import {i18n} from "@/plugins/i18n";
const changeMode = ytVueGlobalStore.instantGet('changeMode');
const userRole = ytVueGlobalStore.instantGet("user").userRole;
const getHeaderButtons = (userRole) => {
  let buttons = {};
  if(userRole !== 'Expert' || changeMode.isExpertMode === false) {
    buttons = {
      'helpme' : {
        'id' : '1',
        'title' : i18n.t('mainMenu.helpme'),
        'type' : 'button',
        'link' : '#',
        'icon' : '',
        'isMobile' : false,
        'isDesktop' : true,
        'isExpertSection' : false,
        'isUserSection' : false,
        'isDefault' : false,
        'trackEvent' : 'want_to_go help_header',
        'on_click' : 'modalHelpMeOpenHelper',
        'ajax' : {
          'action' : '/local/ajax/booking/ajax.booking.php',
          'dataTemplate' : 'helpme',
          'class' : 'js-yt-modals',
        },
        'slug' : 'help_me'
      },
      'howItWorks' : {
        'id' : '2',
        'title' : i18n.t('mainMenu.howItWorks'),
        'type' : 'button',
        'link' : '#',
        'on_click' : 'modalHowItWorksOpenHelper',
        'slug' : 'how_it_works'
      }
    }
  }

  return buttons;
}

export default {
  namespaced: true,
  state: {
    headerButtons: getHeaderButtons(userRole) || {}
  },
  mutations: {
    setHeaderButtons(state, value) {
      state.headerButtons = value
    }
  },
  actions: {
    setHeaderButtons({commit},{userRole}){
      const buttons = getHeaderButtons(userRole);
      commit('setHeaderButtons', buttons);
    }
  }
};
