import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

export default {
  namespaced: true,
  state: {
    user: {},
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const { data: { data } } = await axiosYtApiV2.get("/user");
        commit("setUser", data);
      } catch (error) {
        console.error(error);
      }
    },
  },
}
