<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="preloader"
      :class="[`_${position}`, { _overlay: overlay }]"
    >
      <div class="preloader__icon">
        <div class="preloader__icon-svg">
          <svg-inline>
            <icon icon="loader-circle" />
          </svg-inline>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  components: {
    SvgInline,
    Icon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "static",
      validator(position) {
        return ["static", "absolute", "fixed"].includes(position);
      },
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// @todo move to global
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.preloader {
  @include mixin.full-pos(0);
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: auto;
  height: auto;

  &._static {
    position: static;
  }

  &._absolute {
    position: absolute;
  }

  &._fixed {
    position: fixed;
  }

  &._overlay {
    background: rgba(var.$black, 0.05);
  }

  &__icon {
    @include mixin.aspect-ratio();
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 72px;
    box-shadow: 1px 4px 20px rgba(var.$secondary, 0.35);
    border-radius: 16px;
    background: var.$white;

    &-svg {
      width: percentage(func.divide(32, 72));
      overflow: hidden;
      animation: spin 1s linear infinite;
    }
  }
}
</style>
