var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lang_modal-background"},[_c('div',{staticClass:"lang_modal"},[_c('div',{staticClass:"lang_modal-header"},[_c('div',{staticClass:"lang_modal-title"},[_vm._v("Language")]),_c('button',{staticClass:"lang_modal-close",on:{"click":_vm.hidePopup}})]),_c('div',{staticClass:"lang_modal-list"},[_vm._l((_vm.langs),function([code, emoji, name, isAvaiable]){return [(!isAvaiable)?_c('Tooltip',{key:code,attrs:{"text":'Coming soon',"isClick":true}},[_c('span',{staticClass:"lang_modal-lang",attrs:{"emoji":emoji},on:{"click":function($event){return _vm.trackEvent(
                    'Click Language link',
                    'Header main menu',
                    code
                  )}}},[_vm._v(" "+_vm._s(name)+" ")])]):_c('a',{key:code,staticClass:"lang_modal-lang",class:{ 'lang_modal-lang--active': _vm.currentLanguage.id == code },attrs:{"href":'?lang=' + code,"emoji":emoji},on:{"click":function($event){return _vm.trackEvent(
                    'Click Language link',
                    'Header main menu',
                    code
                  )}}},[_vm._v(" "+_vm._s(name)+" ")])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }