import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";
import { i18n } from "@/plugins/i18n";

const axiosTour = {
  getTour(tourId, language_code, abortPromise = null) {
    const controller = new AbortController();
    if (abortPromise) {
      abortPromise.then(() => {
        controller.abort();
      });
    }

    return axiosYtApiV2.get(
      `tours/${tourId}` +
        (language_code ? `?language_code=${language_code}` : ""),
      {
        signal: controller.signal,
      }
    );
  },

  getTourRefundPolicies() {
    return axiosYtApiV2.get("refund-policies/trim");
  },

  getPublicTourData(tourId, params) {
    return axiosYtApiV2.get(`tours/public/${tourId}`, {
      params: {
        lang: i18n.locale,
        ...params
      }
    });
  },

  getTourLanguage(tourId, language) {
    return axiosYtApiV2.get(`tours/${tourId}?language_code=${language}`);
  },

  postPublushTour(tourId) {
    return axiosYtApiV2.post(`/tours/${tourId}/publish`);
  },

  postRejectTour(tourId) {
    return axiosYtApiV2.post(`/tours/${tourId}/reject`);
  },

  postChangeStatusModerate(tourId) {
    return axiosYtApiV2.post(`/tours/${tourId}/send-to-moderation`);
  },

  postSaveTour(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/save`, data);
  },

  predictSave(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/predict-save`, data);
  },

  postAutoSaveTour(tourId, data, abortPromise) {
    const controller = new AbortController();
    if (abortPromise) {
      abortPromise.then(() => {
        controller.abort();
      });
    }
    return axiosYtApiV2.post(`/tours/${tourId}/auto-save`, data, {
      signal: controller.signal,
    });
  },

  postDeleteTour(tourId) {
    return axiosYtApiV2.post(`/tours/${tourId}/delete`);
  },

  postSetStatusDraft(tourId) {
    return axiosYtApiV2.post(`/tours/${tourId}/to-draft`);
  },

  postCreateCopy(tourId) {
    return axiosYtApiV2.post(`/tours/${tourId}/copy`);
  },

  getFormConfig(tourId) {
    return axiosYtApiV2.get(`/tours/${tourId}/config/form`);
  },

  getExpertList(tourId, term, take = 20, skip = 0) {
    return axiosYtApiV2.get(`/tours/${tourId}/config/expert-list?term=${term}&take=${take}&skip=${skip}`);
  },

  getPresets() {
    return axiosYtApiV2.get(`/tours/presets`);
  },

  postAddComment(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/moderate-comments`, data);
  },

  getPhoto(tourId) {
    return axiosYtApiV2.get(`/tours/${tourId}/moderate-photos`);
  },

  postAddPhoto(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/moderate-photos`, data);
  },

  postDeletePhoto(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/moderate-photo-delete`, data);
  },

  postUpdateComment(tourId, commentId, data) {
    return axiosYtApiV2.post(
      `/tours/${tourId}/moderate-comments/${commentId}/update`,
      data
    );
  },

  postDeleteComment(tourId, commentId) {
    return axiosYtApiV2.post(
      `/tours/${tourId}/moderate-comments/${commentId}/delete`
    );
  },

  getTourHistory(tourId, query) {
    return axiosYtApiV2.get(`/tours/${tourId}/history?${query}`);
  },

  getQuestions(tourId) {
    return axiosYtApiV2.get(`/tours/${tourId}/questions`);
  },

  addQuestions(tourId, questions) {
    return axiosYtApiV2.post(`/tours/${tourId}/questions`, { questions });
  },

  updateQuestion(tourId, questionId, question) {
    return axiosYtApiV2.post(
      `/tours/${tourId}/questions/${questionId}/update`,
      { question }
    );
  },

  deleteQuestion(tourId, questionId) {
    return axiosYtApiV2.post(`/tours/${tourId}/questions/${questionId}/delete`);
  },

  postNewPhoto(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/upload-photo`, data);
  },

  postNewDayPhoto(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/upload-day-photo`, data);
  },
  getParticipants(tourId, offerId) {
    return axiosYtApiV2.get(
      `/tours/public/${tourId}/offers/${offerId}/participants`
    );
  },
  getParticipantDetail(tourId, offerId, userId) {
    return axiosYtApiV2.get(`/tours/public/${tourId}/offers/${offerId}/participants/${userId}`);
  },

  getSimilar(tourId) {
    return axiosYtApiV2.get(`/tours/public/${tourId}/similar`);
  },

  getToursHistory() {
    return axiosYtApiV2.get("/tours/history");
  },

  saveUnsaved(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/unsaved-changes`, data);
  },

  getUnsaved(tourId, lang = "ru") {
    return axiosYtApiV2.get(
      `/tours/${tourId}/unsaved-changes?language_code=${lang}`
    );
  },

  postTourPreview(tourId, data) {
    return axiosYtApiV2.post(`/tours/${tourId}/set-preview`, data);
  }
};

export { axiosTour };
