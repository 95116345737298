const notificationsCenter = {
  notificationsCenter: {
    popupHeader: "Уведомления",
    popupSeeAll: "Смотреть все",
    lkHeader: "Центр уведомлений",
    marAllRead: "Отметить все прочитанными",
    noNotificationsTitle: "Уведомлений нет",
    noNotificationsText: "Здесь вы узнаете о новых бронированиях, оплатах и прочих новостях о ваших турах, а также обо всех шагах на YouTravel",
    showMore: "Показать еще"
  },
};

export { notificationsCenter };
