const dropdownTriggerEn = {
  dropdownTrigger: {
    default: {
      extended: "Shrink",
      shrunk: "Show more",
    },
    description: {
      extended: "Shrink description",
      shrunk: "Expand description",
    },
    more: {
      extended: "Show less",
      shrunk: "Show more",
    },
    all: {
      extended: "Shrink",
      shrunk: "Show all",
    },
    days: {
      extended: "Shrink all days",
      shrunk: "Expand all days",
    },
  },
};

export { dropdownTriggerEn };
