const participantsEn = {
  participants: {
    count: "{n} participants | {n} participant | {n} participants",
    view: "View participants",
    detailTitle: "Who is already traveling",
    btnRegister: "Register to view",
    btnBooking: "Join the group",
  },
};

export { participantsEn };
