const dates = {
  dates: {
    timeAgo: "ago",
    timeAgoSecond1: "seconds",
    timeAgoSecond2: "seconds",
    timeAgoSecond5: "seconds",
    savedAgo: "Saved",
  },
};

export { dates };
