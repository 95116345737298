<template>
  <div class="serp-tour-selector" :class="{ active: isActive }">
    <Icon
      v-show="!activeTourSearchOption"
      icon="search"
      color="#121212"
      class="serp-tour-selector__icon-search"
    />

    <Multiselect
      class="multiselect-search"
      :class="{ mt6: tours.length }"
      ref="multiselectRef"
      placeholder=""
      label="tour"
      selectedLabel=""
      selectLabel=""
      deselectLabel=""
      :searchable="true"
      :options="tours"
      :loading="isLoading"
      :internal-search="false"
      :limit="3"
      :value="activeTourSearchOption"
      :preserveSearch="true"
      group-values="options"
      group-label="label"
      :group-select="false"
      :autofocus="autofocus"
      :clearOnSelect="false"
      @search-change="fetchLocation"
      @open="openHandler"
      @close="closeHandler"
      @input="querySearch = $event"
      @select="locationHandler"
    >
      <template v-slot:singleLabel="{ option }">
        <div
          class="active-item"
          v-html="option.label ? option.label : ''"
        ></div>
      </template>

      <template v-slot:noOptions>
        <span
          v-if="!isLoadingRecommended"
          class="serp-tour-selector__placeholder"
          v-text="searchLabels.startTyping"
        ></span>
        <div v-if="isLoadingRecommended" class="serp-tour-selector__loader">
          <Icon icon="loader-circle" />
        </div>
      </template>

      <template v-slot:noResult>
        <span
          class="serp-tour-selector__placeholder"
          v-text="searchLabels.nothingToShow"
        ></span>
      </template>

      <template slot="placeholder">
        <span v-text="placeholder"></span>
      </template>

      <template slot="caret"><div class="empty"></div></template>

      <template #beforeList>
        <div class="serp-tour-selector__before-list">
          <location-nearby
            @trigger="onLocationTrigger"
            @get-current-position="onGetCurrentPosition"
            @pending="isLoading = $event"
          />
        </div>
      </template>

      <template v-slot:option="{ option, search }">
        <!-- TODO: добавить поле trackEvent в API c тайтлом экшена аналитики-->
        <!-- заменить на @click="trackEventSelector(option.trackEvent)-->
        <div
          v-if="option.id"
          class="search-item"
          @click="
            trackEvent(
              place == 'main' ? 'Main page Search' : 'Header Search',
              'Click Suggested option',
              option.type,
              {
                item: option.label,
              }
            )
          "
        >
          <div class="description">
            <span class="item-title" v-html="option.label"></span>
            <span v-if="option.description" class="item-description">
              {{ search }}
            </span>
          </div>
          <div class="type" v-if="option.type !== 'tour'">
            <span class="item-type">{{ option.count }}</span>
          </div>
        </div>

        <div v-else class="search-item">
          <div class="description--group">
            <span class="item-title" v-html="option.$groupLabel"></span>
          </div>
        </div>
      </template>
    </Multiselect>

    <div
      v-show="activeTourSearchOption && !isActive"
      class="serp-tour-selector__icon-remove"
      @click.stop="locationHandler(null)"
    >
      <Icon icon="close" color="#121212" />
    </div>

    <div
      v-if="isActive"
      class="serp-tour-selector__icon-close"
      @click="closeHandler"
    >
      <Icon icon="close" color="#121212" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import { i18n } from "@/plugins/i18n";
import { axiosLocations } from "@/services/scopes/axios-locations.js";
import { ytEvents } from "@/plugins/analytics";
import Multiselect from "vue-multiselect";
import LocationNearby from "@/components/organisms/location-nearby/index.vue";
import helper from "@/mixins/helper.js";

export default {
  mixins: [helper],
  components: {
    Icon,
    Multiselect,
    LocationNearby,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
    place: {
      validator: function (prop) {
        return ["", "main", "header"].indexOf(prop) !== -1;
      },
      default: "",
    },
  },
  data: () => ({
    isActive: false,
    isLoading: false,
    isLoadingRecommended: false,
    searchLabels: {
      startTyping: i18n.t("tourSelector.startTyping"),
      nothingToShow: i18n.t("tourSelector.nothingToShow"),
      chooseDirection: i18n.t("tourSelector.chooseDirection"),
    },
    tours: [],
    querySearch: "",
    activeTourSearchOption: null,
  }),
  computed: {
    placeholder() {
      return this.querySearch
        ? this.querySearch
        : this.$t("serp.topMenu.where");
    },
  },
  methods: {
    locationHandler(evt) {
      this.querySearch = evt ? evt.label : "";
      this.activeTourSearchOption = evt;
      this.isLoading = false;
      this.isActive = false;
      this.$emit("updateLocation", this.activeTourSearchOption);
    },
    onLocationTrigger() {
      this.$refs.multiselectRef.deactivate();
    },
    onGetCurrentPosition({ latitude, longitude }) {
      this.locationHandler({
        type: "distance",
        label: this.$t("locationNearby.label"),
        lat: latitude,
        lon: longitude,
        radius: 1000,
      });
    },
    async openHandler() {
      if (fakeWindow.innerWidth < 1024) {
        const scrollY =
          document.documentElement.style.getPropertyValue("--scroll-y");
        const body = document.body;
        body.style.position = "fixed";
        body.style.width = "100%";
        body.style.top = `-${scrollY}`;
      }

      this.emitHeaderShow(false);
      this.$refs.multiselectRef.$refs.search.removeAttribute("readonly");
      this.isLoadingRecommended = true;
      this.isActive = true;
      this.$emit("isOpen", true);

      if (!this.querySearch) {
        await this.getRecommendedLocations();
      } else if (!this.tours?.length) {
        const {
          data: {
            data: { autocomplete: locations },
          },
        } = await axiosLocations.getList(this.querySearch);
        if (locations) {
          this.tours = this.parseLocations(locations);
        }
      }

      this.isLoadingRecommended = false;
    },
    closeHandler() {
      if (fakeWindow.innerWidth < 1024) {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = "";
        body.style.top = "";
        fakeWindow.scrollTo(0, parseInt(scrollY || "0") * -1);
      }

      this.emitHeaderShow(true);
      this.isActive = false;
      this.$emit("isOpen", false);
    },
    trackEvent(category, name, label, params) {
      ytEvents.track(category, name, label, params);
    },
    async fetchLocation(evt) {
      this.querySearch = evt;

      if (!this.querySearch) {
        await this.getRecommendedLocations();
        return;
      }

      this.isLoading = true;

      const {
        data: {
          data: { autocomplete: locations },
        },
      } = await axiosLocations
        .getList(this.querySearch)
        .catch((e) => console.log(e));

      if (locations) {
        this.tours = this.parseLocations(locations);
      }

      this.isLoading = false;
    },
    async getRecommendedLocations() {
      let initTours = [];

      const {
        data: {
          data: { popular_items, search_history },
        },
      } = await axiosLocations.getList().catch((e) => console.log(e));

      if (search_history) {
        initTours.push({
          type: "history",
          label: this.$t("serp.topMenu.history"),
          options: this.parseLocationOptions(search_history),
        });
      }

      if (popular_items) {
        initTours.push({
          type: "popular",
          label: this.$t("serp.topMenu.popular"),
          options: this.parseLocationOptions(popular_items),
        });
      }

      this.tours = initTours;
    },
    parseLocations(locations) {
      let filteredlLcations = locations.filter(({ type }) => type !== "type");

      if (this.isIosApp) {
        filteredlLcations = filteredlLcations.filter((el) => {
          if (el.type === "location") {
            return false;
          } else if (el.type === "region") {
            return el.id !== 470;
          }
          return true;
        });
      }

      return Object.keys(filteredlLcations)
        .reduce(
          (acc, key) => {
            const type = acc.findIndex(
              ({ type }) => type === filteredlLcations[key].type
            );
            const typeBetter = acc.findIndex(
              ({ type }) => type === 'better'
            );
            if (acc?.[type]) {
              acc[type].options.push({
                ...filteredlLcations[key],
                count: i18n.tc(
                  "tourSelector.tours",
                  filteredlLcations[key].tour_count
                ),
              });
            }
            if(key < 4 && this.querySearch?.length){
              acc[typeBetter].options.push({
                ...filteredlLcations[key],
                count: i18n.tc(
                  "tourSelector.tours",
                  filteredlLcations[key].tour_count
                ),
              });
            }
            return acc;
          },
          [
            {
              type: "better",
              label: this.$t("serp.topMenu.better"),
              options: [],
            },
            {
              type: "history",
              label: this.$t("serp.topMenu.history"),
              options: [],
            },
            {
              type: "country",
              label: this.$t("serp.topMenu.countries"),
              options: [],
            },
            {
              type: "continent",
              label: this.$t("serp.topMenu.continents"),
              options: [],
            },
            {
              type: "region",
              label: this.$t("serp.topMenu.regions"),
              options: [],
            },
            {
              type: "location",
              label: this.$t("serp.topMenu.destinations"),
              options: [],
            },
            {
              type: "tour",
              label: this.$t("serp.topMenu.tours"),
              options: [],
            },
            {
              type: "popular",
              label: this.$t("serp.topMenu.popular"),
              options: [],
            },
          ]
        )
        .filter(({ options }) => options.length);
    },
    parseLocationOptions(locations) {
      let filteredlLcations = locations.filter(({ type }) => type !== "type");

      if (this.isIosApp) {
        filteredlLcations = filteredlLcations.filter((el) => {
          if (el.type === "location") {
            return false;
          } else if (el.type === "region") {
            return el.id !== 470;
          }
          return true;
        });
      }

      return Object.keys(filteredlLcations).reduce((acc, key) => {
        acc.push({
          ...filteredlLcations[key],
          count: i18n.tc(
            "tourSelector.tours",
            filteredlLcations[key].tour_count
          ),
        });

        return acc;
      }, []);
    },
  },
  mounted() {
    const input = this.$refs.multiselectRef.$refs.search;

    input.setAttribute("readonly", true);
    input.addEventListener(
      "blur",
      () => {
        input.setAttribute("readonly", true);
      },
      true
    );

    fakeWindow.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${fakeWindow.scrollY}px`
      );
    });
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("scroll");
    fakeWindow.removeEventListener("blur");
  },
};
</script>

<style lang="scss">
@use "@/assets/scss/libraries/elements/serp-tour-selector.scss";

.item-title {
  b {
    color: var.$teal-400;
  }
}

.mt6 .multiselect__content {
  margin-bottom: 6px;
}
</style>
<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.serp-tour-selector {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  background: var.$gray-light;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;

  &.active {
    border-color: var.$black;
    background: var.$white;

    @media (max-width: var.$size-lg) {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 20px;

      position: fixed;
      top: 0;
      left: 0;
      z-index: 100000;
      background: var.$white;
      padding: 16px 20px 16px 16px;

      .serp-tour-selector {
        border-radius: 8px;
        border-color: var.$gray-light;
        background: var.$gray-light;
      }
    }
  }

  &__placeholder {
    margin-left: 20px;
    & > span {
      width: 200px;
    }
    &:hover {
      background: var.$white !important;
    }
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;

    & > svg {
      animation: spin 1s linear infinite;
    }
  }

  &__before-list {
    margin: 16px;
  }

  &__icon-close {
    top: -40px;
    left: 0;
    width: 100%;
    display: flex;
    height: 40px;
    padding: 0 20px;
    position: absolute;
    align-items: center;
    background: var.$white;
    justify-items: flex-end;
  }

  @media (min-width: var.$size-lg) {
    &__icon-close {
      display: none;
    }
  }
}

.search-item {
  position: relative;

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-title {
  margin: 0;

  b {
    color: var.$teal-400;
  }
}

.description {
  max-width: 70%;

  color: var.$black;

  overflow: hidden;
  white-space: break-spaces;

  &--group {
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    color: var.$secondary;
    text-transform: uppercase;
  }
}

.item-description {
  margin: 0;

  color: var.$black;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.type {
  vertical-align: middle;
}

.item-type {
  padding: 0;

  color: var.$secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
