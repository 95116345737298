export default {
  auth: {
    default: {
      title: "You are not authorized",
      text: "To continue, please log in",
      login: "Login",
    },
    signin: {
      title: "Log in",
      continueWithEmail: "Continue with email",
      continueWithPhone: "Continue with phone number",
      loginWithPhone: "Log in with phone number",
      loginWithEmail: "Log in with email",
      wrongEmail: "Email is not valid",
      wrongPhone: "Invalid phone number entered",
      or: "Or",
    },
    confirm: {
      title: "Confirm your number",
      change: "Change number",
      code: "Code",
      codeSentTo: "Enter the code we sent over SMS to",
      resendAfter: "Resend code after {seconds} s.",
      resend: "Resend code",
    },
    existing: {
      title: "Hello, {name}!",
      login: "Log in",
      continueWith: "Continue with {social}",
      different: "Log in to a different account",
    },
    initial: {
      title: "Log in or sign up",
      or: "Already have an account?",
      login: "Log in",
      signup: "Sign up",
    },
    password: {
      title: "Password recovery",
      forgotPassword: "Forgot password?",
      password: "Password",
      resetPasswordSent:
        "We have sent an email with instructions to reset your password to <b>{email}</b>. Please click the link in the email to reset your password",
      resetPassword:
        "Enter your email, and we will send you<br> a <b>link</b> to reset your password",
      enterPassword: "Enter password",
      changeEmail: "Change e-mail",
      resentEmailAfter: "Resend in {sec} sec",
      resentEmail: "Resend",
    },
    signup: {
      title: "Sign up",
      traveler: "I'm a Traveler",
      expert: "I'm a Travel Expert",
      fill: "The required field is not completed",
      name: "Name",
      lastName: "Last name",
      signup: "Sign up",
      individual: "Individual",
      entity: "Legal entity",
      touroperator: "Tour operator",
      team: "There is a team of tour leaders and guides, I myself deal with organizational issues on tours",
      tourLeader:
        "I work as a tour leader or guide, I organize everything myself",
      vk:{
        button: "Continue with VK",
      },
      terms:
        "By submitting the form, you agree to the terms & conditions <a target='_blank' href='/help/public-offer'>public offer</a> and agree to <a target='_blank' href='/help/privacy-agree'>personal data processing</a>.",
    },
    ok: "Ok",
    sendLink: "Reset password",
  },
};
