const serpDetailRu = {
  serpDetail: {
    stickyAnchors: {
      description: "Описание",
      route: "Маршрут",
      conditions: "Что включено",
      allocation: "Проживание",
      accommodation: "Варианты размещения",
      services: "Доп. услуги",
      important: "Важно знать",
      reviews: "Отзывы",
    },
    awards: {
      bestExpert: "Лучший организатор 2023",
      bestTours: "Выбор путешественников",
    },
    bookingSteps: {
      1: {
        text: "Выберите путешествие",
      },
      2: {
        text: "Забронируйте место",
        tooltip:
          "Для бронирования потребуется только предоплата&nbsp;&mdash; от&nbsp;15% от&nbsp;стоимости тура",
      },
      3: {
        text: "Оплатите безопасно",
        tooltip: `<p>
                    Данные карт надежно защищены по&nbsp;стандартам VISA, Mastercard и&nbsp;МИР.
                  </p>
                  <p>
                    <img
                      src="/static/i/serp-detail/payment-systems.png"
                      alt=""
                      class="u-img-responsive"
                      loading="lazy"
                    />
                  </p>`,
      },
    },
    generalInfo: {
      seeAllocations: "Где будем жить",
      seeRoute: "Смотреть маршрут",
      language: "Язык",
      attending: "Кто уже едет",
      age: "{countParticipants} человек, {age_from}-{age_to} лет",
      route: "Маршрут",
      outOfPlaces: "Места закончились",
    },
    description: {
      activity: "Активности в рамках одной локации или изменение нескольких локаций в течение маршрута, инфраструктурно-развитые локации, периодические вылазки на природу.",
      allocation: "Скромный уровень комфорта, декора и удобств.",
    },
    mainImpressions: "Главные впечатления",
    organizer: {
      organized: "Тур проводит",
      expert: "тревел-эксперт",
      guide: "гид",
      fromTeam: "Из&nbsp;команды организатора {name}",
    },
    program: {
      title: "Программа",
      day: "День",
      comment: "Комментарий от&nbsp;тревел-эксперта",
    },
    conditions: "Включено в&nbsp;стоимость",
    allocation: "Проживание",
    accommodation: "Варианты размещения",
    services: "Услуги",
    important: {
      title: "Важно знать",
      common: "Подготовка к туру",
      customCancellation: "Условия отмены",
      demands: "Пожелания к&nbsp;гостю",
      visa: "Визы",
      prepay: {
        title: "Нужно ли&nbsp;предоплачивать тур полностью?",
        text: "Размер предоплаты определяется Тревел-экспертом и&nbsp;указан на&nbsp;странице тура. Для большинства туров стандартная предоплата не&nbsp;превышает&nbsp;50% стоимости тура. За&nbsp;день до&nbsp;начала тура оплатите тур полностью, если иное не&nbsp;прописано в&nbsp;информации о&nbsp;туре. Полная оплата может осуществляться через платформу или напрямую Тревел-эксперту. При возникновении сложностей с&nbsp;оплатой обратитесь в&nbsp;службу поддержки сервиса.",
      },
      book: {
        title: "Как забронировать",
        text: "Выберите даты и перейдите по кнопке {action}. Вас ждет быстрая и безопасная оплата банковской картой или с помощью Yandex Pay/Apple Pay/Google Pay. Поездки с моментальным бронированием подтверждаются автоматически. В других случаях вы резервируете место в группе внесением предоплаты, и тревел-эксперт одобряет вашу заявку в течение суток. Если заявка будет отклонена - предоплата будет возвращена моментально. {ask} заранее перед бронированием.",
        action: "Забронировать тур",
        ask: "Задайте вопросы автору тура",
      },
    },
    arrival: {
      title: "Информация по&nbsp;прибытию",
      start: "Старт",
      finish: "Финиш",
      localTime: "местное время",
    },
    similar: "Похожие туры",
    sameCountry: "Ещё",
    sameType: "Ещё",
    recentlyViewed: "Вы&nbsp;недавно смотрели",
    special: "Специальные предложения",
    included: "Включено",
    notIncluded: "Не включено",
    complaint: {
      title: "Пожаловаться на тур",
      complaintPopup: {
        next: "Далее",
        uploadFile: "Загрузить файл",
        step_1: {
          header: "Спасибо, что помогаете <br> нам быть лучше!",
          bodyHeader: "Поделитесь с нами, <br>почему вы хотите пожаловаться на тур?",
          radio_1: "Дубликат тура",
          radio_2: "Нарушение авторского права",
          radio_3: "Недостоверная информация о туре"
        },
        step_2: {
          bodyHeader_1: "Добавьте ссылку на тур на платформе Youtravel.me, который дублирует этот тур",
          bodyHeader_2: "Расскажите о проблеме",
          bodyHeader_3: "Расскажите, что вам кажется<br> недостоверным или подозрительным",
          btnText: "Оставить жалобу",
          hint_1: "Пожаловаться можно только на тур на Youtravel.me",
          placeholder_1: "Ссылка на тур на Youtravel.me",
          hint_2: "Не менее 20 и не более 500 символов",
          placeholder_2: "Введите текст",
          hint_3: 'Ответ придет на ваш e-mail. Если вы хотите вернуть деньги за тур, перейдите в раздел <a href="/lk/arbitration/" target="_blank">Урегулирование</a>.'
        },
        step_3: {
          bodyHeader: "Спасибо за неравнодушие, ваша жалоба принята. Мы ответим в течение 24 часов",
          btnText: "Закрыть",

        }
      }
    }
  },
};

export { serpDetailRu };
