<template>
  <div class="service-field">
    <div class="row-top">
      <Multisuggest
        :value="data.value"
        :options="data.options"
        :placeholder="data.placeholder"
        @change-input="
          $emit('change-field', {
            index: index,
            value: $event,
          })
        "
      >
        <template v-slot:error-helper>
          <div v-if="validator.value.$error">
            <span v-if="!validator.value.$error.required">
              {{ $t("validation.required") }}
            </span>
          </div>
        </template>
      </Multisuggest>
    </div>
    <div class="row-bottom">
      <Btn
        tag="button"
        btn-type="button"
        class="btn-delete"
        outline="outline violet-2"
        color="color-violet-2"
        @btn-click="$emit('delete-btn-click')"
      >
        <template v-slot:content>
          <span class="btn-text">{{
            $t("serviceListMultisuggest.actions.delete")
          }}</span>
          <Icon icon="icon-trash" />
        </template>
      </Btn>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Btn from "@/components/atoms/btn/index.vue";
import Multisuggest from "@/components/molecules/multisuggest/index.vue";

export default {
  components: {
    Icon,
    Btn,
    Multisuggest,
  },
  props: {
    data: {
      type: Object,
      validator: (prop) =>
        typeof prop.options === "object" &&
        (typeof prop.value === "object" || prop.value === null) &&
        typeof prop.placeholder === "string",
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.row-top {
  margin-bottom: 16px;

  display: flex;

  @media (max-width: var.$md) {
    flex-direction: column;
  }
}

.btn-delete {
  padding: 8px 18px;
}
</style>
