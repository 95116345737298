function trackEvent(category, name, label, props) {
  if (typeof fakeWindow.trackEvent == "function") {
    try {
      fakeWindow.trackEvent(name, category, label, props);
    } catch (err) {
      console.error(err);
    }
  }
}

export function useAnalytics() {
  return {
    trackEvent,
  };
}
