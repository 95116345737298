<template>
  <div class="tooltip"
    :tabindex="isClick ? '-1' : undefined"
    :class="{ 'tooltip--hoverable': !isClick }">
    <slot></slot>
    <div class="tooltip-hint">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'isClick'],
}
</script>

<style>
.tooltip {
  position: relative;
  font-size: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 1;
}

.tooltip:focus > .tooltip-hint,
.tooltip--hoverable:hover > .tooltip-hint {
  opacity: 1;
  visibility: visible;
}

.tooltip-hint {
  background: #121212;
  visibility: hidden;
  color: #fff;
  padding: 1em 1.3333em;
  position: absolute;
  z-index: 1;
  border-radius: 12px;
  max-width: 200px;
  width: max-content;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: 200ms ease;
}

.tooltip-hint::before {
  width: 1em;
  height: 1em;
  background: inherit;
  content: '';
  display: inline-block;
  top: -.5em;
  transform: translateX(-50%) rotate(45deg);
  left: 50%;
  position: absolute;
  border-radius: 0px;
}
</style>
