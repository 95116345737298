import { enUS, ru } from "date-fns/locale";
import { i18n } from "@/plugins/i18n";

export default function getDateFnsLocale(
  languageId = i18n.locale || "ru"
) {
  const locales = {
    en: enUS,
    ru,
  };

  return locales[languageId];
}
