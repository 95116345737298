<template>
  <mounting-portal mount-to="#vue-popups" append slim>
    <transition name="popup">
      <div
        v-if="modelValue"
        class="yt-popup u-fixed-full"
        :class="[className, { [`_size-${size}`]: !!size }]"
      >
        <div
          v-scroll-lock="modelValue"
          class="yt-popup__container u-absolute-fill"
          @click.self="close"
        >
          <div class="yt-popup__content" @click.self="close">
            <div class="yt-popup__content-wrapper">
              <transition name="fade">
                <yt-button-arrow
                  v-if="showBackButton"
                  dir="left"
                  class="yt-popup__back"
                  @click="$emit('back')"
                />
              </transition>
              <transition name="fade">
                <yt-button-close
                  v-if="showCloseButton"
                  class="yt-popup__close"
                  @click="close({ isButton: true })"
                />
              </transition>
              <div class="yt-popup__content-container">
                <div class="yt-popup__slot">
                  <slot />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </mounting-portal>
</template>

<script>
import YtButtonArrow from "@/components/ui-kit/yt-button-arrow/index.vue";
import YtButtonClose from "@/components/ui-kit/yt-button-close/index.vue";

export default {
  components: {
    YtButtonArrow,
    YtButtonClose,
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    className: {
      type: [String, Object, Array],
      default: "",
    },
    size: {
      type: String,
      default: "default",
      validator(size) {
        return ["default", "sm", "md"].includes(size);
      },
    },
  },
  mounted() {
    fakeWindow.addEventListener("keydown", this.onEsc);
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("keydown", this.onEsc);
  },
  methods: {
    close({ isButton = false }) {
      this.$emit("close", isButton);
      this.$emit("change", false);
    },
    onEsc({ keyCode }) {
      if (keyCode === 27 && this.modelValue) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$b: ".yt-popup";

#{$b} {
  outline: none;
  z-index: 99999;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::before {
    @include mixin.decor();
    content: "";
    position: absolute;
    top: -200px;
    right: 0;
    bottom: -200px;
    left: 0;
    background-color: rgba(var.$black, 0.4);
  }

  &__container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::after {
      display: inline-block;
      content: "";
      vertical-align: middle;
      height: 100%;
      width: 0;
    }
  }

  &__content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    z-index: 1045;
    width: 100%;

    @include mixin.size-lg-min {
      padding: (var.$inner-pd * 3) var.$inner-pd;
    }

    &-wrapper {
      position: relative;
      cursor: auto;
      text-align: left;
      white-space: normal;
      margin: 0 auto;
      transition: var.$transition-default;

      @include mixin.size-lg-min {
        max-width: 832px;

        #{$b}._size-sm & {
          max-width: 440px;
        }

        #{$b}._size-md & {
          max-width: 534px;
        }
      }
    }

    &-container {
      display: flex;
      position: relative;
      min-height: 100vh;
      box-sizing: border-box;
      padding: 24px;
      background: var.$white;

      @include mixin.size-lg-min {
        min-height: auto;
        box-shadow: 1px 3px 60px rgba(var.$secondary, 0.35);
        border-radius: 24px;
      }
    }
  }

  &__slot {
    width: 100%;
  }

  &__back,
  &__close {
    position: absolute;
    z-index: 2;
  }

  &__back {
    top: 16px;
    left: 24px;
  }

  &__close {
    top: 24px;
    right: 24px;
  }
}
</style>
