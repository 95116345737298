const popupFlexibleCancellationRu = {
  popupFlexibleCancellation: {
    title: "Гибкие условия отмены",
    list: [
      "100% возврат при отмене за&nbsp;15&nbsp;дней до&nbsp;старта и&nbsp;в&nbsp;течение 24&nbsp;часов после оплаты;",
      "50% возврат от&nbsp;14&nbsp;до&nbsp;2&nbsp;дней до&nbsp;начала;",
      "Без возврата&nbsp;&mdash; за&nbsp;3&nbsp;дня до&nbsp;начала тура",
    ],
    accept: "Понятно, закрыть",
  },
};

export { popupFlexibleCancellationRu };
