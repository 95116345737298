const howItWorksRu = {
  howItWorks: {
    what: {
      question: "Что такое авторские туры?",
      title: "Это новый формат необычных путешествий в&nbsp;уникальные места",
      items: [
        {
          image: "/static/i/how-it-works/what-item-1.jpg",
          title: "Нестандартные маршруты",
          text: "И&nbsp;места для &laquo;своих&raquo; от&nbsp;проверенных авторов путешествий",
        },
        {
          image: "/static/i/how-it-works/what-item-2.jpg",
          title: "Путешествие небольшой компанией",
          text: "Близких по&nbsp;духу единомышленников",
        },
        {
          image: "/static/i/how-it-works/what-item-3.jpg",
          title: "Никаких забот",
          text: "Проживание, питание и&nbsp;активности за&nbsp;тебя уже продумал автор путешествия",
        },
      ],
    },
    benefits: [
      {
        icon: "thumbs-up",
        title: "Гарантия лучшей цены",
        text: "Мы&nbsp;гарантируем самую низкую стоимость",
      },
      {
        icon: "checkmark-circle-outline",
        title: "Проверяем организаторов",
        text: "Туры только с&nbsp;надежными профессионалами",
      },
      {
        icon: "phone-talk",
        title: "Поддержка 24/7",
        text: "Отвечаем на&nbsp;ваши вопросы и&nbsp;помогаем найти лучшее путешествие",
      },
      {
        icon: "shield-outline",
        title: "Платежи под защитой",
        text: "Безопасная оплата и&nbsp;полная гарантия на&nbsp;все услуги",
      },
    ],
    steps: {
      title: "Как забронировать?",
      list: [
        {
          title: "<a href='/tours'>Выберите</a> тур",
          text: "У&nbsp;нас представлено более 30&nbsp;000 туров от&nbsp;5&nbsp;600 проверенных тревел-экспертов",
        },
        {
          title: "Уточните все детали",
          text: "Расспросите обо всем важном автора тура напрямую",
        },
        {
          title: "Забронируйте место",
          text: "Для бронирования потребуется только предоплата&nbsp;&mdash; от&nbsp;15% от&nbsp;стоимости тура",
        },
      ],
    },
    close: "Закрыть",
  },
};

export { howItWorksRu };
