export default {
  popupPay: {
    title: 'Пополнение баланса',
    infoCardRu: 'Выпущенная банком РФ',
    infoCardEn: 'Выпущенная зарубежным банком',
    input: {
      placeholder: 'Сумма пополнения, ₽',
      help: 'Минимальная сумма пополнения 3000 ₽',
    }
  }
}
