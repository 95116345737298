<template>
  <transition name="fade">
    <div
      v-show="isActive"
      ref="popup"
      class="overlay-container"
      role="button"
      tabindex="0"
      @click.self="closePopup"
      @keydown.esc="closePopup"
    >
      <transition v-if="getLastActivePopup" name="fade" mode="out-in">
        <component
          :is="getLastActivePopup.name"
          :data="getLastActivePopup.data"
        />
      </transition>
    </div>
  </transition>
</template>

<script>
import PopupPhoto from "@/components/molecules/popup-photo/index.vue";
import PopupCancelTour from "@/components/molecules/popup-cancel-tour/index.vue";
import SurveyModal from "@/components/organisms/survey-modal/index.vue";
import dashboardSettings from "@/components/organisms/dashboard-settings/index.vue";
import OnboardingModal from "@/components/organisms/onboarding-modal/index.vue";
import tourEditModal from "@/components/organisms/tour-edit-modal/index.vue";

import languageModal from "@/components/organisms/language-modal/index.vue";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    PopupCancelTour,
    PopupPhoto,
    SurveyModal,
    languageModal,
    dashboardSettings,
    OnboardingModal,
    tourEditModal,
  },
  props: {
    canBlur: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      isActive: (state) => state.popup.isActive,
    }),
    ...mapGetters({
      getLastActivePopup: "popup/getLastActivePopup",
    }),
  },
  methods: {
    ...mapMutations({
      hidePopup: "popup/hidePopup",
    }),
    closePopup() {
      if (this.canBlur) {
        this.hidePopup();
      }
    },
  },
  watch: {
    isActive(value) {
      if (value) {
        // Для того чтобы фокус переходил
        // на popup для закрытия через esc
        this.$nextTick(() => {
          this.$refs.popup.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
  display: flex;
  height: 100vh;
  width: 100vw;
}
@media only screen and (max-width: 480px) {
  .overlay-container {
    background-color: #fff;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
