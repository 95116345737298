<template>
  <div class="preloader">
    <div class="preloader__circle">
      <div class="preloader__circle-left"></div>
      <div class="preloader__circle-right"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.preloader__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  animation-name: rotateCircle;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes rotateCircle {
  0% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg);
  }

  25% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate3d(1, 1, 0, 180deg);
  }

  75% {
    transform: translate(-50%, -50%) rotate3d(1, 1, 0, 180deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate3d(1, 1, 0, 0deg);
  }
}

.preloader__circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  background-color: var.$neutral-100;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  animation-name: backgroundCircle;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes backgroundCircle {
  0% {
    background-color: var.$neutral-100;
  }

  25% {
    background-color: var.$neutral-100;
  }

  50% {
    background-color: var.$violet-1;
  }

  75% {
    background-color: var.$violet-1;
  }

  100% {
    background-color: var.$neutral-100;
  }
}

.preloader__circle-left {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  background-color: var.$violet-1;
  width: 50%;
  animation-name: leftProgressBar;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes leftProgressBar {
  0% {
    top: 100%;
    bottom: 0;
    background-color: var.$violet-1;
  }

  25% {
    top: 0;
    bottom: 0;
    background-color: var.$violet-1;
  }

  50% {
    top: 0;
    bottom: 0;
    background-color: var.$neutral-100;
  }

  75% {
    top: 0;
    bottom: 100%;
    background-color: var.$neutral-100;
  }

  100% {
    top: 0;
    bottom: 100%;
    background-color: var.$neutral-100;
  }
}

.preloader__circle-right {
  position: absolute;
  top: 0;
  bottom: 100%;
  right: 0;
  background: var.$violet-1;
  width: 50%;
  animation-name: rightProgressBar;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes rightProgressBar {
  0% {
    top: 0;
    bottom: 100%;
    background-color: var.$violet-1;
  }

  25% {
    top: 0;
    bottom: 0;
    background-color: var.$violet-1;
  }

  50% {
    top: 0;
    bottom: 0;
    background-color: var.$neutral-100;
  }

  75% {
    top: 100%;
    bottom: 0;
    background-color: var.$neutral-100;
  }

  100% {
    top: 100%;
    bottom: 0;
    background-color: var.$neutral-100;
  }
}
</style>
