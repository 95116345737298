export default [{
  text: 'settings.profile',
  link: '/lk/profile/'
}, {
  text: 'settings.team',
  link: '/lk/team/'
}, {
  text: 'settings.notifications',
  link: '/lk/notifications/'
}];
