export default {
  auth: {
    default: {
      title: "Вы не авторизованы",
      text: "Чтобы продолжить, войдите в аккаунт",
      login: "Войти",
    },
    signin: {
      title: "Вход в аккаунт",
      continueWithEmail: "Продолжить через email",
      continueWithPhone: "Продолжить через телефон",
      loginWithPhone: "Войти по телефону",
      loginWithEmail: "Войти по email",
      wrongEmail: "Введен неверный e-mail",
      wrongPhone: "Введен неверный номер телефона",
      or: "Или",
      yandex: {
        button: "Войти c Яндекс ID",
        popup: {
          title: "Вход через Яндекс ID",
          text: "Спасибо, что обратили внимание на авторизацию через Яндекс ID. В настоящее время мы тестируем функционал. Пока мы тестируем воспользуйтесь, пожалуйста, другим методом авторизации. Спасибо и хорошего дня!",
        },
      },
    },
    confirm: {
      title: "Подтверждение",
      change: "Изменить номер",
      code: "Код",
      codeSentTo: "Код отправлен на",
      resendAfter: "Отправить повторно через {seconds} с.",
      resend: "Отправить повторно",
    },
    existing: {
      title: "Здравствуйте, {name}!",
      login: "Войти",
      continueWith: "Войти с помощью {social}",
      different: "Войти в другой аккаунт",
    },
    initial: {
      title: "Вход или регистрация",
      or: "Уже есть аккаунт?",
      login: "Войти",
      signup: "Зарегистрироваться",
    },
    password: {
      title: "Восстановление пароля",
      forgotPassword: "Забыли пароль?",
      password: "Пароль",
      resetPasswordSent:
        "Отправили письмо с инструкцией для восстановления пароля на <b>{email}</b>. Перейдите по ссылке в письме, чтобы восстановить пароль",
      resetPassword:
        "Введите e-mail и мы отправим вам<br><b>ссылку</b> для восстановления пароля",
      enterPassword: "Введите пароль",
      changeEmail: "Изменить e-mail",
      resentEmailAfter: "Отправить повторно через {sec} сек.",
      resentEmail: "Отправить повторно",
    },
    signup: {
      title: "Регистрация",
      traveler: "Я путешественник",
      expert: "Я организатор",
      fill: "Заполните поле",
      name: "Имя",
      lastName: "Фамилия",
      signup: "Зарегистрироваться",
      individual: "Физическое лицо",
      entity: "Юридическое лицо",
      touroperator: "Туроператор",
      team: "Есть команда турлидеров и гидов, сам занимаюсь организационными вопросами в турах",
      tourLeader: "Работаю как турлидер или гид, организую все сам",
      vk:{
        button: "Продолжить с VK",
      },
      terms:
        "Нажимая «Зарегистрироваться», я соглашаюсь с условиями <a target='_blank' href='/help/public-offer'>публичной оферты</a> и выражаете свое согласие на <a target='_blank' href='/help/privacy-agree'>обработку персональных данных</a>.",
    },
    ok: "Хорошо",
    sendLink: "Восстановить пароль",
  },
};
