export default {
  namespaced: true,
  state: {
    countryCode: ""
  },

  actions: {
    async setCountryCode({commit}) {
      try {
        const countryCode = (await (await fetch("https://ip2c.org/s")).text()).substr(2, 2);
        commit('setCountryCode', countryCode);
      } catch (error) {
        console.error(error);
      }
    },
  },

  getters: {
    getCountryCode(state) {
      return state.countryCode;
    },
  },

  mutations: {
    setCountryCode(state, countryCode) {
      if (window.geoInfo && window.geoInfo.isDev === true && window.geoInfo.countryCode) {
        countryCode = window.geoInfo.countryCode;
      }
      state.countryCode = countryCode;
    },
  },
};
