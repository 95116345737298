import { ytVueGlobalStore } from "@/services/yt-vue-global-store";
import { i18n } from "@/plugins/i18n";

const changeMode = ytVueGlobalStore.instantGet('changeMode');
const user = ytVueGlobalStore.instantGet("user");
const updateMainMenu = (user) => {
  let mainMenu = [];
  let noExpertMode = user.userRole !== 'Expert' || changeMode.isExpertMode === false ;

  if (noExpertMode || i18n.locale === "en") {

    mainMenu.push({
      id: 0,
      title: i18n.t('mainMenu.tours'),
      link: "/tours/",
      type: "tab",
      icon: "icon-plane",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: i18n.locale === "en" ? [] : [
        {
          id: 0,
          title: "Все",
          link: "/tours/",
          trackEvent: "",
          slug: "menu_main_sub_all_tours",
        },
        {
          id: 1,
          title: "По типам",
          link: "/tours/type",
          trackEvent: "",
          slug: "menu_main_sub_tours_types",
        },
        {
          id: 3,
          title: "Горящие туры",
          link: "/tours/specials/goryashchie-tury",
          trackEvent: "",
          rel: "nofollow",
          slug: "menu_main_sub_tours_hot",
        },
        {
          id: 5,
          title: "Туры выходного дня",
          link: "/tours/specials/weekend",
          trackEvent: "",
          slug: "menu_main_sub_tours_weekend",
        },
        {
          id: 7,
          title: "Однодневные туры",
          link: "/tours/days-1",
          trackEvent: "",
          slug: "menu_main_sub_tours_oneday",
        },
        {
          id: 8,
          title: "Отзывы путешественников",
          link: "/reviews/",
          trackEvent: "",
          slug: "menu_main_sub_reviews",
        },
        {
          id: 9,
          title: "Журнал о путешествиях",
          link: "/blog/",
          trackEvent: "",
          slug: "menu_main_sub_blog",
        },
        {
          id: 10,
          title: "Подарочный сертификат",
          link: "/gift/",
          trackEvent: "",
          slug: "menu_main_sub_gift",
        },
      ],
      slug: "menu_main_tours",
    });

    if (i18n.locale === "ru" && user.favoritesCount > 0) {
      mainMenu[0].options.push({
        id: 6,
        title: "Избранные",
        link: "/tours/favorites/",
        trackEvent: "",
        slug: "menu_main_sub_tours_favorites",
      });

      mainMenu[0].options.sort(function (a, b) {
        return a.id - b.id;
      })
    }
  }

  if (noExpertMode && i18n.locale !== "en") {
    mainMenu.push({
      id: 1,
      title: i18n.t('mainMenu.company'),
      link: "#",
      type: "tab",
      icon: "icon-document",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [
        {
          id: 0,
          title: "Индивидуальные туры",
          link: "/lp/grouptours",
          trackEvent: "",
          ADD_PARAM: "rel='nofollow'",
          slug: "menu_main_sub_individual_tours",
        },
        {
          id: 1,
          title: "Корпоративные туры",
          link: "/corporatetours/",
          trackEvent: "",
          ADD_PARAM: "rel='nofollow'",
          slug: "menu_main_sub_corporate_tours",
        },
        {
          id: 2,
          title: "Турагентствам",
          link: "/partners-b2b/",
          trackEvent: "",
          ADD_PARAM: "rel='nofollow'",
          slug: "menu_main_sub_partners_b2b",
        },
        {
          id: 3,
          title: "Корпоративные промокоды",
          link: "https://en.youtravel.me/promo",
          trackEvent: "",
          ADD_PARAM: "rel='nofollow'",
          slug: "menu_main_sub_corporate_promo",
        },
        {
          id: 4,
          title: "Подарочные сертификаты для компаний",
          link: "/corpsert/",
          trackEvent: "",
          ADD_PARAM: "rel='nofollow'",
          slug: "menu_main_sub_corp_certs",
        },
        {
          id: 5,
          title: "Партнерская программа",
          link: "/partner",
          trackEvent: "",
          ADD_PARAM: "rel='nofollow'",
          slug: "menu_main_sub_corp_partner",
        },
        {
          id: 6,
          title: "Для рекламных интеграций",
          link: "/promo",
          trackEvent: "",
          ADD_PARAM: "rel='nofollow'",
          slug: "menu_main_sub_corp_promo",
        },
      ],
      slug: "menu_main_company",
    });
  }

  if (!noExpertMode || i18n.locale === 'en') {
    mainMenu.push({
      id: 2,
      title: i18n.t('mainMenu.about'),
      link: "/about/",
      type: "tab",
      icon: "icon-support",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [],
      slug: "menu_main_about_us",
    });
  }

  if (!user.isLoggedIn) {
    let moreLink = "https://en.youtravel.me/travelexpert";
    if (i18n.locale === "en") {
        moreLink = "https://en.tripsider.com/travel-experts"
    }
    mainMenu.push({
      id: 3,
      title: i18n.t('mainMenu.te'),
      link: "/te/",
      type: "tab",
      icon: "icon-document",
      isMobile: true,
      isDesktop: true,
      isExpertSection: true,
      isUserSection: true,
      isDefault: true,
      isCounter: false,
      trackEvent: "",
      options: [
        {
          id: 0,
          title: i18n.t('mainMenu.more'),
          link: moreLink,
          trackEvent: {
            category: "Main menu",
            name: "Click More info menu item",
            props: { lang: i18n.locale },
          },
          slug: "menu_main_sub_more_info",
        },
        {
          id: 1,
          title: i18n.t('mainMenu.signUp'),
          link: "/lk/?register=yes",
          trackEvent: { category: "Main menu", name: "Click Sign up menu item" },
          slug: "menu_main_sub_sign_up",
          TE_REGISTER: "Y",
        },
      ],
      slug: "menu_main_experts",
    });
  }

  mainMenu.push({
    id: 4,
    title: i18n.t('mainMenu.support'),
    link: "/support/",
    type: "tab",
    icon: "icon-support",
    isMobile: true,
    isDesktop: true,
    isExpertSection: true,
    isUserSection: true,
    isDefault: true,
    isCounter: false,
    trackEvent: "",
    options: i18n.locale === "en" ? [] : [
      {
        id: 0,
        title: "О нас",
        link: "/about/",
        trackEvent: "",
        slug: "menu_main_sub_about_us",
      },
      {
        id: 1,
        title: "Поддержка",
        link: "/support/",
        trackEvent: "",
        slug: "menu_main_sub_support",
      },
      {
        id: 2,
        title: "Тревел-экспертам",
        link: "https://en.youtravel.me/travelexpert",
        trackEvent: "",
        slug: "menu_main_sub_experts",
      },
    ],
    slug: "menu_main_support",
  });
  return mainMenu;
}

export default {
  namespaced: true,
  state: {
    mainMenu : updateMainMenu(user) || {},
  },
  mutations: {
    setMainMenu(state, value) {
      state.mainMenu = value;
    }
  },
  actions: {
    updateMainMenu({commit},{user}) {
      const mainMenu = updateMainMenu(user);
      commit("setMainMenu", mainMenu);
    }
  }
};
