import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

export async function getSurvey(type) {
  return axiosYtApiV2.get(`survey/${type}/get`);
  // const { data } = await mockAPI/*axiosYtApiV2.get*/(`/survey/${type}/get`);
  // return data;
}

export async function saveSurvey({ type, stepCode, fields }) {
  return axiosYtApiV2.post(`survey/${type}/save`, {stepCode, fields});

  // await mockAPI/*axiosYtApiV2.post*/(`/survey/${type}/save`, {
  //   stepCode,
  //   fields,
  // });
}

// function mockAPI(path, _data, _fakeDealy = 0) {
//   const type = path.split('/')[2];
//   if (path.includes('save')) {
//     saveFieldsValue(_data, type);
//
//     if (isLastStep(_data, type)) {
//       addModals(type);
//     }
//
//     return new Promise((resolve) => {
//       setTimeout(() => {
//         resolve({
//           data: {
//             status: "success",
//           },
//         });
//       }, _fakeDealy);
//     });
//   }
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         data: {
//           success: true,
//           data: JSON.parse(JSON.stringify(dataByType[type])),
//         }
//       });
//     }, _fakeDealy);
//   });
// }
//
// function isLastStep({ stepCode }, type) {
//   return dataByType[type].steps.slice(-1).pop().code === stepCode;
// }
//
// function addModals(type) {
//   dataByType[type].modals = [{
//     "code": "string",
//     "title": "Thank you for your time!",
//     "description": "You are one step closer to attract more travellers via our platform",
//     "button": "Close"
//   }];
// }
//
// function saveFieldsValue({ fields, stepCode }, type) {
//   const step = dataByType[type].steps.find(step => step.code === stepCode);
//   if (!step) {
//     console.error('step not found');
//     return;
//   }
//
//   fields.forEach(field => {
//     const stepField = step.fields.find(stepField => stepField.code === field.code);
//
//     if (!field.value) {
//       console.log('field.value is empty');
//       return;
//     }
//
//     if (!stepField) {
//       console.error('step field not found');
//       return;
//     }
//
//     if(stepField.options) {
//       stepField.options.forEach(option => {
//         option.isChecked = field.value.includes(option.code);
//       });
//       return;
//     }
//     stepField.value = field.value;
//   });
// }

// verificaton
// const data2 = {
//   "modals": [],
//   "steps": [
//     {
//       "title": "Прикрепите ссылки на ресурсы, где вы размещаете свои программы",
//       "fields": [
//         {
//           "type": "input",
//           "placeholder": "Ваш сайт",
//           "value": "",
//           "code": "link_site"
//         },
//         {
//           "type": "input",
//           "placeholder": "VK",
//           "value": "",
//           "code": "link_vk"
//         },
//         {
//           "type": "input",
//           "placeholder": "Instagram",
//           "value": "",
//           "code": "link_instagram"
//         },
//         {
//           "type": "input",
//           "placeholder": "Facebook",
//           "value": "",
//           "code": "link_facebook"
//         },
//         {
//           "type": "list",
//           'title': 'Другие сервисы для публикации авторских туров',
//           "action_title": "+ Добавить еще ссылку",
//           "options" : [
//             {
//               "id": 0,
//               "type": "input",
//               "placeholder": "Вставьте ссылку",
//               "value": "",
//             },
//           ],
//           "code" : "link_other"
//         },
//       ],
//       "code": "links"
//     },
//     {
//       "title": "Предоставьте не менее 3-х отзывов на ваши туры",
//       'description': 'Заполните хотя бы одно поле',
//       "fields": [
//         {
//           "type": "input",
//           "placeholder": "Ссылка на отзыв в Instagram",
//           "value": "",
//           "code": "inst"
//         },
//         {
//           "type": "input",
//           "placeholder": "Ссылка на отзыв в VK",
//           "value": "",
//           "code": "vk"
//         },
//         {
//           "type": "input",
//           "placeholder": "Ссылка на отзыв в Facebook ",
//           "value": "",
//           "code": "fb"
//         },
//         {
//           "type" : 'files',
//           "title" : "<span class='onboardingSurvey-text-green'>Загрузите</span> или перетащите скриншоты",
//           "options": [
//             {
//               "id": 577878,
//               "path": "/patch/to/img1"
//             }
//           ],
//           "code": "image_review"
//         }
//       ],
//       "code": "reviews"
//     },
//     {
//       "title": "Опишите свой самый сложный случай из практики",
//       "description": 'Как вы его решали? К каким результатам в итоге пришли?',
//       "fields": [
//         {
//           "type": "textarea",
//           "placeholder": "Введите ответ (макс. 500 символов)",
//           "value": "",
//           "code": 'experience'
//         },
//       ],
//       "code": "experience"
//     },
//     {
//       "title": "Представьте ситуацию...",
//       "description": "Через 4 часа к вам прилетает группа туристов, вы готовитесь к организации трансфера в запланированный отель. К вам поступает звонок с ресепшена, что в отеле случилась авария, поэтому в течение недели будет отсутствовать горячая вода в номерах. Ваши действия?",
//       "fields": [
//         {
//           "type": "textarea",
//           "placeholder": "Введите ответ (макс. 500 символов)",
//           "value": "",
//           "code": 'situation_1'
//         },
//       ],
//       "code": "situation_1"
//     },
//     {
//       "title": "Представьте ситуацию...",
//       "description": "Вы запланировали в туре посещение важной достопримечательности. Поездка до места экскурсии занимает 4 часа (2 часа туда и 2 часа обратно). По прибытию администрация вам сообщает, что место закрыто в связи с аварией, поэтому нет возможности провести экскурсию. Ваши действия? Если вы работаете с гидами, то представьте, что гид общается с вами по телефону. Какие должны быть действия у гида в этой ситуации, и ваши действия как организатора тура?",
//       "fields": [
//         {
//           "type": "textarea",
//           "placeholder": "Введите ответ (макс. 500 символов)",
//           "value": "",
//           "code": 'situation_2'
//         },
//       ],
//       "code": "situation_2",
//     },
//     {
//       "title": "Как вы решаете конфликтную ситуацию с клиентом? Как реагируете на негативные отзывы?",
//       "description": "Если у вас не было опыта получения негативных отзывов, то как планируете работать в случае возникновения конфликтной ситуации с клиентом?",
//       "fields": [
//         {
//           "type": "textarea",
//           "placeholder": "Введите ответ (макс. 500 символов)",
//           "value": "",
//           "code": 'situation_3'
//         },
//       ],
//       "code": "situation_3"
//     },
//   ]
// }
//
// // intro
// const data = {
//   "modals": [],
//   "steps": [
//     {
//       "title": "Форма собственности",
//       "fields": [
//         {
//           "type": "radioLink",
//           "options": [
//             {
//               "title": "Физическое лицо",
//               "code": "individual",
//               "isChecked": false
//             },
//             {
//               "title": "Юридическое лицо",
//               "code": "legal",
//               "isChecked": false
//             }
//           ],
//           "code": "ownership"
//         }
//       ],
//       "code": "ownership"
//     },
//     {
//       "title": "Расскажите о вашей команде",
//       "fields": [
//         {
//           "type": "radioLink",
//           "options": [
//             {
//               "title": "У меня есть команда турлидеров и гидов",
//               "description": "Сам занимаюсь организационными вопросами",
//               "code": "team",
//               "isChecked": false
//             },
//             {
//               "title": "Организую все сам",
//               "description": "Работаю как турлидер или гид",
//               "code": "individual",
//               "isChecked": false
//             }
//           ],
//           "code": "team_type"
//         }
//       ],
//       "code": "team_type"
//     },
//     {
//       "title": "Как давно вы проводите туры на коммерческой основе?",
//       "fields": [
//         {
//           "type": "radioLink",
//           "options": [
//             {
//               "title": "Это будет мой первый опыт",
//               "code": "first_experience",
//               "isChecked": false
//             },
//             {
//               "title": "1-2 года",
//               "code": "1-2_years",
//               "isChecked": false
//             },
//             {
//               "title": "3-5 лет",
//               "code": "3-5_years",
//               "isChecked": false
//             },
//             {
//               "title": "6-10 лет",
//               "code": "6-10_years",
//               "isChecked": false
//             },
//             {
//               "title": "Больше 10 лет",
//               "code": "more_10_years",
//               "isChecked": false
//             }
//           ],
//           "code": "experience"
//         }
//       ],
//       "code": "experience"
//     },
//     {
//       "title": "Прикрепите ссылки на ресурсы, где вы размещаете свои программы",
//       "fields": [
//         {
//           "type": "input",
//           "title": "Ссылка на вашу страницу в Instagram",
//           "placeholder": "Instagram",
//           "value": "",
//           "code": "ig"
//         },
//         {
//           "type": "input",
//           "title": "Ссылка на вашу страницу в vk",
//           "placeholder": "VK",
//           "value": "",
//           "code": "vk"
//         }
//       ],
//       "code": "links"
//     },
//     {
//       "title": "На каких типах авторских туров вы специализируетесь как организатор?",
//       "fields": [
//         {
//           "type": "checkboxSuggest",
//           "suggestInput": {
//             "title": "label для инпута с поиском, если нужен",
//             "placeholder": "placeholder для инпута с поиском, если нужен",
//           },
//           "options": [
//             {
//               "title": "У меня есть команда турлидеров и гидов",
//               "description": "Сам занимаюсь организационными вопросами",
//               "code": "team",
//               "isChecked": false
//             },
//             {
//               "title": "Организую все сам",
//               "description": "Работаю как турлидер или гид",
//               "code": "individual",
//               "isChecked": false
//             }
//           ],
//           "code": "some_id"
//         }
//       ],
//       "code": "tours_type",
//     },
//   ]
// }
//
// const dataByType = {
//   'intro': data,
//   'verification': data2,
// }
