import { axiosYtV2 } from "@/plugins/axios";
import { i18n } from "@/plugins/i18n";

async function getToursSearch(params, signal = undefined) {
  const { data } = await axiosYtV2.get(`/serp/tours?${params}`, {
    signal,
  });

  return data?.data || [];
}

async function getToursFacets(params) {
  const { data } = await axiosYtV2.get(`/serp/tours/facets?${params}`);

  return data?.data || [];
}

async function getGroupsSearch(params) {
  const { data } = await axiosYtV2.get(
    `/serp/travellers/search-groups?${params}`
  );

  return data?.data || [];
}

async function getLocationById(type, locationId) {
  const lang = i18n.locale;
  const { data } = await axiosYtV2.get(
    `/serp/locations/${type}/${locationId}`,
    {
      params: {
        lang,
      },
    }
  );

  return data.data;
}

async function getListingTours(params) {
  const { data } = await axiosYtV2.get(`/serp/tours/promo?${params}`);

  return data ? data?.data : [];
}

async function getBannersSerp(params) {
  const { data } = await axiosYtV2.get(`/banners/serp?${params}`);

  return data?.data || [];
}

export function useSerp() {
  return {
    getToursSearch,
    getToursFacets,
    getGroupsSearch,
    getLocationById,
    getListingTours,
    getBannersSerp,
  };
}
