const bookingPopupEn = {
  bookingPopup: {
    day: "day",
    fieldset: {
      chooseDates: "Choose dates",
      participants: "Travelers",
      customDates: "Custom dates",
      customDatesPlaceholder: "from — to",
      places: ["place", "places", "places"],
      noPlaces: "no places",
    },
    chooseDates: "Choose dates",
    choose: "Choose",
    error: "Error!",
    book: "Request to book",
    fullyPaid: "Fully paid",
    payTour: "Go to payment",
    postPayTour: "Pay rest",
    bookAgain: "Book again",
    awaitConfirm: "Await for confirm",
    bookShort: "Request",
    from: "from",
    left: "Left {free}&nbsp;out of&nbsp;{total}&nbsp;places",
    badges: {
      guarantee: {
        title: "Guarantee of&nbsp;holding",
        tooltip:
          "The tour will be&nbsp;held even when the group is&nbsp;not full",
      },
      instant: {
        title: "Instant booking",
        tooltip: "Get confirmation right after booking",
      },
    },
    note: {
      prepay: "You don&rsquo;t need to&nbsp;pay for the reservation.",
      prepayFree: "You don&rsquo;t need to&nbsp;pay for the reservation.",
      cancel: "Full refund&nbsp;within&nbsp;24&nbsp;hours.",
      flexible: "Flexible cancellation policy",
      prepayment: "Prepayment",
    },
    individual: {
      trigger: "Or request individual program",
      tooltip: "We can arrange it exclusively for you and your friends.",
      tooltipName: "Individual program",
    },
    pers: "pers.",
    popupTitle: "Dates and travelers",
    outOfPlaces: {
      text: {
        over: "Unfortunately, seats are over.",
        leave: "Leave",
        pick: " your contact details and we&nbsp;will find a&nbsp;tour for you",
      },
      submit: "Pick me a tour",
    },
    duration: "Duration ",
    installment: {
      tooltip: "Intallment from {symbol} {from} / month",
    },

    popup: {
      sandContact : {
        title: "Book a tour",
        name: "Name",
        lastName: "Surname",
        error: {
          name: 'Enter your name',
          lastName: 'Enter your Surname',
          email: 'Enter your email',
          phone: 'Enter your phone number'
        },
        btn: "Book a tour"
      },
      sendPhone: {
        title: "Enter your phone number",
        desc: "You will be able to request a call back from a travel expert if you want to voice your questions",
        btn: "Continue"
      },
      helpMe : {
        title: "Tour selection request",
        desc: "Leave your contacts and we will get in touch with you to help you choose the right tour.",
        note: "Working hours of our managers from 09:00 - 21:00 Moscow time",
        btn: "Leave contacts",
        result: {
          title: "Thank you for your request!",
          text: "We will contact you within <b>10 minutes</b> to help you choose the right tour."
        },
        wrongPhone: "Invalid phone number entered",
      },
      redirect: {
        textFirst: "You will now be redirected to the payment page...",
        textSecond: "If this does not happen, click «Go to payment»",
        btn: "Go to payment"
      },
      termsGeneral: {
        text1: "By submitting the form, you agree to the terms conditions ",
        textOffer: "public offer",
        text2: "and agree to",
        textPrivacy: "processing of personal data."
      },
      termsBooking: {
        text1: "By clicking \"Order a tour\", I accept the terms of the ",
        textOffer: "public offer",
        text2: "and agree to",
        textPrivacy: "processing of personal data."
      },

    },
    bonuses: {
      text: "bonuses | bonuses | bonuses | bonuses",
      tooltip: "<a href='https://tripsider.com/'>Tripsider.com</a>",
    },

  },
};

export { bookingPopupEn };
