export default {
  createNewTour: "Create new tour",
  support: "Support",
  reviewing: "Reviewing",
  startTutorial: "Show me how it works",
  copyProfileLink: "Public Profile Page",
  copyProfileLinkTip: "Copy to clipboard!",
  copyProfileLinkTipCopied: "Link copied!",
  onboarding: {
    title: "Welcome to YouTravel.me! You are only 4 steps away from the first sale",
    titleV2: "Welcome to YouTravel.me! You are only 5 steps away from the first sale",
    titleVerified: "You are a verified travel expert now!",
    subtitleVerified: "Go to Dashboard to start publishing your tours and working with the platform",
    updateProfile: "Update your profile",
    updateProfile_description: "Provide personal details and how we can reach you",
    payments: "Payments & payouts",
    payments_description: "Provide payments details to recieve your payouts",
    proveExperience: "Prove your experience",
    proveExperience_description: "Fill in the form to become trusted Travel Expert",
    collectReviews: "Collect reviews",
    collectReviews_description: "Add reviews from your customers on published tours",
    toLk: "Go to Dashboard",
    survey: {
      step: "Step",
      stepOf: 'of',
      next: "Done",
      back: "Back",
      select: "Select",
      errors: {
        required: 'Field is required'
      }
    }
  },
  sidebar: {
    myProfile: "My Profile",
    myTours: "My Tours",
    orders: "Orders",
    requests: "Booking Requests",
    payments: "Payments",
    travelers: "Travelers",
    arbitration: "Arbitration",
    myReviews: "Reviews",
    billings: "Payments & Payouts",
    bonusAccount: "Bonus account",
    billingDetails: "Bank details and accounts",
  },
  tutorial: {
    populateSections: {
      title: "Populate 4 sections to start selling your tours",
      intro: "It will take up to 1 hour, but give you a whole new sales channel for your tours!"
    },
    populateSectionsV2: {
      title: "Populate 5 sections to start selling your tours",
      intro: "It will take up to 1 hour, but give a whole new sales channel for your tours!"
    },
    createTour: {
      title: "Send your tour to moderation",
      intro: "To complete “Create a tour” step",
    },
    beforeVerification: {
      title: "Before sending your application for verification...",
      intro: "Make sure you have completed other steps",
    },
    support: {
      title: "Do you have any questions?",
      intro: "Go to the support",
    },

    dashboardTitle: {
      title: 'Meet the new design of the travel creator personal account',
      intro: ''
    },
    dashboardProfile: {
      title: 'New menu',
      intro: 'This icon contains the menu for you personal account. Click the icon or hover your cursor to unfold it.',
    },
    dashboardNotifications: {
      title: 'The notifications center',
      intro: 'Notifications from YouTravel.me are to appear right here',
    },
    dashboardChats: {
      title: 'Chats with travellers',
      intro: 'The messages from travellers will appear here',
    },
    dashboardStatistics: {
      title: 'New statistics',
      intro: 'There’s a last 30 days data so far, but we work on new filters!',
    },
    dashboardStatisticsViews: {
      title: 'Tour views statistics',
      intro: 'It will take a while for statistics to be gathered, stay tuned!',
    },
  },

  blockedStatusHintMessage: "Please complete previous steps",

  statistics: {
    title: "Statistics",
    lastUpdate: "Last update",
    percentHint: "Compared to the previous month",
    tooltip: 'Statistics for the last 30 days',
  },
  advices: {
    title: "How to improve the work",
  },
  verificationIntro: {
    btn: "Become verified expert",
    text: "Welcome to YouTravel.me! <br> Become verified expert to publish tours",
  },
}
