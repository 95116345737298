const profileMenuRu = {
  profileMenu: {
    dashboard: 'Личный кабинет',
    payments: 'Оплаты',
    myTours: 'Мои туры',
    chat: 'Чат',
    aboutMe: 'Обо мне',
    account: 'Личный кабинет',
    requisites: 'Счета и Платежи',
    promotion: 'Заказать продвижение',
    myProfile: 'Мой профиль',
    verification: 'Запрос на проверку',
    orders: 'Заказы',
    reviews: 'Мои отзывы',
    myTeam: 'Моя команда',
    myTravels: 'Мои путешествия',
    myToursMenuBooking: 'Мои бронирования',
    arbitration: 'Урегулирование',
    notifications: 'Центр уведомлений',
    integrations: 'Интеграции',
    requests: 'Заявки на бронирования',
    tourists: 'Путешественники',
    menuBonusAccount: 'Бонусный счет',
    menuBillingDetails: 'Реквизиты и счета',
    menuDashboard: 'Личный кабинет',
    menuProfile: 'Персональная информация',
    menuNotifications: 'Настройка оповещений',
    menuTravellerProfile: 'Анкета путешественника',
    menuBonusTransactionHistory: 'История транзакций',
    personalAndTeam: 'Данные о себе и команде',
    dashboardMainPage: 'Главная страница',
    regimeChangeTravel : 'В режим путешественника',
    regimeChangeExpert: "В режим организатора"
  }
};

export { profileMenuRu };
