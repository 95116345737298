<template>
  <div class="onboarding_modal">
    <div class="onboadring_modal-icon"
      v-if="data.type == 'verifiedCheckDone'">
    </div>
    <div class="onboarding_modal-header">
      <div class="onboarding_modal-title" v-if="data.title">
        {{ data.title }}
      </div>
      <button
        class="onboarding_modal-action onboarding_modal-action--close"
        @click="close">
      </button>
    </div>
    <div class="onboarding_modal-description">
      {{ data.description }}
    </div>
    <button class="onboarding_modal-button"
      v-if="data.type == 'verifiedCheckDone'"
      @click="close">
      {{ data.btn }}
    </button>
    <button class="onboarding_modal-button"
      v-else
      @click="handleSupportClick">
      {{ data.btn }}
    </button>
    <div class="onboarding_modal-hint" v-if="data.hint" v-html="data.hint">
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { hideModal } from "@/services/scopes/axios-dashboard.js";
import { ytEvents } from "@/plugins/analytics";

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    ...mapMutations({
      hidePopup: "popup/hidePopup",
    }),
    async close() {
      this.hidePopup();
      await hideModal(this.data.type);

      if (this.data.type == 'verifiedCheckDone') {
        this.trackEvent('(LK Onboarding MVP)Congrats verified TE popup', 'Click close popup button');
      }
    },
    async handleSupportClick() {
      await this.close();
      this.trackEvent("(LK Onboarding MVP)Support button", "Click support button");
      fakeWindow.location.href = '/lk/im/support';
    },
    trackEvent(category, name, label, params) {
      ytEvents.track(category, name, label, params);
    },
  },
};
</script>

<style>
.onboarding_modal-body {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
.onboarding_modal {
  background: #fff;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  border-radius: 16px;
  position: absolute;
  --modal_padding: 27px;
  padding: var(--modal_padding);
  max-width: 440px;
  z-index: 999; /* TODO FIX */
  --action2: 28px;

  /* center XY */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.onboarding_modal-header {
  margin-bottom: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.onboarding_modal-title {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  line-height: 19px;
  width: 100%;
  padding: 0 24px;
}

.onboarding_modal-action {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  z-index: 1;
  background-color: transparent;
}
.onboarding_modal-action--close {
  position: absolute;
  margin-left: auto;
  right: var(--modal_padding);
  top: var(--modal_padding);
  background-image: url(/local/templates/youtravel/assets/i/close.svg);
}

.onboarding_modal-description,
.onboarding_modal-hint {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #121212;
}

.onboarding_modal-button {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 14.5px 0;
  letter-spacing: -0.02em;
  margin-top: 24px;
  background: #EEEEF2;
  border-radius: 50px;
  width: 100%;
  border: none;
  cursor: pointer;
  color: #121212;
  min-width: 100%;
  display: block;
  text-transform: none;
}

.onboarding_modal-hint {
  color: #828296;
  margin-top: 28px;
}

.onboadring_modal-icon {
  width: 64px;
  height: 64px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-image: url(/static/i/green_success.svg);
  background-position: center;
  background-size: cover;
}

.onboarding_modal-hint > a {
  color: #828296;
  text-decoration: underline;
}
</style>
