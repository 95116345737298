const notificationsCenter = {
  notificationsCenter: {
    popupHeader: "Notifications",
    popupSeeAll: "See all",
    lkHeader: "Notification Center",
    marAllRead: "Accept all",
    noNotificationsTitle: "No notifications",
    noNotificationsText: "Here you will find out about new bookings, payments and other news about your tours, as well as all steps on YouTravel",
    showMore: "Show more"
  },
};

export { notificationsCenter };
