export default [
  {
    code: 'city',
    answer: '',
  },
  {
    code: "journeyGoal",
    options: [
      "unexpectedDirectinos",
      "activeRest",
      "samePeople",
      "extendNetwork",
      "seeMany",
    ],
    answers: [],
  },
  {
    code: "age",
    options: ["-18", "18-24", "24-35", "36-49", "50-60", "+60"],
    answer: null,
  },
  {
    code: "tourFrequency",
    options: [
      "more_once_month",
      "once_month",
      "3-4_times_year",
      "once_year",
      "rare_once_year",
    ],
    answer: null,
  },
  {
    code: "interests",
    options: [
      "places",
      "architecture",
      "history",
      "art",
      "theatre",
      "shopping",
      "bars",
      "yacht",
      "art-space",
      "child",
      "photo",
      "walk",
      "nature",
      "inductrial",
    ],
    answers: [],
    maxAnswer: 5,
  },
  {
    code: "introvertedness",
    options: [1, 2, 3, 4, 5],
    answer: null,
  },
  {
    code: "personal_info",
    options: {
      name: "",
    },
  },
];
