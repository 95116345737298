<template>
  <div class="location-nearby">
    <div class="location-nearby__container">
      <yt-button
        class="location-nearby__trigger u-w-full"
        color="gray"
        :disabled="permissionDenied"
        squared
        @click="onTrigger"
      >
        <span class="location-nearby__trigger-container">
          <span class="location-nearby__trigger-icon u-flex-shrink-0">
            <svg-inline tag="span">
              <icon icon="icon-geo-sharp" />
            </svg-inline>
          </span>
          <span
            class="location-nearby__trigger-text font-semibold"
            v-html="$t('locationNearby.trigger.text')"
          ></span>
        </span>
      </yt-button>
      <div
        v-if="permissionDenied"
        class="location-nearby__denied u-text-red u-text-center font-text-small"
        v-html="$t('locationNearby.denied')"
      ></div>
      <div
        v-else
        class="location-nearby__hint u-text-center font-text-small"
        v-html="$t('locationNearby.hint')"
      ></div>
    </div>
    <popup v-model="popups.geo" size="sm">
      <div class="location-nearby__popup">
        <div class="location-nearby__popup-icon">
          <svg-inline>
            <icon icon="geo-circle-error" />
          </svg-inline>
        </div>
        <div
          class="location-nearby__popup-text font-h2 u-text-center"
          v-html="$t('locationNearby.popup.text')"
        ></div>
        <yt-button class="u-w-full" @click="onAsk">
          {{ $t("locationNearby.popup.button") }}
        </yt-button>
      </div>
    </popup>
  </div>
</template>

<script>
import YtButton from "@/components/ui-kit/yt-button/index.vue";
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";
import Popup from "@/components/ui-kit/popup/index.vue";

export default {
  components: {
    YtButton,
    SvgInline,
    Icon,
    Popup,
  },
  data() {
    return {
      permissionState: undefined,
      popups: {
        geo: false,
      },
    };
  },
  computed: {
    permissionGranted() {
      return this.permissionState === "granted";
    },
    permissionDenied() {
      return this.permissionState === "denied";
    },
  },
  mounted() {
    this.checkGeoPermission();
  },
  methods: {
    onTrigger() {
      if (this.permissionDenied) {
        return;
      }

      this.$emit("trigger");

      if (this.permissionGranted) {
        this.getCurrentPosition();
        return;
      }

      this.popups.geo = true;
    },
    onAsk() {
      this.popups.geo = false;
      this.getCurrentPosition();
    },
    getCurrentPosition() {
      this.permissionState = "prompt";
      this.$emit("pending", true);
      if(vueOptions.ssr) {
        return;
      }
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          this.permissionState = "granted";
          this.$emit("get-current-position", coords);
          this.$emit("pending", false);
        },
        () => {
          this.permissionState = "denied";
          this.$emit("pending", false);
        }
      );
    },
    checkGeoPermission() {
      if(vueOptions.ssr) {
        return;
      }
      return navigator.permissions
        ?.query({ name: "geolocation" })
        .then(({ state }) => {
          this.permissionState = state;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.location-nearby {
  &__trigger {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      width: 20px;
      margin-right: 6px;
    }
  }

  &__denied {
    font-weight: normal;
    margin-top: 8px;
  }

  &__hint {
    margin: 5px 0 0 0;
  }

  &__popup {
    * {
      font-family: var.$font-family-sf-pro-text;
    }

    &-icon {
      max-width: 64px;
      margin: 0 auto 16px;
    }

    &-text {
      margin-bottom: 32px;
    }
  }
}
</style>
