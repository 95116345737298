<template>
  <YtPopup :modelValue="visible" @close="handleClose" size="sm">
    <div class="modal-title" v-text="modal.title"></div>
    <div class="modal-description" v-text="modal.description"></div>
    <!-- buttons -->
    <div class="modal-buttons">
      <template v-if="modal.type == 'notification'">
        <YtButton class="modal-button"
                  size="md"
                  @click="handleOk">
          {{$t('ok')}}
        </YtButton>
      </template>
      <!-- yes or not -->
      <template v-else-if="modal.type != 'unsaved'">
        <YtButton class="modal-button"
          size="md"
          @click="handleYes">
          {{$t('yes')}}
        </YtButton>
        <YtButton class="modal-button"
          size="md"
          color="gray"
          outline
          @click="handleNo">
          {{$t('no')}}
        </YtButton>
      </template>
      <template v-else>
        <YtButton class="modal-button"
          size="md"
          @click="handleSave">
          {{ $t('save') }}
        </YtButton>
      </template>
    </div>
  </YtPopup>
</template>

<script>
import YtButton from '@/components/ui-kit/yt-button/index.vue';
import YtPopup from '@/components/ui-kit/yt-popup/index.vue';
import { ytEvents } from "@/plugins/analytics";

export default {
  components: {
    YtButton,
    YtPopup,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    status: {
      type: String,
      required: true,
      default: '',
    },

  },
  methods: {
    handleYes() {
      ytEvents.track('Tour edit page(new)', 'Click discalimer button', null, {
        action: 'yes',
        status: this.status,
      });
      this.$emit('close');
      this.$emit('confirm');
    },
    handleSave() {
      ytEvents.track('Tour edit page(new)', 'Click discalimer button', null, {
        action: 'save',
        status: this.status,
      });
      this.$emit('close');
      this.$emit('confirm');
    },
    handleNo() {
      ytEvents.track('Tour edit page(new)', 'Click discalimer button' , null, {
        action: 'no',
        status: this.status,
      });
      this.$emit('close');
      this.$emit('reject');
    },
    handleOk() {
      this.$emit('close');
      this.$emit('reject');
    },
    handleClose() {

      this.$emit('close');
      this.$emit('reject');
    }
  },
}
</script>

<style>
.modal-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  min-height: 22px;
  text-align: center;
  max-width: 93%;
  margin: 0 auto;
}

.modal-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  line-height: 18px;
  text-align: center;
}

.modal-buttons {
  margin-top: 32px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.modal-button {
  width: 118px;
}
</style>
