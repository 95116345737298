import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";
import { i18n } from "@/plugins/i18n";
import Cookies from "js-cookie";
import dataCollector from "@/plugins/dataCollector/index";
import "@/plugins/dataCollector/index";

const axiosLocations = {
  getLocations(query) {
    return axiosYtApiV2.get(`/locations/search?q=${query}`);
  },

  async getStaticLocations(query) {
    return await axiosYtApiV2.get(`/locations/search?q=${query}`);
  },

  getLocationId(locationId) {
    return axiosYtApiV2.get(`/locations/${locationId}`);
  },
  getLocationInfo(locationId) {
    return axiosYtApiV2.get(`/locations/${locationId}/info`);
  },

  getRegions(countries) {
    return axiosYtApiV2.get(`/locations/regions?countries=${countries}`);
  },
  getList(term = "", type = "", take = 20, sortBy = "rank") {
    let query = `?term=${term}&type=${type}&take=${take}&lang=${i18n.locale}&skip=0&sort_by=${sortBy}`;

    if (!term) {
      let guest_id = Cookies.get("BX_USER_ID");

      if (!guest_id) {
        guest_id = dataCollector.guid();
        Cookies.set("BX_USER_ID", guest_id);
      }

      query = `?guest_id=${guest_id}&take=${5}&lang=${i18n.locale}&skip=0&sort_by=${sortBy}`;
    }

    return axiosYtApiV2.get(`/suggest/locations-with-history${query}`);
  },
  getHistory(userId) {
    return axiosYtApiV2.get(
      `/suggest/getHistory?take=3&skip=0&lang=${i18n.locale}&user=${userId}`
    );
  },
  getLocationsByCountryCode(countryCode) {
    let guest_id = Cookies.get("BX_USER_ID");

    if (!guest_id) {
      guest_id = dataCollector.guid();
      Cookies.set("BX_USER_ID", guest_id);
    }

    const country = countryCode || i18n.locale;
    const query = `?guest_id=${guest_id}&take=${5}&lang=${i18n.locale}&skip=0&country_code=${country.toLowerCase()}`;

    return axiosYtApiV2.get(`/suggest/locations-with-history${query}`);
  },
};

export { axiosLocations };
