import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

const axiosDays = {
  getDays(tourId) {
    return axiosYtApiV2.get(`tours/${tourId}/days`);
  },

  getDay(tourId, dayId) {
    return axiosYtApiV2.post(`tours/${tourId}/days/${dayId}`);
  },

  addDay(tourId, data) {
    return axiosYtApiV2.post(`tours/${tourId}/days`, data);
  },

  updateDay(tourId, dayId, data) {
    return axiosYtApiV2.post(`tours/${tourId}/days/${dayId}/update`, data);
  },

  predictSave(tourId, dayId, data) {
    return axiosYtApiV2.post(`tours/${tourId}/days/${dayId}/predict-save`, data);
  },

  resortDays(tourId, data) {
    return axiosYtApiV2.post(`tours/${tourId}/days/resort`, data);
  },
  deleteDay(tourId, dayId) {
    return axiosYtApiV2.post(`tours/${tourId}/days/${dayId}/delete`);
  },
};

export { axiosDays };
