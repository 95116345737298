const mainMenuRu = {
  mainMenu: {
    tours: 'Путешествия',
    company: 'Компаниям',
    about: 'О нас',
    te: 'Организаторам туров',
    more: 'Узнать больше',
    signUp: 'Зарегистироваться',
    support: 'Помощь',
    helpme: 'Подберите мне тур',
    howItWorks: 'Как это работает'
  }
};

export { mainMenuRu };
